export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';

export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const PASSWORD_CHANGES = 'PASSWORD_CHANGES';

export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';

export const SET_TASK_CURRENT_USER = 'set_task_current_user';
export const SET_TASKS_DATA = 'set_tasks_data';
export const SET_TASK_LIST_DATA = 'set_task_list_data';
export const ADD_TASK = 'add_task';
export const DELETE_TASK = 'delete_task';
export const UPDATE_TASK = 'update_task';
export const SET_FILTER_DATA = 'set_filter_data';
export const ADD_TASK_LIST = 'add_task_list';
export const UPDATE_TASK_LIST = 'update_task_list';
export const DELETE_TASK_LIST = 'delete_task_list';
export const SET_TASK_DETAIL = 'set_task_detail';
export const SEND_MESSAGE = 'send_message';
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed';
export const GET_TASKS_COUNTS = 'get_tasks_counts';

//mail app
export const GET_LABELS_LIST = 'get_labels_list';
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_MAILS_LIST = 'get_mails_list';
export const UPDATE_MAIL_FOLDER = 'update_mail_folder';
export const UPDATE_MAIL_LABEL = 'upade_mail_label';
export const UPDATE_FAVORITE_STATUS = 'update_favorite_status';
export const UPDATE_READ_STATUS = 'update_read_status';
export const UPDATE_IMPORTANT_STATUS = 'update_important_status';
export const COMPOSE_MAIL = 'compose_mail';
export const SET_FILTER_TYPE = 'set_filter_type';
export const GET_SELECTED_MAIL = 'GET_SELECTED_MAIL';
export const UPDATE_SELECTED_MAIL = 'update_selected_mail';
export const NULLIFY_SELECTED_MAIL = 'nullify_selected_mail';
export const REPLY_TO_MAIL = 'reply_to_mail';
export const GET_MAIL_COUNTS = 'get_mail_count';
export const ADD_LABEL = 'add_label';
export const ADD_CONNECTION = 'add_connection';
export const REMOVE_CONNECTION = 'remove_connection';

export const SET_CHAT_USERS = 'set_chat_users';
export const SET_CONTACT_USERS = 'set_contact_users';
export const SET_CURRENT_USER = 'set_current_user';
export const SET_CONVERSATION_DATA = 'set_conversation_data';
export const SEND_NEW_CHAT_MESSAGE = 'send_new_chat_message';
export const SEND_NEW_MEDIA_MESSAGE = 'send_new_media_message';

//Contact App
export const GET_CONTACTS_LIST = 'get_contacts_list';
export const SET_CURRENT_CONTACT = 'set_current_contact';
export const CREATE_CONTACT = 'create_contact';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const DELETE_CONTACT = 'delete_contact';
export const UPDATE_CONTACT_LABEL = 'update_contact_label';
export const UPDATE_CONTACT = 'update_contact';
export const GET_CONTACT_COUNTS = 'get_contact_counts';
export const UPDATE_LABEL_ITEM = 'update_label_item';
export const DELETE_LABEL_ITEM = 'delete_label_item';

export const GET_USER_DETAIL = 'get_user_detail';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_POST = 'create_post';
export const UPDATE_POST = 'update_post';

// Users Module
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_BULK_USERS = 'DELETE_BULK_USERS';

export const SET_VERIFY_USER = 'set_verify_user';

export const GET_GENRE_LIST = 'GET_GENRE_LIST';

export const GET_VERIFICATION_REQUEST = 'GET_VERIFICATION_REQUEST';
export const GET_PAGE_VERIFICATION_REQUEST_LIST = 'GET_PAGE_VERIFICATION_REQUEST_LIST';

//Dashboard
export const SAVE_DASHBOARD_DATA = 'save_dashboard_data';
export const SAVE_POPULER_PROFILES_DATA = 'save_populer_profiles_data';
export const SAVE_RECENT_FLAG_DATA = 'save_recent_flag_data';
export const SAVE_POPULER_POSTS_DATA = 'save_populer_posts_data';
export const APPEND_POPULER_POSTS_DATA = 'append_populer_posts_data';
export const SAVE_POPULER_POSTS_META_DATA = 'save_populer_posts_meta_data';
export const SAVE_POST_CATEGORIES_DATA = 'save_post_categories_data';

//User Management
export const SAVE_USERS_LIST_DATA = 'save_users_list_data';
export const SAVE_USER_PROFILE_DATA = 'save_user_profile_data';
export const SAVE_USER_POSTS_DATA = 'save_user_posts_data';
export const SAVE_USER_MORE_POSTS_DATA = 'save_user_more_posts_data';
export const DELETE_USER_POST = 'delete_user_post';
export const EDIT_USER_LIST = 'edit_user_list';
export const EDIT_PROFILE_DETAIL = 'edit_profile_detail';
export const SAVE_POST_COMMENTS_DATA = 'save_post_comments_data';
export const SAVE_COMMENTS_META = 'save_comments_meta';
export const APPEND_USER_POSTS_DATA = 'append_user_posts_data';
export const USER_MORE_POSTS_META_DATA = 'user_more_posts_meta_data';

//Reports Mangement
export const SAVE_REPORTS_LIST_DATA = 'save_reports_list_data';
export const SAVE_FLAG_TYPES_LIST_DATA = 'save_flag_types_list_data';

export const APPEND_REPORTS_LIST_DATA = 'append_reports_list_data';
//Reward Management
export const GET_REWARD_LIST_DATA = 'get_reward_list_data';
export const GET_REWARD_LIST_META = 'get_reward_list_meta';

export const ADD_REWARD_LIST_DATA = 'add_reward_list_data';
export const REWARD_COIN_LIST_DATA = 'reward_coin_list_data';
export const GET_REWARD_TYPES_LIST = 'get_reward_types_list';
export const GET_COIN_LIST_DATA = 'get_coin_list_data';

//Airdrop Management
export const GET_AIRDROP_LIST_DATA = 'get_airdrop_list_data';

//Content Management

export const GET_CONTENT_TYPE = 'get_content_type';
export const ADD_CONTENT_TYPE = 'add_content_type';
export const EDIT_CONTENT_TYPE = 'edit_content_type';
export const DELETE_CONTENT_TYPE = 'delete_content_type';
export const GET_CONTENT_POSTS_DATA = 'get_content_posts_data';
export const GET_CONTENT_FILTER_POSTS_DATA = 'get_content_filter_posts_data';

export const CLEAR_CONTENT_POSTS_FILTER = 'clear_content_filter_posts_data';
export const GET_CONTENT_USER_LIST = 'get_content_user_list';
export const GET_INTEREST_TYPE = 'get_interest_type';

export const GET_HASHTAGS_LIST = 'get_hash_tags_list';
export const ADD_HASHTAGS_TYPE = 'add_hash_tags_type';
export const EDIT_HASHTAGS_TYPE = 'edit_hash_tags_type';
export const GET_CONTENT_TITLE_LIST = 'get_content_title_list';

// ADMIN WALLET

export const GET_LOCKED_FUNDS_DATA = 'get_locked_funds_data';
export const UPDATE_WALLET_fUNDS_DATA = 'update_wallet_funds_data';
export const GET_WALLET_USER_LIST_DATA = 'get_wallet_user_list_data';
export const SUCCESS_UPLOAD_CSV = 'success_upload_csv';

export const STORY_LIST_DATA = 'story_list_data';
export const GET_REDEEM_HISTORY = 'get_redeem_history';

export const POST_DETAIL_SUCCESS = 'post_detail';

//APPS SETTING

export const GET_SETTING_LIST = 'get_setting_list';
export const ADD_SETTING_TYPE = 'add_setting_type';
export const EDIT_SETTING_TYPE = 'edit_setting_type';

//FOLLOWERS
export const USERS_FOLLOWING_DATA = 'user_following_data';
export const USERS_FOLLOWERS_DATA = 'users_followers_data';

//BUSINESS PAGES

export const SAVE_PAGE_LIST_DATA = 'save_page_list_data';
export const SAVE_PAGE_CATEGORIES_LIST_DATA = 'SAVE_PAGE_CATEGORIES_LIST_DATA';
export const PAGE_FOLLOWING_DATA = 'PAGE_FOLLOWING_DATA';
export const PAGE_FOLLOWER_DATA = 'PAGE_FOLLOWER_DATA';
export const CHANGE_STATUS_PAGE_PROFILE = 'CHANGE_STATUS_PAGE_PROFILE';
export const SAVE_PAGE_POSTS_DATA = 'SAVE_PAGE_POSTS_DATA';
export const PAGE_STORY_LIST_DATA = 'PAGE_STORY_LIST_DATA';
export const PAGE_FOLLOWERS_DATA = 'PAGE_FOLLOWERS_DATA';
export const PAGE_PROFILE_LIST_DATA = 'PAGE_PROFILE_LIST_DATA';
export const PAGE_MORE_POSTS_META_DATA = 'PAGE_MORE_POSTS_META_DATA';
export const APPEND_PAGE_POSTS_DATA = 'APPEND_PAGE_POSTS_DATA';
export const SAVE_PAGE_POST_COMMENTS_DATA = 'SAVE_PAGE_POST_COMMENTS_DATA';
export const SAVE_PAGE_POST_COMMENTS_META = 'SAVE_PAGE_POST_COMMENTS_META';
