import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "constant/constant";
import { useDispatch, useSelector } from "react-redux";
import { AuhMethods } from "services/auth";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export const SocialContext = createContext();

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");

  return accessToken ? true : false;
}

export default function SocialAuth(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [subAdminAssign, setsubadminAssign] = useState([]);
  const MySwal = withReactContent(Swal);
  const sweetAlerts = (row) => {
    MySwal.fire({
      title: "Account Blocked",
      icon: "error",
      html: <i>Your Account has been blocked by admin</i>,
      //   onOpen: () => {
      //     SwalModal.clickConfirm();
      //   },
      // }).then(() => {
      //   return SwalModal.fire("<p>Shorthand works too</p>");
    });
  };
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.admin?.authUser?.access_token);
  // console.log("token ----", token);
  const TaralICODetails = async ({ token }) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}admin/detail`,
        headers: {
          Authorization: token,
        },
      }).then(({ data }) => {
        if (data.data) {
          // console.log("data ----", data.data.status);
          if (data.data.status == "0") {
            // alert("Your has been blocked by admin!");

            sweetAlerts();
            dispatch(AuhMethods["admin"].onLogout());
          } else {
          }
          setUserData(data.data);
        } else {
        }
        if (data.permissionData) {
          setsubadminAssign(data.permissionData);
        } else {
        }
      });
    } catch (error) {}
    // };
  };
  useEffect(() => {
    TaralICODetails({
      token: token,
    });
  }, [token]);
  let data = {
    userLoggedIn: isLogin,
    userData,
    subAdminAssign,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
  };

  return (
    <SocialContext.Provider value={data}>
      {props.children}
    </SocialContext.Provider>
  );
}
