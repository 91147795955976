import {
  SAVE_REPORTS_LIST_DATA,
  SAVE_FLAG_TYPES_LIST_DATA,
  APPEND_REPORTS_LIST_DATA,
} from '../../@jumbo/constants/ActionTypes';

export const setReportsListData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_REPORTS_LIST_DATA,
      payload: data,
    });
  };
};

export const setFlagTypesListData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_FLAG_TYPES_LIST_DATA,
      payload: data,
    });
  };
};

export const appendPopulerPostsData = data => {
  return dispatch => {
    dispatch({
      type: APPEND_REPORTS_LIST_DATA,
      payload: data,
    });
  };
};
