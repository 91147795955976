/* eslint-disable no-lone-blocks */
import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import React from "react";
import axios from "../../config";
import {
  getLockedfundList,
  setWalletListData,
  updateWalletFunds,
  getRedeemHistory
} from "redux/actions/Wallet";
import { AuhMethods } from "..";

//Dashboard apis
const WalletManagementServicesAuth = {
  //Dashboard Details API
  getWalletListAPI: ({
    token,
    page,
    perPage,
    keyword,
    status,
    from_date,
    to_date
  }) => {
    const pageData = page != undefined ? "&page=" + (page + 1) : "";
    const statusData = status != undefined ? "&status=" + status : "";
    const keywordData = keyword != undefined ? "&keyword=" + keyword : "";

    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            "admin/wallet/list?" +
              keywordData +
              "&perPage=" +
              perPage +
              pageData +
              statusData +
              "&from_date=" +
              from_date +
              "&to_date=" +
              to_date,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(setWalletListData(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response.data.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  getWalletReedemBalanceListAPI: ({
    token,
    page,
    perPage,
    keyword,
    params
  }) => {
    const pageData = page != undefined ? "&page=" + (page + 1) : "";
    // const keywordData = keyword != undefined ? '&keyword=' + keyword : '';

    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/wallet/redeem_history/${params}?order=${"desc"}${pageData}&perPage=${perPage}`
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(getRedeemHistory(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response.data.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Populer Profiles API
  updateUserWalletFundAPI: ({
    token,
    params,
    page,
    rowsPerPage,
    searchText
  }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put("admin/wallet/update_funds", params)
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data?.message));
              setTimeout(() => {
                dispatch(
                  AuhMethods["walletManagement"].getWalletListAPI({
                    token: token,
                    page: page,
                    perPage: rowsPerPage,
                    keyword: searchText
                  })
                );
              }, 1000);

              // dispatch(updateWalletFunds(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Populer Posts API
  lockedFundList: ({ token, page, perPage, keyword }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/wallet/locked_funds_list?keyword=${
              keyword ? keyword : ""
            }&page=${page}&perPage=${perPage}&order=desc`,
            {},
            { withCredentials: true }
          )
          // .get('admin/wallet/locked_funds_list', params)
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(getLockedfundList(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  }
};

export default WalletManagementServicesAuth;
