import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuhMethods } from 'services/auth';
import { useLocation } from 'react-router';

const EditContent = () => {
  const [state, setState] = useState('');
  const token = useSelector(state => state.admin.authUser.access_token);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [contentData, setContentData] = useState(location?.state?.row);

  useEffect(() => {
    setState(location?.state?.row?.content);
  }, [location?.state?.row?.content]);
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setState(data);
  };

  const handleSubmit = () => {
    let data = { id: contentData?.id, content: state };
    dispatch(AuhMethods['contentManagement'].updateContentTitleAPI(token, data));
    setTimeout(() => {
      history.goBack();
    }, 2500);
  };

  return (
    <React.Fragment>
      <CKEditor editor={ClassicEditor} activeClass="p10" data={contentData?.content} onChange={handleChange} />{' '}
      <Box display="flex" justifyContent="flex-start" mb={5}>
        <Box marginTop={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            // disabled={location?.state?.row?.content.toString() == state.toString()}
          >
            Update
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default EditContent;
