import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MoreHoriz, Close, Done, ViewAgenda } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { capitalizeFLetter } from "@jumbo/utils/commonHelper";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const UserListRow = ({ row, page, idx, changeStatus }) => {
  // console.log("row------", row);
  const classes = useStyles();

  // *********** option for Page ***********************
  const getUserActions = () => {
    const actions = [
      // row.status === "0"
      //   ? { action: "enable", label: "Enable", icon: <Done /> }
      //   : row.status === "1"
      //   ? { action: "Disable", label: "Disable", icon: <Close /> }
      //   : row.status === "2"
      //   ? { action: "accepted", label: "APPROVE", icon: <Done /> }
      //   : {},
      { action: "View", label: "View", icon: <VisibilityIcon /> }
      // { action: "rejected", label: "Edit", icon: <EditIcon /> },
    ];
    // if (row.status === "0") {
    //   actions.push({ action: "rejected", label: "REJECT", icon: <Close /> });
    // }

    return actions;
  };

  const onUserMenuClick = (menu, row) => {
    changeStatus(menu.action, menu.label, row?.id, row);
  };

  const labelId = `enhanced-table-checkbox-${row.user_id}`;
  const userActions = getUserActions();
  const {
    details,
    inf_details,
    user_details,
    amount,
    status,
    admin_commision
  } = row;
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
      <TableCell>{page * 10 + idx + 1}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {inf_details.name === null ? "N/A" : inf_details.name}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {user_details?.name === null ? "N/A" : user_details?.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {amount === null ? "N/A" : `${amount} INR`}
        </Typography>
      </TableCell>
      <TableCell>
        {details?.start_date
          ? moment(details?.start_date).format("lll")
          : "N/A"}
      </TableCell>
      <TableCell>{admin_commision && `${admin_commision} INR`}</TableCell>
      <TableCell>{status && capitalizeFLetter(status)}</TableCell>

      <TableCell align="center">
        <CmtDropdownMenu
          row={row}
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
