import React, { useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";

import CmtVertical from "../../../../../@coremat/CmtNavigation/Vertical";
import { sidebarNavs } from "../menus";
import { SocialContext } from "../../../../../context/SocialAuth";
import { useContext, useEffect } from "react";

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: "100%",
    transition: "all 0.3s ease",
    ".Cmt-sidebar-fixed &, .Cmt-Drawer-container &": {
      height: "calc(100% - 167px)",
    },
    ".Cmt-modernLayout &": {
      height: "calc(100% - 72px)",
    },
    ".Cmt-miniLayout &": {
      height: "calc(100% - 91px)",
    },
    ".Cmt-miniLayout .Cmt-sidebar-content:hover &": {
      height: "calc(100% - 167px)",
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const auth = useContext(SocialContext);
  const [subadminAssign, setsubadminAssign] = useState([]);
  // console.log("sidebarNavs --->>", auth.userData);

  useEffect(() => {
    let menuItems1 = [];
    if (auth.subAdminAssign.length >= 1) {
      for (var i = 0; i < sidebarNavs.length; i++) {
        for (var k = 0; k < auth.subAdminAssign.length; k++) {
          if (sidebarNavs[i].title == auth.subAdminAssign[k].route_name) {
            menuItems1.push(sidebarNavs[i]);
          }
          // if (
          //   i > 0 &&
          //   sidebarNavs[i - 1].title !== "account-settings" &&
          //   sidebarNavs[i].title == "account-settings"
          // ) {
          //   menuItems1.push(sidebarNavs[i]);
          // }
        }
        if (sidebarNavs[i].title == "account-settings") {
          menuItems1.push(sidebarNavs[i]);
        }
      }
    }
    setsubadminAssign(menuItems1);
  }, [auth.subAdminAssign, sidebarNavs]);

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      {/* For Sub Admin */}
      {auth.userData.role == "Subadmin" && (
        <CmtVertical menuItems={subadminAssign} />
      )}
      {/* For Admin */}
      {auth.userData.role != "Subadmin" && (
        <CmtVertical menuItems={sidebarNavs} />
      )}
    </PerfectScrollbar>
  );
};

export default SideBar;
