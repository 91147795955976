import React, { useEffect, useState } from "react";
import GridContainer from "../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import GeneralInfo from "./GeneralInfo";
import Activities from "./Activities";
import NewsFeed from "./NewsFeed";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FilterOptionsHeader from "./FilterOptionsHeader";
import ContentFilter from "./ContentFilter";
import { AuhMethods } from "services/auth";
import { clearContentFilter } from "redux/actions/ContentManagement";

const useStyles = makeStyles(theme => ({
  wallLeftSidebar: {
    "@media screen and (min-width: 960px) and (max-width: 1020px)": {
      flexBasis: "40%",
      maxWidth: "40%"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  wallMainContent: {
    "@media screen and (min-width: 960px) and (max-width: 1020px)": {
      flexBasis: "60%",
      maxWidth: "60%"
    }
  },
  wallRightSidebar: {
    "@media screen and (max-width: 1020px)": {
      display: "none"
    }
  }
}));

const ContentDetails = () => {
  const [page, setPage] = useState(1);
  const [filterContent, setFilterContent] = useState({});
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tabValue] = useState("");
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const token = useSelector(state => state.admin.authUser.access_token);
  const contentUserList = useSelector(
    state => state?.contentManagement?.contentUserList
  );

  const contentPostListMeta = useSelector(
    state => state?.contentManagement?.ContentPostsDataMeta
  );

  const userDetail = contentUserList;
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {}, [contentUserList]);
  useEffect(() => {
    dispatch(
      AuhMethods["contentManagement"].getContentUserListAPI({
        token: token,
        page: page,
        perPage: 10
        // keyword: searchText,
      })
    );

    dispatch(
      AuhMethods["contentManagement"].contentTypesListAPI({
        token,
        page: page,
        perPage: 10 //keyword: searchText
      })
    );
  }, []);

  const clearFilters = () => {
    setFilterContent({});
    setOpenFilterDialog(false);
    dispatch(clearContentFilter());
    dispatch(
      AuhMethods["contentManagement"].getContentPostsListAPI({
        token: token,
        categoryId: tabValue,
        page: 1,
        perPage: 10,
        params: {}
      })
    );
  };
  const onFilter = () => {
    setOpenFilterDialog(true);
  };

  useEffect(() => {
    let id = setTimeout(() => {
      if (id) {
        getSearch();
      }
    }, 1000);

    return () => {
      clearInterval(id);
      dispatch(clearContentFilter([]));
    };
  }, [searchValue]);

  const onSearch = (search, event) => {
    setSearchValue(search);
  };

  const getSearch = () => {
    dispatch(
      AuhMethods["contentManagement"].getContentUserListAPI({
        token: token,
        page: page,
        perPage: 10,
        keyword: searchValue
      })
    );
  };

  const handleCloseUserDialog = () => {
    setOpenFilterDialog(false);
  };

  const onSubmitClick = data => {
    setFilterContent(data);
    dispatch(clearContentFilter([]));
    dispatch(
      AuhMethods["contentManagement"].getContentFilterPostsListAPI({
        token: token,
        page: page,
        perPage: 10,
        keyword: "",
        order: "",
        sortBy: "", //selectedTab,
        params: data
      })
    );

    setOpenFilterDialog(false);
  };

  const handleScroll = e => {
    if (Array.isArray(contentPostListMeta)) {
      if (page != contentPostListMeta[0]?.pages) {
        dispatch(
          AuhMethods["contentManagement"].getContentPostsListAPI({
            token: token,
            categoryId: tabValue,
            page: page,
            perPage: 10,
            params: filterContent
          })
        );
        setPage(page + 1);
      }
    }
  };
  return (
    <React.Fragment>
      {userDetail && (
        <div className="customHeightSetting">
          <div>
            <FilterOptionsHeader
              classes={classes}
              userDetail={{ name: "abc" }}
              tabValue={tabValue}
              onFilter={onFilter}
              onSearch={onSearch}
            />
          </div>
          <div>
            <GridContainer>
              <Grid
                item
                xs={12}
                sm={5}
                md={3}
                className={classes.wallLeftSidebar}
              >
                <GeneralInfo userDetail={userDetail} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
                md={6}
                className={classes.wallMainContent}
              >
                <NewsFeed handleScroll={handleScroll} />
              </Grid>
              <Grid item xs={12} md={3} className={classes.wallRightSidebar}>
                <Activities />
              </Grid>
            </GridContainer>
          </div>
        </div>
      )}
      {openFilterDialog && (
        <ContentFilter
          open={openFilterDialog}
          handleCloseUserDialog={handleCloseUserDialog}
          onSubmitClick={onSubmitClick}
          isEdit={false}
          filterContent1={filterContent}
          clearFilters={clearFilters}
        />
      )}
    </React.Fragment>
  );
};

export default ContentDetails;
