import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import React from "react";
import axios from "../../config";

import { resolve } from "path";
import { reject } from "lodash";
import { AuhMethods } from "..";

import { getSettingList } from "redux/actions/AppSetting";

//Content Management apis
const AppSettingsAuth = {
  //Content Type API  && interest type api
  settingListAPI: ({ token, page, perPage, order }) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/settings/list?order=${
              order ? order : "asc"
            }&perPage=${perPage}&page=${page + 1}`
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data?.message));
              dispatch(fetchSuccess());
              dispatch(getSettingList(data));

              // dispatch(AuhMethods['contentManagement'].reportsListAPI({ token, page, perPage }));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Add or Edit Setting List Api
  addOrEditSettingTypeAPI: (token, namees, params, page, perPage, order) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .post(
            `admin/settings/add_update
          `,
            params
          )
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));
              setTimeout(() => {
                dispatch(
                  AuhMethods["AppSettings"].settingListAPI({
                    token,
                    page: page,
                    perPage: perPage,
                    order: order,
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
          })
          .catch(function(error) {
            console.log(error);
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },
};
export default AppSettingsAuth;
