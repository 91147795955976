import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { Checkbox } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  authContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AssignResponsibilityCheckbox = ({
  name,
  label,
  assigned,
  index,
  permitions,
  setpermitions,
  Checked,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
  });

  const handleChange = (e) => {
    try {
      // if (event.target.name !== "checkedA") {
      //   setState({ ...state, [event.target.name]: event.target.checked });
      // } else {
      console.log(e.target.checked, "permitions -----assigned------", assigned);
      let permissions = [...permitions];
      // console.log("permissions ---->>", permissions);
      if (e.target.checked) {
        permissions[index] = assigned;
        // this.setState({ permissions: permissions });
      } else {
        permissions[index] = 0;
        // this.setState({ permissions: permissions });
      }
      setpermitions(permissions);
      console.log("permitions -----assigned------", permissions);
      //   for (let j = 0; j < permitions.length; j++) {
      //     // console.log(permitions[j], "permitions -----assigned------", assigned);
      //     if (permitions[j] != assigned) {
      //       console.log(
      //         permitions[j],
      //         "permitions -----assigned------",
      //         assigned
      //       );
      //       setState({
      //         checkedA: true,
      //         checkedB: true,
      //         checkedC: true,
      //       });
      //       let values = [...permitions];
      //       values.push(assigned);
      //       setpermitions(values);
      //       console.log("error", values);
      //     } else {
      //       setState({
      //         checkedA: false,
      //         checkedB: false,
      //         checkedC: false,
      //       });
      //       let listPer = [...permitions];
      //       listPer.forEach((element, i) => {
      //         if (assigned == element) {
      //           listPer.splice(i, 1);
      //         }
      //       });
      //       setpermitions(listPer);
      //     }
      //   }
      // }
    } catch (error) {
      console.log("error---", error);
    }
  };
  //   const handleChange = (event) => {
  //     if (event.target.name !== "checkedA") {
  //       setState({ ...state, [event.target.name]: event.target.checked });
  //     } else {
  //       if (!state.checkedA) {
  //         setState({
  //           checkedA: true,
  //           checkedB: true,
  //           checkedC: true,
  //         });
  //         let values = [...permitions];
  //         values.push(assigned?.id);
  //         setpermitions(values);
  //         console.log("error", values);
  //       } else {
  //         setState({
  //           checkedA: false,
  //           checkedB: false,
  //           checkedC: false,
  //         });
  //         let listPer = [...permitions];
  //         listPer.forEach((element, i) => {
  //           if (assigned?.id == element) {
  //             listPer.splice(i, 1);
  //           }
  //         });
  //         setpermitions(listPer);
  //       }
  //     }
  //   };

  const token = useSelector((state) => state.admin.authUser.access_token);
  return (
    <FormControlLabel
      control={
        <Checkbox
          //   checked={state.checkedA}
          //   checked={Checked}
          checked={
            permitions[index] !== undefined &&
            permitions[index] != -1 &&
            permitions[index] == assigned
              ? true
              : false
          }
          onChange={(e) => handleChange(e)}
          name={"checkedA"}
        />
      }
      label={label}
    />
  );
};

export default AssignResponsibilityCheckbox;

AssignResponsibilityCheckbox.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
