import React, { useContext, useEffect, useState, useRef } from "react";
import PostsList from "./PostsList";
import { useSelector, useDispatch } from "react-redux";
import { getWallHeight } from "../../../../@jumbo/constants/AppConstants";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PerfectScrollbar from "react-perfect-scrollbar";
import AppContext from "../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext";
import PostsTabOptions from "./PostsTabOptions";
import { AuhMethods } from "services/auth";

const useStyles = makeStyles(() => ({
  perfectScrollbarNewsFeed: {
    height: props => `calc(100vh - ${props.height}px)`
  }
}));

const NewsFeed = ({ handleScroll }) => {
  const listInnerRef = useRef(null);
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token);
  const classes = useStyles({
    height: getWallHeight(200, showFooter)
  });
  const [tabValue, setTabValue] = useState("latestPosts");
  useEffect(() => {
    getPostsDetail("");
  }, []);

  const handleTabChange = (event, newValue) => {
    let typePost = newValue === "mostLikedPosts" ? "most_liked" : "";

    setTabValue(newValue);
    getPostsDetail(typePost);
  };

  const getPostsDetail = sort => {
    dispatch(
      AuhMethods["contentManagement"].getContentPostsListAPI({
        token: token,
        page: 1,
        perPage: 10,
        sortBy: sort
      })
    );
  };

  const onScroll = e => {
    let el = document.getElementById("kk");
    if (
      parseInt(el.scrollHeight - el.scrollTop) === parseInt(el.clientHeight)
    ) {
      handleScroll();
    }
  };

  return (
    <PerfectScrollbar
      id="kk"
      className={classes.perfectScrollbarNewsFeed + "prefect_scroll_data"}
      onScroll={onScroll}
    >
      <PostsTabOptions
        classes={classes}
        userDetail={{ name: "abc" }}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
      />
      <PostsList page={1} ref={listInnerRef} />
    </PerfectScrollbar>
  );
};

export default NewsFeed;
