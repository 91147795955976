import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 27,
    left: "calc(-50% + 40px)",
    right: "calc(50% + 40px)",
    "@media (max-width: 480px)": {
      top: 24,
      left: "calc(-50% + 30px)",
      right: "calc(50% + 30px)"
    }
  },
  active: {
    color: "black",
    "& $line": {
      backgroundImage: "linear-gradient(108.43deg, #3f51b5 0%, #3f51b521 100%)"
    }
  },
  completed: {
    color: "#fafafa",
    "& $line": {
      backgroundImage: "linear-gradient(108.43deg, #3f51b5 0%, #3f51b521 100%)"
    }
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: "#0f08084d",
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  main: {
    border: "2px solid rgba(255, 255, 255, 0.1)",
    borderRadius: "100%"
  },
  root: {
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    border: "2.5px solid rgb(10 10 10)",
    zIndex: 1,
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },

  active: {
    color: "#fff",
    background: "#3f51b5"
  },
  completed: {
    color: "#fff",
    background: "#3f51b5"
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: "1",
    2: "2",
    3: "3"
  };

  return (
    <div
      className={clsx(classes.main, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed
        })}
      >
        {icons[String(props.icon)]}
      </div>
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiStepper-root": {
      [theme.breakpoints.down("xs")]: {
        padding: "24px 0px"
      }
    },
    "& .MuiStepLabel-label": {
      color: "#fff !important"
    }
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function getSteps() {
  return ["Step 1", "Step 2", "Step 3"];
}

export default function CustomizedSteppers({ activeStep }) {
  const classes = useStyles();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div></div>
    </div>
  );
}
