import React, { useEffect, useState } from "react";
import { TextField, TextareaAutosize } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ContentLoader from "@jumbo/components/ContentLoader";
import AuthWrapper from "@jumbo/components/Common/authComponents/AuthWrapper";
// import { AuhMethods } from "services/auth";
import { alpha } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtAvatar from "@coremat/CmtAvatar";
import { useDropzone } from "react-dropzone";
import Button from "@material-ui/core/Button";
import CmtList from "@coremat/CmtList";
import IconButton from "@material-ui/core/IconButton";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { emailNotValid, requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useForm, Controller } from "react-hook-form";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { isValidEmail } from "@jumbo/utils/commonHelper";
import { addNewUser, updateUser } from "redux/actions/Users";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { AuhMethods } from "services/auth";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
// import RejectDialog from "../RejectDialog";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { usersModule } from "@fake-db";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordMisMatch } from "@jumbo/constants/ErrorMessages";
import * as yup from "yup";
import axios from "axios";
import { API_URL } from "constant/constant";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
// import ConfirmDialog from 'commanComponent/confirm';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  },
  authContent: {
    padding: 30,
    "& .btnB": {
      margin: "0 15px"
    },
    "& .inputPasswordCustom1": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start"
    },
    [theme.breakpoints.up("md")]: {
      order: 1,
      width: props => (props.variant === "default" ? "50%" : "100%")
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  },
  textFieldRoot1: {
    maxWidth: "530px",
    minWidth: "330px",
    height: "fit-content !important",
    minHeight: "230px",
    width: "100%",
    padding: "15px",
    borderColor: alpha(theme.palette.common.dark, 0.12)
    // "& .MuiOutlinedInput-notchedOutline": {

    // },
  }
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);
  // useEffect(() => {
  // }, []);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return (
    <NumberFormat
      {...other}
      onValueChange={onNumberChange}
      value={phoneNumber}
      format="(###) ###-####"
    />
  );
}

const labels = [
  { title: "Posts", slug: "posts" },
  { title: "Likes", slug: "likes" },
  { title: "Comments", slug: "comments" }
];

const FundType = [
  { title: "Locked", type: "locked" },
  { title: "Unlocked", type: "unlocked" }
];

const splitName = user => {
  if (user) {
    const [fName, mName, lName] = user.name.split(" ");
    return [fName, lName ? mName + " " + lName : mName];
  }

  return ["", ""];
};

const schema = yup.object().shape({
  planName: yup
    .string()
    .required("This field is required.")
    .min(4, "Must contain 4 characters. "),

  description: yup
    .string()
    .required("This field is required.")
    .min(8, "Must Contain 8 Characters ")
    .max(256, "Must be less than 256 Characters"),
  featureList: yup.string().required("This field is required."),
  planAmount: yup.string().required("This field is required."),
  planAmountInInr: yup.string().required("This field is required."),
  planAmountInTaral: yup.string().required("This field is required.")
});

const AddBostSubscription = ({ wrapperVariant = "default" }) => {
  const classes = useStyles();
  let types = window.location;
  const type = types.hash.split("#")[1];
  const idds = types.search.split("?")[1];
  const token = useSelector(state => state.admin.authUser.access_token);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      planName: "",
      planAmount: "",
      planAmountInInr: "",
      featureList: "",
      planAmountInTaral: "",
      description: ""
    },
    resolver: yupResolver(schema)
  });
  const history = useHistory();

  const dispatch = useDispatch();

  const onSubmit = async data => {
    if (type !== "Edit") {
      AddNewBoost(data);
    } else {
      UpdateNewBoost(data);
    }
  };
  const UpdateNewBoost = async data => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      await axios({
        method: "PUT",
        url: `${API_URL}admin/boost_plan/update`,
        headers: {
          Authorization: token
        },
        data: {
          id: idds,
          name: data.planName,
          description: data.description,
          total_count: data.featureList,
          total_inr: data.planAmount,
          inr: data.planAmountInInr,
          taral: data.planAmountInTaral
        }
      }).then(({ data }) => {
        if (data.data) {
          dispatch(fetchSuccess());
          history.goBack();
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  const AddNewBoost = async data => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      await axios({
        method: "POST",
        url: `${API_URL}admin/boost_plan/create`,
        headers: {
          Authorization: token
        },
        data: {
          name: data.planName,
          description: data.description,
          total_count: data.featureList,
          total_inr: data.planAmount,
          inr: data.planAmountInInr,
          taral: data.planAmountInTaral
        }
      }).then(({ data }) => {
        if (data.data) {
          dispatch(fetchSuccess());
          history.goBack();
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  const ViewAPIHandlet = async data => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      await axios({
        method: "GET",
        url: `${API_URL}admin/boost_plan/details`,
        headers: {
          Authorization: token
        },

        params: {
          id: data
        }
      }).then(({ data }) => {
        if (data.data) {
          dispatch(fetchSuccess());
          setValue("planName", data.data.name);
          setValue("description", data.data.description);
          setValue("featureList", data.data.total_count);
          setValue("planAmount", data.data.total_inr);
          setValue("planAmountInInr", data.data.inr);
          setValue("planAmountInTaral", data.data.taral);
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  useEffect(() => {
    if (type && idds) {
      ViewAPIHandlet(idds);
    }
  }, [type, idds]);

  return (
    <AuthWrapper variant={wrapperVariant}>
      <div>
        <Box className={classes.authContent}>
          <Typography
            component="div"
            variant="h1"
            className={classes.titleRoot}
          >
            Add Boost Plan
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={2}>
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                {<IntlMessages id="appModule.planName" />}
              </Typography>
              <Controller
                name="planName"
                control={control}
                render={({ value }) => {
                  return (
                    <TextField
                      disabled={type === "View"}
                      name="planName"
                      label={<IntlMessages id="appModule.planName" />}
                      fullWidth
                      type={"text"}
                      onChange={event => {
                        if (event.target.value.length <= 26) {
                          setValue("planName", event.target.value);
                        }
                      }}
                      value={getValues("planName")}
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldRoot}
                      error={errors.planName?.message}
                      helperText={errors.planName?.message}
                    />
                  );
                }}
              />
              {/* <p style={{ color: "red" }}>{errors.planName?.message}</p> */}
            </Box>
            <Box mb={2} className="inputPasswordCustom">
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                {<IntlMessages id="appModule.planAmountInInr" />}
              </Typography>
              <Controller
                name="planAmount"
                control={control}
                render={() => (
                  <TextField
                    disabled={type === "View"}
                    type={"number"}
                    label={<IntlMessages id="appModule.planAmountInInr" />}
                    fullWidth
                    value={getValues("planAmount")}
                    onChange={event => {
                      setValue("planAmount", event.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    error={errors.planAmount?.message}
                    helperText={errors.planAmount?.message}
                  />
                )}
              />

              {/* <p style={{ color: "red" }}>{errors.planAmount?.message}</p> */}
            </Box>

            <Box mb={2} className="inputPasswordCustom">
              <Typography
                component="div"
                variant="h1"
                className={classes.titleRoot}
              >
                {<IntlMessages id="appModule.planAmountInInrTaral" />}
              </Typography>
              <Box className="inputPasswordCustom1">
                <Controller
                  name="planAmountInInr"
                  control={control}
                  // rules={{ required: true }}

                  render={() => (
                    <TextField
                      disabled={type === "View"}
                      type={"number"}
                      label={<IntlMessages id="appModule.planAmountInInr" />}
                      fullWidth
                      value={getValues("planAmountInInr")}
                      onChange={event => {
                        setValue("planAmountInInr", event.target.value);
                      }}
                      // helperText={confirmPasswordError}
                      error={errors.planAmountInInr?.message}
                      helperText={errors.planAmountInInr?.message}
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldRoot}
                    />
                  )}
                />

                {/* <p style={{ color: "red" }}>
                  {errors.planAmountInInr?.message}
                </p> */}
                <Controller
                  name="planAmountInTaral"
                  control={control}
                  // rules={{ required: true }}

                  render={() => (
                    <TextField
                      disabled={type === "View"}
                      type={"number"}
                      label={<IntlMessages id="appModule.planAmountInTaral" />}
                      fullWidth
                      value={getValues("planAmountInTaral")}
                      onChange={event => {
                        setValue("planAmountInTaral", event.target.value);
                        // setConfirmPasswordError("");
                      }}
                      // helperText={confirmPasswordError}
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldRoot}
                      error={errors.planAmountInTaral?.message}
                      helperText={errors.planAmountInTaral?.message}
                    />
                  )}
                />

                {/* <p style={{ color: "red" }}>
                  {errors.planAmountInTaral?.message}
                </p> */}
              </Box>
            </Box>
            <Box mb={2} className="inputPasswordCustom">
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                {<IntlMessages id="appModule.featureList" />}
              </Typography>
              <Controller
                name="featureList"
                control={control}
                render={() => (
                  <TextField
                    disabled={type === "View"}
                    type={"number"}
                    label={<IntlMessages id="appModule.featureList" />}
                    fullWidth
                    value={getValues("featureList")}
                    onChange={event => {
                      setValue("featureList", event.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    error={errors.featureList?.message}
                    helperText={errors.featureList?.message}
                  />
                )}
              />

              <p style={{ color: "#000" }}>
                <IntlMessages id="appModule.featureMessage" />
              </p>
              {/* <p style={{ color: "red" }}>{errors.featureList?.message}</p> */}
            </Box>
            <Box mb={2} className="inputPasswordCustom">
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                {<IntlMessages id="appModule.description" />}
              </Typography>
              <Controller
                name="description"
                control={control}
                render={() => (
                  <TextareaAutosize
                    disabled={type === "View"}
                    type={"number"}
                    label={<IntlMessages id="appModule.description" />}
                    fullWidth
                    multiline
                    // aria-label="minimum height"
                    rows={3}
                    value={getValues("description")}
                    onChange={event => {
                      setValue("description", event.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot1}
                    error={errors.description?.message}
                    helperText={errors.description?.message}
                  />
                )}
              />

              {/* <p style={{ color: "#000" }}>
                <IntlMessages id="appModule.featureMessage" />
              </p> */}
              <p style={{ color: "red" }}>{errors.description?.message}</p>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={5}
            >
              <Button
                onClick={() => history.goBack()}
                variant="contained"
                color="primary"
                className="btnB"
              >
                <IntlMessages id="appModule.backButton" />
              </Button>
              {type !== "View" && (
                <Button type="submit" variant="contained" color="primary">
                  <IntlMessages
                    id={
                      type !== "Edit"
                        ? "appModule.addSubscription"
                        : "appModule.editSubscription"
                    }
                  />
                </Button>
              )}
            </Box>
          </form>
          <ContentLoader />
        </Box>
      </div>
    </AuthWrapper>
  );
};

export default AddBostSubscription;

AddBostSubscription.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
