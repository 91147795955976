import React from 'react';
import CmtCard from '../../../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../../../@coremat/CmtCard/CmtCardHeader';
import Box from '@material-ui/core/Box';
// import UserInfo from '../../../NewsFeed/PostsList/PostItem/UserInfo';
import CmtCardContent from '../../../../../../@coremat/CmtCard/CmtCardContent';
import PostStats from '../../../NewsFeed/PostsList/PostItem/PostStats';
import CmtList from '../../../../../../@coremat/CmtList';
// import CommentItem from '../CommentListView/CommentItem/CommentItem';
// import AddComment from './AddComment';
import Attachments from '../../../NewsFeed/PostsList/PostItem/Attachments';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UserInfo from './UserInfo';

const useStyles = makeStyles(() => ({}));

const PostItem = ({ item }) => {
  const classes = useStyles();
  // const { owner, date, content, attachments, comments, id } = item;

  return (
    <CmtCard>
      {/* Same user detail upper post */}
      <CmtCardHeader title={<UserInfo user={item} />} />
      {/* <CmtCardContent>
        <Box>
          <Box mb={2} component="p">
            {content}
          </Box>
          {attachments.length > 0 && <Attachments attachments={attachments} />}
        </Box>
      </CmtCardContent> */}
    </CmtCard>
  );
};

export default PostItem;

PostItem.prototype = {
  item: PropTypes.object.isRequired,
};
