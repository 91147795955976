/* eslint-disable no-lone-blocks */
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import React from 'react';
import axios from '../../config';
import {
  setDashboardData,
  setPopulerProfilesData,
  setRecentFlagData,
  setPopulerPostsData,
  setPostCategoriesData,
  setPopulerPostsMetaData,
  appendPopulerPostsData,
} from 'redux/actions/Dashboard';

//Dashboard apis
const DashboardServicesAuth = {
  //Dashboard Details API
  dashboardDetailsAPI: ({ token }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common['Authorization'] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get('admin/user/dashboard', {}, { withCredentials: true })
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(setDashboardData(data.data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response.data.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Populer Profiles API
  populerProfilesAPI: ({ token }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common['Authorization'] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get('admin/user/popular_profiles', {}, { withCredentials: true })
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(setPopulerProfilesData(data.data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            console.log(error);
            // dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Recent Flags API
  recentFlagAPI: token => {
    // page, perPage, order, sortBy
    return dispatch => {
      if (token) {
        axios.defaults.headers.common['Authorization'] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get('admin/post/recent_flaged_posts?' + '&perPage=10' + '&page=1', {}, { withCredentials: true })
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(setRecentFlagData(data.data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            console.log(error);
            // dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Populer Posts API
  populerPostsAPI: ({ token, categoryId, keyword, page, perPage }) => {
    const keywordData = keyword != '' ? '&keyword=' + keyword : '';
    const categoryIdData = categoryId != '' ? '&category_id=' + categoryId : '';
    return dispatch => {
      if (token) {
        axios.defaults.headers.common['Authorization'] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            'admin/post/popular_posts?' + categoryIdData + keywordData + '&perPage=' + perPage + '&page=' + page,
            // `admin/post/popular_posts?perPage=${perPage}&page=${page}${categoryIdData}${keywordData} `,
            {},
            { withCredentials: true },
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              {
                page == 1 ? dispatch(setPopulerPostsData(data.data)) : dispatch(appendPopulerPostsData(data.data));
              }
              dispatch(setPopulerPostsMetaData(data.meta));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            console.log(error);
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //TREND VIEWED API
  trendViewedPostsAPI: ({ token, type, categoryId, keyword, page, perPage }) => {
    const keywordData = keyword != '' ? '&keyword=' + keyword : '';
    const categoryIdData = categoryId != '' ? '&category_id=' + categoryId : '';
    return dispatch => {
      if (token) {
        axios.defaults.headers.common['Authorization'] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/post/trending_viewed_posts?type=${type}` +
              categoryIdData +
              keywordData +
              '&perPage=' +
              perPage +
              '&page=' +
              page,
            {},
            { withCredentials: true },
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              {
                page == 1 ? dispatch(setPopulerPostsData(data.data)) : dispatch(appendPopulerPostsData(data.data));
              }
              dispatch(setPopulerPostsMetaData(data.meta));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            console.log(error);
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //TOP REWARD PROFILE API
  topRewardProfileAPI: ({ token, categoryId, keyword, page, perPage, type }) => {
    const keywordData = keyword != '' ? '&keyword=' + keyword : '';
    const categoryIdData = categoryId != '' ? '&category_id=' + categoryId : '';
    let url = '';
    return dispatch => {
      if (token) {
        axios.defaults.headers.common['Authorization'] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/user/top_profiles?type=${type}` + keywordData + '&perPage=' + perPage + '&page=' + page,
            // `admin/post/popular_posts?perPage=${perPage}&page=${page}${categoryIdData}${keywordData} `,
            {},
            { withCredentials: true },
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              {
                page == 1 ? dispatch(setPopulerPostsData(data.data)) : dispatch(appendPopulerPostsData(data.data));
              }
              dispatch(setPopulerPostsMetaData(data.meta));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            console.log(error);
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // Post Categories API
  postCategoriesAPI: token => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common['Authorization'] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get('admin/user/post_categories', {}, { withCredentials: true })
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(setPostCategoriesData(data.data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            console.log(error);
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
};

export default DashboardServicesAuth;
