import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import {
  Container,
  Grid,
  Box,
  makeStyles,
  Typography,
  Paper,
  Button
} from "@material-ui/core";
import Axios from "axios";
import { API_URL } from "constant/constant";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { fetchSuccess } from "redux/actions";
import InfluencerDetails from "./EscrowComponents/InfluencerDetails";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    padding: 24,
    paddingLeft: 0,
    // marginBottom: 24,
    position: "relative"
  },
  box: {
    wordBreak: "break-word",
    "& h5": {
      marginBottom: "8px"
    },
    "& h3": {
      marginBottom: "8px"
    }
  }
}));

const EscrowView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token);
  const classes = useStyles();
  const [isUpdating, setIsUpdating] = useState(false);
  const [requestView, setRequestView] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const {
    user_details,
    inf_details,
    details,
    user_status,
    is_paid,
    id,
    status,
    reason
  } = requestView;
  const getRequestDataHandler = async id => {
    try {
      const response = await Axios({
        method: "GET",
        url: `${API_URL}admin/influencer_apply/view_request/${id}`,
        headers: {
          Authorization: token
        }
      });
      if (response.status == 200) {
        setRequestView(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const releaseFundAPIHandler = async (id, status) => {
    try {
      setIsUpdating(true);
      const response = await Axios({
        method: "PUT",
        url: `${API_URL}admin/influencer_apply/accept_reject_job/${id}`,
        headers: {
          Authorization: token
        },
        params: {
          type: status
        }
      });
      if (response.status === 200) {
        getRequestDataHandler(location.search.split("?")[1]);
        dispatch(fetchSuccess(response.data.message));
        setOpenConfirm(false);
      }
      setIsUpdating(false);
    } catch (error) {
      console.log(error);
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    const reqId = location.search.split("?")[1];
    if (reqId) {
      getRequestDataHandler(reqId);
    }
  }, [location.search]);

  return (
    <Container
      maxWidth="lg"
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Box margin={"16px 0px"}>
            <Typography variant="h1" className={classes.titleRoot}>
              Influencer Details
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box margin={"16px 0px"}>
            <Typography variant="h1" className={classes.titleRoot}>
              User Details
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <InfluencerDetails data={inf_details} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <InfluencerDetails data={user_details} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper
            elevation={3}
            style={{ padding: "16px", marginBottom: "16px" }}
          >
            <Box className={classes.box}>
              <Typography variant="h3">Description</Typography>
              <Typography variant="h5">
                {details && details.description}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography variant="h3">Amount</Typography>
                <Typography variant="h5">
                  {details && `${details.total_inr} INR`}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h3">Date</Typography>
                <Typography variant="h5">
                  {details &&
                    details.start_date &&
                    moment(details.start_date).format("DD/MM/YYYY")}
                </Typography>
              </Box>
            </Box>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: "16px", marginBottom: "16px" }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <Typography variant="h3">Amount escrow:</Typography>
                <Typography variant="h5">
                  {details && `${details.total_inr} INR`}
                </Typography>
              </Box>
              <Typography variant="h5">
                {" "}
                {details &&
                  details.start_date &&
                  moment(details.start_date).format("DD/MM/YYYY")}
              </Typography>
            </Box>
          </Paper>
          {!is_paid && user_status !== "pending" && (
            <Paper
              elevation={3}
              style={{ padding: "16px", marginBottom: "16px" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3">{`${
                    user_status === "rejected" ? "Rejected" : "Accepted"
                  } by User`}</Typography>
                  {reason && (
                    <Typography variant="h5">{`Reason: ${reason}`}</Typography>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginTop: "12px" }}
                    // onClick={() =>
                    //   releaseFundAPIHandler(
                    //     id,
                    //     user_status === "rejected" ? "accept" : "reject"
                    //   )
                    // }
                    onClick={() => setOpenConfirm(true)}
                    disabled={isUpdating}
                  >
                    {`Release fund to ${
                      user_status === "rejected" ? "user" : "influencer"
                    }`}
                  </Button>
                  {user_status === "rejected" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginTop: "12px", marginLeft: "12px" }}
                      // onClick={() => releaseFundAPIHandler(id, "accept")}
                      onClick={() => setOpenConfirm(true)}
                      disabled={isUpdating}
                    >
                      Release fund to influencer
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>
      </Grid>
      {openConfirm && (
        <ConfirmDialog
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          onConfirm={() =>
            releaseFundAPIHandler(
              id,
              user_status === "rejected" ? "reject" : "accept"
            )
          }
          title="Release fund"
          content={`Are you sure want to release fund to ${
            user_status === "rejected" ? "user" : "influencer"
          }?`}
        />
      )}
    </Container>
  );
};

export default EscrowView;
