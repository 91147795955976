import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { AuhMethods } from "services/auth";
import FollowRecord from "@jumbo/components/Common/FollowRecord/FollowingRecord";
import { usersFollowerData } from "redux/actions/UsersModule";

const useStyles = makeStyles(theme => ({
  socialStatsRoot: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    paddingBottom: 22
  },
  socialStatsItem: {
    paddingLeft: 12,
    paddingRight: 12,
    cursor: "pointer",
    width: "33.33%",
    "&:not(:first-child)": {
      borderLeft: `1px solid ${theme.palette.borderColor.main}`
    }
  }
}));

const SocialStats = ({ userDetail }) => {
  const [page, setPage] = useState(1);
  const [itemType, setItemType] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [followerDialogue, setFollowerDialogue] = useState(false);
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  const followData = useSelector(state => state.usersModule.userFollower); //Fetch data from Redux
  const {
    total_followers,
    total_following,
    rewards,
    reward_points
  } = userDetail;
  const classes = useStyles();
  const dispatch = useDispatch();
  const getFollowers = () => {
    setItemType("Followers");
    let order = "desc";
    let userId = userDetail?.user_id;
    dispatch(
      AuhMethods["usersModule"].userFollowerList(
        token,
        order,
        perPage,
        page,
        userId
      )
    );
    setFollowerDialogue(true);
  };
  const getFollowing = () => {
    setItemType("Following");
    let order = "desc";
    let userId = userDetail?.user_id;
    dispatch(
      AuhMethods["usersModule"].userFollowingList(
        token,
        order,
        perPage,
        page,
        userId
      )
    );
    setFollowerDialogue(true);
  };

  const handleCloseViewDialog = () => {
    setItemType("");
    setFollowerDialogue(false);
    dispatch(usersFollowerData([]));
  };
  return (
    <Box className={`${classes.socialStatsRoot} socialStstsCustom`}>
      <Box className={classes.socialStatsItem}>
        <Typography component="div" variant="h3">
          {/* {total_followers > 2000 ? '2k+' : total_followers} */}
          {total_followers}
        </Typography>
        <Box
          component="span"
          color="text.secondary"
          fontSize={12}
          onClick={getFollowers}
        >
          Followers
        </Box>
      </Box>
      <Box className={classes.socialStatsItem}>
        <Typography component="div" variant="h3">
          {total_following}
        </Typography>
        <Box
          component="span"
          color="text.secondary"
          fontSize={12}
          onClick={getFollowing}
        >
          Following
        </Box>
      </Box>
      <Box className={classes.socialStatsItem}>
        <Typography component="div" variant="h3">
          {reward_points ? reward_points : 0}
        </Typography>
        <Box component="span" color="text.secondary" fontSize={12}>
          Reward Points
        </Box>
      </Box>
      {followerDialogue &&
        Array.isArray(followData?.data) &&
        followData?.data.length > 0 && (
          <FollowRecord
            open={followerDialogue}
            onCloseDialog={handleCloseViewDialog}
            itemType={itemType}
            data={followData?.data}
          />
        )}
    </Box>
  );
};

export default SocialStats;

SocialStats.prototype = {
  userDetail: PropTypes.object.isRequired
};
