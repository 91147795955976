import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid } from "@material-ui/core";
import useStyles from "./index.style";
import DashboardCard from "../../../../@jumbo/components/Common/DashboardCard/index";

const UserDashboard = ({ count }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Toolbar>
        <Grid container spacing={2}>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={<span style={{ color: "#fafafa" }}>Total Plan</span>}
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={
                count?.totalInActivePlan + count?.totalActivePlan
                  ? count?.totalInActivePlan + count?.totalActivePlan
                  : "0"
              }
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={
                <span style={{ color: "#fafafa" }}>Total Active Plan</span>
              }
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalActivePlan ? count?.totalActivePlan : "0"}
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={
                <span style={{ color: "#fafafa" }}>Total Inactive Plan</span>
              }
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalInActivePlan ? count?.totalInActivePlan : "0"}
              description=""
              item="0"
            />
          </Grid>
        </Grid>
      </Toolbar>
    </React.Fragment>
  );
};

export default UserDashboard;
