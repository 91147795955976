import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import moment from "moment";
import Axios from "axios";
import { API_URL } from "constant/constant";
import { useDispatch } from "react-redux";
import { fetchSuccess } from "../../../../redux/actions";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import AddRewardModal from "../AddRewardModal";
import AddTrenchModal from "../AddTrenchModal";

const TrenchesLevelRow = ({ data, index, token, callBack }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const deleteTrenchHandler = async status => {
    try {
      const response = await Axios({
        method: "DELETE",
        url: `${API_URL}admin/rewards_setting/delete_trunch/${data.id}`,
        headers: {
          Authorization: token
        }
      });
      if (response.status == 200) {
        setOpen(false);
        dispatch(fetchSuccess(response?.data?.message));
        callBack();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={`trench${data.id}`}>
        <TableCell>{data?.level}</TableCell>
        <TableCell>{`${data?.value} Reward Points`}</TableCell>
        <TableCell>
          <ul>
            {data?.rewards_data &&
              JSON.parse(data?.rewards_data)?.map(item => {
                return (
                  <li>
                    {`${item?.reward} ${
                      item?.type !== "other" ? item?.type?.toUpperCase() : ""
                    }`}
                  </li>
                );
              })}
          </ul>
        </TableCell>
        <TableCell align="left" onClick={event => event.stopPropagation()}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenEdit(true)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: "20px" }}
            onClick={() => setOpen(true)}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      {open && (
        <ConfirmDialog
          open={open}
          title="Delete"
          content={`Are you sure, you want to delete this trench?`}
          onClose={() => setOpen(false)}
          onConfirm={deleteTrenchHandler}
        />
      )}
      {openEdit && (
        <AddTrenchModal
          type="edit"
          open={openEdit}
          token={token}
          data={data}
          callBack={callBack}
          onClose={() => setOpenEdit(false)}
        />
      )}
    </>
  );
};

export default TrenchesLevelRow;
