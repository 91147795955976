import {
  ADD_REWARD_LIST_DATA,
  REWARD_COIN_LIST_DATA,
  GET_REWARD_LIST_DATA,
  GET_REWARD_LIST_META,
  GET_REWARD_TYPES_LIST,
  SUCCESS_UPLOAD_CSV,
  GET_AIRDROP_LIST_DATA,
  GET_COIN_LIST_DATA,
} from '../../@jumbo/constants/ActionTypes';

export const addRewardList = data => {
  return dispatch => {
    dispatch({
      type: ADD_REWARD_LIST_DATA,
      payload: data,
    });
  };
};

export const getRewardList = data => {
  return dispatch => {
    dispatch({
      type: GET_REWARD_LIST_DATA,
      payload: data,
    });
  };
};
export const rewardListMeta = data => {
  return dispatch => {
    dispatch({
      type: GET_REWARD_LIST_META,
      payload: data,
    });
  };
};

export const getRewardTypes = data => {
  return dispatch => {
    dispatch({
      type: GET_REWARD_TYPES_LIST,
      payload: data,
    });
  };
};

export const successUploadCSV = data => {
  return dispatch => {
    dispatch({
      type: SUCCESS_UPLOAD_CSV,
      payload: data,
    });
  };
};

export const getAirdropList = data => {
  return dispatch => {
    dispatch({
      type: GET_AIRDROP_LIST_DATA,
      payload: data,
    });
  };
};

export const getCoinlist = data => {
  return dispatch => {
    dispatch({
      type: GET_COIN_LIST_DATA,
      payload: data,
    });
  };
};
