import {
  SAVE_PAGE_LIST_DATA,
  SAVE_PAGE_CATEGORIES_LIST_DATA,
  PAGE_FOLLOWING_DATA,
  PAGE_FOLLOWERS_DATA,
  CHANGE_STATUS_PAGE_PROFILE,
  SAVE_PAGE_POSTS_DATA,
  PAGE_STORY_LIST_DATA,
  PAGE_PROFILE_LIST_DATA,
  APPEND_PAGE_POSTS_DATA,
  SAVE_PAGE_POST_COMMENTS_DATA,
  SAVE_PAGE_POST_COMMENTS_META,
} from '@jumbo/constants/ActionTypes';

export const setPageListData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_PAGE_LIST_DATA,
      payload: data,
    });
  };
};

export const getPageCategoriesList = data => {
  return dispatch => {
    dispatch({
      type: SAVE_PAGE_CATEGORIES_LIST_DATA,
      payload: data,
    });
  };
};

export const pageFollowerData = data => {
  return dispatch => {
    dispatch({
      type: PAGE_FOLLOWERS_DATA,
      payload: data,
    });
  };
};

export const setPageLikeCommentsData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_PAGE_POST_COMMENTS_DATA,
      payload: data,
    });
  };
};

export const setPageLikeCommentsMeta = data => {
  return dispatch => {
    dispatch({
      type: SAVE_PAGE_POST_COMMENTS_META,
      payload: data,
    });
  };
};

export const pageFollowingData = data => {
  return dispatch => {
    dispatch({
      type: PAGE_FOLLOWING_DATA,
      payload: data,
    });
  };
};

export const changePageStatusFromProfile = id => {
  return dispatch => {
    dispatch({
      type: CHANGE_STATUS_PAGE_PROFILE,
      payload: id,
    });
  };
};

export const setPagePostsData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_PAGE_POSTS_DATA,
      payload: data,
    });
  };
};
export const pageStoryListData = data => {
  return dispatch => {
    dispatch({
      type: PAGE_STORY_LIST_DATA,
      payload: data,
    });
  };
};

export const pageMorePostsMetaData = data => {
  return dispatch => {
    dispatch({
      type: PAGE_STORY_LIST_DATA,
      payload: data,
    });
  };
};

export const setPageProfileData = data => {
  return dispatch => {
    dispatch({
      type: PAGE_PROFILE_LIST_DATA,
      payload: data,
    });
  };
};

export const appendPageMorePostsData = data => {
  return dispatch => {
    dispatch({
      type: APPEND_PAGE_POSTS_DATA,
      payload: data,
    });
  };
};
