import React from "react";
//  import CmtCardHeader from "../../../../../@coremat";
import CmtCardHeader from "../../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCard from "../../../../../@coremat/CmtCard";
import CmtCardContent from "../../../../../@coremat/CmtCard/CmtCardContent";
import Box from "@material-ui/core/Box";
import { Typography, Grid } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import GridContainer from "@jumbo/components/GridContainer";

const useStyles = makeStyles(() => ({
  bioTitle: {
    marginBottom: 16,
  },
  wallRightSidebar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
}));

const TokenDetails = ({ header, userId }) => {
  const classes = useStyles();
  // const { biography } = userDetail;
  console.log("userId --------- details", userId);
  return (
    <Grid container>
      <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
        <CmtCardHeader title={`${header} :`} />
      </Grid>
      <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
        <Typography variant="p">{userId}</Typography>
      </Grid>
    </Grid>
  );
};

export default TokenDetails;
