import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import { API_URL } from "constant/constant";
import axios from "axios";
import { fetchError } from "redux/actions";
import { DownLoadCSVFunction } from "customHook/debounse";

const UserTableToolbar = ({
  selected,
  count,
  userWhiteList,
  setUserWhiteList,
  status,
  from_date,
  to_date
}) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token);

  const DownLoadeCSV = async count => {
    try {
      // console.log("count --- testing ", count);
      const res = await axios({
        method: "GET",
        url: `${API_URL}admin/user/list`,
        headers: {
          Authorization: token
        },
        params: {
          perPage: count,
          page: 1,
          status,
          from_date,
          to_date
        }
      })
        // return res;
        .then(({ data }) => {
          if (data.data) {
            let arrayOfObjects = data.data;

            /* ********************************* B ********************************* */
            // console.log(
            //   "arrayOfObjects.length ------- ",
            //   arrayOfObjects.length
            // );
            if (!arrayOfObjects.length) {
              return;
            }

            DownLoadCSVFunction(arrayOfObjects, "user_data_");
            // var csvContent = "data:text/csv;charset=utf-8,";
            // let dataString;
            // arrayOfObjects.forEach(function(dataMember, index) {
            //   dataString = dataMember.join(",");
            //   csvContent +=
            //     index < data.length ? dataString + "\n" : dataString;
            // });

            // var encodedUri = encodeURI(csvContent);
            // var link = document.createElement("a");
            // link.setAttribute("href", encodedUri);
            // link.setAttribute(
            //   "download",
            //   "upload_data" + new Date().getTime() + ".csv"
            // );
            // link.click();

            // var csvContent = "data:text/csv;charset=utf-8,";

            // // headers
            // csvContent += objectToCSVRow(Object.keys(arrayOfObjects[0]));

            // arrayOfObjects.forEach(function(item) {
            //   csvContent += objectToCSVRow(item);
            // });
            // console.log("csvContent", csvContent);
            // var encodedUri = encodeURI(csvContent);
            // var link = document.createElement("a");
            // link.setAttribute("href", encodedUri);
            // link.setAttribute("download", "testingUserS.csv");
            // document.body.appendChild(link); // Required for FF
            // link.click();
            // document.body.removeChild(link);
            /* ********************************* C ********************************* */
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  const onDeleteCLick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    // dispatch(deleteBulkUsers(selected, () => setSelected([])));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const numSelected = selected.length;

  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          {userWhiteList ? (
            <Button
              color="primary"
              style={{ color: "#000", textTransform: "capitalize" }}
              onClick={() => setUserWhiteList(false)}
            >
              Users
            </Button>
          ) : (
            <Button
              color="primary"
              style={{
                cursor: "default",
                borderBottom: "1px solid #3f51b5",
                textTransform: "capitalize"
              }}
            >
              Users
            </Button>
          )}
          {userWhiteList ? (
            <Button
              color="primary"
              style={{
                cursor: "default",
                borderBottom: "1px solid #3f51b5",
                textTransform: "capitalize"
              }}
            >
              Whitelist Users
            </Button>
          ) : (
            <Button
              color="primary"
              style={{ color: "#000", textTransform: "capitalize" }}
              onClick={() => setUserWhiteList(true)}
            >
              Whitelist Users
            </Button>
          )}
        </Typography>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={onDeleteCLick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <React.Fragment>
            <div>
              <Button
                variant="contained"
                color="secondary"
                style={{ width: "260px" }}
                onClick={() => DownLoadeCSV(count)}
                // size="large"
              >
                Download CSV
              </Button>
            </div>
          </React.Fragment>
        )}
      </Toolbar>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete users`}
        content={"Are you sure, you want to  delete selected users?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchText: PropTypes.string,
  handleSearchTextChange: PropTypes.func,
  onUserAdd: PropTypes.func
};

export default React.memo(UserTableToolbar);
