import React, { useEffect, useContext } from "react";
import Communities from "./Communities";

import makeStyles from "@material-ui/core/styles/makeStyles";

import PerfectScrollbar from "react-perfect-scrollbar";
import { getWallHeight } from "../../../../../@jumbo/constants/AppConstants";
import AppContext from "../../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext";
import { Typography, Grid, Button, Box } from "@material-ui/core";
import TokenDetails from "./TokenDetails";
import GridContainer from "@jumbo/components/GridContainer";
import moment from "moment";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  perfectScrollbarActives: {
    height: (props) => `calc(100vh - ${props.height}px)`,
    "& .Cmt-media-object": {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
      "&:hover": {
        backgroundColor: "transparent",
        transform: "none",
        boxShadow: "none",
        "& .MuiAvatar-root": {
          transform: "none",
          boxShadow: "none",
        },
      },
    },
  },
  titleRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    padding: 24,
    // marginBottom: 24,
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      zIndex: 1,
      width: 36,
      height: 4,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const Activities = ({ userId }) => {
  const { showFooter } = useContext(AppContext);
  const classes = useStyles();
  const history = useHistory();
  //   height: getWallHeight(200, showFooter),
  // });

  useEffect(() => {}, [userId]);
  console.log("userId --------- ", userId);

  return (
    <div>
      <Typography component="div" variant="h3" className={classes.titleRoot}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {" "}
          <span>Token Detail</span>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              history.push({
                pathname: "/vesting-setting",
                search: window.location.search.split("?")[1],
              })
            }
          >
            Vesting Setting
          </Button>
        </div>
      </Typography>
      {/* <Button component="div" variant="h3" className={classes.titleRoot}>
          Token Detail
        </Button> */}
      <PerfectScrollbar className={classes.perfectScrollbarActives}>
        {/* <Communities userId={userId} /> */}
        <Grid container>
          <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails header={"Token Name"} userId={"Taral"} />
          </Grid>
          <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails
              header={"Contract Address"}
              userId={
                userId.contract_address &&
                `${userId.contract_address.slice(
                  0,
                  6
                )}...${userId.contract_address.slice(-4)}`
              }
            />
          </Grid>
          <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails
              header={"Total Token"}
              userId={userId.number_of_token}
            />
          </Grid>
          <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails
              header={"Price Per Token"}
              userId={userId.price_per_token}
            />
          </Grid>
          <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails
              header={"From Date"}
              userId={moment(userId.from_date).format("lll")}
            />
          </Grid>
          <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails
              header={"To Date"}
              userId={moment(userId.to_date).format("lll")}
            />
          </Grid>
          {/* <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails header={"to_date"} userId={userId.token_type} />
          </Grid> */}
        </Grid>
      </PerfectScrollbar>
    </div>
  );
};

export default Activities;
