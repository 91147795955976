import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MoreHoriz, Close, Done, ViewAgenda } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import CmtButtons from "@coremat/CmtButtons";
import { useHistory } from "react-router-dom";
// import withReactContent from "sweetalert2-react-content";
// import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const UserListRow = ({ row, isSelected, page, idx, changeStatus }) => {
  // console.log("row------", row);
  const classes = useStyles();
  const history = useHistory();

  // *********** option for Page ***********************
  const getUserActions = () => {
    const actions = [
      row.status === "0"
        ? { action: "accepted", label: "Disable", icon: <Close /> }
        : row.status === "1"
        ? { action: "rejected", label: "Enable", icon: <Done /> }
        : row.status === "2"
        ? { action: "accepted", label: "APPROVE", icon: <Done /> }
        : {},
      { action: "rejected", label: "View", icon: <VisibilityIcon /> },
      { action: "rejected", label: "Edit", icon: <EditIcon /> },
    ];
    if (row.status === "0") {
      actions.push({ action: "rejected", label: "REJECT", icon: <Close /> });
    }

    return actions;
  };

  const onUserMenuClick = (menu, row) => {
    console.log(row, "selectedUser ----- menu", menu);
    changeStatus(menu.action, menu.label, row.id);
  };

  const labelId = `enhanced-table-checkbox-${row.user_id}`;
  const isItemSelected = isSelected(row.user_id);
  const userActions = getUserActions(row.status, row.verified);

  console.log("selectedUser ----- userActions", userActions);
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.user_id}
      selected={isItemSelected}
    >
      <TableCell>{page * 10 + idx + 1}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          {/* <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_url} alt={row.name} />
          </Box> */}
          <div>
            {row.created_by == "admin" ? (
              <Typography
                className={classes.titleRoot}
                component="div"
                variant="h4"
              >
                Admin
              </Typography>
            ) : (
              <Typography
                className={classes.titleRoot}
                component="div"
                variant="h4"
              >
                {row.name === null ? "N/A" : row.name}
              </Typography>
            )}
          </div>
        </Box>
      </TableCell>
      <TableCell>{moment(row.created_at).format("lll")}</TableCell>
      <TableCell>{moment(row.from_date).format("lll")}</TableCell>
      <TableCell>{moment(row.to_date).format("lll")}</TableCell>

      <TableCell>
        {row.status === "1"
          ? "Active"
          : row.status === "0"
          ? "Inactive"
          : "Unverified"}
      </TableCell>
      {/* <TableCell>{row.vesting ? "Completed" : "Pending"}</TableCell> */}

      <TableCell
        align="center"
        // onClick={(event) => event.stopPropagation()}
      >
        {/* <CmtDropdownMenu
          row={row}
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        /> */}
        <Button
          variant="contained"
          color="primary"
          //  onClick={() => sweetAlerts("row")}
          onClick={() =>
            history.push({
              pathname: "/taral-ico-details",
              search: row.id.toString(),
            })
          }
          style={{ marginRight: "15px" }}
        >
          View ICO Investor
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            history.push({
              pathname: "/vesting-setting",
              search: row.id.toString(),
            })
          }
        >
          Vesting Setting
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
