import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { TextField } from "@material-ui/core";
import Axios from "axios";
import { API_URL } from "constant/constant";
import { useDispatch } from "react-redux";
import { fetchSuccess } from "../../../../redux/actions";
const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const unitArray = [
  { name: "Daily", value: "daily" },
  { name: "Monthly", value: "monthly" }
];

const AddRewardModal = ({ open, onCloseDialog, data, token, callback }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = useState(false);
  const [formData, setFormData] = useState({
    creatorReward: "",
    engagerReward: ""
  });
  useEffect(() => {
    setFormData({
      creatorReward: data.creator_points,
      engagerReward: data.user_points
    });
  }, [data]);
  const updateRewardDataHandler = async () => {
    try {
      setIsSubmit(true);
      if (formData.creatorReward === "" || formData.engagerReward === "") {
        return;
      }

      setIsSubmit(false);
      const response = await Axios({
        method: "PUT",
        url: `${API_URL}admin/rewards_setting/update`,
        headers: {
          Authorization: token
        },
        data: {
          id: data.id,
          creator_points: formData.creatorReward,
          user_points: formData.engagerReward,
          is_active: data.is_active
        }
      });
      if (response.status === 200) {
        onCloseDialog();
        callback();
        dispatch(fetchSuccess(response?.data?.message));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={`${classes.dialogRoot} dialogCustom`}>
        <span>Edit Reward</span>
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000"
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers className="customFormStyles">
        <Box>
          <GridContainer>
            <Grid>
              <AppTextInput
                fullWidth
                type="number"
                variant="outlined"
                label={"Creator Reward"}
                value={formData.creatorReward}
                onChange={e => {
                  setFormData({ ...formData, creatorReward: e.target.value });
                }}
                // helperText={unitPeriodError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <Box>
          <GridContainer>
            <Grid>
              <AppTextInput
                fullWidth
                type="Number"
                variant="outlined"
                label={"Engager Reward"}
                value={formData.engagerReward}
                onChange={e => {
                  setFormData({ ...formData, engagerReward: e.target.value });
                }}
                // helperText={valueError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={updateRewardDataHandler}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddRewardModal;

AddRewardModal.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
