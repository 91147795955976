import React from "react";
import Box from "@material-ui/core/Box";
import UserInfo from "./UserInfo";
import PropTypes from "prop-types";

const CommentItem = ({ item, classes, itemType }) => {
  return (
    <Box mb={6}>
      {itemType === "comments" && (
        <UserInfo
          user={item?.commented_user_data}
          date={item?.created_at}
          classes={classes}
        />
      )}
      {(itemType === "likes" || itemType === "dislikes") && (
        <UserInfo
          user={item?.like_user_data}
          date={item?.created_at}
          classes={classes}
        />
      )}

      <Box ml={14}>
        {itemType === "comments" && (
          <Box mt={2} component="p" color="text.secondary">
            {item.comment}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CommentItem;

CommentItem.prototype = {
  item: PropTypes.object.isRequired
};
