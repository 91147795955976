import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import useStyles from "./index.style";
import DashboardCard from "../../../../@jumbo/components/Common/DashboardCard/index";
import GridContainer from "@jumbo/components/GridContainer";

const UserDashboard = ({ count }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Toolbar>
        <GridContainer>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={<span style={{ color: "#fafafa" }}>Total Request</span>}
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={
                count?.totalPending + count?.totalReject + count?.totalVerify
                  ? count?.totalPending +
                    count?.totalReject +
                    count?.totalVerify
                  : "0"
              }
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={
                <span style={{ color: "#fafafa" }}>Total Pending Request</span>
              }
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              description=""
              amount={count?.totalPending ? count?.totalPending : "0"}
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={
                <span style={{ color: "#fafafa" }}>Total Verify Request</span>
              }
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalVerify ? count?.totalVerify : "0"}
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={<span style={{ color: "#fafafa" }}>Rejected Request</span>}
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalReject ? count?.totalReject : "0"}
              description=""
              item="0"
            />
          </Grid>
        </GridContainer>
      </Toolbar>
    </React.Fragment>
  );
};

export default UserDashboard;
