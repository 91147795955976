import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import showPasswordIcon from "../../../../images/visible.png";
import hidePasswordIcon from "../../../../images/nonVisible.png";
import IntlMessages from "../../../utils/IntlMessages";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import { AuhMethods } from "../../../../services/auth";
import ContentLoader from "../../ContentLoader";
import { alpha, makeStyles } from "@material-ui/core/styles";
import CmtImage from "../../../../@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import AuthWrapper from "./AuthWrapper";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  }
}));

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please insert valid email")
    .required("This field is required."),
  password: yup
    .string()
    .required("This field is required.")
    .min(
      8,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  remember: yup.boolean().notRequired()
});

const SignIn = ({
  method = "admin",
  variant = "default",
  wrapperVariant = "default"
}) => {
  const [rememberUser, setRememberUser] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const state = useSelector(state => state);
  useEffect(() => {}, [state]);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      remember: false
    },
    resolver: yupResolver(schema)
  });
  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    let email = rememberMe ? localStorage.getItem("email") : "";
    let password = rememberMe ? localStorage.getItem("password") : "";
    let rem = rememberMe ? localStorage.getItem("rememberMe") : false;
    setValue("email", email);
    setValue("password", password);
    setValue("remember", rem);
    setRememberUser(rem);
  }, []);

  const onSubmit = data => {
    if (rememberUser) {
      localStorage.setItem("rememberMe", rememberUser ? data.remember : false);
      localStorage.setItem("email", rememberUser ? data.email : "");
      localStorage.setItem("password", rememberUser ? data.password : "");
    } else {
      localStorage.setItem("rememberMe", "");
      localStorage.setItem("email", "");
      localStorage.setItem("password", false);
    }
    dispatch(AuhMethods[method].onLogin(data));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <CmtImage src={"/images/auth/login-img.png"} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <Typography
            component="div"
            variant="h1"
            className={classes.titleRoot}
          >
            NowWay Admin
          </Typography>
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Login
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <Controller
              name="email"
              control={control}
              render={({ value }) => {
                return (
                  <TextField
                    name="email"
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={event => {
                      setValue("email", event.target.value);
                    }}
                    value={getValues("email")}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                );
              }}
            />
            <p style={{ color: "red" }}>{errors.email?.message}</p>
          </Box>
          <Box mb={2} className="inputPasswordCustom">
            <Controller
              name="password"
              control={control}
              render={() => (
                <TextField
                  type={showPassword ? "text" : "password"}
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  value={getValues("password")}
                  onChange={event => {
                    setValue("password", event.target.value);
                  }}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              )}
            />
            <div className="eyeFieldStyle">
              {showPassword ? (
                <div className="hideShowIcon">
                  <img
                    src={showPasswordIcon}
                    alt=""
                    onClick={() => setShowPassword(false)}
                  />
                </div>
              ) : (
                <div className="hideShowIcon">
                  <img
                    src={hidePasswordIcon}
                    alt=""
                    onClick={() => setShowPassword(true)}
                  />
                </div>
              )}
            </div>
            <p style={{ color: "red" }}>{errors.password?.message}</p>
          </Box>
          <div className="rems">
            <input
              name="remember"
              type="checkbox"
              checked={rememberUser}
              onChange={e => {
                setValue("remember", e.target.checked);
                setRememberUser(e.target.checked);
              }}
            />
            Remember me
          </div>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Button type="submit" variant="contained" color="primary">
              <IntlMessages id="appModule.logins" />
            </Button>
          </Box>
        </form>

        {dispatch(AuhMethods[method].getSocialMediaIcons())}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
