import React from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import CmtAvatar from "@coremat/CmtAvatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import ClearIcon from "@material-ui/icons/Clear";
import useStyles from "./index.style";
import { Block, CheckCircleOutline } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import CmtImage from "@coremat/CmtImage";
import { IMAGE_URL } from "constant/constant";
import CmtVideo from "@coremat/CmtVideo";

const UserDetailView = ({ viewDetails, open, onCloseDialog }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box ml="auto" mt={-2} display="flex" alignItems="start">
        <Box ml={1}>
          <Tooltip
            title={
              viewDetails.is_verify == "2"
                ? "Rejected"
                : viewDetails.is_verify == "1"
                ? "Approved"
                : ""
            }
          >
            <IconButton aria-label="filter list">
              {viewDetails.is_verify === "2" && <Block color="primary" />}
              {viewDetails.is_verify === "1" && (
                <CheckCircleOutline color="primary" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <Box ml={1}>
          <IconButton onClick={onCloseDialog}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>
      <Box mr={3} display="flex" alignItems="start">
        {viewDetails?.image_url && (
          <Box className={classes.avatarView}>
            <CmtImage
              // size={70}
              className={classes.gridThumb}
              // src={viewDetails.image_url}
              src={`${IMAGE_URL + viewDetails?.image_url}`}
              alt={viewDetails.name}
            />
          </Box>
        )}
        {viewDetails.video_url && (
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtVideo
              // size={10}

              height={"160px"}
              width={"160px"}
              className={classes.gridThumb}
              src={`${IMAGE_URL + viewDetails?.video_url}`}
              alt={viewDetails.name}
              keyAuto={true}
            />
          </Box>
        )}
      </Box>

      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="start">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>
                {viewDetails.name}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              //  mb={{ xs: 4, sm: 7 }}
            >
              {/* <EmailIcon /> */}

              <Box component="p" color="common.dark">
                Status:
              </Box>
              <Box
                ml={5}
                color="primary.main"
                component="p"
                className="pointer"
              >
                <Typography className={classes.titleRoot1}>
                  {viewDetails.is_verify === "0"
                    ? "Pending"
                    : viewDetails.is_verify === "1"
                    ? "Verified"
                    : viewDetails.is_verify === "2"
                    ? "Rejected"
                    : ""}
                </Typography>
              </Box>
            </Box>
            {viewDetails.is_verify === "1" && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  //  mb={{ xs: 4, sm: 7 }}
                >
                  <Box component="p" color="common.dark">
                    Payment :
                  </Box>
                  <Box
                    ml={5}
                    color="primary.main"
                    component="p"
                    className="pointer"
                  >
                    <Typography className={classes.titleRoot1}>
                      {viewDetails.is_paid ? "Paid" : "Pending"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  //  mb={{ xs: 4, sm: 7 }}
                >
                  <Box component="p" color="common.dark">
                    Pay In INR :
                  </Box>
                  <Box
                    ml={5}
                    color="primary.main"
                    component="p"
                    className="pointer"
                  >
                    <Typography className={classes.titleRoot1}>
                      {viewDetails.inr}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  //  mb={{ xs: 4, sm: 7 }}
                >
                  <Typography className={classes.titleRoot} variant="h1">
                    Pay In INR & Taral
                  </Typography>
                  <Box
                    ml={5}
                    color="primary.main"
                    component="p"
                    className="pointer"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      //  mb={{ xs: 4, sm: 7 }}
                    >
                      <Box component="p" color="common.dark">
                        INR :
                      </Box>
                      <Box
                        ml={5}
                        color="primary.main"
                        component="p"
                        className="pointer"
                      >
                        <Typography className={classes.titleRoot1}>
                          {viewDetails.total_inr}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box component="p" color="common.dark">
                        Taral :
                      </Box>
                      <Box
                        ml={5}
                        color="primary.main"
                        component="p"
                        className="pointer"
                      >
                        <Typography className={classes.titleRoot1}>
                          {viewDetails.taral}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} component="p" color="common.dark">
          Contact Detail
        </Box>
        <Box display="flex" alignItems="center">
          <CmtAvatar
            src={`${IMAGE_URL}` + viewDetails.user_data.profile_url}
            alt={viewDetails.user_data.name}
          />
          <Box ml={5} color="primary.main" component="p" className="pointer">
            {viewDetails.user_data.name}
          </Box>
          <Box ml={5} color="primary.main" component="p" className="pointer">
            ( {viewDetails.user_data.username} )
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          Description :
          <Box ml={5}>
            <Typography color="primary.main" component="p" className="pointer">
              {viewDetails.description}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
