import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { AuhMethods } from 'services/auth';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';

const FollowingInfo = ({ userDetail }) => {
  // const [isFollow, setIsFollow] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux

  console.log(userDetail, '=============>userDetail');
  const { status, user_id } = userDetail;

  useEffect(() => {}, [userDetail]);

  const chnageUserStatus = () => {
    const params = { business_page_id: user_id, action: status == '1' ? '0' : '1' };

    dispatch(AuhMethods['businessModule'].changePageStatusAPI(token, params, 'Profile'));
    setTimeout(() => {
      dispatch(AuhMethods['businessModule'].pageProfileAPI({ token: token, userId: params.business_page_id }));
    }, 1000);
  };

  const handleCancelBlock = () => {
    setConfirm(false);
  };

  const handleConfirmBlock = () => {
    setConfirm(false);
    chnageUserStatus();
  };
  return (
    <Box mb={4}>
      {/* <Box component="p" mb={4}>
        {`You are ${isFollow ? 'blocking' : 'not blocking'} ${name} @${username}`}{' '}
      </Box> */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setConfirm(true);
        }}>
        {status == '0' ? 'Unblock' : 'Block'}
      </Button>
      <ConfirmDialog
        open={confirm}
        title={`${status == '0' ? 'Unblock' : 'Block'}`}
        content={`Are you sure, you want to ${status == '0' ? 'Unblock' : 'Block'}?`}
        onClose={handleCancelBlock}
        onConfirm={handleConfirmBlock}
      />
    </Box>
  );
};

export default FollowingInfo;

FollowingInfo.prototype = {
  userDetail: PropTypes.object.isRequired,
};
