import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import useStyles from "./index.style";
import { useHistory } from "react-router-dom";
const UserTableToolbar = () => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <React.Fragment>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          Page Management
        </Typography>

        <React.Fragment>
          <div>
            <Button
              color="primary"
              style={{ width: "260px" }}
              onClick={() => history.push("/create-page")}
            >
              + Create Page
            </Button>
          </div>
        </React.Fragment>
      </Toolbar>
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {};

export default React.memo(UserTableToolbar);
