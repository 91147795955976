import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { updatePostLikeStatus } from "../../../../../../redux/actions/WallApp";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import CommentListView from "../../CommentListView";
import { AuhMethods } from "services/auth";
import like from "../../../../../../images/like.png";
import dislike from "../../../../../../images/dislike.png";
import comment from "../../../../../../images/comment.png";
import view from "../../../../../../images/view.png";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  iconSm: {
    fontSize: "16px !important",
    marginRight: 6
  }
}));

// const PostStats = ({ method = 'usersModule', variant = 'default', wrapperVariant = 'default', item }) => {
const PostStats = ({ item, type }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [itemType, setItemType] = useState("");
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux

  useEffect(() => {}, [item]);
  const toggleCommentsClick = () => {
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };

  const handleUserDelete = user => {
    // setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    let uAction = item?.status == 0 ? 1 : 0;
    setOpenConfirmDialog(false);

    // item?.user_id  type=type
    dispatch(
      AuhMethods["usersModule"].deleteUserPostDashAPI(
        token,
        item?.id,
        1,
        10,
        uAction,
        item?.user_id,
        type
      )
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const { id, total_likes, total_comments, total_views, total_dislikes } = item;

  return (
    <Box
      marginTop={"24px"}
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      mb={{ xs: 4, sm: 6 }}
      color="text.disabled"
      fontSize={12}
    >
      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        className="pointer"
        onClick={() => {
          if (total_likes > 0) {
            toggleCommentsClick();
            setItemType("likes");
          }
        }}
      >
        <img src={`${like}`} className={`${classes.iconSm} customStyleIcons`} />
        <Box ml={1}>{total_likes > 0 && total_likes}</Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        onClick={() => {
          if (total_dislikes > 0) {
            toggleCommentsClick();
            setItemType("dislikes");
          }
        }}
        className="pointer"
      >
        <img
          src={`${dislike}`}
          className={`${classes.iconSm} customStyleIcons`}
        />
        <Box ml={1}>{total_dislikes > 0 && total_dislikes} </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        onClick={() => {
          if (total_comments > 0) {
            toggleCommentsClick();
            setItemType("comments");
          }
        }}
        className="pointer"
      >
        <img
          src={`${comment}`}
          className={`${classes.iconSm} customStyleIcons`}
        />
        <Box ml={1}>{total_comments > 0 && total_comments} </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        onClick={() => {
          setItemType("viwes");
        }}
        className="pointer"
      >
        <img src={`${view}`} className={`${classes.iconSm} customStyleIcons`} />
        <Box ml={1}>{total_views > 0 && total_views} </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        mb={{ xs: 2, sm: 0 }}
        ml="auto"
        className="pointer"
      >
        <Button
          variant="contained"
          color="primary"
          disabled={item?.status == 2}
          onClick={handleUserDelete}
        >
          {item?.status == 0 || item?.status == 2 ? "Active" : "Inactive"}
        </Button>
      </Box>
      {openViewDialog && (
        <CommentListView
          open={openViewDialog}
          onCloseDialog={handleCloseViewDialog}
          commentId={id}
          itemType={itemType}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`${
          item?.status == 0 || item?.status == 2 ? "Active" : "Inactive"
        }`}
        content={`Are you sure, you want to ${
          item?.status == 0 || item?.status == 2 ? "active" : "inactive"
        } this post?`}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  );
};

export default PostStats;

PostStats.prototype = {
  item: PropTypes.object.isRequired
};
