import { alpha, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2)
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  },
  cardRoot: {
    color: theme.palette.common.white
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: alpha(theme.palette.secondary.light, 0.15)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  },
  chipsRoot: {
    display: "flex",
    justifyContent: "center",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    "& > *": {
      margin: theme.spacing(0.5)
    }
  }
}));

export default useStyles;
