import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Axios from "axios";
import { API_URL } from "constant/constant";
import { fetchSuccess } from "redux/actions";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const AddEditContentType = ({ open, onCloseDialog, callBack, type, data }) => {
  const classes = useStyles();
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  const dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const addEditMasterCategoryHandler = async () => {
    try {
      setIsProcessing(true);
      const response = await Axios({
        method: type === "add" ? "POST" : "PUT",
        url: `${API_URL}${
          type == "add"
            ? "admin/post/add_master_category"
            : `admin/post/update_master_category/${data.id}`
        }`,
        headers: {
          Authorization: token
        },
        data: {
          name: categoryName
        }
      });
      if (response.status === 200) {
        dispatch(fetchSuccess(response.data.message));
        setCategoryName("");
        callBack();
        onCloseDialog();
      }
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };
  useEffect(() => {
    if (type === "edit") {
      setCategoryName(data.name);
    }
  }, [type]);

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      className={`${classes.dialogRoot} dialogCustom`}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        <span> {`${type === "add" ? "Add" : "Edit"} Interest Category`}</span>
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000"
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        ></Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            style={{ marginBottom: 12 }}
            fullWidth
            variant="outlined"
            label="Name"
            value={categoryName}
            onChange={e => {
              if (e.target.value.length <= 48) {
                setCategoryName(e.target.value);
              }
            }}
            // helperText={firstNameError}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog} disabled={isProcessing}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={addEditMasterCategoryHandler}
              disabled={isProcessing}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditContentType;
