import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Chip, Menu, Grid, Box } from "@material-ui/core";
import useStyles from "./index.style";
import DashboardCard from "../../../../@jumbo/components/Common/DashboardCard/index";

const UserDashboard = ({ count }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Toolbar>
        <Grid container spacing={2}>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={<span style={{ color: "#fafafa" }}>Total </span>}
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={
                count?.totalPending + count?.totalReject + count?.totalApprove
                  ? count?.totalPending +
                    count?.totalReject +
                    count?.totalApprove
                  : "0"
              }
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={<span style={{ color: "#fafafa" }}>Total Pending </span>}
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalPending ? count?.totalPending : "0"}
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={<span style={{ color: "#fafafa" }}>Total Approve </span>}
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalApprove ? count?.totalApprove : "0"}
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={<span style={{ color: "#fafafa" }}>Total Rejected </span>}
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalReject ? count?.totalReject : "0"}
              description=""
              item="0"
            />
          </Grid>
        </Grid>
      </Toolbar>
    </React.Fragment>
  );
};

UserDashboard.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchText: PropTypes.string,
  handleSearchTextChange: PropTypes.func,
  onUserAdd: PropTypes.func
};

export default React.memo(UserDashboard);
