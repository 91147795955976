import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import PropTypes from "prop-types";
import { Button, Chip, Menu, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { deleteBulkUsers } from "redux/actions/Users";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import CmtSearch from "@coremat/CmtSearch";
import useStyles from "./index.style";
import Checkbox from "@material-ui/core/Checkbox";
import { API_URL } from "constant/constant";
import { useHistory } from "react-router-dom";
// import { API_URL } from "constant/constant";
import axios from "axios";
import moment from "moment";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import { DownLoadCSVFunction } from "customHook/debounse";
var objectToCSVRow = function(dataObject) {
  // console.log("dataObject ---- ", dataObject);
  var dataArray = new Array();
  for (var o in dataObject) {
    var innerValue = dataObject[o] === null ? "" : dataObject[o].toString();
    var result = innerValue.replace(/"/g, '""');
    result = '"' + result + '"';
    dataArray.push(result);
  }
  return dataArray.join(" ") + "\r\n";
};
const filterOptionsList = [
  { label: "Active", value: "active" },
  { label: "Suspended", value: "suspended" },
];

const UserTableToolbar = ({
  selected,
  setSelected,
  onUserAdd,
  filterOptions,
  setFilterOptions,
  searchText,
  handleSearchTextChange,
  count,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();

  const token = useSelector((state) => state.admin.authUser.access_token);
  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const onDeleteCLick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    // dispatch(deleteBulkUsers(selected, () => setSelected([])));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const DownLoadeCSV = async (count) => {
    try {
      console.log("count --- testing ", count);
      const res = await axios({
        method: "POST",
        url: `${API_URL}admin/business_pages/ico/ico_history`,
        headers: {
          Authorization: token,
        },
        // params: {
        //   perPage: count,
        //   page: 1,
        // },
        data: {
          // keyword,
          perPage: Number(count),
          page: 1,
        },
      })
        // return res;
        .then(({ data }) => {
          //console.log("data -----", data);
          if (data.data) {
            let items = data.data;
            let arrayOfObjects = data.data;

            /* ********************************* A ********************************* */

            /* ********************************* B ********************************* */

            if (!arrayOfObjects.length) {
              return;
            }
            DownLoadCSVFunction(arrayOfObjects, "ico_investor_list_");

            // var csvContent = "data:text/csv;charset=utf-8,";

            // // headers
            // csvContent += objectToCSVRow(Object.keys(arrayOfObjects[0]));

            // arrayOfObjects.forEach(function(item) {
            //   csvContent += objectToCSVRow(item);
            // });

            // var encodedUri = encodeURI(csvContent);
            // var link = document.createElement("a");
            // link.setAttribute("href", encodedUri);
            // link.setAttribute(
            //   "download",
            //   "ico_investor_list_" + moment().format("DD-MM-YYYY") + ".csv"
            // );
            // document.body.appendChild(link); // Required for FF
            // link.click();
            // document.body.removeChild(link);
            /* ********************************* C ********************************* */
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const numSelected = selected.length;
  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h4"
            id="tableTitle"
            component="div"
          >
            Investor List
            {/* <Button color="primary" onClick={() => onUserAdd(true)}>
              Add New User
            </Button> */}
          </Typography>
        )}

        {/* <React.Fragment>
          <div>
            <Button
              color="primary"
              style={{ width: "200px", marginRight: "20px" }}
              onClick={() => history.push("/add-subscription")}
            >
              Add Boost Plan
            </Button>
          </div>
          <Menu
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            // onClose={handleClose}
          ></Menu>
        </React.Fragment> */}

        <div>
          <Button
            variant="contained"
            color="secondary"
            style={{ width: "260px" }}
            // class="sr-only"
            onClick={() => DownLoadeCSV(count)}
          >
            <span class="sr-only"> Download Investor CSV</span>
          </Button>
        </div>
      </Toolbar>
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchText: PropTypes.string,
  handleSearchTextChange: PropTypes.func,
  onUserAdd: PropTypes.func,
};

export default React.memo(UserTableToolbar);
