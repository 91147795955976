import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import UserInfo from './userInfo';

const FollowList = ({ item, classes, itemType }) => {
  return (
    <Box mb={6}>
      {item['follow_user_data'] !== undefined ? (
        <UserInfo user={item?.follow_user_data} date={item?.created_at} classes={classes} />
      ) : (
        <UserInfo user={item?.following_user_data} date={item?.created_at} classes={classes} />
      )}

      <Box ml={14}>
        {itemType === 'comments' && (
          <Box mt={2} component="p" color="text.secondary">
            {item.comment}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FollowList;

FollowList.prototype = {
  item: PropTypes.object.isRequired,
};
