import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import CmtAvatar from '@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import CmtList from '@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { usersModule } from '@fake-db';
import CommentItem from '../PostsList/PostItem/CommentItem';
import { AuhMethods } from 'services/auth';

const CommentListView = ({ open, onCloseDialog, item, itemType }) => {
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  const datas = useSelector(state => state.contentManagement.postViewDetail);
  const [pageNum, setPageNum] = useState(1);
  const dispatch = useDispatch();
  const classes = useStyles();
  // const { currentUser } = usersModule  //= useSelector(({ usersReducer }) => usersReducer);
  const { comments } = item;
  // const { name, email, status, phones, company, designation, profile_pic, starred } = currentUser;

  useEffect(() => {
    if (item?.id) {
      getPostDetail(10);
    }
  }, [item]);
  const getPostDetail = perPage => {
    let itemId = item?.id;
    dispatch(AuhMethods['contentManagement'].postDetailAPI(token, itemId, itemType, perPage));
  };
  const viewMore = () => {
    let page = (pageNum + 1) * 10;
    setPageNum(page);
    getPostDetail(page);
  };
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Box ml={1} style={{ textTransform: 'capitalize' }}>
                {itemType}
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Cross button */}
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box px={6} py={5}>
        {/* Comment List  */}
        {Array.isArray(datas?.data) && (
          <CmtList
            data={datas?.data}
            renderRow={(item, index) => <CommentItem key={index} item={item} itemType={itemType} classes={classes} />}
          />
        )}
        {Array.isArray(datas?.data) && datas?.data.length < datas?.meta?.total && (
          <p onClick={() => viewMore()}> view more</p>
        )}
      </Box>
    </Dialog>
  );
};

export default CommentListView;
// UserDetailView
CommentListView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
