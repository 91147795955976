import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import React from "react";
import axios from "../../config";
import {
  setReportsListData,
  setFlagTypesListData,
} from "redux/actions/ReportsManagement";
import { resolve } from "path";
import { reject } from "lodash";
import { AuhMethods } from "..";
import moment from "moment";

//Report Management apis
const ReportsManagementServicesAuth = {
  //Repors List API
  reportsListAPI: ({
    token,
    page,
    perPage,
    keyword,
    status,
    from_date,
    to_date,
    order,
  }) => {
    const pageData = page != "" ? "&page=" + page : "";
    order = order != "" ? order : "";
    // const pageData = "&page=" + page ? page : ""; //(page + 1);
    const keywordData =
      keyword != "" ? (keyword != undefined ? "&keyword=" + keyword : "") : "";
    const statusData = status != undefined ? "&status=" + status : "";
    from_date = from_date != undefined ? "&from_date=" + from_date : "";
    to_date = to_date != undefined ? "&to_date=" + to_date : "";

    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/post/report/list?${order}` +
              keywordData +
              "&perPage=" +
              perPage +
              pageData +
              statusData +
              from_date +
              to_date,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(setReportsListData(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // Flag Types List API
  flagTypesListAPI: ({ token, page, perPage }) => {
    const pageData = page != undefined ? "&page=" + (page + 1) : "";
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            "admin/post/report/categories?" + "&perPage=" + perPage + pageData,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(setFlagTypesListData(data.data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Add Flag Type API
  addFlagTypeAPI: (token, params) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .post("admin/post/report/add_category", params, {
            withCredentials: true,
          })
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));
              setTimeout(() => {
                dispatch(
                  ReportsManagementServicesAuth.flagTypesListAPI({
                    token: token,
                    page: "0",
                    perPage: "60",
                  })
                );
              }, 1000);
              //  dispatch(addFlagType(data.data))
            } else {
              dispatch(fetchError(data?.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },

  //Update Flag API
  updateFlagTypeAPI: (token, id, params) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put("admin/post/report/edit_category", id, params, {
            withCredentials: true,
          })
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));
              setTimeout(() => {
                dispatch(
                  AuhMethods["reportsManagement"].flagTypesListAPI({
                    token: token,
                    page: "0",
                    perPage: "60",
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },

  // Delete Flag API
  deleteFlagAPI: ({ token, id }) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .delete(
            "admin/post/report/delete_category/" + id,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));
              setTimeout(() => {
                dispatch(
                  AuhMethods["reportsManagement"].flagTypesListAPI({
                    token: token,
                    page: "0",
                    perPage: "60",
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
            // resolve(data)
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  },

  // Delete Flag Report API
  deleteFlagReportPost: (payload) => {
    // token, id
    return (dispatch) => {
      if (payload?.token) {
        axios.defaults.headers.common["Authorization"] = payload?.token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put(
            "/admin/post/report/delete",
            { post_id: payload?.id, action: payload?.action },
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));
              // dispatch(fetchSuccess('Selected post was deleted successfully.'));
              setTimeout(() => {
                dispatch(ReportsManagementServicesAuth.reportsListAPI(payload));
              }, 700);
              // dispatch(AuhMethods['reportsManagement'].flagTypesListAPI({ token: token, page: '0', perPage: '60' }));
            } else {
              dispatch(fetchError(data?.message));
            }
            // resolve(data)
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  },
};
export default ReportsManagementServicesAuth;
