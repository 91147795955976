import React from "react";
import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import { Typography, Grid } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import ReactTooltip from "react-tooltip";

const useStyles = makeStyles(() => ({
  bioTitle: {
    marginBottom: 16
  },
  wallRightSidebar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    "& a": {
      textDecoration: "none"
    }
  }
}));

const TokenDetails = ({ header, influencerDetails, coppyUrl, type }) => {
  const classes = useStyles();
  // const { biography } = userDetail;
  return (
    <Grid container>
      <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
        <CmtCardHeader title={`${header} :`} />
      </Grid>
      {type === "non-copy" ? (
        <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
          <Typography variant="p">{influencerDetails}</Typography>
        </Grid>
      ) : (
        <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
          {coppyUrl && (
            <div style={{ marginLeft: "5px" }}>
              <InfoIcon data-tip data-for={`tooltip-name+${coppyUrl}`} />

              <ReactTooltip
                id={`tooltip-name+${coppyUrl}`}
                type="warning"
                effect="solid"
              >
                <span>{coppyUrl}</span>
              </ReactTooltip>
            </div>
          )}
          <Link href={coppyUrl} target="_blank">
            <Typography variant="p">{influencerDetails} </Typography>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

export default TokenDetails;
