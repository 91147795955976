import React, { useState, useEffect } from "react";
import useStyles from "./index.style";
import { Button, Paper, Menu, Grid, Box, Typography } from "@material-ui/core";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import Axios from "axios";
import { API_URL } from "../../../../constant/constant";
import { useSelector } from "react-redux";
import ToastMessage from "routes/Dashboards/Intranet/TaskListExpandable/ToastMessage";
export default function UpdateVerification() {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    profileVerification: "",
    becomeAnInfluencer: ""
  });
  const [showMessage, setShowMessage] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const token = useSelector(state => state.admin.authUser.access_token);
  const getLimitData = async () => {
    try {
      const response = await Axios({
        method: "GET",
        url: `${API_URL}admin/user/get_limit`
      });
      if (response) {
        const result = response.data.data;
        setFormData({
          profileVerification: result.ver_limit,
          becomeAnInfluencer: result.inf_limit
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateLimitHandler = async () => {
    try {
      if (formData.profileVerification === "") {
        alert("Please enter profile verfication limit");
        return;
      }
      if (formData.becomeAnInfluencer === "") {
        alert("Please enter influencer limit");
        return;
      }
      setIsUpdating(true);
      const response = await Axios({
        method: "POST",
        url: `${API_URL}admin/user/set_verification_limit`,
        headers: {
          Authorization: token
        },
        data: {
          ver_limit: formData.profileVerification,
          inf_limit: formData.becomeAnInfluencer
        }
      });
      if (response.status === 200) {
        console.log("response", response);

        setShowMessage(true);
        getLimitData();
      }
      setIsUpdating(false);
    } catch (error) {
      console.log(error);
      setIsUpdating(false);
    }
  };
  useEffect(() => {
    getLimitData();
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box className={classes.innerContainer}>
          <Box mb={6}>
            <Typography variant="h2">Update minimum followers count</Typography>
          </Box>

          <Grid container spacing={4}>
            <Grid item lg={6} xs={12}>
              <AppTextInput
                fullWidth
                type="Number"
                variant="outlined"
                label={"Enter followers count for profile verification"}
                value={formData.profileVerification}
                onChange={e =>
                  setFormData({
                    ...formData,
                    profileVerification: e.target.value
                  })
                }
                // helperText={valueError}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <AppTextInput
                fullWidth
                type="Number"
                variant="outlined"
                label={"Enter followers count to become an influencer"}
                value={formData.becomeAnInfluencer}
                onChange={e =>
                  setFormData({
                    ...formData,
                    becomeAnInfluencer: e.target.value
                  })
                }
                // helperText={valueError}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={updateLimitHandler}
                disabled={isUpdating}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {showMessage && (
        <ToastMessage
          open={showMessage}
          onClose={() => setShowMessage(false)}
          message={"Limit has been updated successfully."}
        />
      )}
    </div>
  );
}
