import {
  SAVE_REPORTS_LIST_DATA,
  SAVE_FLAG_TYPES_LIST_DATA,
  APPEND_REPORTS_LIST_DATA,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  reportsList: [],
  reportListMeta: {},
  flagTypesList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_REPORTS_LIST_DATA: {
      return {
        ...state,
        reportsList: action.payload.data,
        reportListMeta: action.payload.meta,
      };
    }
    case SAVE_FLAG_TYPES_LIST_DATA: {
      return {
        ...state,
        flagTypesList: action.payload,
      };
    }

    case APPEND_REPORTS_LIST_DATA: {
      return {
        ...state,
        reportsList: [...state.reportsList, ...action.payload],
      };
    }

    default:
      return state;
  }
};
