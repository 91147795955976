import React, { useEffect } from "react";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import { timeFromNow } from "@jumbo/utils/dateHelper";
import {
  Block,
  CheckCircleOutline,
  Delete,
  Edit,
  Mail,
  MoreHoriz,
  PeopleAltOutlined,
  Visibility,
} from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { sentMailToUser, updateUserStatus } from "redux/actions/Users";

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getUserActions = (user) => {
  const actions = [
    { action: "view", label: "View", icon: <Visibility /> },
    { action: "delete", label: "Delete", icon: <Delete /> },
    { action: "profile", label: "Profile", icon: <PeopleAltOutlined /> },
  ];
  return actions;
};

const FlaggedReportListRow = ({
  row,
  isSelected,
  onRowClick,
  onUserEdit,
  onUserDelete,
  onUserView,
  onUserPostView,
  idx,
  page,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {}, [row]);
  const onUserMenuClick = (menu) => {
    if (menu.action === "view") {
      onUserPostView(row);
    } else if (menu.action === "delete") {
      onUserDelete(row);
    } else if (menu.action === "profile") {
      onUserView(row);
    }

    // else if (menu.action === 'block') {
    //   onUserDelete(row);
    // }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  const userActions = getUserActions(row);

  return (
    <TableRow
      hover
      // onClick={event => onRowClick(event, row.user_id, row)} // Press Row
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      {/* <TableCell padding="checkbox">
        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell> */}
      <TableCell>{page * 10 + idx + 1}</TableCell>

      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          {/* <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_pic} alt={row.name} />
          </Box> */}
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row?.post_data?.user_data.name
                ? row.post_data.user_data.name
                : "unknown"}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{timeFromNow(row?.post_data?.created_at)}</TableCell>
      {/* <TableCell> {row.post_meta_data.post_type} </TableCell> */}

      <TableCell>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row?.post_data?.post_meta_data[0]?.post_type !== undefined
            ? row?.post_data?.post_meta_data[0]?.post_type
            : "Text"}
        </Typography>
      </TableCell>
      <TableCell>{row?.report_user_data?.name}</TableCell>
      {/* <TableCell align="right">{row.emailUsage} GB</TableCell> */}
      <TableCell>{row?.report_keyword?.keyword}</TableCell>
      <TableCell>{timeFromNow(row?.created_at)}</TableCell>
      <TableCell
        className="333"
        style={{ width: 400, overflow: "ellipsis", whiteSpace: "nowrap" }}
      >
        {row?.post_data?.status === "0"
          ? "Inactive"
          : row?.post_data?.status === "1"
          ? "Active"
          : "Deleted"}
      </TableCell>
      {/* <TableCell>
        <button onClick={() => onUserPostView(row)}>View </button>
      </TableCell>
      <TableCell>
        <button onClick={() => onUserDelete(row)}>Delete</button>
      </TableCell>
      <TableCell>
        <button onClick={() => onUserView(row)}>Profile</button>
      </TableCell> */}

      <TableCell align="center" onClick={(event) => event.stopPropagation()}>
        <CmtDropdownMenu
          row={row}
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(FlaggedReportListRow);
