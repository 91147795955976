import React, { useState, useEffect } from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import PageStepper from "./PageStepper";
import CreatePage from "./CreatePage";
import CustomisePage from "./CustomisePage";
import LastCustomise from "./LastCustomise";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "constant/constant";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";

const useStyles = makeStyles(theme => ({
  IndexPageContainer: {
    padding: "30px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px"
    },
    "& .StepperBox": {
      maxWidth: "600px"
    }
  }
}));
export default function() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [tabButton, setTabButton] = useState("step1");
  const [valuesData, setValuesData] = useState({
    pageName: "",
    category: "",
    General: "",
    email: "",
    phone: "",
    province: "",
    state: "",
    postcode: "",
    country: "",
    profile_pic: "",
    cover_pic: ""
  });
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token);
  const actionType = window.location.hash.split("#")[1];

  const ViewAPIHandlet = async () => {
    const idds = window.location.search.split("?")[1];
    try {
      dispatch(fetchStart());
      await axios({
        method: "GET",
        url: `${API_URL}/admin/pages/detail/${idds}`,
        headers: {
          Authorization: token
        }
      })
        // return res;
        .then(({ data }) => {
          if (data.data) {
            const result = data.data;
            dispatch(fetchSuccess());
            setValuesData({
              pageName: result.name,
              category: result.genre,
              General: result.bio,
              email: result.email,
              phone: result.phone_number,
              province: result.city,
              state: result.state,
              postcode: result.zip_code,
              country: result.country,
              profile_pic: result.profile_pic,
              cover_pic: result.cover_pic
            });
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  useEffect(() => {
    ViewAPIHandlet();
  }, []);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  return (
    <Paper>
      <Box className={classes.IndexPageContainer}>
        <Box align="center">
          <Box className="StepperBox">
            <PageStepper
              activeStep={activeStep}
              handleNext={() => handleNext()}
              setActiveStep={setActiveStep}
            />
          </Box>
        </Box>
        {tabButton === "step1" && (
          <CreatePage
            setTabButton={setTabButton}
            handleNext={() => handleNext()}
            setValuesData={setValuesData}
            valuesData={valuesData}
            actionType={actionType}
          />
        )}
        {tabButton === "step2" && (
          <CustomisePage
            setTabButton={setTabButton}
            handleNext={() => handleNext()}
            setValuesData={setValuesData}
            valuesData={valuesData}
          />
        )}
        {tabButton === "step3" && (
          <LastCustomise valuesData={valuesData} actionType={actionType} />
        )}
      </Box>
    </Paper>
  );
}
