import React from "react";
import TableCell from "@material-ui/core/TableCell";
import InfoIcon from "@material-ui/icons/Info";
import ReactTooltip from "react-tooltip";
import TableRow from "@material-ui/core/TableRow";
import { toTitleCase } from "@jumbo/utils/dateHelper";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    marginLeft: 30
  },
  titleRoot1: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
    // marginLeft: 30,
  }
}));

const ContentListRow = ({ row, isSelected, onFlagEdit, onFladDelete }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onFlagEditClick = () => {
    onFlagEdit(row);
  };

  const onUserMenuClick = menu => {
    if (menu.action === "edit") {
      onFlagEdit(row);
    } else if (menu.action === "delete") {
      onFladDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
              style={{ display: "flex", alignItems: "center" }}
            >
              {toTitleCase(row.key_name)}
              {row.description && (
                <div style={{ marginLeft: "5px" }}>
                  <InfoIcon data-tip data-for={`tooltip-name+${row.id}`} />

                  <ReactTooltip
                    id={`tooltip-name+${row.id}`}
                    type="warning"
                    effect="solid"
                  >
                    <span>{row.description}</span>
                  </ReactTooltip>
                </div>
              )}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.verify_user_value ? <span>{row.value} </span> : row.value}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.verify_user_value && <span> {row.verify_user_value} </span>}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.influencer_user_value && (
                <span> {row.influencer_user_value}</span>
              )}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot1}
              component="div"
              variant="h4"
            >
              {row.status === 1 ? "Active " : "Inactive"}
            </Typography>
          </div>
        </Box>
      </TableCell>

      <TableCell align="left" onClick={event => event.stopPropagation()}>
        <Button variant="contained" color="primary" onClick={onFlagEditClick}>
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(ContentListRow);
