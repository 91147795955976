import React, { useState } from "react";
import CmtList from "../../../../../../@coremat/CmtList";
import PagePostItem from "./PagePostItem";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { postsList, user } from "@fake-db/apps/wall";
import { AuhMethods } from "services/auth";

const PostsList = ({ postData, type }) => {
  // const { feedPosts } = useSelector(({ wallApp }) => wallApp);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.admin.authUser.access_token); //Fetch data from Redux

  const feedPosts = postsList; // FROM FAKE DATABASE
  const pagePostsData = useSelector(
    (state) => state?.businessPageManagement?.pagePostsData
  );

  // let pagePostsDataArray = [];

  // if (pagePostsData) {
  //   pagePostsDataArray.push(pagePostsData);
  // }

  // console.log('pagePostsDataArray==',PagePostData)

  const loadMoreData = () => {
    // dispatch(
    //   AuhMethods['usersModule'].userMorePostsAPI({
    //     token: token,
    //     userId: '1',
    //     page: '1',
    //     perPage: '10',
    //     type: 'latest',
    //   }),
    // ); // page, perPage, type
  };

  return (
    <CmtList
      data={pagePostsData}
      renderRow={(item, index) => (
        <Box mb={6} key={index}>
          {console.log(item, "jjjjjjjjjj")}
          <PagePostItem item={item} postData={pagePostsData} type={type} />
        </Box>
      )}
      onEndReached={loadMoreData}
    />
  );
};

export default PostsList;
