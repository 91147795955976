import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CreatePost from './CreatePost';
import PostsList from './PostsList';
import { useDispatch } from 'react-redux';
import { getFeedPosts } from '../../../../redux/actions/WallApp';
import { getWallHeight } from '../../../../@jumbo/constants/AppConstants';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppContext from '../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext';
import PostsTabOptions from './PostsTabOptions';
import PostItem from './PostsList/PostItem';
import { postsList, user } from '@fake-db/apps/wall';

const useStyles = makeStyles(() => ({
  perfectScrollbarNewsFeed: {
    height: props => `calc(100vh - ${props.height}px)`,
  },
}));

const NewsFeed = ({ width, reportsManagementData, handleScroll }) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const classes = useStyles({
    height: getWallHeight(width, showFooter),
  });
  const [tabValue, setTabValue] = useState('latestPosts');

  useEffect(() => {
    // dispatch(getFeedPosts());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const item = reportsManagementData;
  const fakeItem = postsList[0];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarNewsFeed} onScroll={handleScroll}>
      {/* <Box mb={6}> */}
      {/* <CreatePost /> */}
      {/* <PostsTabOptions /> */}

      {/* <PostsTabOptions
        classes={classes}
        userDetail={{ name: 'abc' }}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
      /> */}
      {/* </Box> */}
      {/* <PostsList /> */}
      <PostItem item={item} />
    </PerfectScrollbar>
  );
};

export default NewsFeed;
