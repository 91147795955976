import {
  ADD_REWARD_LIST_DATA,
  GET_REWARD_LIST_DATA,
  GET_REWARD_LIST_META,
  GET_REWARD_TYPES_LIST,
  SUCCESS_UPLOAD_CSV,
  GET_AIRDROP_LIST_DATA,
  GET_COIN_LIST_DATA,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  rewardList: [],
  rewardListMeta: {},
  rewardTypesList: [],
  addRewardList: [],
  csvFile: {},
  airdropList: {},
  CoinList: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REWARD_LIST_DATA: {
      return {
        ...state,
        rewardList: action.payload,
      };
    }

    case GET_REWARD_LIST_META: {
      return {
        ...state,
        rewardListMeta: action.payload,
      };
    }
    case GET_REWARD_TYPES_LIST: {
      return {
        ...state,
        rewardTypesList: action.payload,
      };
    }

    case ADD_REWARD_LIST_DATA: {
      return {
        ...state,
        addRewardList: action.payload,
      };
    }

    case SUCCESS_UPLOAD_CSV: {
      return {
        ...state,
        csvFile: action.payload,
      };
    }

    case GET_AIRDROP_LIST_DATA: {
      return {
        ...state,
        airdropList: action.payload,
      };
    }
    case GET_COIN_LIST_DATA: {
      return {
        ...state,
        CoinList: action.payload.data,
      };
    }

    default:
      return state;
  }
};
