// import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
const API_HOST = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: API_HOST, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
    // withCredentials: true,
  }
});

// const dispatch = useDispatch();
// const token = useSelector((state) => state.admin.authUser.access_token);
export const handleUploadFiles = async (filedata, token, type) => {
  var data = new FormData();
  data.append("image", filedata);
  data.append("type", type);
  try {
    const response = await axios({
      method: "POST",
      url: `${API_HOST}admin/file/upload`,
      headers: {
        Authorization: token
      },
      data: data
    });
    if (response.data.data) {
      const splitImg = response.data.data?.Key; //main network
      return splitImg;
    } else {
      // toast.error(response.data.message);
    }
  } catch (err) {
    // console.error(err.response);
  }
};
