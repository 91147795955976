import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import React from "react";

const headCells = [
  {
    id: "sr_no.",
    numeric: false,
    disablePadding: false,
    label: "Sr No."
  },
  {
    id: "interest_name",
    numeric: false,
    disablePadding: false,
    label: "Interest Name"
  },
  {
    id: "no_post",
    numeric: false,
    disablePadding: false,
    label: "Posts"
  },
  {
    id: "no_like",
    numeric: false,
    disablePadding: false,
    label: "Likes"
  },
  {
    id: "no_comment",
    numeric: false,
    disablePadding: false,
    label: "Comment"
  },
  {
    id: "no_users",
    numeric: false,
    disablePadding: false,
    label: "Users"
  }
  // { id: "Verified", numeric: false, disablePadding: false, label: "Category" },
];

function UserTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className="like-padding"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default UserTableHead;
