import React, { useState } from 'react';
import CmtCard from '../../../../@coremat/CmtCard';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import { IMAGE_URL } from '../../../../constant/constant';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: '100%',
    '& .Cmt-card-content': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleRoot: {
    marginBottom: 20,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 25,
  },
  btnRoot: {
    height: 40,
  },
}));

const options = [
  { title: 'Posts', slug: 'posts' },
  { title: 'Likes', slug: 'likes' },
  { title: 'Comments', slug: 'comments' },
];

const DownloadSampleFile = () => {
  const classes = useStyles();
  const [type, setType] = useState('');
  const [typeError, setTypeError] = useState('');

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardContent>
        <Typography component="div" variant="h4" className={classes.titleRoot}>
          Sample File
        </Typography>
        {/* <Typography component="div" variant="h2" className={classes.subTitle}>
          Dont's miss our weekly news and updates
        </Typography> */}
        {/* <Box mb={4}>
           <TextField fullWidth id="uncontrolled" label="Enter User name" defaultValue="xyz@gmai" variant="outlined" /> 
          <AppSelectBox
            fullWidth
            data={options}
            label="Type"
            valueKey="slug"
            variant="outlined"
            labelKey="title"
            value={type}
            onChange={e => {
              setType(e.target.value);
              setTypeError('');
            }}
            helperText={typeError}
          />
        </Box> */}
        <Box mt="auto">
          {/* <Button
            className={classes.btnRoot}
            variant="contained"
            color="primary"
            htmltype="submit"
            // disabled={!type.trim() ? true : false}
            onClick={() => downloadSampleDatafile()}>
            Download
          </Button> */}
          <a href={`${IMAGE_URL}airdrop_files/reward_sample.csv`} download="proposed_file_name">
            Download
          </a>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default DownloadSampleFile;
