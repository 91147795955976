import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import Admin from './Admin';
import Dashboard from './dashboard';
import UsersModule from './UsersModule';
import ReportsManagement from './ReportsManagement';
import RewardsManagement from './RewardsManagement';
import ContentManagement from './ContentManagement';
import WalletManagement from './WalletManagement';
import AppsSetting from './AppsSetting';
import BusinessPageManagement from './BusinessPageManagement';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    admin: Admin,
    dashboard: Dashboard,
    usersModule: UsersModule,
    businessPageManagement: BusinessPageManagement,
    reportsManagement: ReportsManagement,
    rewardsManagement: RewardsManagement,
    contentManagement: ContentManagement,
    walletManagement: WalletManagement,
    appSetting: AppsSetting,
  });
