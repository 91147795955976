import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { updatePostLikeStatus } from '../../../../../../redux/actions/WallApp';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CommentIcon from '@material-ui/icons/Comment';
import ShareIcon from '@material-ui/icons/Share';
import Delete from '@material-ui/icons/Delete';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import CommentListView from '../../CommentListView';
import like from '../../../../../../images/like.png';
import dislike from '../../../../../../images/dislike.png';
import comment from '../../../../../../images/comment.png';
import view from '../../../../../../images/view.png';
import { AuhMethods } from 'services/auth';
import { postDetailAPIsuccess } from 'redux/actions/ContentManagement';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  iconSm: {
    fontSize: '16px !important',
    marginRight: 6,
  },
}));

const PostStats = ({ item }) => {
  const [itemType, setItemType] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [page, setPage] = React.useState(1);
  // const [selectedUser, setSelectedUser] = useState({});
  const [perPage, setPerPage] = React.useState(10);
  const token = useSelector(state => state.admin.authUser.access_token);
  const postLikesComent = useSelector(state => state?.contentManagement?.postViewDetail);

  useEffect(() => {}, [postLikesComent]);
  useEffect(() => {
    // dispatch(AuhMethods['usersModule'].postCommentsAPI({ token, commentId, page, perPage }));
    dispatch(AuhMethods['contentManagement'].postDetailAPI(token, item?.post_id, itemType, perPage));
  }, [item, itemType]);
  const toggleLikeStatus = () => {
    dispatch(updatePostLikeStatus(item.id, !item.liked));
  };

  const toggleCommentsClick = () => {
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    dispatch(postDetailAPIsuccess([]));
    setOpenViewDialog(false);
  };

  const handleUserDelete = user => {
    // setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    // dispatch(deleteUser(selectedUser.id));
    //   item?.post_data?.id
    let userId = item?.post_data?.user_id;
    let postId = item?.post_data?.id;
    let uAction = item?.post_data?.status == 0 || item?.post_data?.status == 2 ? '1' : '0';
    let params = {
      token: token,
      userId: userId,
      id: postId,
      action: uAction,
      page: page,
      perPage: perPage,
      keyword: '',
    };
    dispatch(AuhMethods['reportsManagement'].deleteFlagReportPost(params));
    // dispatch(AuhMethods['usersModule'].deleteUserReportPost(token, postId, page, perPage, uAction, userId));
    // dispatch(AuhMethods['usersModule'].deleteUserPostDashAPI(token, item?.id, page, perPage, uAction, item?.user_id, type));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  // const { likes, comments, shares, views } = item;
  const { total_likes: likes, total_dislikes, total_comments: comments, total_views } = item.post_data;
  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" mb={{ xs: 4, sm: 6 }} color="text.disabled" fontSize={12}>
      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        onClick={() => {
          toggleCommentsClick();
          setItemType('likes');
        }}
        // onClick={toggleLikeStatus}
        className="pointer">
        {/* <Box display="flex" alignItems="center" mr={5} mb={{ xs: 2, sm: 0 }} className="pointer"> */}
        {/* <ThumbUpAltIcon className={classes.iconSm} /> */}
        <img src={`${like}`} className={`${classes.iconSm} customStyleIcons`} />
        <Box ml={1}>{likes > 0 ? likes : 0}</Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        onClick={() => {
          toggleCommentsClick();
          setItemType('dislikes');
        }}
        className="pointer">
        <img src={`${dislike}`} className={`${classes.iconSm} customStyleIcons`} />
        <Box ml={1}>{total_dislikes > 0 ? total_dislikes : 0} </Box>
      </Box>
      {/* <Box display="flex" alignItems="center" mr={5} mb={{ xs: 2, sm: 0 }} className="pointer">
        <VisibilityIcon className={classes.iconSm} />
        <Box ml={1}>{views > 0 && views} views</Box>
      </Box> */}
      {/* <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 0 }} className="pointer"> */}
      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        onClick={() => {
          toggleCommentsClick();
          setItemType('comments');
        }}
        className="pointer">
        {/* <CommentIcon className={classes.iconSm} /> */}
        <img src={`${comment}`} className={`${classes.iconSm} customStyleIcons`} />
        {/* <Box ml={1}>{comments.length > 0 && comments.length} comments</Box> */}
        <Box ml={1}>{comments > 0 ? comments : 0} </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        onClick={() => {
          // toggleCommentsClick();
          setItemType('viwes');
        }}
        className="pointer">
        {/* <CommentIcon className={classes.iconSm} /> */}
        <img src={`${view}`} className={`${classes.iconSm} customStyleIcons`} />
        <Box ml={1}>{total_views > 0 ? total_views : 0} </Box>
      </Box>
      <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 0 }} ml="auto" className="pointer">
        {/* <ShareIcon className={classes.iconSm} /> */}
        {/* Delete Post Button */}
        {/* <Delete className={classes.iconSm} /> */}
        {/* <DeleteOutlineIcon className={`${classes.iconSm} customStyleIcons`} /> */}
        <Button variant="contained" color="primary" onClick={handleUserDelete} disabled={item?.post_data?.status == '2'}>
          {item?.post_data?.status == '0' || item?.post_data?.status == '2' ? 'Active' : 'Inactive'}
        </Button>
        {/* <Box ml={1}>Delete</Box> */}
      </Box>
      {/* Pass data with item key on the comment list view */}
      {openViewDialog && Array.isArray(postLikesComent?.data) && postLikesComent?.data.length > 0 && (
        <CommentListView
          open={openViewDialog}
          onCloseDialog={handleCloseViewDialog}
          commentId={item?.post_id}
          itemType={itemType}
          comments={postLikesComent}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`${item?.post_data?.status == 0 || item?.post_data?.status == 2 ? 'Active' : 'Inactive'}`}
        content={`Are you sure, you want to ${
          item?.post_data?.status == 0 || item?.post_data?.status == 2 ? 'active' : 'inactive'
        } this post?`}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  );
};

export default PostStats;

PostStats.prototype = {
  item: PropTypes.object.isRequired,
};
