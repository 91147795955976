import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { updateAuthUser } from '../../../redux/actions/Admin';
import React from 'react';
import axios from '../../config';
import { paswordChanges } from 'redux/actions/Auth';

//Account Settings apis
const AccountSettingsAuth = {
  //Edit Email API
  // editEmailAPI: ({ email, token }) => {
  //   return dispatch => {
  //     if (token) {
  //       axios.defaults.headers.common['Authorization'] = token;
  //     }

  //     try {
  //       dispatch(fetchStart());
  //       axios
  //         .put('admin/edit_email', { email: email }, { withCredentials: true })
  //         .then(({ data }) => {
  //           if (data.message) {
  //             dispatch(fetchSuccess(data.message));
  //             dispatch(updateAuthUser(email));
  //           }
  //         })
  //         .catch(function(error) {
  //           dispatch(fetchError(error.response.data.message));
  //         });
  //     } catch (error) {
  //       dispatch(fetchError(error.message));
  //     }
  //   };
  // },

  //Change Password API
  changePasswordAPI: ({ data, token }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common['Authorization'] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put(
            'admin/change_password',
            {
              old_password: data.oldPassword,
              new_password: data.newPassword,
              confirm_password: data.confirmPassword,
            },
            { withCredentials: true },
          )
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data.message));
              dispatch(paswordChanges(true));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response.data.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
};

export default AccountSettingsAuth;
