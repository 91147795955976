import Box from '@material-ui/core/Box';
import React from 'react';
import { getCustomDateTime } from '../../@jumbo/utils/dateHelper';

export const statisticsGraphData = [
  { month: 'Jan', price: 200 },
  { month: 'Feb', price: 300 },
  { month: 'Mar', price: 550 },
  { month: 'Apr', price: 500 },
  { month: 'May', price: 700 },
  { month: 'Jun', price: 450 },
  { month: 'Jul', price: 770 },
  { month: 'Aug', price: 900 },
];

export const dealsAnalyticsData = [
  { month: 'Jan', queries: 400, deals: 400 },
  { month: 'Feb', queries: 500, deals: 600 },
  { month: 'Mar', queries: 400, deals: 300 },
  { month: 'Apr', queries: 350, deals: 200 },
  { month: 'May', queries: 700, deals: 700 },
  { month: 'Jun', queries: 100, deals: 600 },
  { month: 'Jul', queries: 500, deals: 50 },
  { month: 'Aug', queries: 350, deals: 550 },
  { month: 'Sep', queries: 300, deals: 200 },
  { month: 'Oct', queries: 200, deals: 500 },
  { month: 'Nov', queries: 200, deals: 600 },
  { month: 'Dec', queries: 200, deals: 100 },
];

export const popularAgents = [
  {
    id: 1,
    name: 'Albert Hall',
    deals: 23,
    profilePic: 'https://via.placeholder.com/150x150',
    rating: 3.5,
    profileCompleted: 10,
  },
  {
    id: 2,
    name: 'John Hall',
    deals: 20,
    profilePic: 'https://via.placeholder.com/150x150',
    rating: 4.5,
    profileCompleted: 10,
  },
  {
    id: 3,
    name: 'Jackson Hall',
    deals: 21,
    profilePic: 'https://via.placeholder.com/150x150',
    rating: 3.5,
    profileCompleted: 10,
  },
  {
    id: 4,
    name: 'Jonty Hall',
    deals: 22,
    profilePic: 'https://via.placeholder.com/150x150',
    rating: 4.5,
    profileCompleted: 10,
  },
  {
    id: 5,
    name: 'Jonathan Hall',
    deals: 23,
    profilePic: 'https://via.placeholder.com/150x150',
    rating: 3.5,
    profileCompleted: 10,
  },
  {
    id: 6,
    name: 'Shane Hall',
    deals: 24,
    profilePic: 'https://via.placeholder.com/150x150',
    rating: 4.5,
    profileCompleted: 10,
  },
  {
    id: 7,
    name: 'Lisa Hall',
    deals: 25,
    profilePic: 'https://via.placeholder.com/150x150',
    rating: 3.5,
    profileCompleted: 10,
  },
  {
    id: 8,
    name: 'Cheeni Hall',
    deals: 26,
    profilePic: 'https://via.placeholder.com/150x150',
    rating: 4.5,
    profileCompleted: 10,
  },
  {
    id: 9,
    name: 'Chilbram Hall',
    deals: 27,
    profilePic: 'https://via.placeholder.com/150x150',
    rating: 3.5,
    profileCompleted: 10,
  },
  {
    id: 10,
    name: 'Danny Hall',
    deals: 83,
    profilePic: 'https://via.placeholder.com/150x150',
    rating: 4.5,
    profileCompleted: 10,
  },
];

export const propertyTabCategories = [
  { name: 'New Jersey', slug: '1' },
  { name: 'Colorado', slug: '2' },
  { name: 'Albama', slug: '3' },
];

export const propertiesList = [
  {
    id: 1,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title:
      'Luxury family home at beach side Luxury family home at beach side Luxury family home at beach side Luxury family home at beach side Luxury family home at beach side  ',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 26, 2020',
    availability: 'sale',
    isTrending: true,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'new_jersey',
  },
  {
    id: 2,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title: 'Sunset view Apartment in Colarado',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 25, 2020',
    availability: 'rent',
    isTrending: false,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'colorado',
  },
  {
    id: 3,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title: 'Best property in Albama',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 23, 2020',
    availability: 'rent',
    isTrending: false,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'albama',
  },
  {
    id: 4,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title: 'Best house deal in New jersey',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 24, 2020',
    availability: 'sale',
    isTrending: false,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'new_jersey',
  },
  {
    id: 5,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title: 'Luxury apartment in Colarado',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 28, 2020',
    availability: 'rent',
    isTrending: true,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'colorado',
  },
  {
    id: 6,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title: 'Plot in Albama',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 29, 2020',
    availability: 'sale',
    isTrending: true,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'albama',
  },
  {
    id: 7,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title: 'House in New jersey',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 24, 2020',
    availability: 'sale',
    isTrending: false,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'new_jersey',
  },
  {
    id: 8,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title: 'Flat in Colarado',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 20, 2020',
    availability: 'rent',
    isTrending: true,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'colorado',
  },
  {
    id: 9,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title: '3 BHK house in Albama',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 28, 2020',
    availability: 'sale',
    isTrending: false,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'albama',
  },
  {
    id: 10,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title: 'Best house for family in New Jersey',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 26, 2020',
    availability: 'rent',
    isTrending: true,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'new_jersey',
  },
  {
    id: 11,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title: 'Villa in Colarado',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 16, 2020',
    availability: 'rent',
    isTrending: true,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'colorado',
  },
  {
    id: 12,
    images: [
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 1',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 2',
      },
      {
        image: 'https://via.placeholder.com/640x420',
        title: 'image 3',
      },
    ],
    title: 'Sunrise view apartment in Albama',
    address: '2972, Washington Road, New Jersey',
    bedrooms: 3,
    bathrooms: 3,
    area: '1400 m2',
    owner: { id: 1, name: 'John Nash' },
    publishedDate: 'June 28, 2020',
    availability: 'sale',
    isTrending: false,
    price: '$670,500',
    pricePerSqFt: '$587/sqft',
    category: 'albama',
  },
];

export const recentActivities = [
  {
    id: 1,
    date: getCustomDateTime(0, 'days', 'MMM DD, YYYY'),
    user: {
      id: 12,
      name: 'Alex Dolgove',
      profilePic: 'https://via.placeholder.com/150x150',
    },
    mediaList: [
      {
        id: 123,
        name: 'Media1',
        mediaUrl: 'https://via.placeholder.com/640x420',
      },
      {
        id: 124,
        name: 'Media2',
        mediaUrl: 'https://via.placeholder.com/640x420',
      },
      {
        id: 125,
        name: 'Media3',
        mediaUrl: 'https://via.placeholder.com/640x420',
      },
    ],
    // content:
    // [
    // <Box component="span" color="primary.main" className="pointer" mr={1}>
    //   Alex Dolgove
    // </Box>,
    title: 'left a 5 star review on Albama’s House',
    // ],
  },
  // {
  //   id: 2,
  //   date: getCustomDateTime(0, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Kailasha',
  //     profilePic: '',
  //   },
  //   mediaList: [],
  //   content: [
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Kailasha
  //     </Box>,
  //     'is looking for a house in New Jersey, USA',
  //   ],
  // },
  // {
  //   id: 3,
  //   date: getCustomDateTime(0, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Chelsea Johns',
  //     profilePic: 'https://via.placeholder.com/150x150',
  //   },
  //   mediaList: [],
  //   content: [
  //     'Agent ',
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Chelsea Johns
  //     </Box>,
  //     'has added 7 new photos to the property ',
  //     <Box component="span" color="primary.main">
  //       Albama's house
  //     </Box>,
  //   ],
  // },
  // {
  //   id: 4,
  //   date: getCustomDateTime(-1, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Domnic Brown',
  //     profilePic: 'https://via.placeholder.com/150x150',
  //   },
  //   mediaList: [
  //     {
  //       id: 123,
  //       name: 'Media1',
  //       mediaUrl: 'https://via.placeholder.com/640x420',
  //     },
  //     {
  //       id: 124,
  //       name: 'Media1',
  //       mediaUrl: 'https://via.placeholder.com/640x420',
  //     },
  //     {
  //       id: 125,
  //       name: 'Media1',
  //       mediaUrl: 'https://via.placeholder.com/640x420',
  //     },
  //   ],
  //   content: [
  //     'Welcome to a new agent ',
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Domnic Brown
  //     </Box>,
  //     'in the company.',
  //   ],
  // },
  // {
  //   id: 5,
  //   date: getCustomDateTime(-1, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Michael Dogov',
  //     profilePic: 'https://via.placeholder.com/150x150',
  //   },
  //   mediaList: [],
  //   content: [
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Michael Dogov
  //     </Box>,
  //     'is looking for an office space in Colarado, USA.',
  //   ],
  // },
  // {
  //   id: 6,
  //   date: getCustomDateTime(-2, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Domnic Harris',
  //     profilePic: 'https://via.placeholder.com/150x150',
  //   },
  //   mediaList: [],
  //   content: [
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Domnic Harris
  //     </Box>,
  //     "left a 5 star rating on Albama's property.",
  //   ],
  // },
  // {
  //   id: 7,
  //   date: getCustomDateTime(-2, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Garry Sobars',
  //     profilePic: 'https://via.placeholder.com/150x150',
  //   },
  //   mediaList: [],
  //   content: [
  //     ' Callback request from ',
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Garry Sobars
  //     </Box>,
  //     'for the property ',
  //     <Box component="span" color="primary.main" className="pointer">
  //       Dmitri house
  //     </Box>,
  //   ],
  // },
  // {
  //   id: 8,
  //   date: getCustomDateTime(0, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Guptil Sharma',
  //     profilePic: 'https://via.placeholder.com/150x150',
  //   },
  //   mediaList: [
  //     {
  //       id: 123,
  //       name: 'Media1',
  //       mediaUrl: 'https://via.placeholder.com/122x122',
  //     },
  //     {
  //       id: 124,
  //       name: 'Media2',
  //       mediaUrl: 'https://via.placeholder.com/122x122',
  //     },
  //     {
  //       id: 125,
  //       name: 'Media3',
  //       mediaUrl: 'https://via.placeholder.com/122x122',
  //     },
  //   ],
  //   content: [
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Guptil Sharma
  //     </Box>,
  //     "left a 5 star rating on Aloboma's house",
  //   ],
  // },
  // {
  //   id: 9,
  //   date: getCustomDateTime(0, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Jeson Born',
  //     profilePic: 'https://via.placeholder.com/150x150',
  //   },
  //   mediaList: [],
  //   content: [
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Jeson Born
  //     </Box>,
  //     'is looking for a house in New jersey, USA.',
  //   ],
  // },
  // {
  //   id: 10,
  //   date: getCustomDateTime(0, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Jimmy Jo',
  //     profilePic: 'https://via.placeholder.com/150x150',
  //   },
  //   mediaList: [],
  //   content: [
  //     'Agent ',
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Jimmy Jo
  //     </Box>,
  //     'has added 7 new photos to the property ',
  //     <Box component="span" color="primary.main">
  //       Albama's house
  //     </Box>,
  //   ],
  // },
  // {
  //   id: 11,
  //   date: getCustomDateTime(-1, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Jonathan Lee',
  //     profilePic: 'https://via.placeholder.com/150x150',
  //   },
  //   mediaList: [
  //     {
  //       id: 123,
  //       name: 'Media1',
  //       mediaUrl: 'https://via.placeholder.com/122x122',
  //     },
  //     {
  //       id: 124,
  //       name: 'Media1',
  //       mediaUrl: 'https://via.placeholder.com/122x122',
  //     },
  //     {
  //       id: 125,
  //       name: 'Media1',
  //       mediaUrl: 'https://via.placeholder.com/122x122',
  //     },
  //   ],
  //   content: [
  //     'Welcome to a new agent ',
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Jonathan Lee
  //     </Box>,
  //     'in the company.',
  //   ],
  // },
  // {
  //   id: 12,
  //   date: getCustomDateTime(-1, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Joshua',
  //     profilePic: 'https://via.placeholder.com/150x150',
  //   },
  //   mediaList: [],
  //   content: [
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Joshua
  //     </Box>,
  //     'is looking for an office space in Colarado, USA.',
  //   ],
  // },
  // {
  //   id: 13,
  //   date: getCustomDateTime(-2, 'days', 'MMM DD, YYYY'),
  //   user: {
  //     id: 12,
  //     name: 'Stella Johnson',
  //     profilePic: 'https://via.placeholder.com/150x150',
  //   },
  //   mediaList: [],
  //   content: [
  //     <Box component="span" color="primary.main" className="pointer" mr={1}>
  //       Stella Johnson
  //     </Box>,
  //     "left a 5 star rating on Albama's property.",
  //   ],
  // },
];

export const newCustomers = [
  {
    id: 1,
    name: 'Albert Hall',
    profilePic: 'https://via.placeholder.com/150x150',
  },
  {
    id: 2,
    name: 'John Hall',
    profilePic: 'https://via.placeholder.com/150x150',
  },
  { id: 3, name: 'Jackson Hall', profilePic: '' },
  {
    id: 4,
    name: 'Jonty Hall',
    profilePic: 'https://via.placeholder.com/150x150',
  },
  {
    id: 5,
    name: 'Jonathan Hall',
    profilePic: 'https://via.placeholder.com/150x150',
  },
  {
    id: 6,
    name: 'Shane Hall',
    profilePic: 'https://via.placeholder.com/150x150',
  },
  {
    id: 7,
    name: 'Lisa Hall',
    profilePic: 'https://via.placeholder.com/150x150',
  },
  { id: 8, name: 'Cheeni Hall', profilePic: '' },
  {
    id: 9,
    name: 'Chilbram Hall',
    profilePic: 'https://via.placeholder.com/150x150',
  },
  {
    id: 10,
    name: 'Danny Hall',
    profilePic: 'https://via.placeholder.com/150x150',
  },
];

//USERS

const today = new Date();
const currentYear = today.getFullYear();
const loginYear = 2021;

export const usersModule = [
  {
    id: 1457690400,
    name: 'Alex Dolgove',
    profile_pic: 'https://via.placeholder.com/150',
    email: 'alex-dolgove@example.com',
    emailUsage: 1.11, // GB
    phones: [{ phone: '(215)-659-7529', label: 'Home' }],
    designation: 'CEO',
    starred: false,
    company: 'TCS',
    labels: [2],
    status: 'active',
    suspendedBy: 'Admin',
    suspendedAt: new Date(),
    lastLoginAt: new Date(loginYear, 1, 1),
    createdAt: new Date(currentYear, 1, 1),
    updatedAt: new Date(currentYear, 1, 1),
  },
  {
    id: 1457690401,
    name: 'Chelsea Johns',
    profile_pic: 'https://via.placeholder.com/150',
    email: 'chelsea-johnss@example.com',
    emailUsage: 1.6, // GB
    phones: [{ phone: '(215)-659-7529', label: 'home' }],
    designation: 'CFO',
    starred: false,
    company: 'Infosys',
    labels: [2, 1],
    status: 'suspended',
    suspendedBy: 'Admin',
    suspendedAt: new Date(currentYear, 1, 1),
    lastLoginAt: new Date(loginYear, 2, 17),
    createdAt: new Date(currentYear, 1, 1),
    updatedAt: new Date(currentYear, 1, 1),
  },
  {
    id: 1457690402,
    name: 'Domnic Brown',
    profile_pic: 'https://via.placeholder.com/150',
    email: 'domnic-brown@example.com',
    emailUsage: 1.8, // GB
    phones: [{ phone: '(215)-748-7855', label: 'home' }],
    designation: 'Designer',
    starred: false,
    company: 'Accenture',
    labels: [3],
    status: 'active',
    suspendedBy: 'Admin',
    suspendedAt: new Date(),
    lastLoginAt: new Date(loginYear, 6, 6),
    createdAt: new Date(currentYear, 1, 1),
    updatedAt: new Date(currentYear, 1, 1),
  },
  {
    id: 1457690403,
    name: 'Domnic Harris',
    profile_pic: 'https://via.placeholder.com/150',
    email: 'domnic-brown@example.com',
    emailUsage: 1.7, // GB
    phones: [{ phone: '(215)-659-7529', label: 'home' }],
    designation: 'PHP Developer',
    starred: false,
    company: 'Pizza Hut',
    labels: [],
    status: 'active',
    suspendedBy: 'Admin',
    suspendedAt: new Date(),
    lastLoginAt: new Date(loginYear, 5, 20),
    createdAt: new Date(currentYear, 1, 1),
    updatedAt: new Date(currentYear, 1, 1),
  },
  {
    id: 1457690404,
    name: 'Garry Sobars',
    profile_pic: 'https://via.placeholder.com/150',
    email: 'garry-sobars@example.com',
    emailUsage: 2.4, // GB
    phones: [{ phone: '(215)-659-8965', label: 'home' }],
    designation: 'HR Manager',
    starred: true,
    company: 'Dominos',
    labels: [],
    status: 'suspended',
    suspendedBy: 'Admin',
    suspendedAt: new Date(currentYear, 1, 1),
    lastLoginAt: new Date(loginYear, 5, 30),
    createdAt: new Date(currentYear, 1, 1),
    updatedAt: new Date(currentYear, 1, 1),
  },
  {
    id: 1457690405,
    name: 'Jeson Born',
    profile_pic: 'https://via.placeholder.com/150',
    email: 'jeson-born@example.com',
    emailUsage: 0.4, // GB
    phones: [{ phone: '(215)-876-5434', label: 'home' }],
    designation: 'Marketing Head',
    starred: false,
    company: 'Subway',
    labels: [],
    status: 'active',
    suspendedBy: 'Admin',
    suspendedAt: new Date(),
    lastLoginAt: new Date(loginYear, 6, 5),
    createdAt: new Date(currentYear, 1, 1),
    updatedAt: new Date(currentYear, 1, 1),
  },
  {
    id: 1457690406,
    name: 'Jimmy Jo',
    profile_pic: 'https://via.placeholder.com/150g',
    email: 'jimmy-jo@example.com',
    emailUsage: 0.9, // GB
    phones: [{ phone: '(215)-659-7529', label: 'home' }],
    designation: 'BDO',
    starred: true,
    company: 'Honda',
    labels: [],
    status: 'active',
    suspendedBy: 'Admin',
    suspendedAt: new Date(),
    lastLoginAt: new Date(loginYear, 4, 12),
    createdAt: new Date(currentYear, 1, 1),
    updatedAt: new Date(currentYear, 1, 1),
  },
  {
    id: 1457690407,
    name: 'John Smith',
    profile_pic: 'https://via.placeholder.com/150',
    email: 'john-smith@example.com',
    emailUsage: 1.1, // GB
    phones: [{ phone: '(215)-456-2346', label: 'home' }],
    designation: 'CCO',
    starred: false,
    company: 'TVS',
    labels: [],
    status: 'active',
    suspendedBy: 'Admin',
    suspendedAt: new Date(),
    lastLoginAt: new Date(loginYear, 4, 16),
    createdAt: new Date(currentYear, 1, 1),
    updatedAt: new Date(currentYear, 1, 1),
  },
  {
    id: 1457690408,
    name: 'Jonathan Modano',
    profile_pic: 'https://via.placeholder.com/150',
    email: 'jonathan-modano@example.com',
    emailUsage: 2.2, // GB
    phones: [{ phone: '(215)-278-4357', label: 'home' }],
    designation: 'Developer',
    starred: true,
    company: 'Hero',
    labels: [],
    status: 'active',
    suspendedBy: 'Admin',
    suspendedAt: new Date(),
    lastLoginAt: new Date(loginYear, 3, 21),
    createdAt: new Date(currentYear, 1, 1),
    updatedAt: new Date(currentYear, 1, 1),
  },
  {
    id: 1457690409,
    name: 'Kadir M',
    profile_pic: 'https://via.placeholder.com/150',
    email: 'kadir-m@example.com',
    emailUsage: 1.2, // GB
    phones: [{ phone: '(215)-286-0788', label: 'home' }],
    designation: 'UI-UX Designer',
    starred: false,
    company: 'Hyundai',
    labels: [],
    status: 'active',
    suspendedBy: 'Admin',
    suspendedAt: new Date(),
    lastLoginAt: new Date(loginYear, 3, 19),
    createdAt: new Date(currentYear, 1, 1),
    updatedAt: new Date(currentYear, 1, 1),
  },
  {
    id: 1457690410,
    name: 'Ken Ramirez',
    profile_pic: 'https://via.placeholder.com/150',
    email: 'ken-remirez@example.com',
    emailUsage: 3.1, // GB
    phones: [{ phone: '(215)-586-4676', label: 'home' }],
    designation: 'CEO',
    starred: false,
    company: 'Maruti',
    labels: [],
    status: 'active',
    suspendedBy: 'Admin',
    suspendedAt: new Date(),
    lastLoginAt: new Date(loginYear, 5, 11),
    createdAt: new Date(currentYear, 1, 1),
    updatedAt: new Date(currentYear, 1, 1),
  },
  // {
  //   id: 1457690500,
  //   name: 'Michael Dogov',
  //   profile_pic: 'https://via.placeholder.com/150',
  //   email: 'michael-dogov@example.com',
  //   emailUsage: 2.5, // GB
  //   phones: [{ phone: '(215)-659-7529', label: 'home' }],
  //   designation: 'CEO',
  //   starred: false,
  //   company: 'Chevrolet',
  //   labels: [],
  //   status: 'suspended',
  //   suspendedBy: 'Admin',
  //   suspendedAt: new Date(currentYear, 1, 1),
  //   lastLoginAt: new Date(loginYear, 2, 21),
  //   createdAt: new Date(currentYear, 1, 1),
  //   updatedAt: new Date(currentYear, 1, 1),
  // },
  // {
  //   id: 1457690501,
  //   name: 'Stella Johnson',
  //   profile_pic: 'https://via.placeholder.com/150',
  //   email: 'stella-johnson@example.com',
  //   emailUsage: 2.3, // GB
  //   phones: [{ phone: '(215)-745-2345', label: 'home' }],
  //   designation: 'CFO',
  //   starred: false,
  //   company: 'Morgan Garrage',
  //   labels: [],
  //   status: 'active',
  //   suspendedBy: 'Admin',
  //   suspendedAt: new Date(),
  //   lastLoginAt: new Date(loginYear, 3, 13),
  //   createdAt: new Date(currentYear, 1, 1),
  //   updatedAt: new Date(currentYear, 1, 1),
  // },
  // {
  //   id: 1457690502,
  //   name: 'Steve Smith',
  //   profile_pic: 'https://via.placeholder.com/150',
  //   email: 'steve-smith@example.com',
  //   emailUsage: 2.6, // GB
  //   phones: [{ phone: '(215)-748-3265', label: 'home' }],
  //   designation: 'Designer',
  //   starred: false,
  //   company: 'Tata',
  //   labels: [],
  //   status: 'active',
  //   suspendedBy: 'Admin',
  //   suspendedAt: new Date(),
  //   lastLoginAt: new Date(loginYear, 2, 23),
  //   createdAt: new Date(currentYear, 1, 1),
  //   updatedAt: new Date(currentYear, 1, 1),
  // },
  // {
  //   id: 1457690503,
  //   name: 'Noah Owens',
  //   profile_pic: 'https://via.placeholder.com/150',
  //   email: 'noah-owens@example.com',
  //   emailUsage: 1.6, // GB
  //   phones: [{ phone: '(215)-756-3465', label: 'home' }],
  //   designation: 'PHP Developer',
  //   starred: false,
  //   Company: 'Levis',
  //   labels: [],
  //   status: 'active',
  //   suspendedBy: 'Admin',
  //   suspendedAt: new Date(),
  //   lastLoginAt: new Date(loginYear, 3, 9),
  //   createdAt: new Date(currentYear, 1, 1),
  //   updatedAt: new Date(currentYear, 1, 1),
  // },
  // {
  //   id: 1457690404,
  //   name: 'Ellen Manning',
  //   profile_pic: '',
  //   email: 'ellen-manning@example.com',
  //   emailUsage: 1.6, // GB
  //   phones: [{ phone: '(215)-659-3246', label: 'home' }],
  //   designation: 'HR Manager',
  //   starred: true,
  //   company: 'John Players',
  //   labels: [],
  //   status: 'active',
  //   suspendedBy: 'Admin',
  //   suspendedAt: new Date(),
  //   lastLoginAt: new Date(loginYear, 2, 26),
  //   createdAt: new Date(currentYear, 1, 1),
  //   updatedAt: new Date(currentYear, 1, 1),
  // },
  // {
  //   id: 1457690505,
  //   name: 'Angel Jones',
  //   profile_pic: '',
  //   email: 'angel-jones@example.com',
  //   emailUsage: 0.8, // GB
  //   phones: [{ phone: '(215)-876-3246', label: 'home' }],
  //   designation: 'Marketing Head',
  //   starred: false,
  //   company: 'Jaguar',
  //   labels: [],
  //   status: 'active',
  //   suspendedBy: 'Admin',
  //   suspendedAt: new Date(),
  //   lastLoginAt: new Date(loginYear, 5, 18),
  //   createdAt: new Date(currentYear, 1, 1),
  //   updatedAt: new Date(currentYear, 1, 1),
  // },
  // {
  //   id: 1457690506,
  //   name: 'Dollie Norton',
  //   profile_pic: '',
  //   email: 'dollie-norton@example.com',
  //   emailUsage: 0.7, // GB
  //   phones: [{ phone: '(215)-785-0967', label: 'home' }],
  //   designation: 'BDO',
  //   starred: true,
  //   company: 'Reliance',
  //   labels: [],
  //   status: 'active',
  //   suspendedBy: 'Admin',
  //   suspendedAt: new Date(),
  //   lastLoginAt: new Date(loginYear, 6, 2),
  //   createdAt: new Date(currentYear, 1, 1),
  //   updatedAt: new Date(currentYear, 1, 1),
  // },
  // {
  //   id: 1457690507,
  //   name: 'Joshua Brian',
  //   profile_pic: '',
  //   email: 'joshua-brian@example.com',
  //   emailUsage: 0.6, // GB
  //   phones: [{ phone: '(215)-456-0677', label: 'home' }],
  //   designation: 'CCO',
  //   starred: false,
  //   company: 'Flipkart',
  //   labels: [],
  //   status: 'active',
  //   suspendedBy: 'Admin',
  //   suspendedAt: new Date(),
  //   lastLoginAt: new Date(loginYear, 6, 1),
  //   createdAt: new Date(currentYear, 1, 1),
  //   updatedAt: new Date(currentYear, 1, 1),
  // },
  // {
  //   id: 1457690508,
  //   name: 'Amay Mathur',
  //   profile_pic: '',
  //   email: 'amay-mathur@example.com',
  //   emailUsage: 2.1, // GB
  //   phones: [{ phone: '(215)-278-5475', label: 'home' }],
  //   designation: 'Developer',
  //   starred: true,
  //   company: 'Snapdeal',
  //   labels: [],
  //   status: 'active',
  //   suspendedBy: 'Admin',
  //   suspendedAt: new Date(),
  //   lastLoginAt: new Date(loginYear, 6, 4),
  //   createdAt: new Date(currentYear, 1, 1),
  //   updatedAt: new Date(currentYear, 1, 1),
  // },
  // {
  //   id: 1457690509,
  //   name: 'Chris Mathew',
  //   profile_pic: '',
  //   email: 'chris-mathew@example.com',
  //   emailUsage: 1.2, // GB
  //   phones: [{ phone: '(215)-286-0456', label: 'home' }],
  //   designation: 'UI-UX Designer',
  //   starred: false,
  //   company: 'Amazon',
  //   labels: [],
  //   status: 'active',
  //   suspendedBy: 'Admin',
  //   suspendedAt: new Date(),
  //   lastLoginAt: new Date(loginYear, 5, 28),
  //   createdAt: new Date(currentYear, 1, 1),
  //   updatedAt: new Date(currentYear, 1, 1),
  // },
  // {
  //   id: 1457690510,
  //   name: 'Nikki Johnson',
  //   profile_pic: '',
  //   email: 'nikki-johnson@example.com',
  //   emailUsage: 0.3, // GB
  //   phones: [{ phone: '(215)-586-2355', label: 'home' }],
  //   designation: 'CEO',
  //   starred: false,
  //   company: 'Myntra',
  //   labels: [],
  //   status: 'active',
  //   suspendedBy: 'Admin',
  //   suspendedAt: new Date(),
  //   lastLoginAt: new Date(loginYear, 1, 14),
  //   createdAt: new Date(currentYear, 1, 1),
  //   updatedAt: new Date(currentYear, 1, 1),
  // },
];
