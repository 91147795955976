import React from 'react';
import CmtList from '../../../../../@coremat/CmtList';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { postsList, user } from '@fake-db/apps/wall';
import UserItem from './UserItem';

const UsersList = () => {
  // const { feedPosts } = useSelector(({ wallApp }) => wallApp);
  const contentUserList = useSelector(state => state?.contentManagement?.contentUserList);

  const feedPosts = contentUserList;

  return (
    <CmtList
      data={feedPosts}
      renderRow={(item, index) => (
        <Box mb={6} key={index}>
          <UserItem item={item} />
        </Box>
      )}
    />
  );
};

export default UsersList;
