import React from "react";

const CmtVideo = ({ alt, height, width, keyAuto, ...restProps }) => {
  const altValue = alt ? alt : "";
  return (
    <>
      <video
        style={{
          height: height,
          width: width,
        }}
        controls={keyAuto}
        id="vid"
        onClick={({ target: video }) =>
          video.paused ? video.play() : video.pause()
        }
      >
        <source {...restProps} type="video/mp4" autoplay loop={false} />
      </video>
      {/* // <video 
    //   alt={altValue}
    //   {...restProps}
    //   style={{
    //     height: "60px",
    //     width: "60px",
    //   }}
    // /> */}
    </>
  );
};

export default CmtVideo;
