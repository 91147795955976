import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import AddEditFlag from "./AddEditFlag";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import NoRecordFound from "./NoRecordFound";
import { getDateText } from "../../../@jumbo/utils/dateHelper";
import FlagTypeTableToolbar from "./FlagTypeTableToolbar";
import FlagTypeTableHead from "./FlagTypeTableHead";
import FlagTypeListRow from "./FlagTypeListRow";
import { AuhMethods } from "services/auth";
import ReportsManagementServicesAuth from "services/auth/ReportsManagement";

const FlagReportsType = ({ method = "reportsManagement" }) => {
  const classes = useStyles();
  const flagNameListing = useSelector(
    ({ reportsManagement }) => reportsManagement.flagTypesList
  );
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(60);
  const [selected, setSelected] = React.useState([]);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [name, setName] = useState(false);
  const [id, setId] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState({ name: "" });
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  const FetchData = useSelector(state => state?.common);

  const dispatch = useDispatch();
  const getUsersListByDate = () => {
    return flagNameListing.map(activity => {
      activity.category = getDateText(activity.date);
      return activity;
    });
  };
  const users = getUsersListByDate();

  useEffect(() => {
    dispatch(
      AuhMethods[method].flagTypesListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage
      })
    );
  }, []);

  const handleCloseUserDialog = () => {
    setOpenFlagDialog(false);
    // dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleFlagEdit = data => {
    setName(data.keyword);
    setId(data.id);
    setOpenFlagDialog(true);
    setEditFlag(true);
  };

  const handleFlagAdd = user => {
    // dispatch(setCurrentUser(user));
    setName("");
    setOpenFlagDialog(true);
    setEditFlag(false);
  };

  const handleFlagDelete = data => {
    setSelectedFlag(data);
    setName(data.keyword);

    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    // dispatch(deleteUser(selectedFlag.id));
    setOpenConfirmDialog(false);
    dispatch(
      ReportsManagementServicesAuth.deleteFlagAPI({
        token: token,
        id: selectedFlag.id
      })
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <FlagTypeTableToolbar
          selected={selected}
          setSelected={setSelected}
          onFlagAdd={handleFlagAdd}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <FlagTypeTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {!!users.length ? (
                stableSort(users, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <FlagTypeListRow
                      key={index}
                      row={row}
                      // onRowClick={console.log("handleRowClick")}
                      onFlagEdit={handleFlagEdit}
                      onFladDelete={handleFlagDelete}
                      isSelected={isSelected}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {openFlagDialog && (
        <AddEditFlag
          open={openFlagDialog}
          onCloseDialog={handleCloseUserDialog}
          isEdit={editFlag}
          name={name}
          id={id}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={"Are you sure, you want to delete this flag type?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default FlagReportsType;
