import {
  GET_CONTENT_TYPE,
  ADD_CONTENT_TYPE,
  EDIT_CONTENT_TYPE,
  DELETE_CONTENT_TYPE,
  GET_CONTENT_POSTS_DATA,
  CLEAR_CONTENT_POSTS_FILTER,
  GET_CONTENT_FILTER_POSTS_DATA,
  GET_CONTENT_USER_LIST,
  ADD_HASHTAGS_TYPE,
  GET_HASHTAGS_LIST,
  POST_DETAIL_SUCCESS,
  GET_INTEREST_TYPE,
  GET_CONTENT_TITLE_LIST,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  InterestTypeList: {},
  ContentTypeList: {},
  ContentPostsData: [],
  contentUserList: [],
  contentUserListMeta: [],
  ContentPostsDataMeta: [],
  HashTagsList: [],
  HashTagsListMeta: [],
  postViewDetail: {},
  TitleList: [],
  TitleListMeta: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONTENT_TYPE: {
      return {
        ...state,
        ContentTypeList: action.payload,
      };
    }
    case ADD_CONTENT_TYPE: {
      return {
        ...state,
        ContentTypeList: [...state.ContentTypeList, ...action.payload.data],
      };
    }

    case GET_INTEREST_TYPE: {
      return {
        ...state,
        InterestTypeList: action.payload,
      };
    }

    case GET_CONTENT_POSTS_DATA: {
      var apiData = [];
      if (action.payload.meta.page === 1) {
        apiData = [...action.payload.data];
      } else {
        apiData = [...state.ContentPostsData, ...action.payload.data];
      }
      return {
        ...state,
        // ContentPostsData: [...state.ContentPostsData, ...action.payload.data],
        ContentPostsData: apiData,
        ContentPostsDataMeta: [action.payload.meta],
      };
    }

    case CLEAR_CONTENT_POSTS_FILTER: {
      return {
        ...state,
        ContentPostsData: [],
        ContentPostsDataMeta: [],
      };
    }

    case GET_CONTENT_FILTER_POSTS_DATA: {
      // return {
      //   ...state,
      //   ContentPostsData: action.payload.data,
      //   ContentPostsDataMeta: [action.payload.meta],
      // };
      var apiData = [];
      if (action.payload.meta.page === 1) {
        apiData = [...action.payload.data];
      } else {
        apiData = [...state.ContentPostsData, ...action.payload.data];
      }
      return {
        ...state,
        ContentPostsData: apiData,
        ContentPostsDataMeta: [action.payload.meta],
      };
    }
    case GET_CONTENT_USER_LIST: {
      return {
        ...state,
        contentUserList: [...action.payload.data],
        contentUserListMeta: [action.payload.meta],
      };
    }

    case GET_HASHTAGS_LIST: {
      return {
        ...state,
        HashTagsList: [...action.payload.data],
        HashTagsListMeta: [action.payload.meta],
      };
    }
    case ADD_HASHTAGS_TYPE: {
      return {
        ...state,
        HashTagsList: [...state.HashTagsList, ...action.payload.data],
      };
    }

    case POST_DETAIL_SUCCESS: {
      return {
        ...state,
        postViewDetail: action.payload,
      };
    }
    case GET_CONTENT_TITLE_LIST: {
      return {
        ...state,
        TitleList: action.payload.data,
        TitleListMeta: action.payload.meta,
      };
    }

    default:
      return state;
  }
};
