import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import UserInfoComment from './UserInfoComment';

const CommentItem = ({ item, classes, itemType }) => {
  const [isLiked, setIsLiked] = useState(item.liked);

  useEffect(() => {}, [itemType, item]);
  return (
    <Box mb={6}>
      {itemType === 'likes' && <UserInfoComment user={item?.like_user_data} date={item.created_at} classes={classes} />}
      {itemType === 'dislikes' && <UserInfoComment user={item?.like_user_data} date={item.created_at} classes={classes} />}
      {itemType === 'comments' && (
        <UserInfoComment user={item.commented_user_data} date={item.created_at} classes={classes} />
      )}
      {/* {itemType === 'views' && <UserInfoComment user={item.commented_user_data} date={item.created_at} classes={classes} />} */}
      {itemType === 'comments' && (
        <Box ml={14}>
          <Box mt={2} component="p" color="text.secondary">
            {item?.comment}
          </Box>
          {/* <Box mt={3} display="flex" alignItems="center">
          <Button size="small" variant="contained" color="primary" onClick={() => setIsLiked(!isLiked)}>
            {isLiked ? 'Unlike' : 'Like'}
          </Button>
          <Box ml={2.5}>
            <Button size="small" variant="contained" color="secondary">
              Comment
            </Button>
          </Box>
        </Box> */}
        </Box>
      )}
    </Box>
  );
};

export default CommentItem;

CommentItem.prototype = {
  item: PropTypes.object.isRequired,
};
