import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "redux/actions/Users";
import AddEditContentType from "./AddEditContentType";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import NoRecordFound from "./NoRecordFound";
import { getDateText } from "../../../@jumbo/utils/dateHelper";
import ContentTypeTableToolbar from "./ContentTypeTableToolbar";
import ContentTypeTableHead from "./ContentTypeTableHead";
import ContentListRow from "./ContentListRow";
import { AuhMethods } from "services/auth";

const PageCategories = () => {
  const classes = useStyles();
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedName, setName] = useState([]);
  const [selectedId, setId] = useState([]);
  const [users, setUser] = useState([]);
  const token = useSelector(state => state.admin.authUser.access_token);
  const FetchData = useSelector(state => state?.common);
  const BusinessPageCatagories = useSelector(
    state => state?.businessPageManagement
  );
  const BusinessPageCatagoriesMeta = useSelector(
    state => state?.businessPageManagement?.pageCategoryListMeta
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      AuhMethods["businessModule"].pageCategoriesListAPI({
        token,
        page: page,
        perPage: rowsPerPage,
        order: order
      })
    );
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (BusinessPageCatagories?.pageCategoryList) {
      const activity = BusinessPageCatagories?.pageCategoryList?.map(item => {
        item.category = getDateText(item.updated_at);
        return item;
      });
      setUser(activity);
    }
  }, [BusinessPageCatagories?.pageCategoryList]);

  const handleCloseUserDialog = () => {
    setOpenFlagDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFlagEdit = user => {
    setId(user?.id);
    setName(user?.name);
    setOpenFlagDialog(true);
    setEditFlag(true);
  };

  const handleFlagAdd = user => {
    setOpenFlagDialog(true);
    setEditFlag(false);
  };

  const handleFlagDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(
      AuhMethods["businessModule"].deletePageCategoriesAPI({
        token: token,
        id: selectedUser.id,
        page: page,
        perPage: rowsPerPage,
        order: order
      })
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ContentTypeTableToolbar
          selected={selected}
          setSelected={setSelected}
          onFlagAdd={handleFlagAdd}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          data={BusinessPageCatagories}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <ContentTypeTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {Array.isArray(users) && users.length > 0 ? (
                users.map((row, index) => (
                  <ContentListRow
                    key={index}
                    row={row}
                    onFlagEdit={handleFlagEdit}
                    onFladDelete={() => handleFlagDelete(row)}
                    isSelected={isSelected}
                  />
                ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {BusinessPageCatagoriesMeta?.total > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 500]}
            component="div"
            count={BusinessPageCatagoriesMeta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      </Paper>

      {openFlagDialog && (
        <AddEditContentType
          open={openFlagDialog}
          onCloseDialog={handleCloseUserDialog}
          isedit={editFlag}
          selectedName={selectedName}
          selectedId={selectedId}
          page={page}
          perPage={rowsPerPage}
          order={order}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={"Are you sure, you want to delete this category type?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default PageCategories;
