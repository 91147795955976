import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MoreHoriz, Close, Done, ViewAgenda } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import CmtButtons from "@coremat/CmtButtons";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import { API_URL } from "constant/constant";
import { useDispatch, useSelector } from "react-redux";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";

const useStyles = makeStyles((theme) => ({
  Root: {
    "& .MuiTableCell-root": {
      padding: 10,
    },
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const MySwal = withReactContent(Swal);
const UserListRow = ({
  row,
  isSelected,
  page,
  idx,
  changeStatus,
  VestingRuleData,
  callBack,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [id, setIdd] = React.useState("");
  // *********** option for Page ***********************
  const sweetAlerts = (row) => {
    MySwal.fire({
      title: "Release Tokens",
      icon: "error",
      html: <i>You have release ICO tokens for investor {row.name} ,</i>,
      //   onOpen: () => {
      //     SwalModal.clickConfirm();
      //   },
      // }).then(() => {
      //   return SwalModal.fire("<p>Shorthand works too</p>");
    });
  };
  // *********** option for Page ***********************
  const token = useSelector((state) => state.admin.authUser.access_token);
  const TaralICODetails = async (row, ReleaseData) => {
    let percent = VestingRuleData[row?.release_history?.length]?.percent;
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "POST",
        url: `${API_URL}admin/ico/release_token`,
        headers: {
          Authorization: token,
        },
        data: {
          id: row.id,
          token: row.total_quantity_purchase,
          // percentage: ReleaseData.percent,
          percentage: percent,
        },
      })
        // return res;
        .then(({ data }) => {
          if (data.data) {
            dispatch(fetchSuccess());
            // setICOList(data.data);
            // setpageListMeta(data.meta);
            // dispatch(setUsersListData(data));
            callBack();
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  // *********** option for Page ***********************
  const getUserActions = () => {
    const actions = [
      row.status === "0"
        ? { action: "accepted", label: "Disable", icon: <Close /> }
        : row.status === "1"
        ? { action: "rejected", label: "Enable", icon: <Done /> }
        : row.status === "2"
        ? { action: "accepted", label: "APPROVE", icon: <Done /> }
        : {},
      { action: "rejected", label: "View", icon: <VisibilityIcon /> },
      { action: "rejected", label: "Edit", icon: <EditIcon /> },
    ];
    if (row.status === "0") {
      actions.push({ action: "rejected", label: "REJECT", icon: <Close /> });
    }

    return actions;
  };

  const onUserMenuClick = (menu, row) => {
    changeStatus(menu.action, menu.label, row.id);
  };

  const labelId = `enhanced-table-checkbox-${row.user_id}`;
  const isItemSelected = isSelected(row.user_id);
  const userActions = getUserActions(row.status, row.verified);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.user_id}
      selected={isItemSelected}
      className={classes.Root}
    >
      <TableCell>{page * 10 + idx + 1}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          {/* <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_url} alt={row.name} />
          </Box> */}
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row?.name === null ? "N/A" : row?.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{moment(row?.purchase_date).format("ll")}</TableCell>
      <TableCell>
        {row.selected_currency == "inr"
          ? parseFloat(row.paid_amount).toFixed(2)
          : parseFloat(row.paid_amount).toFixed(6)}{" "}
        <span style={{ textTransform: "uppercase" }}>
          {row.selected_currency}
        </span>
      </TableCell>
      <TableCell>{row.total_quantity_purchase}</TableCell>
      <TableCell>{parseFloat(row.released_token).toFixed(2)}</TableCell>
      {/* <TableCell>{row.released_token}</TableCell> */}
      <TableCell>
        {VestingRuleData?.length == row.release_history.length
          ? "Released"
          : row.release_history.length > 0 && VestingRuleData?.length > 0
          ? row.release_history.length !== VestingRuleData?.length
            ? moment(
                VestingRuleData[row?.release_history?.length]?.date
              ).format("ll")
            : "No token to release"
          : moment(VestingRuleData[0]?.date).format("ll")}
      </TableCell>

      <TableCell>
        {row?.status === "1"
          ? "Active"
          : row?.status === "0"
          ? "Inactive"
          : "Unverified"}
      </TableCell>
      <TableCell>
        {VestingRuleData?.length == row.release_history.length
          ? "Released"
          : "Pending"}
      </TableCell>

      <TableCell
        align="center"
        // onClick={(event) => event.stopPropagation()}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={
            VestingRuleData?.length == row.release_history.length ||
            row?.release_history?.length === VestingRuleData?.length ||
            moment(
              VestingRuleData[
                row.release_history.length !== VestingRuleData?.length
                  ? row?.release_history?.length
                  : 0
              ]?.date
            ).unix() > moment().unix()
          }
          // onClick={() => sweetAlerts(row)}
          onClick={() =>
            TaralICODetails(row, VestingRuleData[row?.release_history?.length])
          }
        >
          Release
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
