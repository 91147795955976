import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { AuhMethods } from "services/auth";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const AddEditContentType = ({
  open,
  onCloseDialog,
  isedit,
  selectedId,
  selectedName
}) => {
  const classes = useStyles();
  const [hashTagList, setHashTagList] = useState(
    selectedName ? selectedName : ""
  );
  const [firstNameError, setFirstNameError] = useState("");
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  const dispatch = useDispatch();
  const onSubmitClick = () => {
    if (!hashTagList || hashTagList.length === 0) {
      setFirstNameError(requiredMessage);
    } else {
      dispatch(
        AuhMethods["contentManagement"].addHashTagsAPI(token, hashTagList)
      );
      onCloseDialog();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      isedit={isedit}
      className={classes.dialogRoot}
    >
      <DialogTitle className={`${classes.dialogRoot} dialogCustom`}>
        {" "}
        <span> {"Create Hash Tags"}</span>
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000"
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        ></Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            style={{ marginBottom: 12 }}
            fullWidth
            variant="outlined"
            label="HashTags"
            value={hashTagList}
            onChange={e => {
              let tt = e.target.value.trim();
              if (tt && tt.length <= 40) {
                setHashTagList(e.target.value.trim());
                setFirstNameError("");
              } else {
                setHashTagList(e.target.value);
                if (e.target.value === "") {
                  setFirstNameError("This field is required");
                } else {
                  setFirstNameError("No white space allowed");
                }
              }
            }}
            helperText={firstNameError}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={hashTagList === "" || firstNameError}
              onClick={onSubmitClick}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditContentType;

AddEditContentType.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
