import React, { useEffect, useState, useCallback } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
import UserTableHead from "./UserTableHead";
import UserTableToolbar from "./UserTableToolbar";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "redux/actions/Users";
import AddEditUser from "./AddEditUser";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import UserDetailView from "./UserDetailView";
import NoRecordFound from "./NoRecordFound";
import { useHistory } from "react-router-dom";
import { AuhMethods } from "services/auth";
import UserDashboard from "./UserDashboard";
import UserFilter from "./UserFilter";
import moment from "moment";
import { MoreVertIcon } from "@material-ui/icons/MoreVert";
import AssignUserModal from "./AssignUserModal";

const PageList = () => {
  const history = useHistory();
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [verifyToggle, setVerifyToggle] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [from_date, handleFromChange] = useState();
  const [to_date, handleToChange] = useState();
  const [status, setstatus] = useState();
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const FetchData = useSelector(state => state?.common);
  const token = useSelector(state => state.admin.authUser.access_token);
  const pageListMeta = useSelector(
    state => state?.businessPageManagement?.pageListMeta
  );
  const pageList = useSelector(
    state => state?.businessPageManagement?.pageList
  );
  const dispatch = useDispatch();
  const callDataAPI = useCallback(() => {
    dispatch(
      AuhMethods["businessModule"].pageListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText,
        order,
        status,
        // moment(from_date).format("DD-MM-YYYY"),
        from_date: from_date ? moment(from_date).format("YYYY-MM-DD") : "",
        to_date: to_date ? moment(to_date).format("YYYY-MM-DD:23:59:59") : ""
      })
    );
  }, [page, rowsPerPage, searchText, from_date, to_date, status]);

  useEffect(() => {
    callDataAPI();
  }, []);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentUser(null));
  };
  const handleSearchTextChange = e => {
    let data = e.target.value.replace(/^\s/, "");
    setSearchText(data);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleUserView = pageId => {
    history.push({
      pathname: "/pageDetails",
      state: { id: pageId.id }
    });
  };
  const onVerify = data => {
    setVerifyToggle(true);
    setSelectedUser(data);
  };
  const assginUserPage = data => {
    setOpenAssignModal(true);
    setSelectedUser(data);
  };
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleUserBlock = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleUserStatus = e => {
    setOpenConfirmDialog(false);
    const params = {
      id: selectedUser.id,
      status: selectedUser?.status === "0" ? "1" : "0"
    };
    dispatch(
      AuhMethods["businessModule"].PageVerificationProcessAPI(
        token,
        params,
        "pageList"
      )
    );
    setTimeout(
      () =>
        dispatch(
          AuhMethods["businessModule"].pageListAPI({
            token: token,
            page: page,
            perPage: rowsPerPage,
            keyword: searchText,
            order,
            from_date: "",
            to_date: ""
          })
        ),
      1000
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelVerify = () => {
    setVerifyToggle(false);
  };
  const handleConfirmVerify = () => {
    let params = {
      id: selectedUser?.id
    };
    dispatch(
      AuhMethods["businessModule"].BusinessPageVerificationAPI({
        token,
        params
      })
    );
    setTimeout(
      () =>
        dispatch(
          AuhMethods["businessModule"].pageListAPI({
            token: token,
            page: page,
            perPage: rowsPerPage,
            keyword: searchText,
            order,
            from_date: "",
            to_date: ""
          })
        ),
      1000
    );
    setVerifyToggle(false);
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserTableToolbar />

        <UserDashboard count={pageListMeta} />
        <UserFilter
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          actionHandleIcon={<MoreVertIcon />}
          from_date={from_date}
          handleFromChange={handleFromChange}
          to_date={to_date}
          handleToChange={handleToChange}
          status={status}
          setstatus={setstatus}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <UserTableHead />
            <TableBody>
              {!!pageList.length ? (
                pageList
                  .slice(0, rowsPerPage)
                  .map((row, index) => (
                    <UserListRow
                      key={index}
                      page={page}
                      idx={index}
                      row={row}
                      onUserBlock={handleUserBlock}
                      onUserView={handleUserView}
                      assginUserPage={assginUserPage}
                      onVerify={onVerify}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={pageListMeta?.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openUserDialog && (
        <AddEditUser
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}
      {openViewDialog && (
        <UserDetailView
          open={openViewDialog}
          onCloseDialog={handleCloseViewDialog}
        />
      )}
      {verifyToggle && (
        <ConfirmDialog
          open={verifyToggle}
          title={`Confirm to delete ${
            selectedUser.name ? selectedUser.name : ""
          }`}
          content={`Are you sure, you want to delete this page?`}
          onClose={handleCancelVerify}
          onConfirm={handleConfirmVerify}
        />
      )}

      {selectedUser && (
        <AssignUserModal
          selectedUser={selectedUser}
          open={openAssignModal}
          onClose={() => setOpenAssignModal(false)}
          callDataAPI={callDataAPI}
        />
      )}

      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          title={`Confirm ${
            selectedUser?.status == "1" ? "inactive" : "active"
          } ${selectedUser.name ? selectedUser.name : ""}`}
          content={`Are you sure, you want to ${
            selectedUser?.status == "1" ? "inactive" : "active"
          } this page?`}
          onClose={handleCancelDelete}
          onConfirm={handleUserStatus}
        />
      )}
    </div>
  );
};

export default PageList;
