import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import CmtAvatar from '@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import { emailNotValid, requiredMessage } from '@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { isValidEmail } from '@jumbo/utils/commonHelper';
import { addNewUser, updateUser } from 'redux/actions/Users';
import { inherits } from 'util';
import { AuhMethods } from 'services/auth';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} format="(###) ###-####" />;
}

const labels = [
  { title: 'Posts', slug: 'posts' },
  { title: 'Likes', slug: 'likes' },
  { title: 'Comments', slug: 'comments' },
];

const splitName = user => {
  if (user) {
    const [fName, mName, lName] = user.name.split(' ');
    return [fName, lName ? mName + ' ' + lName : mName];
  }

  return ['', ''];
};

const AddEditFlag = ({ open, onCloseDialog, isEdit, name, id }) => {
  const classes = useStyles();
  //   const currentUser = useSelector(({ usersReducer }) => usersReducer.currentUser);
  const currentUser = {};
  const [flagName, setflagName] = useState(name);
  const [reportId, setReportId] = useState(id);

  const [type, setType] = useState('');
  const [number, setNumber] = useState('');
  const [amount, setAmount] = useState('');

  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [email, setEmail] = useState('');
  // const [profile_pic, setProfile_pic] = useState('');
  // const [company, setCompany] = useState('');
  // const [designation, setDesignation] = useState('');
  // const [phones, setPhones] = useState([{ phone: '', label: 'home' }]);

  const [firstNameError, setFirstNameError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [numberError, setNumberError] = useState('');
  const [amountError, setAmountError] = useState('');

  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux

  useEffect(() => {
    if (currentUser) {
      // const [fName, lName] = splitName(currentUser);
      // setFirstName(fName);
      // setLastName(lName);
      // setProfile_pic(currentUser.profile_pic);
      // setEmail(currentUser.email);
      // setCompany(currentUser.company);
      // setDesignation(currentUser.designation);
      // setPhones(currentUser.phones);
    }
  }, [currentUser]);

  const onSubmitClick = () => {
    if (!flagName) {
      setFirstNameError(requiredMessage);
    } else {
      //Create Reward API
      isEdit
        ? dispatch(AuhMethods['reportsManagement'].updateFlagTypeAPI(token, { id: reportId, name: flagName }))
        : dispatch(AuhMethods['reportsManagement'].addFlagTypeAPI(token, { name: flagName }));
      onCloseDialog();
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} isedit={isEdit} className={`${classes.dialogRoot} dialogCustom`}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {isEdit ? 'Edit Flag' : 'Create Flag'}
        {/* <CloseIcon onClick={onCloseDialog} /> */}
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#000',
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}></Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            style={{ marginBottom: 12 }}
            fullWidth
            variant="outlined"
            label="Name"
            value={flagName}
            onChange={e => {
              if (e.target.value.length <= 40) {
                setflagName(e.target.value.replace(/^\s/, ''));
                setFirstNameError('');
              }
            }}
            helperText={firstNameError}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditFlag;

AddEditFlag.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
