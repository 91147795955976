import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Chip, Menu, Grid, Box } from "@material-ui/core";
import useStyles from "./index.style";
import DashboardCard from "../../../../../@jumbo/components/Common/DashboardCard/index";

const UserDashboard = ({
  selected,
  setSelected,
  onUserAdd,
  filterOptions,
  setFilterOptions,
  searchText,
  handleSearchTextChange,
  count,
  dailyData
}) => {
  const classes = useStyles();

  const numSelected = selected.length;
  console.log(count);
  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        <Grid container spacing={2}>
          {/* {dailyData &&
            dailyData.map((data) => {
              return ( */}
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              // width="140px"
              // height="134px"
              title={<span style={{ color: "#fafafa" }}>Total Plan</span>}
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalActivePlan + count?.totalInActivePlan}
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              // width="140px"
              // height="134px"
              // title={"Total Active Plan"}
              title={
                <span style={{ color: "#fafafa" }}>Total Active Plan</span>
              }
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalActivePlan ? count?.totalActivePlan : "0"}
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              // width="140px"
              // height="134px"
              // title={"Total Inactive Plan"}
              title={
                <span style={{ color: "#fafafa" }}>Total Inactive Plan</span>
              }
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalInActivePlan}
              description=""
              item="0"
            />
          </Grid>

          {/* );
            })} */}
        </Grid>
      </Toolbar>
    </React.Fragment>
  );
};

UserDashboard.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchText: PropTypes.string,
  handleSearchTextChange: PropTypes.func,
  onUserAdd: PropTypes.func
};

export default React.memo(UserDashboard);
