import {
  SAVE_USERS_LIST_DATA,
  SAVE_USER_PROFILE_DATA,
  SAVE_USER_POSTS_DATA,
  DELETE_USER_POST,
  EDIT_USER_LIST,
  EDIT_PROFILE_DETAIL,
  SAVE_USER_MORE_POSTS_DATA,
  SAVE_POST_COMMENTS_DATA,
  SAVE_COMMENTS_META,
  APPEND_USER_POSTS_DATA,
  USER_MORE_POSTS_META_DATA,
  SET_VERIFY_USER,
  USERS_FOLLOWING_DATA,
  USERS_FOLLOWERS_DATA,
  GET_VERIFICATION_REQUEST,
  GET_GENRE_LIST,
  GET_PAGE_VERIFICATION_REQUEST_LIST,
} from '../../@jumbo/constants/ActionTypes';

export const setUsersListData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_USERS_LIST_DATA,
      payload: data,
    });
  };
};

export const usersFollowerData = data => {
  return dispatch => {
    dispatch({
      type: USERS_FOLLOWERS_DATA,
      payload: data,
    });
  };
};

export const usersFollowingData = data => {
  return dispatch => {
    dispatch({
      type: USERS_FOLLOWING_DATA,
      payload: data,
    });
  };
};

export const setUserProfileData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_USER_PROFILE_DATA,
      payload: data,
    });
  };
};

export const setUserPostsData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_USER_POSTS_DATA,
      payload: data,
    });
  };
};

export const appendUserMorePostsData = data => {
  return dispatch => {
    dispatch({
      type: APPEND_USER_POSTS_DATA,
      payload: data,
    });
  };
};
export const userMorePostsMetaData = data => {
  return dispatch => {
    dispatch({
      type: USER_MORE_POSTS_META_DATA,
      payload: data,
    });
  };
};

export const setUserMorePostsData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_USER_MORE_POSTS_DATA,
      payload: data,
    });
  };
};

export const deleteUserPost = id => {
  return dispatch => {
    dispatch({
      type: DELETE_USER_POST,
      payload: id,
    });
  };
};

export const editUserStatusFromUserList = id => {
  return dispatch => {
    dispatch({
      type: EDIT_USER_LIST,
      payload: id,
    });
  };
};

export const editUserStatusFromProfile = id => {
  return dispatch => {
    dispatch({
      type: EDIT_PROFILE_DETAIL,
      payload: id,
    });
  };
};

export const setPostCommentsData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_POST_COMMENTS_DATA,
      payload: data,
    });
  };
};

export const setCommentsMeta = data => {
  return dispatch => {
    dispatch({
      type: SAVE_COMMENTS_META,
      payload: data,
    });
  };
};

export const setVerfiy = data => {
  return dispatch => {
    dispatch({
      type: SET_VERIFY_USER,
      payload: data,
    });
  };
};
export const getVerificationRequest = data => {
  return dispatch => {
    dispatch({
      type: GET_VERIFICATION_REQUEST,
      payload: data,
    });
  };
};

export const getPageVerificationRequest = data => {
  return dispatch => {
    dispatch({
      type: GET_PAGE_VERIFICATION_REQUEST_LIST,
      payload: data,
    });
  };
};

export const getGenreList = data => {
  return dispatch => {
    dispatch({
      type: GET_GENRE_LIST,
      payload: data,
    });
  };
};
