import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";

const headCells = [
  {
    id: "Sr No",
    numeric: false,
    disablePadding: false,
    label: "Sr No"
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Plan Name"
  },
  {
    id: "online_offline_business",
    numeric: false,
    disablePadding: false,
    label: "Created Date"
  },
  // {
  //   id: "interested_for_advertise_business_page",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Intrusted for advertise"
  // },
  { id: "status", numeric: false, disablePadding: false, label: "Status" }
];

function UserTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className="like-padding"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default UserTableHead;
