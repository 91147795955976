/*import Firebase from './firebase';
import JWTAuth from './jwt';*/

import AccountSettingsAuth from './AccountSettings';
import AdminAuth from './Admin';
import WalletManagementServicesAuth from './AdminWallets';
import AppSettingsAuth from './AppsSetting';
import BasicAuth from './Basic';
import BusinessModuleServicesAuth from './BusinessModule';
import ContentManagementServicesAuth from './ContentManagement';
import DashboardServicesAuth from './Dashboard';
import ReportsManagementServicesAuth from './ReportsManagement';
import RewardManagementServicesAuth from './Rewards';
import UsersModuleServicesAuth from './UsersModule';

export const AuhMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  admin: AdminAuth,
  basic: BasicAuth,
  accountSettings: AccountSettingsAuth,
  dashboard: DashboardServicesAuth,
  usersModule: UsersModuleServicesAuth,
  reportsManagement: ReportsManagementServicesAuth,
  rewardsManagement: RewardManagementServicesAuth,
  contentManagement: ContentManagementServicesAuth,
  walletManagement: WalletManagementServicesAuth,
  AppSettings: AppSettingsAuth,
  usersModule: UsersModuleServicesAuth,
  businessModule: BusinessModuleServicesAuth,
};
