import React, { useState } from "react";
import { TableCell, TableRow, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddEditContentType from "../AddEditContentType";
import AssignCategoryModal from "../AssignCategoryModal";
const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const UserListRow = ({ row, page, idx }) => {
  const classes = useStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
        <TableCell>{page * 10 + idx + 1}</TableCell>
        <TableCell>{"Testing"}</TableCell>

        <TableCell>1</TableCell>
        <TableCell>1</TableCell>
        <TableCell>1</TableCell>
        <TableCell>1</TableCell>

        <TableCell align="left" onClick={event => event.stopPropagation()}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenEdit(true)}
          >
            Edit
          </Button>

          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: "20px" }}
            onClick={() => setOpenAssign(true)}
          >
            Assign
          </Button>
        </TableCell>
      </TableRow>
      {openEdit && (
        <AddEditContentType
          type="eitadd"
          open={openEdit}
          onCloseDialog={() => setOpenEdit(false)}
        />
      )}
      {openAssign && (
        <AssignCategoryModal
          open={openAssign}
          onClose={() => setOpenAssign(false)}
        />
      )}
    </React.Fragment>
  );
};

export default UserListRow;
