import { SAVE_AUTH_USER, UPDATE_EMAIL } from '../../@jumbo/constants/AdminTypes';

const INIT_STATE = {
  authUser: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case UPDATE_EMAIL: {
      state.authUser.email = action.payload;
      return {
        ...state,
        authUser: state.authUser,
      };
    }
    default:
      return state;
  }
};
