import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { updatePostLikeStatus } from '../../../../../../redux/actions/WallApp';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CommentIcon from '@material-ui/icons/Comment';
import ShareIcon from '@material-ui/icons/Share';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import CommentListView from '../../CommentListView';
import LikeListView from '../../LikeListView';
import like from '../../../../../../images/like.png';
import dislike from '../../../../../../images/dislike.png';
import comment from '../../../../../../images/comment.png';
import view from '../../../../../../images/view.png';
import { postDetailAPIsuccess } from 'redux/actions/ContentManagement';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  iconSm: {
    fontSize: '16px !important',
    marginRight: 6,
  },
}));

const PostStats = ({ item, postAction }) => {
  const dispatch = useDispatch();
  const [itemType, setItemType] = useState('');
  const [itemTypeId, setItemTypeId] = useState(null);
  const classes = useStyles();
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [actions, setActions] = useState('');

  useEffect(() => {}, [item]);
  // const toggleLikeStatus = () => {
  //   dispatch(updatePostLikeStatus(item.id, !item.liked));
  // };

  const toggleCommentsClick = review => {
    switch (review) {
      case 'likes':
        if (item?.total_likes > 0) {
          setOpenViewDialog(true);
        }
        break;
      case 'dislikes':
        if (item?.total_dislikes > 0) {
          setOpenViewDialog(true);
        }
        break;
      case 'comments':
        if (item?.total_comments > 0) {
          setOpenViewDialog(true);
        }
        break;

      default:
        break;
    }
  };
  const toggleViewsClick = () => {
    setOpenViewDialog(true);
  };
  const toggleDislikeClick = () => {
    setOpenViewDialog(true);
  };
  const toggleLikeClick = () => {
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    dispatch(postDetailAPIsuccess([]));
    setOpenViewDialog(false);
  };

  const handleUserDelete = typeAction => {
    // setSelectedUser(user);
    setActions(typeAction);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    postAction(actions);
    // dispatch(deleteUser(selectedUser.id));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  // const { likes, comments, shares, views } = item;
  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" mb={{ xs: 4, sm: 6 }} color="text.disabled" fontSize={12}>
      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        onClick={() => {
          toggleCommentsClick('likes');
          setItemType('likes');
        }}
        className="pointer">
        {/* <Box display="flex" alignItems="center" mr={5} mb={{ xs: 2, sm: 0 }} className="pointer"> */}
        <img src={`${like}`} className={`${classes.iconSm} customStyleIcons`} />
        {/* <ThumbUpAltIcon className={classes.iconSm} /> */}
        <Box ml={1}>{item?.total_likes > 0 ? item?.total_likes : 0} </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        onClick={() => {
          toggleCommentsClick('dislikes');
          setItemType('dislikes');
        }}
        className="pointer">
        <img src={`${dislike}`} className={`${classes.iconSm} customStyleIcons`} />
        <Box ml={1}>{item?.total_dislikes > 0 ? item?.total_dislikes : 0} </Box>
      </Box>
      {/* <Box display="flex" alignItems="center" mr={5} mb={{ xs: 2, sm: 0 }} onClick={toggleCommentsClick} className="pointer">
        <CommentIcon className={classes.iconSm} />
        <Box ml={1}>{item?.total_comments > 0 && item?.total_comments} Dislike</Box>{' '}
      </Box> */}
      {/* <Box display="flex" alignItems="center" mr={5} mb={{ xs: 2, sm: 0 }} className="pointer">
        <VisibilityIcon className={classes.iconSm} />
        <Box ml={1}>{views > 0 && views} views</Box>
      </Box> */}
      {/* <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 0 }} className="pointer"> */}
      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        onClick={() => {
          toggleCommentsClick('comments');
          setItemType('comments');
        }}
        className="pointer">
        {/* <CommentIcon className={classes.iconSm} /> */}
        <img src={`${comment}`} className={`${classes.iconSm} customStyleIcons`} />
        <Box ml={1}>{item?.total_comments > 0 ? item?.total_comments : 0} </Box>{' '}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        mr={5}
        mb={{ xs: 2, sm: 0 }}
        // onClick={() => {
        //   toggleCommentsClick();
        //   setItemType('viwes');
        // }}
        className="pointer">
        {/* <CommentIcon className={classes.iconSm} /> */}
        <img src={`${view}`} className={`${classes.iconSm} customStyleIcons`} />
        <Box ml={1}>{item?.total_views > 0 ? item?.total_views : 0} </Box>
      </Box>

      {/* <Box display="flex" alignItems="center" mr={5} mb={{ xs: 2, sm: 0 }} onClick={toggleCommentsClick} className="pointer">
        <CommentIcon className={classes.iconSm} />
        <Box ml={1}>{item?.total_comments > 0 && item?.total_comments} views</Box>{' '}
      </Box> */}

      <Box
        display="flex"
        alignItems="center"
        mb={{ xs: 2, sm: 0 }}
        ml="auto"
        disabled={item?.status == 2 ? true : false}
        // onClick={() => handleUserDelete('delete')}
        className="pointer">
        {/* <ShareIcon className={classes.iconSm} /> */}
        {/* Delete Post Button */}
        {/* <DeleteOutlineIcon className={`${classes.iconSm} customStyleIcons`} /> */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleUserDelete('delete')}
          disabled={item?.status == 2 ? true : false}>
          {item?.status == 0 || item?.status == 2 ? 'Active' : 'Inactive'}
        </Button>
      </Box>
      {/* Pass data with item key on the comment list view */}
      {openViewDialog && (
        <CommentListView open={openViewDialog} onCloseDialog={handleCloseViewDialog} item={item} itemType={itemType} />
      )}

      {/* {openViewDialog && <DislikeListView open={openViewDialog} onCloseDialog={handleCloseViewDialog} item={item} />} */}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`${item?.status == 0 || item?.status == 2 ? 'Post Active' : ' Post Inactive '}`}
        content={`Are you sure, you want to ${item?.status == 0 || item?.status == 2 ? 'active' : 'inactive'} this post?`}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  );
};

export default PostStats;

PostStats.prototype = {
  item: PropTypes.object.isRequired,
};
