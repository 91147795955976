import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  FormControlLabel,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import { useDispatch, useSelector } from "react-redux";
import { AuhMethods } from "services/auth";
import { useDebounce } from "@jumbo/utils/commonHelper";
import moment from "moment";
import Axios from "axios";
import { API_URL } from "constant/constant";
import { fetchSuccess } from "redux/actions";
const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  },
  dialogActionsRoot: {
    padding: "16px 24px"
  },
  userBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}));
export default function AssignCategoryModal({
  open,
  onClose,
  callDataAPI,
  data
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [status, setstatus] = useState();
  const token = useSelector(state => state.admin.authUser.access_token);
  const [searchText, setSearchText] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const userListMeta = useSelector(
    state => state?.contentManagement?.InterestTypeList?.meta
  );
  const usersList = useSelector(
    state => state?.contentManagement?.InterestTypeList?.list
  );
  const search = useDebounce(searchText, 800);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const assignUserHandler = async () => {
    try {
      setIsProcessing(true);
      const response = await Axios({
        method: "PUT",
        url: `${API_URL}admin/post/assign_category/${data.id}`,
        data: {
          cat_array: selectedUsers
        }
      });
      if (response.status === 200) {
        callDataAPI();
        dispatch(fetchSuccess(response.data.message));
        onClose();
      }
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };
  useEffect(() => {
    if (open) {
      dispatch(
        AuhMethods["contentManagement"].contentTypesListAPI({
          token,
          page: page,
          perPage: rowsPerPage,
          order: "desc",
          type: "post_category"
        })
      ); //token, page, perPage, keyword
    }
  }, [page, rowsPerPage, search, status, open]);
  useEffect(() => {
    if (
      data &&
      data.name !== "" &&
      data?.assigned_categories !== "null" &&
      data?.assigned_categories !== null
    ) {
      const previousData = data.assigned_categories;
      setSelectedUsers(previousData ? JSON.parse(previousData) : []);
    }
  }, [data]);
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isProcessing) {
          setSearchText("");
          onClose();
        }
      }}
      aria-labelledby="confirm-dialog-title"
      className={classes.dialogRoot}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="confirm-dialog-title"
        className={classes.dialogTitleRoot}
      >
        Assign Categories
      </DialogTitle>
      <DialogContent dividers>
        <Box className={classes.parentBox}>
          <Box className="searchField">
            <AppTextInput
              name="Search"
              id="search"
              label="Search"
              type="text"
              fullWidth
              variant="outlined"
              onChange={e => setSearchText(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <UserHeadTable
              classes={classes}
              usersList={usersList}
              selectedUsers={selectedUsers}
              setSelectedUsers={item => setSelectedUsers(item)}
            />
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={userListMeta?.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button
          onClick={() => {
            if (!isProcessing) {
              setSearchText("");
              onClose();
            }
          }}
          color="primary"
          variant="outlined"
        >
          Close
        </Button>
        <Button onClick={assignUserHandler} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const UserHeadTable = ({
  usersList,
  classes,
  setSelectedUsers,
  selectedUsers
}) => {
  return (
    <TableContainer>
      <Table
        stickyHeader
        className={classes.table}
        aria-labelledby="tableTitle"
        aria-label="sticky enhanced table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Sr. No.</TableCell>
            <TableCell>Name</TableCell>

            <TableCell>Assign</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersList &&
            usersList.map((data, i) => {
              return (
                <UserTabRow
                  data={data}
                  index={i}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={item => setSelectedUsers(item)}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const UserTabRow = ({ data, index, setSelectedUsers, selectedUsers }) => {
  return (
    <TableRow key={`User${index}`}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{data?.name}</TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedUsers?.includes(data.id)}
              onClick={() => {
                const findFun = selectedUsers?.includes(data.id);
                if (!findFun) {
                  setSelectedUsers([...selectedUsers, data.id]);
                } else {
                  const temp = selectedUsers?.filter(item => item !== data.id);
                  setSelectedUsers(temp);
                }
              }}
              name="checkedF"
            />
          }
        />
      </TableCell>
    </TableRow>
  );
};
