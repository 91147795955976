import React, { useEffect, useState } from "react";
import AuthWrapper from "@jumbo/components/Common/authComponents/AuthWrapper";
// import { AuhMethods } from "services/auth";
import { alpha } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import { TextField, TextareaAutosize, Checkbox } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  authContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AssignResponsibilityCheckbox = ({
  name,
  label,
  assigned,
  index,
  permitions,
  setpermitions,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
  });

  const handleChange = (event) => {
    if (event.target.name !== "checkedA") {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      if (!state.checkedA) {
        setState({
          checkedA: true,
          checkedB: true,
          checkedC: true,
        });
        let values = [...permitions];
        values.push(assigned?.id);
        setpermitions(values);
        console.log("error", values);
      } else {
        setState({
          checkedA: false,
          checkedB: false,
          checkedC: false,
        });
        let listPer = [...permitions];
        listPer.forEach((element, i) => {
          if (assigned?.id == element) {
            listPer.splice(i, 1);
          }
        });
        setpermitions(listPer);
      }
    }
  };

  const token = useSelector((state) => state.admin.authUser.access_token);

  return (
    <div className={classes.authContent}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkedA}
              onChange={handleChange}
              name={"checkedA"}
            />
          }
          label={label}
        />
      </FormGroup>
    </div>
  );
};

export default AssignResponsibilityCheckbox;

AssignResponsibilityCheckbox.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
