import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow } from '@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CmtAvatar from '@coremat/CmtAvatar';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sentMailToUser, updateUserStatus } from 'redux/actions/Users';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    marginLeft: 30,
  },
}));

// const getUserActions = user => {
//   const actions = [
//     // { action: 'view', label: 'View', icon: <Visibility /> },
//     { action: 'edit', label: 'Edit', icon: <Edit /> },
//     // { action: 'email', label: 'Email', icon: <Mail /> },
//   ];
//   // User Management Actions
//   // if (user.status === 'active') {
//   //   actions.push({ action: 'suspend', label: 'Suspend', icon: <Block /> });
//   // } else {
//   //   actions.push({
//   //     action: 'activate',
//   //     label: 'Reactivate',
//   //     icon: <CheckCircleOutline />,
//   //   });
//   // }

//   actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
//   // actions.push({ action: 'block', label: 'Block', icon: <Block /> });
//   return actions;
// };

const FlagTypeListRow = ({ row, isSelected, onFlagEdit, onFladDelete }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const onUserMenuClick = menu => {
  //   if (menu.action === 'edit') {
  //     //handleFlagEdit
  //     onFlagEdit(row);
  //   } else if (menu.action === 'delete') {
  //     onFladDelete(row);
  //   }
  // };

  const onFlagEditClick = () => {
    onFlagEdit(row);
  };

  const onFladDeleteClick = () => {
    onFladDelete(row);
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  // const userActions = getUserActions(row);

  return (
    <TableRow
      hover
      // onClick={event => onRowClick(event, row.id)} // Press Row
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell> */}
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          {/* <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_pic} alt={row.name} />
          </Box> */}
          <div>
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {row.keyword}
            </Typography>
          </div>
        </Box>
      </TableCell>

      <TableCell align="left" onClick={event => event.stopPropagation()}>
        {/* <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} /> */}
        <Button variant="contained" color="primary" onClick={onFlagEditClick}>
          Edit
        </Button>
        <Button variant="contained" color="secondary" style={{ marginLeft: '20px' }} onClick={onFladDeleteClick}>
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(FlagTypeListRow);
