import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Button, Menu } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./index.style";
import { API_URL } from "constant/constant";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { fetchError } from "redux/actions";
import { DownLoadCSVFunction } from "customHook/debounse";

const UserTableToolbar = ({ count }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token);

  const DownLoadeCSV = async count => {
    try {
      await axios({
        method: "GET",
        url: `${API_URL}admin/boost_plan/list`,
        headers: {
          Authorization: token
        },
        params: {
          perPage: count,
          page: 1
        }
      }).then(({ data }) => {
        if (data.data) {
          let arrayOfObjects = data.data;

          if (!arrayOfObjects.length) {
            return;
          }
          DownLoadCSVFunction(arrayOfObjects, "boost_plan_list");
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          Boost Plan
        </Typography>

        <React.Fragment>
          <div>
            <Button
              color="primary"
              style={{ width: "200px", marginRight: "20px" }}
              onClick={() => history.push("/add-subscription")}
            >
              Add Boost Plan
            </Button>
          </div>
          <Menu
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
          ></Menu>

          <div>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: "260px" }}
              onClick={() => DownLoadeCSV(count)}
            >
              Download CSV File
            </Button>
          </div>
        </React.Fragment>
      </Toolbar>
    </React.Fragment>
  );
};

export default UserTableToolbar;
