import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
} from "@material-ui/core";
// import {
//   FaCopy,
//   FaLaptopHouse,
//   FaLessThanEqual,
//   FaTrashAlt,
//   FaUpload,
// } from "react-icons/fa";
// import UserTableToolbar from "./UserTableToolbar";
// import UserTableHead from "./UserTableHead";
import TablePagination from "@material-ui/core/TablePagination";
// import UserListRow from "./UserListRow";
// import UserTableToolbar from "./UserTableToolbar";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtAvatar from "@coremat/CmtAvatar";
import { useDropzone } from "react-dropzone";
import Button from "@material-ui/core/Button";
import CmtList from "@coremat/CmtList";
import IconButton from "@material-ui/core/IconButton";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { emailNotValid, requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { isValidEmail } from "@jumbo/utils/commonHelper";
import { addNewUser, updateUser } from "redux/actions/Users";
import { inherits } from "util";
import { Typography } from "@material-ui/core";
import { AuhMethods } from "services/auth";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
// import RejectDialog from "../RejectDialog";
import { usersModule } from "@fake-db";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useHistory } from "react-router-dom";
// import ConfirmDialog from 'commanComponent/confirm';
// import GeneralInfo from '../../BusinessPage/PageDetails/GeneralInfo/index';
import axios from "axios";
import { API_URL } from "constant/constant";
import AuthWrapper from "@jumbo/components/Common/authComponents/AuthWrapper";

// import { useHistory } from "react-router-dom";
// import { AuhMethods } from "services/auth";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import SettingFields from "./SettingFields";
// import NoRecordFound from "../../BusinessPage/PageList/NoRecordFound";
// import Activities from "./ICOList/Activities/index";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "relative",
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  authContent: {
    padding: 30,
    "& .btnB": {
      margin: "0 15px",
    },
    "& .inputPasswordCustom1": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
    },
    [theme.breakpoints.up("md")]: {
      order: 1,
      width: (props) => (props.variant === "default" ? "50%" : "100%"),
    },
    [theme.breakpoints.up("xl")]: {
      padding: 10,
    },
  },

  titleRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    paddingBottom: 24,
    marginBottom: 24,
    // marginBottom: 24,
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      zIndex: 1,
      width: 36,
      height: 4,
      backgroundColor: theme.palette.primary.main,
    },
  },
  wallRightSidebar: {
    padding: 25,
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "start",
  },
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = (number) => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return (
    <NumberFormat
      {...other}
      onValueChange={onNumberChange}
      value={phoneNumber}
      format="(###) ###-####"
    />
  );
}

const labels = [
  { title: "Posts", slug: "posts" },
  { title: "Likes", slug: "likes" },
  { title: "Comments", slug: "comments" },
];

const FundType = [
  { title: "Locked", type: "locked" },
  { title: "Unlocked", type: "unlocked" },
];

const splitName = (user) => {
  if (user) {
    const [fName, mName, lName] = user.name.split(" ");
    return [fName, lName ? mName + " " + lName : mName];
  }

  return ["", ""];
};

const BostSubscription = () => {
  const classes = useStyles();
  const history = useHistory();
  const [errorONPercentage, setErrorONPercentage] = useState(false);
  const [checked, setchecked] = useState(false);
  const [teamsDataError, setTeamsDataError] = useState(false);
  const [totalPer, setTotalPer] = useState(0);
  const [editOn, setEditOn] = useState(false);
  const token = useSelector((state) => state.admin.authUser.access_token);
  const FetchData = useSelector((state) => state.common);

  const [icoList, setICOList] = React.useState([]);
  const [pageListMeta, setpageListMeta] = React.useState(10);
  const [id, setIdd] = React.useState("");
  console.log("selectedUser data -------- ", icoList);
  const dispatch = useDispatch();
  /* *************************************************************** */
  const [ruleData, setRuleData] = useState([
    {
      percent: 0,
      date: moment().format("YYYY-MM-DD"),
    },
  ]);
  // console.log(" ------ ruleData ", ruleData);
  function handleChange(e, i, type) {
    const values = [...ruleData];
    if (type == "percent") {
      values[i][type] = Number(e.target.value);
    } else {
      values[i][type] = moment(e._d).format("YYYY-MM-DD");
    }
    setRuleData(values);
  }

  const removeLastItem = () => {
    const values = [...ruleData];
    values.pop();
    setRuleData(values);
  };

  const addActionObj = () => {
    const values = [...ruleData];
    let actionObj = {
      percent: 0,
      date: moment().format("YYYY-MM-DD"),
    };
    values.push(actionObj);
    setRuleData(values);
  };
  /* *************************************************************** */

  const EditVestingRules = async () => {
    // const pageData = page != undefined ? page + 1 : "";
    // const keywordData = keyword != undefined ? keyword : "";
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    let ico_id = window.location.search.split("?")[1];
    let data = {
      // ico_id: ico_id,
      vesting_rules: ruleData,
    };
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "PUT",
        url: `${API_URL}admin/business_pages/ico/${id}/edit_vesting_rules`,
        headers: {
          Authorization: token,
        },
        // params: {
        //   keyword,
        //   perPage,
        //   page: pageData,
        // },
        data,
      })
        // return res;
        .then(({ data }) => {
          //console.log("data -----", data);
          if (data.data) {
            dispatch(fetchSuccess());
            setICOList(data.data);
            setpageListMeta(data.meta);

            let ico_id = window.location.search.split("?")[1];
            if (ico_id) {
              GetVestingRulesDetails(ico_id);
            }
            // dispatch(setUsersListData(data));
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.response.data.message));
    }
    // };
  };

  const AddVestingRules = async () => {
    // const pageData = page != undefined ? page + 1 : "";
    // const keywordData = keyword != undefined ? keyword : "";
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    let ico_id = window.location.search.split("?")[1];
    let data = {
      vesting_rules: ruleData,
    };
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "POST",
        url: `${API_URL}admin/business_pages/ico/${ico_id}/add_vesting_rules`,
        headers: {
          Authorization: token,
        },
        // params: {
        //   keyword,
        //   perPage,
        //   page: pageData,
        // },
        data,
      })
        // return res;
        .then(({ data }) => {
          //console.log("data -----", data);
          if (data.data) {
            dispatch(fetchSuccess());
            setICOList(data.data);
            setpageListMeta(data.meta);
            // dispatch(setUsersListData(data));
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.response.data.message));
    }
    // };
  };
  const GetVestingRulesDetails = async (ico_id) => {
    // const pageData = page != undefined ? page + 1 : "";
    // const keywordData = keyword != undefined ? keyword : "";
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    // let ico_id = window.location.search.split("?")[1];
    // let data = {
    //   vesting_rules: ruleData,
    // };
    try {
      // dispatch(fetchStart());
      const res = await axios({
        method: "GET",
        url: `${API_URL}admin/business_pages/ico/${ico_id}/get_vesting_rules`,
        headers: {
          Authorization: token,
        },
        // params: {
        //   keyword,
        //   perPage,
        //   page: pageData,
        // },
        // data,
      })
        // return res;
        .then(({ data }) => {
          if (data.data) {
            setEditOn(true);
            console.log("data ----- vesting", data);
            dispatch(fetchSuccess());
            setRuleData(data.data.rules);
            setIdd(data.data.id);
            setpageListMeta(data.meta);
            // dispatch(setUsersListData(data));
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      // dispatch(fetchError(error.response.data.message));
    }
    // };
  };
  useEffect(() => {
    let ico_id = window.location.search.split("?")[1];
    if (ico_id) {
      GetVestingRulesDetails(ico_id);
    }
  }, [window.location.search]);
  useEffect(() => {
    let sumWithInitial = 0;
    for (let i = 0; i < ruleData.length; i++) {
      sumWithInitial = sumWithInitial + ruleData[i].percent;
    }
    setTotalPer(sumWithInitial);
  }, [ruleData.length, checked]);

  return (
    <div>
      <AuthWrapper variant={"default"}>
        <Box className={classes.authContent}>
          <Typography
            component="div"
            variant="h3"
            className={classes.titleRoot}
          >
            Vesting Rule
          </Typography>
          <GridContainer>
            <Grid item xs={12} md={12} className={classes.wallRightSidebar}>
              {/* <Activities userId={icoList[0]} /> */}
              {/*  /********************************  */}
              <Box className="mainBox numberTextField">
                {/* <Typography variant="h5"> Team</Typography> */}

                {ruleData.map((data, index) => {
                  // {
                  return (
                    <>
                      <SettingFields
                        data={data}
                        handleChange={handleChange}
                        index={index}
                        teamsDataError={teamsDataError}
                      />

                      {ruleData.length - 1 == index && (
                        <>
                          <Grid item lg={1} md={1} sm={12} xs={12}>
                            {" "}
                            <Box>
                              <IconButton
                                onClick={() => removeLastItem()}
                                disabled={
                                  ruleData.length == 1 ||
                                  moment(data.date).unix() <
                                    moment()
                                      .startOf("Days")
                                      .unix()
                                }
                              >
                                <DeleteForeverIcon />
                              </IconButton>{" "}
                            </Box>
                          </Grid>
                          <Box width="100%" textAlign="end">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              size="large"
                              onClick={() => {
                                setchecked(!checked);
                                console.log("teams Data Error", teamsDataError);
                                let sumWithInitial = 0;
                                for (let i = 0; i < ruleData.length; i++) {
                                  sumWithInitial =
                                    sumWithInitial + ruleData[i].percent;
                                }
                                if (sumWithInitial < 100) {
                                  if (
                                    ruleData[index].date != "" &&
                                    ruleData[index].date != undefined &&
                                    ruleData[index].percent != "" &&
                                    ruleData[index].percent != undefined
                                  ) {
                                    setTeamsDataError(false);
                                    addActionObj();
                                  } else {
                                    setTeamsDataError(true);
                                  }
                                } else {
                                  setErrorONPercentage(true);
                                }
                              }}
                            >
                              + Add another ruels
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  );
                })}
                {ruleData.length == 0 && (
                  <Box width="100%" textAlign="end">
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                      size="large"
                      onClick={() => {
                        setTeamsDataError(false);
                        addActionObj();
                        // }
                      }}
                    >
                      + Add another ruels
                    </Button>
                  </Box>
                )}
              </Box>
              <p style={{ color: "red" }}>
                {errorONPercentage &&
                  totalPer !== 100 &&
                  `You have entered a wrong value in percentage, i.e ${
                    ruleData.length === 1 &&
                    ruleData[ruleData.length - 1].percent !== 100
                      ? ruleData[ruleData.length - 1].percent
                      : totalPer
                  } `}
              </p>

              <Button
                color="primary"
                variant="contained"
                disable={teamsDataError}
                onClick={() => {
                  setchecked(!checked);
                  let sumWithInitial = 0;
                  for (let i = 0; i < ruleData.length; i++) {
                    sumWithInitial = sumWithInitial + ruleData[i].percent;
                  }
                  if (
                    ruleData[ruleData.length - 1].date != "" &&
                    ruleData[ruleData.length - 1].date != undefined &&
                    ruleData[ruleData.length - 1].percent != "" &&
                    ruleData[ruleData.length - 1].percent != undefined
                  ) {
                    setTeamsDataError(false);
                    if (sumWithInitial != 100 && ruleData.length !== 1) {
                      setErrorONPercentage(true);
                    } else {
                      if (
                        ruleData.length === 1 &&
                        ruleData[ruleData.length - 1].percent !== 100
                      ) {
                        setErrorONPercentage(true);
                      } else {
                        setErrorONPercentage(false);
                        if (editOn) {
                          EditVestingRules();
                        } else {
                          AddVestingRules();
                        }
                      }
                    }
                  } else {
                    setTeamsDataError(true);
                  }
                }}
              >
                Submit
              </Button>
            </Grid>
          </GridContainer>
        </Box>
      </AuthWrapper>
    </div>
  );
};

export default BostSubscription;

BostSubscription.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
