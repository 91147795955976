import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import {
  Button,
  Dialog,
  Box,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import { useForm, Controller } from "react-hook-form";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
import UserTableHead from "./UserTableHead";
import UserTableToolbar from "./UserTableToolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
// import { setCurrentUser } from "redux/actions/Users";
import AddEditUser from "./AddEditUser";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import UserDetailView from "./UserDetailView";
import NoRecordFound from "./NoRecordFound";
import axios from "axios";
import { API_URL } from "constant/constant";

import { useHistory } from "react-router-dom";
import { AuhMethods } from "services/auth";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import AssignResponsibilityCheckboxCheck from "./AssignResponsibilityCheckboxCheck";
import AssignResponsibilityCheckbox from "../AddSubAdmin/AssignResponsibilityCheckbox";
import IntlMessages from "@jumbo/utils/IntlMessages";
// const subadminList = [
//   {
//     name: "Prabhash",
//     username: "Prabhash",
//     email: "hiprabhash@gmail.com",
//     phone: null,
//     created_at: "2022-12-05T07:31:12.000Z",
//     last_login: "--",
//     status: "0",
//   },
//   {
//     name: "Suraj",
//     username: "banaji",
//     email: "re-suraj@mobiloitte.com",
//     phone: null,
//     created_at: "2022-12-05T07:21:43.000Z",
//     last_login: "2023-01-18T07:31:12.000Z",
//     status: "1",
//   },
//   {
//     name: "Subham",
//     username: "subu",
//     email: "sub1234@gmail.com",
//     phone: null,
//     created_at: "2022-12-05T07:11:12.000Z",
//     last_login: "2023-01-05T07:15:01.000Z",
//     status: "2",
//   },
//   {
//     name: "Abjeet",
//     username: "banaji",
//     email: "abjeet47hg74@gmail.com",
//     phone: null,
//     created_at: "2022-12-05T07:31:12.000Z",
//     last_login: "2023-01-24T07:23:12.000Z",
//     status: "1",
//   },
// ];
const PageList = ({
  method = "usersModule",
  variant = "default",
  wrapperVariant = "default",
}) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      payInr: "",
      amountInr: "",
      amountTaral: "",
    },
    resolver: yupResolver(),
  });
  const classes = useStyles();
  const [orderBy, setOrderBy] = React.useState("name");
  const [valuessss, setValue1] = React.useState({
    payInr: "",
    amountInr: "",
    amountTaral: "",
    min: "",
    max: "",
  });
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [subadminList, setSubadminList] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [verifyToggle, setVerifyToggle] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageListMeta, setpageListMeta] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openRejectedDialog, setOpenRejectedDialog] = useState(false);
  const [editOpenPop, setEditOpenPop] = useState(false);
  // const [openViewDialog, setOpenViewDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [permitionList, setPermitionList] = useState([]);
  const [permitions, setpermitions] = useState([]);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const FetchData = useSelector((state) => state.common);

  const token = useSelector((state) => state.admin.authUser.access_token);

  // const pageListMeta = useSelector(
  //   (state) => state.businessPageManagement.pageListMeta
  // );
  const pageList = useSelector(
    (state) => state.businessPageManagement.pageList
  );
  const dispatch = useDispatch();

  const SubadminListAPI = async ({ token, page, perPage, keyword, order }) => {
    const pageData = page != undefined ? page + 1 : "";
    const keywordData = keyword != undefined ? keyword : "";
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "GET",
        url: `${API_URL}admin/subadmin/list`,
        headers: {
          Authorization: token,
        },
        params: {
          keyword,
          perPage,
          page: pageData,
        },
      })
        // return res;
        .then(({ data }) => {
          //console.log("data -----", data);
          if (data.data) {
            dispatch(fetchSuccess());
            setSubadminList(data.data);
            setpageListMeta(data.meta);
            // dispatch(setUsersListData(data));
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  useEffect(() => {
    SubadminListAPI({
      token: token,
      page: page,
      perPage: rowsPerPage,
      keyword: searchText,
      order,
    });
  }, [page, rowsPerPage, searchText]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    // dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleRowClick = (event, id) => {
    history.push({
      pathname: "/pageDetails",
      state: { id: id },
    });
  };

  const handleSearchTextChange = (e) => {
    let data = e.target.value.replace(/^\s/, "");
    setSearchText(data);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleUserView = (user) => {
    history.push({
      pathname: "/crypto-page-details",
      state: { id: user.id },
    });
  };
  const onVerify = (data) => {
    setVerifyToggle(true);
    setSelectedUser(data);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    // dispatch(setCurrentUser(null));
  };

  const handleUserEdit = (user) => {
    // dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleUserBlock = (user) => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };
  const handleChangeStatus = (action, label, id, row) => {
    let data = {
      action: action,
      label: label,
      id: id,
      row: row,
    };
    let permissions12 = JSON.parse(row.permissions);
    let data1 = [];
    if (permissions12 !== null) {
      permitionList.forEach((element, index) => {
        if (permissions12.includes(element.id)) {
          data1.push(element.id);
        } else {
          data1.push(0);
        }
      });
    }
    setpermitions(data1);
    setSelectedUser(data);
    if (action == "actived") {
      // setOpenConfirmDialog(true);
      setOpenRejectedDialog(true);
    }
    if (action == "block") {
      setOpenRejectedDialog(true);
    }
    if (action == "View") {
      history.push({ pathname: "/log-reports", search: row?.id.toString() });
      // setOpenViewDialog(true);
    }
    if (action == "Edit") {
      setEditOpenPop(true);
      // history.push({ pathname: "/add-sub-admin", search: "Edit" });
      // setOpenViewDialog(true);
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelVerify = () => {
    setVerifyToggle(false);
  };
  const handleReject = () => {
    setOpenRejectedDialog(false);
  };

  // };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const EditSubAdmin = async () => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "PUT",
        url: `${API_URL}admin/subadmin/edit`,
        headers: {
          Authorization: token,
        },
        data: {
          id: selectedUser?.id,
          name: selectedUser?.row?.username,
          email: selectedUser?.row?.email,
          permissions: permitions,
        },
      }).then(({ data }) => {
        SubadminListAPI({
          token: token,
          page: page,
          perPage: rowsPerPage,
          keyword: searchText,
          order,
        });
        console.log(data);
        if (data.message) {
          dispatch(fetchSuccess(data.message));
          setOpenRejectedDialog(false);
          setEditOpenPop(false);
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };
  const EnabledDisabledSubAdmin = async (data) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "PUT",
        url: `${API_URL}admin/subadmin/enable_disable`,
        headers: {
          Authorization: token,
        },
        data: {
          id: data,
        },
      }).then(({ data }) => {
        dispatch(fetchSuccess());
        setOpenRejectedDialog(false);
        console.log(data);
        setEditOpenPop(false);

        SubadminListAPI({
          token: token,
          page: page,
          perPage: rowsPerPage,
          keyword: searchText,
          order,
        });
        if (data.message) {
          dispatch(fetchSuccess(data.message));
          setOpenRejectedDialog(false);
          setEditOpenPop(false);
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };
  const ViewAPIHandlet = async (data) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios
        .get(`${API_URL}admin/permission/list`)
        // return res;
        .then(({ data }) => {
          if (data.data) {
            dispatch(fetchSuccess());
            setPermitionList(data.data);
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  useEffect(() => {
    ViewAPIHandlet();
  }, []);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <UserTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={subadminList.length}
            />
            <TableBody>
              {subadminList.length > 0 ? (
                subadminList.map((row, index) => (
                  <UserListRow
                    key={index}
                    page={page}
                    idx={index}
                    row={row}
                    onRowClick={handleRowClick}
                    onUserEdit={handleUserEdit}
                    changeStatus={handleChangeStatus}
                    onUserView={handleUserView}
                    isSelected={isSelected}
                    onVerify={onVerify}
                  />
                ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={subadminList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openUserDialog && (
        <AddEditUser
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}
      {/* {openViewDialog && (
        <UserDetailView
          viewDetails={selectedUser?.row}
          open={openViewDialog}
          onCloseDialog={handleCloseViewDialog}
        />
      )} */}

      <ConfirmDialog
        open={openRejectedDialog}
        title={`Confirm to ${
          selectedUser?.action == "actived" ? "ACTIVATE" : "BLOCK"
        } ${selectedUser?.row?.username ? selectedUser.row.username : ""}`}
        content={`Are you sure, you want to ${
          selectedUser?.action == "actived" ? "ACTIVATE" : "BLOCK"
        } this SubadminList?`}
        onClose={handleReject}
        onConfirm={() => EnabledDisabledSubAdmin(selectedUser?.row?.id)}
      />

      {/* <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${selectedUser.status == "1" ? "Block" : "Unblock"} ${
          selectedUser.name ? selectedUser.name : ""
        }`}
        content={`Are you sure, you want to ${
          selectedUser.status == "1" ? "block" : "unblock"
        } this user?`}
        onClose={handleCancelDelete}
        onConfirm={}
      /> */}
      {editOpenPop && (
        <Dialog
          open={editOpenPop}
          onClose={() => setEditOpenPop(false)}
          aria-labelledby="confirm-dialog-title"
          className={classes.dialogRoot}
        >
          <DialogTitle
            id="confirm-dialog-title"
            className={classes.dialogTitleRoot}
          >
            {" "}
            <Typography
              component="div"
              variant="h1"
              className={classes.titleRoot}
            >
              Edit {<IntlMessages id="appModule.permission" />}
            </Typography>
            {/* Edit Permitions */}
            {/* Are You  */}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              <div>
                <Box mb={2} className="inputPasswordCustom">
                  <Box className="inputPasswordCustom1">
                    {permitionList &&
                      permitionList.map((data, index) => (
                        <div>
                          {/* <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={gilad}
                            // onChange={handleChange}
                            name={data.title}
                          />
                        }
                        label={data.title.toUpperCase()}
                      />
                    </FormGroup> */}
                          <AssignResponsibilityCheckboxCheck
                            name={data.title}
                            label={data.title}
                            assigned={data.id}
                            index={index}
                            permitions={permitions}
                            setpermitions={setpermitions}
                            // Checked={permitions.map((val) => val === data.id)}
                          />
                          {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checkedB}
                            onChange={handleChange}
                            name={"checkedB"}
                          />
                        }
                        label={"Read Only"}
                      /> */}
                          <br />
                        </div>
                      ))}
                  </Box>
                </Box>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActionsRoot }}>
            <Button
              onClick={() => setEditOpenPop(false)}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={EditSubAdmin} color="primary" variant="contained">
              Edit Permitions
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default PageList;
