import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import React from "react";
import axios from "../../config";

import { resolve } from "path";
import { reject } from "lodash";
import { AuhMethods } from "..";
import {
  addRewardList,
  getRewardList,
  getRewardTypes,
  successUploadCSV,
  getAirdropList,
  rewardListMeta,
  getCoinlist
} from "redux/actions/RewardsManagement";

//Report Management apis
const RewardManagementServicesAuth = {
  rewardListAPI: ({ token, page, perPage, order }) => {
    const pageData =
      page != undefined
        ? `?order=${order}&perPage=${perPage}&page=${page + 1}`
        : "?perPage=10&page=0";
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(`/admin/reward/list${pageData}`)
          .then(({ data }) => {
            if (data.data) {
              dispatch(rewardListMeta(data?.meta));
              dispatch(fetchSuccess());
              dispatch(getRewardList(data.data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  rewardTypesListAPI: ({ token, page, perPage }) => {
    const pageData = page != undefined ? "&page=" + (page + 1) : "";
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get("admin/reward/types")
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(getRewardTypes(data.data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  addRewardApi: (data, params) => {
    return dispatch => {
      let token = data.token;
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      delete data.token;
      dispatch(fetchStart());
      axios
        .post("admin/reward/add", data)
        .then(({ data }) => {
          if (data) {
            dispatch(fetchSuccess(data.message));
            // dispatch(addRewardList(data.data));
            setTimeout(() => {
              dispatch(
                AuhMethods["rewardsManagement"].rewardListAPI({
                  token: token,
                  params: params
                })
              );
            }, 1000);
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error?.response?.data?.message));
        });
    };
  },
  deleteRewardAPI: ({ token, id, action, page }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put(
            "admin/reward/change_status",
            { id: id, action: action },
            { withCredentials: true }
          )
          .then(async ({ data }) => {
            let mydata = await data;
            if (mydata?.message) {
              setTimeout(() => {
                dispatch(fetchSuccess(mydata?.message));
              }, 1000);
              dispatch(
                AuhMethods["rewardsManagement"].rewardListAPI({
                  token: token,
                  page: page,
                  perPage: 10,
                  order: "desc"
                })
              );
            } else {
              dispatch(fetchError(data?.message));
            }
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  },

  coinListAPI: ({ token, page, perPage, order }) => {
    const pageData =
      page != undefined
        ? `?order=${order}&perPage=${perPage}&page=${page + 1}`
        : "?perPage=10&page=0";
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(`admin/reward/coin_list`)
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(getCoinlist(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  airdropProcess: ({ token, id, page, perPage, order }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/reward/airdrop/process_file/${id}`,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));
              setTimeout(() => {
                dispatch(
                  AuhMethods["rewardsManagement"].airdropListAPI({
                    token: token,
                    page: page,
                    perPage: perPage,
                    order: order
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
            // resolve(data)
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  },

  uploadCSV: (token, file) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .post("admin/file/upload_airdrop_file", file, {
            withCredentials: true
          })
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess());
              dispatch(successUploadCSV(data?.data));
            } else {
              dispatch(fetchError(data?.message));
            }
            // resolve(data)
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  },

  uploadAirdropCSV_URL: (
    token,
    file,
    page = 0,
    perPage = 10,
    order = "asc"
  ) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      if (!file.comment || file.comment === "") {
        dispatch(fetchError("Comment should not empty"));
      }
      try {
        dispatch(fetchStart());
        axios
          .post("admin/reward/airdrop/add", file, { withCredentials: true })
          .then(async ({ data }) => {
            if (data.message) {
              dispatch(await fetchSuccess(data?.message));
              setTimeout(() => {
                dispatch(successUploadCSV({}));
                dispatch(
                  AuhMethods["rewardsManagement"].airdropListAPI({
                    token: token,
                    page: page,
                    perPage: perPage,
                    order: order
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
            // resolve(data)
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  },
  airdropListAPI: ({ token, page, perPage, order }) => {
    const pageData =
      page != undefined
        ? `?order=${order}&perPage=${perPage}&page=${page + 1}`
        : "?perPage=10&page=0";
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(`/admin/reward/airdrop/list${pageData}`)
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(getAirdropList({ list: data.data, meta: data.meta }));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  }
};
export default RewardManagementServicesAuth;
