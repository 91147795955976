import {
  SAVE_DASHBOARD_DATA,
  SAVE_POPULER_PROFILES_DATA,
  SAVE_RECENT_FLAG_DATA,
  SAVE_POPULER_POSTS_DATA,
  SAVE_POST_CATEGORIES_DATA,
  SAVE_POPULER_POSTS_META_DATA,
  APPEND_POPULER_POSTS_DATA,
} from '../../@jumbo/constants/ActionTypes';

export const setDashboardData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_DASHBOARD_DATA,
      payload: data,
    });
  };
};

export const setPopulerProfilesData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_POPULER_PROFILES_DATA,
      payload: data,
    });
  };
};

export const setRecentFlagData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_RECENT_FLAG_DATA,
      payload: data,
    });
  };
};

export const setPopulerPostsData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_POPULER_POSTS_DATA,
      payload: data,
    });
  };
};
export const appendPopulerPostsData = data => {
  return dispatch => {
    dispatch({
      type: APPEND_POPULER_POSTS_DATA,
      payload: data,
    });
  };
};

export const setPopulerPostsMetaData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_POPULER_POSTS_META_DATA,
      payload: data,
    });
  };
};

export const setPostCategoriesData = data => {
  return dispatch => {
    dispatch({
      type: SAVE_POST_CATEGORIES_DATA,
      payload: data,
    });
  };
};
