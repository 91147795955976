import {
  SAVE_DASHBOARD_DATA,
  SAVE_POPULER_PROFILES_DATA,
  SAVE_RECENT_FLAG_DATA,
  SAVE_POPULER_POSTS_DATA,
  SAVE_POST_CATEGORIES_DATA,
  SAVE_POPULER_POSTS_META_DATA,
  APPEND_POPULER_POSTS_DATA,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  dashboardData: [],
  populerProfiles: [],
  recentFlags: [],
  populerPosts: [],
  populerPostsMeta: {},
  postCategories: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_DASHBOARD_DATA: {
      return {
        ...state,
        dashboardData: action.payload[0],
      };
    }
    case SAVE_POPULER_PROFILES_DATA: {
      return {
        ...state,
        populerProfiles: action.payload,
      };
    }
    case SAVE_RECENT_FLAG_DATA: {
      return {
        ...state,
        recentFlags: action.payload,
      };
    }
    case SAVE_POPULER_POSTS_DATA: {
      return {
        ...state,
        populerPosts: action.payload,
      };
    }
    case APPEND_POPULER_POSTS_DATA: {
      return {
        ...state,
        populerPosts: [...state.populerPosts, ...action.payload],
      };
    }
    case SAVE_POPULER_POSTS_META_DATA: {
      return {
        ...state,
        populerPostsMeta: action.payload,
      };
    }
    case SAVE_POST_CATEGORIES_DATA: {
      return {
        ...state,
        postCategories: action.payload,
      };
    }
    default:
      return state;
  }
};
