import { ADD_SETTING_TYPE, EDIT_SETTING_TYPE, GET_SETTING_LIST } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  AppSettingList: {},
  AppSettingListMeta: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SETTING_LIST: {
      return {
        ...state,
        AppSettingList: action.payload.data,
        AppSettingListMeta: action.payload.meta,
      };
    }
    case ADD_SETTING_TYPE: {
      return {
        ...state,
        AppSettingList: [...state.AppSettingList, ...action.payload.data],
      };
    }

    case EDIT_SETTING_TYPE: {
      return {
        ...state,
        AppSettingList: [...action.payload.data],
        AppSettingList: [action.payload.meta],
      };
    }

    default:
      return state;
  }
};
