import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtAvatar from "@coremat/CmtAvatar";
import { useDropzone } from "react-dropzone";
import Button from "@material-ui/core/Button";
import CmtList from "@coremat/CmtList";
import IconButton from "@material-ui/core/IconButton";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { emailNotValid, requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { isValidEmail } from "@jumbo/utils/commonHelper";
import { addNewUser, updateUser } from "redux/actions/Users";
import { inherits } from "util";
import { AuhMethods } from "services/auth";
import { Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "relative",
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

const AddEditContentType = ({
  open,
  onCloseDialog,
  isedit,
  selectedId,
  selectedName,
  page,
  perPage,
  order,
  setValue,
}) => {
  const classes = useStyles();
  //   const currentUser = useSelector(({ usersReducer }) => usersReducer.currentUser);
  const currentUser = {};
  const [settingValue, setSettingValue] = useState(isedit ? setValue : "");
  const [previousSettingValue, setPreviousSettingValue] = useState(setValue);

  const [categoryId, setCategoryId] = useState(selectedId);
  const [number, setNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [settingName, setSettingName] = useState("");
  // const [addSettingValue, setAddSettingValue] = useState('');

  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [email, setEmail] = useState('');
  // const [profile_pic, setProfile_pic] = useState('');
  // const [company, setCompany] = useState('');
  // const [designation, setDesignation] = useState('');
  // const [phones, setPhones] = useState([{ phone: '', label: 'home' }]);

  const [firstNameError, setFirstNameError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [amountError, setAmountError] = useState("");
  const token = useSelector((state) => state.admin.authUser.access_token); //Fetch data from Redux
  const contentTypesList = useSelector(
    (state) => state?.contentManagement?.ContentTypeList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(AuhMethods['contentManagement'].addContentTypeAPI(token, { settingValue }));
    // if (currentUser) {
    //   // const [fName, lName] = splitName(currentUser);
    //   // setFirstName(fName);
    //   // setLastName(lName);
    //   // setProfile_pic(currentUser.profile_pic);
    //   // setEmail(currentUser.email);
    //   // setCompany(currentUser.company);
    //   // setDesignation(currentUser.designation);
    //   // setPhones(currentUser.phones);
    // }
  }, []);

  const onSubmitClick = () => {
    if (!settingValue || settingValue.length === 0) {
      setFirstNameError(requiredMessage);
    } else {
      if (isedit) {
        let params = { key_name: selectedName, value: settingValue };
        dispatch(
          AuhMethods["AppSettings"].addOrEditSettingTypeAPI(
            token,
            "",
            params,
            page,
            perPage,
            order
          )
        );
        setSettingValue("");
      } else {
        let params = { key_name: settingName, value: settingValue };
        dispatch(
          AuhMethods["AppSettings"].addOrEditSettingTypeAPI(
            token,
            "",
            params,
            page,
            perPage,
            order
          )
        );
        setSettingValue("");
      }

      onCloseDialog();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      isedit={isedit}
      className={`${classes.dialogRoot} dialogCustom`}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {" "}
        <span>{isedit ? "Edit Setting" : "Create Setting"}</span>
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {isedit ? (
        <DialogContent dividers>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            mb={{ xs: 6, md: 5 }}
          ></Box>

          <Box display="flex" justifyContent="flax-start" mb={4}>
            <Typography
              className={classes.title}
              variant="h5"
              id="tableTitle"
              component="div"
              style={{ marginBottom: 20 }}
            >
              Setting Type: {selectedName}
            </Typography>
          </Box>
          <Box mb={{ xs: 6, md: 5 }}>
            <AppTextInput
              style={{ marginBottom: 12 }}
              fullWidth
              variant="outlined"
              label="Value"
              type="Number"
              value={settingValue}
              onChange={(e) => {
                let tt = e.target.value.trim();
                if (tt) {
                  setSettingValue(e.target.value);
                  setFirstNameError("");
                } else {
                  setSettingValue(e.target.value);
                  if (e.target.value == "") {
                    setFirstNameError("This field must be  number");
                  } else {
                    setFirstNameError("No white space allowed");
                  }
                }
              }}
              helperText={firstNameError}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  settingValue === previousSettingValue ||
                  settingValue === "" ||
                  firstNameError
                }
                onClick={onSubmitClick}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent dividers>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            mb={{ xs: 6, md: 5 }}
          ></Box>
          <Box mb={{ xs: 6, md: 5 }}>
            <AppTextInput
              style={{ marginBottom: 12 }}
              fullWidth
              variant="outlined"
              label="Name"
              value={settingName}
              onChange={(e) => {
                let tt = e.target.value.trim();
                if (tt) {
                  setSettingName(e.target.value);
                  setFirstNameError("");
                } else {
                  setSettingName(e.target.value);
                  if (e.target.value == "") {
                    setFirstNameError("This field is required");
                  } else {
                    setFirstNameError("No white space allowed");
                  }
                }
              }}
              helperText={firstNameError}
            />
          </Box>
          <Box mb={{ xs: 6, md: 5 }}>
            <AppTextInput
              style={{ marginBottom: 12 }}
              fullWidth
              variant="outlined"
              label="Value"
              type="Number"
              value={settingValue}
              onChange={(e) => {
                let tt = e.target.value.trim();
                if (tt) {
                  setSettingValue(e.target.value);
                  setFirstNameError("");
                } else {
                  setSettingValue(e.target.value);
                  if (e.target.value == "") {
                    setFirstNameError("This field is required");
                  } else {
                    setFirstNameError("No white space allowed");
                  }
                }
              }}
              helperText={firstNameError}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  (settingName === "" && settingValue === "") || firstNameError
                }
                onClick={onSubmitClick}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AddEditContentType;

AddEditContentType.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
