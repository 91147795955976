import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import { timeFromNow } from "@jumbo/utils/dateHelper";
import { Block, MoreHoriz, Visibility, VerifiedUser } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, FormControlLabel, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import axios from "axios";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  },
  Root: {
    "& .MuiTableCell-root": {
      padding: 9
    }
  }
}));

const getUserActions = (is_block, is_verify) => {
  const actions = [{ action: "view", label: "View", icon: <Visibility /> }];
  actions.push({
    action: "block",
    label: is_block == "1" ? "Block" : "Unblock",
    icon: <Block />
  });

  actions.push({
    action: "verify",
    label: is_verify == "0" ? "Verify" : "Unverify",
    icon: <VerifiedUser />
  });
  return actions;
};

const UserListRow = ({
  row,
  isSelected,
  onUserBlock,
  onUserView,
  onVerify,
  page,
  idx,
  setHandlePopupTrue,
  setselectedRow
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedF: false
  });

  const token = useSelector(state => state.admin.authUser.access_token);
  const handleChange = async event => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (event.target.checked) {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      setHandlePopupTrue(true);
      setselectedRow(row);
    }
  };

  const onUserMenuClick = menu => {
    if (menu.action === "view") {
      onUserView(row);
    } else if (menu.action === "block") {
      onUserBlock(row);
    } else if (menu.action === "verify") {
      onVerify(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row?.user_id}`;
  const isItemSelected = isSelected(row?.user_id);
  const userActions = getUserActions(row?.status, row?.verified);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?.user_id}
      selected={isItemSelected}
      className={classes.Root}
    >
      <TableCell>{page * 10 + idx + 1}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row?.profile_url} alt={row?.name} />
          </Box>
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row?.name === null ? "N/A" : row?.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row?.detail?.email}</TableCell>
      <TableCell> {row?.username} </TableCell>{" "}
      <TableCell>
        {row.white_list_accounts === null ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedF}
                onChange={handleChange}
                name="checkedF"
              />
            }
          />
        ) : (
          `${
            row?.white_list_accounts?.status == "active"
              ? "ACTIVATE"
              : "DISABLED"
          } / ${row.white_list_accounts?.score}`
        )}
      </TableCell>
      <TableCell>{row?.status === "1" ? "Unblocked" : "Blocked"}</TableCell>
      <TableCell>{row?.verified === "1" ? "Yes" : "No"}</TableCell>
      <TableCell>{timeFromNow(row?.detail?.login_session)}</TableCell>
      <TableCell className="like-padding">{row?.total_likes}</TableCell>
      <TableCell>{row?.total_comments}</TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
