import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import CmtGridView from "../../../../@coremat/CmtGridView";
import CmtImage from "../../../../@coremat/CmtImage";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { AuhMethods } from "services/auth";
import { IMAGE_URL } from "constant/constant";
import ContentStory from "routes/ContentManagement/ContentDetails/Activities/contentStory";
import { storyListData } from "redux/actions";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    paddingBottom: 24,
    marginBottom: 24,
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      zIndex: 1,
      width: 36,
      height: 4,
      backgroundColor: theme.palette.primary.main
    }
  },
  comGridRoot: {
    borderRadius: 4,
    overflow: "hidden",
    marginBottom: 20
  },
  comThumbRoot: {
    overflow: "hidden",
    position: "relative",
    cursor: "pointer"
  },
  comThumb: {
    "& img": {
      width: "100%",
      display: "block"
    }
  },
  comThumbContent: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backgroundImage:
      "linear-gradient(180deg,rgba(0,0,0,.01) 0,rgba(0,0,0,.65))",
    padding: 8,
    fontSize: 12,
    color: theme.palette.common.white
  },
  textLink: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    cursor: "pointer"
  }
}));

const Communities = userId => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedStory, setSelectedStory] = useState([]);
  const [viewer, setViewer] = useState([]);
  const token = useSelector(state => state.admin.authUser.access_token);
  const story = useSelector(
    state => state?.businessPageManagement?.PageStoryList
  );
  const [position, setPosition] = useState(-1);

  useEffect(() => {
    if (userId) {
      getStories();
    }
    return () => {
      dispatch(storyListData([]));
    };
  }, [userId]);

  useEffect(() => {
    let datad = [];
    if (Array.isArray(story?.data)) {
      for (let i = 0; i < story?.data.length; i++) {
        let url = `${IMAGE_URL}${story?.data[i]?.url}`;
        let type = `${story?.data[i]?.type}`;
        let subheading = story?.data[i]?.story_user_detail?.username
          ? `${story?.data[i]?.story_user_detail?.username}`
          : "N/A";
        let profileImage = story?.data[i]?.story_user_detail?.profile_url
          ? `${IMAGE_URL}${story?.data[i]?.story_user_detail?.profile_url}`
          : "https://images.unsplash.com/photo-1533561797500-4fad4750814e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW5zdGFncmFtJTIwc3Rvcmllc3xlbnwwfHwwfHw%3D&w=1000&q=80";
        let name = story?.data[i]?.story_user_detail?.name
          ? `${story?.data[i]?.story_user_detail?.name}`
          : "N/A";
        let gg = {
          url: url,
          duration: 3000,
          type: type,
          header: {
            heading: name,
            subheading: subheading,
            profileImage: profileImage
          }
        };
        datad.push(gg);
        // }
      }

      setViewer(datad);
    }
  }, [position, story]);
  const getStories = () => {
    dispatch(AuhMethods["businessModule"].getPageStoriesAPI({ token, userId }));
  };

  const handleClose = () => {
    setPosition(-1);
  };

  return (
    <Box mb={{ xs: 6, lg: 8, xl: 10 }}>
      <Typography component="div" variant="h3" className={classes.titleRoot}>
        Stories
      </Typography>
      <Box className={classes.comGridRoot}>
        <CmtGridView
          itemPadding={3}
          data={Array.isArray(viewer) && viewer}
          renderRow={(item, index) => (
            <Box key={index} className={classes.comThumbRoot}>
              <Box
                key={index}
                className={classes.comThumb}
                onClick={() => {
                  setSelectedStory(item);
                  setPosition(index);
                }}
              >
                <CmtImage src={item?.header?.profileImage} alt={item?.name} />
              </Box>
              <Box className={classes.comThumbContent}>
                {item?.header?.heading}
              </Box>
            </Box>
          )}
        />
        <ContentStory
          position={position}
          medias={[selectedStory]}
          handleClose={handleClose}
        />
      </Box>
    </Box>
  );
};

export default Communities;
