import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUsers, setCurrentUser } from "redux/actions/Users";
import AddEditContentType from "./AddEditContentType";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { useDebounce } from "@jumbo/utils/commonHelper";
import useStyles from "./index.style";
import NoRecordFound from "./NoRecordFound";
import { usersModule, recentActivities } from "../../../@fake-db";
import { getDateText } from "../../../@jumbo/utils/dateHelper";
import { useHistory } from "react-router-dom";
import ContentTypeTableToolbar from "./ContentTypeTableToolbar";
import ContentTypeTableHead from "./ContentTypeTableHead";
import ContentListRow from "./ContentListRow";
import { AuhMethods } from "services/auth";

const HashTags = () => {
  const history = useHistory();

  const classes = useStyles();
  // const { users } = useSelector(({ usersReducer }) => usersReducer);
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [searchText, setSearchText] = useState("");
  const [selectedName, setName] = useState([]);
  const [selectedId, setId] = useState([]);

  const HashTagsList = useSelector(
    (state) => state?.contentManagement?.HashTagsList
  );
  const HashTagsListMeta = useSelector(
    (state) => state?.contentManagement?.HashTagsListMeta[0]
  );
  const token = useSelector((state) => state.admin.authUser.access_token);
  const FetchData = useSelector((state) => state?.common);

  const dispatch = useDispatch();

  const getUsersListByDate = () => {
    return HashTagsList.map((activity) => {
      activity.category = getDateText(activity.date);
      return activity;
    });
  };
  const users = getUsersListByDate();

  useEffect(() => {
    dispatch(
      AuhMethods["contentManagement"].hashTagsListAPI({
        token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText,
      })
    );
  }, [page, rowsPerPage, searchText]);

  // useEffect(() => {
  //   dispatch(
  //     getUsers(filterOptions, debouncedSearchTerm, () => {
  //       setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
  //       setUsersFetched(true);
  //     }),
  //   );
  // }, [dispatch, filterOptions, debouncedSearchTerm]);

  const handleCloseUserDialog = () => {
    setOpenFlagDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFlagEdit = (user) => {
    // dispatch(setCurrentUser(user));
    // dispatch(setCurrentUser(user));

    setId(user?.id);
    setName(user?.name);
    // setName(newdata?.name);
    setOpenFlagDialog(true);
    setEditFlag(true);
  };

  const handleFlagAdd = (user) => {
    // dispatch(setCurrentUser(user));

    setOpenFlagDialog(true);
    setEditFlag(false);
  };

  const handleFlagDelete = (user) => {
    setSelectedUser({ selectedUser, ...user });
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    let id = selectedUser.id;
    dispatch(AuhMethods["contentManagement"].deleteHashTagsAPI({ token, id }));
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  console.log(HashTagsListMeta, "users -------", users);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ContentTypeTableToolbar
          selected={selected}
          setSelected={setSelected}
          onFlagAdd={handleFlagAdd}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          data={HashTagsList}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <ContentTypeTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {!!users?.length ? (
                // stableSort(users, getComparator(order, orderBy))
                users
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <ContentListRow
                      key={index}
                      row={row}
                      // onRowClick={console.log("handleRowClick")}
                      onFlagEdit={handleFlagEdit}
                      onFladDelete={handleFlagDelete}
                      isSelected={isSelected}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={HashTagsListMeta?.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openFlagDialog && (
        <AddEditContentType
          open={openFlagDialog}
          onCloseDialog={handleCloseUserDialog}
          isedit={editFlag}
          selectedName={selectedName}
          selectedId={selectedId}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={"Are you sure, you want to delete this Hash Tags?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default HashTags;
