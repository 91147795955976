import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MoreHoriz, Close, Done } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const UserListRow = ({ row, page, idx, changeStatus }) => {
  const classes = useStyles();

  // *********** option for Page ***********************
  const getUserActions = () => {
    const actions = [
      row.status === "0"
        ? { action: "enable", label: "Enable", icon: <Done /> }
        : row.status === "1"
        ? { action: "Disable", label: "Disable", icon: <Close /> }
        : row.status === "2"
        ? { action: "accepted", label: "APPROVE", icon: <Done /> }
        : {},
      { action: "rejected", label: "View", icon: <VisibilityIcon /> },
      { action: "rejected", label: "Edit", icon: <EditIcon /> }
    ];

    return actions;
  };

  const onUserMenuClick = (menu, row) => {
    changeStatus(menu.action, menu.label, row?.id, row);
  };
  const labelId = `enhanced-table-checkbox-${row.user_id}`;
  const userActions = getUserActions(row.status, row.verified);
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
      <TableCell>{page * 10 + idx + 1}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.name === null ? "N/A" : row.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{moment(row.created_at).format("lll")}</TableCell>

      <TableCell>
        {row.status === "1"
          ? "Active"
          : row.status === "0"
          ? "Inactive"
          : "Unverified"}
      </TableCell>

      <TableCell align="center">
        <CmtDropdownMenu
          row={row}
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
