import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {
  Button,
  Menu,
  Grid,
  Box,
  MenuItem,
  makeStyles,
  TextField,
  withStyles
} from "@material-ui/core";
import CmtSearch from "@coremat/CmtSearch";
// import useStyles1 from "./index.style";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CasinoIcon from "@material-ui/icons/Casino";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "300",
    borderRadius: 0,
    minWidth: "192px",
    color: "#fff",
    height: "38px",
    padding: "0px 10px",
    letterSpacing: "1px",
    width: "100%",

    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start"
    },
    "& .MuiMenu-list": {
      with: "193px !important"
    },
    "&.activeMenu": {
      background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      position: "relative",
      "&:before": {
        content: "' '",
        position: "absolute",
        top: "26px",
        left: " 0px",
        width: "100%",
        height: "5px",
        background:
          "radial-gradient(50% 50% at 50% 50%, #FDA645 0%, rgba(255, 0, 205, 0) 100%)",
        [theme.breakpoints.only("xs")]: {
          display: "none"
        }
      }
    },
    "& h6": {
      fontWeight: "400"
    }
    // "&:hover": {
    //   background: "#90909838",
    // },
  },
  flexbox: {
    display: "flex",
    alignItems: "center",
    "& h4": {
      color: "#fff"
    }
  },
  MenuItemshover: {
    padding: "6px 10px",
    "&:hover": {
      backgroundColor: "#3f51b5"
    }
  },
  papers: {
    // .poperForMenu {
    //   .MuiPopover-paper {
    //     background: linear-gradient(0deg, #3f51b5, #1e1c26);
    //     width: 138px;
    //     border-radius: 0;
    //     margin-top: 22px;
    //     overflow: visible;
    //     border-radius: 0 0 10px 10px;
    //     &::after {
    //       content: "";
    //       position: absolute;
    //       top: -3px;
    //       left: 50%;
    //       width: 15px;
    //       transform: rotate(45deg) translateX(-50%);
    //       height: 15px;
    //       background: linear-gradient(315deg, #2a2135, #201d28);
    //     }
    //   }
    // }
    // .poperForMenu1 {
    "& .MuiPopover-paper": {
      background: "linear-gradient(0deg, #3f51b5, #969fd6)",
      width: "150px",
      borderRadius: 0,
      marginTop: "17px",
      overflow: "visible",
      borderRadius: "0 0 10px 10px"
      // &::after {
      //   content: "";
      //   position: absolute;
      //   top: -3px;
      //   left: 50%;
      //   width: 15px;
      //   transform: rotate(45deg) translateX(-50%);
      //   height: 15px;
      //   background: linear-gradient(315deg, #9e86a1, #9d85a0);
      // }
    }
    // }
  }
}));
const StyledMenu = withStyles({
  paper: {
    marginTop: "2px"
  },
  "& ..MuiListItem-button": {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#3f51b5"
    }
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));
const UserFilter = ({
  searchText,
  handleSearchTextChange,

  from_date,
  handleFromChange,
  to_date,
  handleToChange,
  status,
  setstatus
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { flexbox, menuButton, MenuItemshover, papers } = useStyles();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Grid container spacing={2} style={{ margin: "12px 0" }}>
          <Grid item lg={43} sm={6} md={3} xs={12}>
            <CmtSearch
              onlyIcon
              placeholder={"Search By influencer name"}
              border={true}
              value={searchText}
              onChange={handleSearchTextChange}
            />
          </Grid>
          <Grid
            item
            lg={43}
            sm={6}
            md={3}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Button
              className={[menuButton]}
              variant="contained"
              color="primary"
              onClick={handleClick}
              size="small"
            >
              {status == 0
                ? "Pending Request "
                : status == 1
                ? "Verified Request"
                : status == 2
                ? "Rejected Request"
                : "Filter with status"}
            </Button>
            <Box>
              <StyledMenu
                id="simple-menu"
                disableScrollLock
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={papers}
              >
                <MenuItem
                  className={MenuItemshover}
                  onClick={() => {
                    handleClose();
                    setstatus();
                  }}
                >
                  <Box className={flexbox}>
                    <AccountBoxIcon
                      style={{ marginRight: "8px", color: "#fff" }}
                    />
                    <Typography
                      variant="h4"
                      style={{ fontSize: "13px" }}
                      className="profileText"
                    >
                      All
                    </Typography>
                  </Box>
                </MenuItem>{" "}
                <MenuItem
                  className={MenuItemshover}
                  onClick={() => {
                    handleClose();
                    setstatus(0);
                  }}
                >
                  <Box className={flexbox}>
                    <Typography
                      variant="h4"
                      style={{ fontSize: "13px" }}
                      className="profileText"
                    >
                      Pending Request
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem
                  className={MenuItemshover}
                  onClick={() => {
                    handleClose();
                    setstatus(1);
                  }}
                >
                  <Box className={flexbox}>
                    <Typography
                      variant="h4"
                      style={{ fontSize: "13px" }}
                      className="profileText"
                    >
                      Verified Request
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem
                  className={MenuItemshover}
                  onClick={() => {
                    handleClose();
                    setstatus(2);
                  }}
                >
                  <Box className={flexbox}>
                    <Typography
                      variant="h4"
                      style={{ fontSize: "13px" }}
                      className="profileText"
                    >
                      Rejected Request
                    </Typography>
                  </Box>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Grid>

          <Grid item lg={6} sm={6} md={6} xs={12}>
            <TextField
              label="Start Date"
              variant="outlined"
              size="small"
              type="date"
              style={{ marginRight: "12px" }}
              defaultValue={from_date && from_date}
              onChange={e => handleFromChange(e.target.value)}
              disableFuture
              values={from_date}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputProps: { max: moment().format("YYYY-MM-DD") }
              }}
            />
            <TextField
              label="End Date"
              variant="outlined"
              size="small"
              type="date"
              style={{ marginRight: "12px" }}
              onChange={e => handleToChange(e.target.value)}
              disableFuture
              values={moment(to_date).format("lll")}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputProps: {
                  min: from_date,
                  max: moment().format("YYYY-MM-DD")
                }
              }}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </React.Fragment>
  );
};

UserFilter.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchText: PropTypes.string,
  handleSearchTextChange: PropTypes.func,
  onUserAdd: PropTypes.func
};

export default React.memo(UserFilter);
