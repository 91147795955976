import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    marginLeft: 30
  }
}));

function ContentTypeTableHead() {
  const styleClasses = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell component="th" id={1} scope="row">
          <Box display="flex" alignItems="center">
            <div>
              <Typography
                className={styleClasses.titleRoot}
                component="div"
                variant="h4"
              >
                Page category
              </Typography>
            </div>
          </Box>
        </TableCell>

        <TableCell align="left">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default ContentTypeTableHead;
