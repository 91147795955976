import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Menu, MenuItem } from "@material-ui/core";

const CmtDropdownMenu = ({ row, TriggerComponent, items, onItemClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setMenuItems(items);
  }, [items]);

  const openMenu = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item, selectedIndex, event, row) => {
    event.stopPropagation();
    console.log(selectedIndex, "item", item);
    let updatedItem = { ...item };

    if (item.onClick && typeof item.onClick === "function") {
      updatedItem = item.onClick(item);
    } else if (onItemClick && typeof onItemClick === "function") {
      updatedItem = onItemClick(item, row);
    }

    if (updatedItem) {
      let hasChange = false;
      const newMenuItems = menuItems.map((item, index) => {
        if (selectedIndex === index) {
          hasChange = true;
          item = updatedItem;
        }
        return item;
      });

      if (hasChange) setMenuItems(newMenuItems);
    }

    closeMenu();
  };

  return (
    <React.Fragment>
      <div className="pointer">
        <TriggerComponent.type {...TriggerComponent.props} onClick={openMenu} />
      </div>
      <Menu
        className="userDropDown"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuItems.map((item, index) => {
          return (
            <MenuItem
              key={index}
              disabled={
                (row?.post_data?.status === "0" && item.label === "Delete") ||
                (row?.post_data?.status === "2" && item.label === "Delete")
              }
              onClick={event =>
                handleMenuItemClick({ ...item }, index, event, row)
              }
            >
              {item.icon}

              <div
                className="ml-2"
                disabled={
                  row?.post_data?.status === "0" ||
                  (row?.post_data?.status === "2" && item.label === "delete")
                }
              >
                {item.label}
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

CmtDropdownMenu.propTypes = {
  items: PropTypes.array.isRequired,
  TriggerComponent: PropTypes.element.isRequired,
  onItemClick: PropTypes.func
};

export default CmtDropdownMenu;
