import React, { useEffect, useState } from "react";
import RewardHistoryHeader from "./RewardHistoryHeader";
import { useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  Typography,
  Box,
  TableRow,
  TableCell
} from "@material-ui/core";
import RewardHistoryRow from "./RewardHistoryRow";
import Axios from "axios";
import { API_URL } from "constant/constant";
import TrenchesToolbar from "./TrenchesToolbar";
import TrenchesTableHead from "./TrenchesTableHead";
import AddTrenchModal from "./AddTrenchModal";
import TrenchesLevelRow from "./TrenchesLevelRow";
import NoRecordFound from "../../BusinessPage/PageCategories/NoRecordFound";

const RewardManagement = () => {
  const token = useSelector(state => state.admin.authUser.access_token);
  //New states for reward section
  const [rewardsList, setRewardsList] = useState([]);

  //New Api for reward section
  const getRewardListHandler = async () => {
    try {
      const response = await Axios({
        method: "GET",
        url: `${API_URL}admin/rewards_setting/list`,
        headers: {
          Authorization: token
        }
      });
      if (response.status === 200) {
        setRewardsList(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getRewardListHandler();
  }, []);

  return (
    <div>
      <div>
        <UserRewardTable
          token={token}
          rewardsList={rewardsList}
          callBack={getRewardListHandler}
        />
        <TrenchesModule token={token} />
      </div>
    </div>
  );
};

export default RewardManagement;

const UserRewardTable = ({ token, callBack, rewardsList }) => {
  return (
    <Paper style={{ marginTop: "40px" }}>
      <Box style={{ padding: "10px" }}>
        <Typography variant="h4" id="tableTitle" component="div">
          Reward Points
        </Typography>
      </Box>

      <TableContainer>
        <Table
          stickyHeader
          aria-labelledby="tableTitle"
          aria-label="sticky enhanced table"
        >
          <RewardHistoryHeader />
          <TableBody>
            {rewardsList &&
              rewardsList.map((data, i) => {
                return (
                  <RewardHistoryRow
                    data={data}
                    index={i}
                    token={token}
                    callback={callBack}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const TrenchesModule = ({ token }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [trenchList, setTrenchList] = useState([]);
  const getTrenchListHandler = async () => {
    try {
      const response = await Axios({
        method: "GET",
        url: `${API_URL}admin/rewards_setting/trunch_list`,
        headers: {
          Authorization: token
        }
      });
      if (response.status === 200) {
        setTrenchList(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTrenchListHandler();
  }, []);

  return (
    <React.Fragment>
      <Paper style={{ marginTop: "40px" }}>
        <TrenchesToolbar setOpenAdd={data => setOpenAdd(data)} />

        <TableContainer>
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <TrenchesTableHead />
            <TableBody>
              {trenchList &&
                trenchList.map((data, i) => {
                  return (
                    <TrenchesLevelRow
                      data={data}
                      index={i}
                      token={token}
                      callBack={getTrenchListHandler}
                    />
                  );
                })}
              {trenchList && trenchList.length === 0 && (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    <NoRecordFound>{"No trench found."}</NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {openAdd && (
        <AddTrenchModal
          type="add"
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          token={token}
          callBack={getTrenchListHandler}
        />
      )}
    </React.Fragment>
  );
};
