import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow } from '@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CmtAvatar from '@coremat/CmtAvatar';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sentMailToUser, updateUserStatus } from 'redux/actions/Users';
import { IMAGE_URL } from 'constant/constant';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'redeemHistory', label: 'Redeem History', icon: <Visibility /> },
    { action: 'manageFunds', label: 'Manage Funds', icon: <Edit /> },
    // { action: 'email', label: 'Email', icon: <Mail /> },
  ];
  // User Management Actions
  // if (user.status === 'active') {
  //   actions.push({ action: 'suspend', label: 'Suspend', icon: <Block /> });
  // } else {
  //   actions.push({
  //     action: 'activate',
  //     label: 'Reactivate',
  //     icon: <CheckCircleOutline />,
  //   });
  // }

  // actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  // actions.push({ action: 'block', label: 'Block', icon: <Block /> });
  return actions;
};

const WalletListRow = ({ row, isSelected, onRowClick, onManageFunds, onUserDelete, onRedeemHistory, page, idx }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === 'redeemHistory') {
      onRedeemHistory(row);
    } else if (menu.action === 'manageFunds') {
      onManageFunds(row);
    }
    //  else if (menu.action === 'email') {
    //   dispatch(sentMailToUser());
    // } else if (menu.action === 'suspend') {
    //   dispatch(updateUserStatus({ id: row.id, status: 'suspended' }));
    // } else if (menu.action === 'activate') {
    //   dispatch(updateUserStatus({ id: row.id, status: 'active' }));
    // }
    else if (menu.action === 'block') {
      onUserDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  const userActions = getUserActions(row);

  return (
    <TableRow
      hover
      // onClick={event => onRowClick(event, row.id)}
      // onClick={event => onRedeemHistory(row)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}>
      <TableCell>{page * 10 + idx + 1}</TableCell>

      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={`${IMAGE_URL}${row?.profile_pic}`} alt={row?.wallet_user_detail?.name} />
          </Box>
          <div>
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {row?.wallet_user_detail?.name !== null ? row?.wallet_user_detail?.name : 'N/A'}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row?.balance}</TableCell>
      <TableCell>{row?.locked_balance}</TableCell>
      <TableCell>{row?.wallet_user_detail?.detail?.email}</TableCell>

      <TableCell> {row?.wallet_user_detail?.username}</TableCell>

      <TableCell>{row?.status}</TableCell>

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(WalletListRow);
