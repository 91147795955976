import React from "react";
import { PostAdd } from "@material-ui/icons";
import ContactsIcon from "@material-ui/icons/Contacts";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import dashboard1x from "../../../../images/tarality/dashboard1x.png";
import Walletmgmt11x from "../../../../images/tarality/Walletmgmt11x.png";
import rewardMgmt1x from "../../../../images/tarality/rewardMgmt1x.png";
import userMgmt1x from "../../../../images/tarality/userMgmt1x.png";
import reportMgmt1x from "../../../../images/tarality/reportMgmt1x.png";
import content1x from "../../../../images/tarality/content1x.png";
import changePassword1x from "../../../../images/tarality/changePassword1x.png";
import PlanManagement from "../../../../images/tarality/PlanManagement.png";
import App1x from "../../../../images/tarality/App1x.png";
import accountSetting1x from "../../../../images/tarality/accountSetting1x.png";
import IntlMessages from "../../../utils/IntlMessages";
import PostAddIcon from "@material-ui/icons/PostAdd";

export const sidebarNavs = [
  {
    title: "Dashboard-management",
    name: <IntlMessages id={"sidebar.dashboard"} />,
    type: "item",
    icon: <img src={`${dashboard1x}`} />,
    link: "/dashboard/listing"
  },
  {
    title: "LeaderBoard",
    name: <IntlMessages id={"sidebar.leaderboard"} />,
    type: "item",
    icon: <img src={`${dashboard1x}`} />,
    link: "/leader-board"
  },
  {
    title: "user-management",
    name: <IntlMessages id={"sidebar.userManagement"} />,
    type: "collapse",
    icon: <img src={`${userMgmt1x}`} />,
    children: [
      {
        name: <IntlMessages id={"sidebar.users"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/users"
      },
      {
        name: <IntlMessages id={"sidebar.interest"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/interest"
      },
      {
        name: <IntlMessages id={"sidebar.genre"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/genre"
      },
      {
        name: <IntlMessages id={"sidebar.verification"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/verification"
      },
      {
        name: <IntlMessages id={"sidebar.updateVerification"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/update-verification"
      }
    ]
  },
  {
    title: "plan-management",
    name: <IntlMessages id={"sidebar.planManagement"} />,
    type: "collapse",
    icon: (
      <img
        src={`${PlanManagement}`}
        style={{ height: "25px", width: "25px" }}
      />
    ),

    children: [
      {
        name: <IntlMessages id={"sidebar.BostSubscription"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/boost-plan"
      },

      {
        name: <IntlMessages id={"sidebar.boostPlanHistory"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/boost-plan-history"
      }
    ]
  },
  {
    title: "influencer-management",
    name: <IntlMessages id={"sidebar.influencerManagement"} />,
    type: "collapse",
    icon: <ContactsIcon />,
    children: [
      {
        name: <IntlMessages id={"sidebar.influencerList"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/influencer-list"
      },
      {
        name: <IntlMessages id={"sidebar.escrow"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/escrow-list"
      }
    ]
  },

  {
    title: "reward-management",
    name: <IntlMessages id={"sidebar.rewardManagement"} />,
    type: "collapse",
    icon: <img src={`${rewardMgmt1x}`} />,
    children: [
      {
        name: <IntlMessages id={"sidebar.rewards"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/rewardManagement"
      },
      {
        name: <IntlMessages id={"sidebar.airdrop"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/airdrop"
      }
    ]
  },
  {
    title: "flag-report-management",
    name: <IntlMessages id={"sidebar.flag"} />,
    type: "collapse",
    icon: <PostAdd />,
    link: "/reportsManagement",
    icon: <img src={`${reportMgmt1x}`} />,

    children: [
      {
        name: <IntlMessages id={"sidebar.reportsManagement"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/reportsManagement"
      },
      {
        name: <IntlMessages id={"sidebar.flagTypes"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/flagReportsType"
      }
    ]
  },
  {
    title: "content-management",
    name: <IntlMessages id={"sidebar.contentManagement"} />,
    type: "collapse",
    icon: <img src={`${content1x}`} />,
    children: [
      {
        name: <IntlMessages id={"sidebar.contentDetails"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/contentDetails"
      },
      {
        name: <IntlMessages id={"sidebar.hashTags"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/hashTags"
      },
      {
        name: <IntlMessages id={"sidebar.updateContent"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/updateContent"
      },

      {
        name: <IntlMessages id={"sidebar.interestDashboard"} />,
        type: "item",
        icon: <img src={`${dashboard1x}`} />,
        link: "/interest-dashboard"
      }
    ]
  },
  {
    title: "wallet-management",
    name: <IntlMessages id={"sidebar.walletManagement"} />,
    type: "collapse",
    icon: <img src={`${Walletmgmt11x}`} />,

    children: [
      {
        name: <IntlMessages id={"sidebar.walletDetails"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/walletDetails"
      },
      {
        name: <IntlMessages id={"sidebar.lockFundsHistory"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/lockFundsHistory"
      }
    ]
  },
  {
    title: "account-settings",
    name: <IntlMessages id={"sidebar.accountSettings"} />,
    type: "collapse",
    icon: <img src={`${accountSetting1x}`} />,

    children: [
      {
        name: <IntlMessages id={"sidebar.changePassword"} />,
        type: "item",
        // icon: <PostAdd />,
        icon: <img src={`${changePassword1x}`} />,

        link: "/updatePassword"
      }
    ]
  },
  {
    title: "app-settings",
    name: <IntlMessages id={"sidebar.appsSettings"} />,
    type: "item",
    icon: <img src={`${App1x}`} />,
    link: "/appsSettings"
  },
  {
    name: <IntlMessages id={"sidebar.subAdmin"} />,
    type: "item",
    icon: <SupervisorAccountIcon />,
    link: "/sub-admin"
  },
  {
    title: "Createpage",
    name: <IntlMessages id={"sidebar.pageManagement"} />,
    type: "item",
    icon: <PostAddIcon />,
    link: "/page-management"
  }
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];
