import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow } from '@jumbo/utils/dateHelper';
import { CloudDownload, Delete, MoreHoriz, Replay } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CmtAvatar from '@coremat/CmtAvatar';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sentMailToUser, updateUserStatus } from 'redux/actions/Users';
import { DownloadApps } from '@jumbo/components/Common';
import ConfirmDialog from 'commanComponent/confirm';
import RewardManagementServicesAuth from '../../../../services/auth/Rewards/index';
import { API_URL } from 'constant/constant';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'downloadReport', label: 'Download Report', icon: <CloudDownload /> },
    // { action: 'Delete', label: 'Delete', icon: <Delete /> },
    { action: 'Process', label: 'Process', icon: <Replay /> },
  ];
  return actions;
};

const RewardHistoryRow = ({ idx, row, isSelected, onRowClick, onUserEdit, onRecordDelete, page, perPage, order }) => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(null);
  const [id, setId] = useState(null);
  const classes = useStyles();
  const token = useSelector(state => state.admin.authUser.access_token);

  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === 'downloadReport') {
      window.location.href = `${API_URL}/admin/reward/airdrop/download_report/${row.id}`;
    }
    //  else if (menu.action === 'edit') {
    //   onUserEdit(row);
    // }
    //  else if (menu.action === 'email') {
    //   dispatch(sentMailToUser());
    // } else if (menu.action === 'suspend') {
    //   dispatch(updateUserStatus({ id: row.id, status: 'suspended' }));
    // } else if (menu.action === 'activate') {
    //   dispatch(updateUserStatus({ id: row.id, status: 'active' }));
    // }
    else if (menu.action === 'Delete') {
      onRecordDelete(row);
    } else if (menu.action === 'Process') {
      setAction(menu.action);
      setOpen(true);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  const userActions = getUserActions(row);

  const gotoProcess = () => {
    setOpen(false);
    dispatch(
      RewardManagementServicesAuth.airdropProcess({ token: token, id: row.id, page: page, perPage: perPage, order: order }),
    );
  };
  return (
    <>
      {open && <ConfirmDialog open={open} setOpen={setOpen} action={action} gotoProcess={gotoProcess} />}
      <TableRow
        hover
        // onClick={event => onRowClick(event, row.id)} // Long Press
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}>
        {/* <TableCell padding="checkbox">
        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell> */}
        <TableCell>{idx + 1}</TableCell>
        <TableCell component="th" id={labelId} scope="row">
          <Box display="flex" alignItems="center">
            {/* <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_pic} alt={row.name} />
          </Box> */}

            <div>
              <Typography className={classes.titleRoot} component="div" variant="h4">
                {row.comment}
              </Typography>
            </div>
          </Box>
        </TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{timeFromNow(row.created_at)}</TableCell>
        <TableCell>
          {/* {row.status === 'suspended' ? `Suspended by ${row.suspendedBy} (${timeFromNow(row.suspendedAt)})` : row.status} */}
          {row.status}
        </TableCell>
        <TableCell>{timeFromNow(row.updated_at)}</TableCell>
        {/* <TableCell align="right">{row.emailUsage} GB</TableCell> */}
        <TableCell align="center" onClick={event => event.stopPropagation()}>
          <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(RewardHistoryRow);
