import { Grid, Typography } from "@material-ui/core";
import React from "react";

const InfluencerDetails = ({ data }) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={4} item>
        <Typography variant="h6">Name :</Typography>
      </Grid>
      <Grid xs={8} item>
        <Typography variant="h6">{data?.name ? data?.name : "N/A"}</Typography>
      </Grid>
      <Grid xs={4} item>
        <Typography variant="h6">Email :</Typography>
      </Grid>
      <Grid xs={8} item>
        <Typography variant="h6">
          {data?.email ? data?.email : "N/A"}
        </Typography>
      </Grid>
      <Grid xs={4} item>
        <Typography variant="h6">Phone Number :</Typography>
      </Grid>
      <Grid xs={8} item>
        <Typography variant="h6">
          {data?.mobile_number ? `+${data?.mobile_number}` : "N/A"}
        </Typography>
      </Grid>
      <Grid xs={4} item>
        <Typography variant="h6">Bio :</Typography>
      </Grid>
      <Grid xs={8} item>
        <Typography variant="h6" style={{ wordBreak: "break-word" }}>
          {data?.biography ? data?.biography : "N/A"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default InfluencerDetails;
