import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { timeFromNow } from "@jumbo/utils/dateHelper";
import {
  Block,
  MoreHoriz,
  Visibility,
  VerifiedUser,
  Edit,
  Delete
} from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { IMAGE_URL } from "constant/constant";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const getUserActions = (is_block, is_verify) => {
  const actions = [
    // { action: "view", label: "View", icon: <Visibility /> },
    { action: "edit", label: "Edit", icon: <Edit /> },
    { action: "delete", label: "Delete", icon: <Delete /> },
    { action: "assign", label: "Assign", icon: <AssignmentTurnedInIcon /> }
  ];
  actions.push({
    action: "block",
    label: is_block == "1" ? "Inactive" : "Active",
    icon: <Block />
  });
  return actions;
};

const UserListRow = ({
  row,
  onUserBlock,
  onUserView,
  onVerify,
  assginUserPage,
  page,
  idx
}) => {
  const classes = useStyles();
  const history = useHistory();
  const onUserMenuClick = menu => {
    if (menu.action === "view") {
      onUserView(row);
    } else if (menu.action === "block") {
      onUserBlock(row);
    } else if (menu.action === "delete") {
      onVerify(row);
    } else if (menu.action === "edit") {
      history.push({
        pathname: "/create-page",
        search: row.id.toString(),
        hash: "edit",
        state: row
      });
    } else if (menu.action === "assign") {
      assginUserPage(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row?.user_id}`;
  const userActions = getUserActions(row?.status, row?.verified);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row?.user_id}>
      <TableCell>{page * 10 + idx + 1}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar
              size={40}
              src={`${IMAGE_URL}${row?.profile_pic}`}
              alt={row?.name}
            />
          </Box>
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row?.name === null
                ? "N/A"
                : row?.name.length >= 30
                ? row?.name.slice(0, 30) + "..."
                : row?.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell> {row?.email} </TableCell>
      <TableCell> {row && row.genre_data && row.genre_data.name} </TableCell>
      <TableCell>
        {row?.created_at && moment(row?.created_at).format("lll")}{" "}
      </TableCell>
      <TableCell>{row?.status === "1" ? "Active" : "Inactive"}</TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
