import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MoreHoriz, Close, Done, Edit, Block } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CmtVideo from "@coremat/CmtVideo";
import { IMAGE_URL } from "constant/constant";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const UserListRow = ({ row, isSelected, page, idx, changeStatus }) => {
  // console.log("row------", row);
  const classes = useStyles();

  // *********** option for Page ***********************
  const getUserActions = () => {
    const actions = [
      // { action: "View", label: "Show Log Reports", icon: <VpnKeyIcon /> },
    ];
    if (row.role != "Administrator") {
      actions.push({
        action: "Edit",
        label: "Edit",
        icon: <Edit />,
      });
      if (row.status === "0") {
        actions.push({
          action: "actived",
          label: "Actived",
          icon: <Done />,
        });
      }
      if (row.status === "1") {
        actions.push({ action: "block", label: "Block", icon: <Block /> });
      }
    }
    return actions;
  };

  const onUserMenuClick = (menu, hdsg, row) => {
    // console.log("row ------------- id", menu, hdsg, row);
    changeStatus(menu.action, menu.label, hdsg.id, hdsg);
  };

  const labelId = `enhanced-table-checkbox-${row.user_id}`;
  const isItemSelected = isSelected(row.user_id);
  const userActions = getUserActions(row.status, row.verified);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.user_id}
      selected={isItemSelected}
    >
      <TableCell>{Number(page) * 10 + idx + 1}</TableCell>
      <TableCell>{row.username}</TableCell>
      <TableCell>{row.email}</TableCell>
      {/* <TableCell>{row.phone}</TableCell> */}
      <TableCell>
        {row.status === "0"
          ? "BLOCKED"
          : row.status === "1"
          ? "ACTIVATE"
          : row.status === "2"
          ? "Rejected"
          : "N/A"}
      </TableCell>
      <TableCell>{moment(row.created_at).format("lll")}</TableCell>
      <TableCell>
        {row.login_time !== "--" ? moment(row.login_time).format("lll") : "--"}
      </TableCell>

      <TableCell align="center" onClick={(event) => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
          row={row}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
