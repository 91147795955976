import React, { useState } from "react";
import * as moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AppHeader from "routes/ContentManagement/Filter/Options/AppHeader";
import clsx from "clsx";
import Sidebar from "routes/ContentManagement/Filter/Options/Sidebar";
import MailsList from "routes/ContentManagement/Filter/Options/MailsList";
import { DialogActions } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative",
    maxHeight: "54%"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  },
  inBuildAppHeaderSidebar: {
    position: "relative"
  },
  inBuildAppCard: {
    display: "flex",
    flexWrap: "wrap"
  },
  applyButton: {
    "& > *": {
      margin: theme.spacing(2)
    }
  }
}));

const ContentFilter = ({
  open,
  onSubmitClick,
  isEdit,
  handleCloseUserDialog,
  filterContent1,
  clearFilters
}) => {
  const classes = useStyles();

  const [viewMode, setViewMode] = useState("date_range");
  const [payloadData, setPayloadData] = useState({ viewMode: viewMode });
  const contentTypesList = useSelector(
    state => state?.contentManagement?.ContentTypeList
  );
  const dispatchData = data => {
    setPayloadData({ filter_type: data.type, filter_data: data.filterData });
  };

  const onClickSendMail = to => {
    setViewMode(to);
  };
  const filterContent = async () => {
    if (viewMode == "deleted" && payloadData?.filter_type == "deleted") {
      if (payloadData.hasOwnProperty("filter_data")) {
        if (payloadData.filter_data.hasOwnProperty("deleted")) {
          let data = await {
            filter_data: {
              deleted: "yes"
            },
            filter_type: "deleted"
          };
          onSubmitClick(payloadData);
        }
      }
    } else if (
      viewMode == "deleted" &&
      !payloadData.hasOwnProperty("filter_data")
    ) {
      let data = await {
        filter_data: {
          deleted: "no"
        },
        filter_type: "deleted"
      };
      onSubmitClick(data);
    }
    if (
      viewMode == "date_range" &&
      payloadData?.filter_type == "date_range" &&
      payloadData.hasOwnProperty("filter_data")
    ) {
      if (
        payloadData?.filter_data.hasOwnProperty("from_date") &&
        payloadData?.filter_data.hasOwnProperty("to_date")
      ) {
        onSubmitClick(payloadData);
      } else if (
        payloadData?.filter_data.hasOwnProperty("from_date") &&
        !payloadData?.filter_data.hasOwnProperty("to_date")
      ) {
        let data = await {
          filter_data: {
            from_date: payloadData?.filter_data.from_date, //'2022-08-01',
            to_date: moment().format("YYYY-MM-DD") //'2022-08-15',
          },
          filter_type: "date_range"
        };
        onSubmitClick(data);
      } else if (
        !payloadData?.filter_data.hasOwnProperty("from_date") &&
        payloadData?.filter_data.hasOwnProperty("to_date")
      ) {
        let data = await {
          filter_data: {
            from_date: moment().format("YYYY-MM-DD"), //'2022-08-01',
            to_date: payloadData?.filter_data.to_date //'2022-08-15',
          },
          filter_type: "date_range"
        };
        onSubmitClick(data);
      }
    } else if (
      viewMode == "date_range" &&
      !payloadData.hasOwnProperty("filter_data")
    ) {
      let data = await {
        filter_data: {
          from_date: moment().format("YYYY-MM-DD"), //'2022-08-01',
          to_date: moment().format("YYYY-MM-DD") //'2022-08-15',
        },
        filter_type: "date_range"
      };
      onSubmitClick(data);
    }
  };
  return (
    <Dialog
      open={open}
      onSubmitClick={onSubmitClick}
      isEdit={isEdit}
      className={`${classes.dialogRoot} dialogCustom closeIconRight`}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        <IconButton
          aria-label="close"
          onClick={handleCloseUserDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000"
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box className={classes.inBuildAppCard}>
          <AppHeader
            viewMode={viewMode}
            handleViewModeChange={console.log("handleViewModeChange")}
          />

          <Box className={clsx(classes.inBuildAppContainer, "")}>
            <Sidebar
              onClickSendMail={onClickSendMail}
              setViewMode={setViewMode}
              onOpenComposeDialog={console.log("onOpenComposeDialog")}
            />
          </Box>

          <Box
            className={classes.inBuildAppHeaderSidebar}
            style={{ flex: 1, height: "200px" }}
          >
            <MailsList
              onClickSendMail={console.log("onClickSendMail")}
              onClickForwardMail={console.log("onClickForwardMail")}
              viewMode={viewMode}
              contentTypesList={contentTypesList}
              dispatchData={dispatchData}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions className={classes.applyButton}>
        <Button
          onClick={() => {
            filterContent();
          }}
          color="primary"
          autoFocus
          variant="contained"
        >
          Apply Filter
        </Button>

        {Object.keys(filterContent1).length > 0 && (
          <Button
            onClick={() => {
              clearFilters();
            }}
            color="primary"
            autoFocus
            variant="contained"
          >
            Clear Filter
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ContentFilter;

ContentFilter.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
