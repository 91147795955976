import React, { useEffect, useState } from 'react';
// import { Grid, Paper, TableContainer } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import RewardHistoryHeader from './RewardHistoryHeader';
import { useHistory } from 'react-router-dom';
import { useDebounce } from '@jumbo/utils/commonHelper';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  TableBody,
  TablePagination,
  Typography,
} from '@material-ui/core';
import { usersModule } from '@fake-db';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import NoRecordFound from '../../UserModules/Users/NoRecordFound'; //'./NoRecordFound';
import RewardHistoryRow from './RewardHistoryRow';
// import RewardDetails from '../RewardDetails';
// import AddRewards from '../AddRewards';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import AddRewardModal from '../Rewards/AddRewardModal';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import DownloadSampleFile from './DownloadSampleFile';
import UploadRewardList from './UploadRewardList';
import { AuhMethods } from 'services/auth';

const useStyles = makeStyles(theme => ({
  orderLg2: {
    [theme.breakpoints.up('lg')]: {
      order: 2,
    },
  },
  orderLg1: {
    [theme.breakpoints.up('lg')]: {
      order: 1,
    },
  },
}));

const handleRequestSort = (event, property) => {
  // const isAsc = orderBy === property && order === 'asc';
  // setOrderBy(property);
  // setOrder(isAsc ? 'desc' : 'asc');
};

const handleSelectAllClick = event => {
  // if (event.target.checked) {
  //   const newSelected = users.map(n => n.id);
  //   setSelected(newSelected);
  //   return;
  // }
  // setSelected([]);
};

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Dashboard', link: '/dashboard' },
  { label: 'Listing', isActive: true },
];

// const handleAddRecord = () => {

//   // setSelectedUser(user);
//   setOpenConfirmDialog(true);
// };
const options = [
  { title: 'Posts', slug: 'posts' },
  { title: 'Likes', slug: 'likes' },
  { title: 'Comments', slug: 'comments' },
];

const Airdrop = () => {
  const history = useHistory();

  const classes = useStyles();
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('desc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [type, setType] = useState('');
  const [typeError, setTypeError] = useState('');

  const dispatch = useDispatch();
  const users = usersModule;

  const token = useSelector(state => state.admin.authUser.access_token);
  const airDropList = useSelector(state => state?.rewardsManagement?.airdropList);
  const FetchData = useSelector(state => state?.common);

  useEffect(() => {
    dispatch(
      AuhMethods['rewardsManagement'].airdropListAPI({ token: token, page: page, perPage: rowsPerPage, order: 'desc' }),
    );
  }, [page, rowsPerPage]);

  useEffect(() => {}, [airDropList]);

  const handleAddRecord = () => {
    // setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleRowClick = (event, id) => {
    // const selectedIndex = selected.indexOf(id);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, id);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    // }
    // setSelected(newSelected);
  };

  const handleUserEdit = user => {
    // dispatch(setCurrentUser(user));
    // setOpenUserDialog(true);
  };

  const handleRecordDelete = user => {
    // setSelectedUser(user);
    // setOpenConfirmDialog(true);
  };

  const isSelected = id => {
    //   selected.indexOf(id) !== -1;
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    // dispatch(deleteUser(selectedUser.id));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <div>
      <div>
        <PageContainer>
          <GridContainer>
            <Grid item xs={12} sm={6} md={3}>
              <DownloadSampleFile />
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
              <UploadRewardList page={page} perPage={rowsPerPage} order={order} />
            </Grid>
          </GridContainer>
        </PageContainer>

        <Paper className={classes.paper} style={{ marginTop: '40px' }}>
          <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
              <RewardHistoryHeader
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={4}
              />
              <TableBody>
                {!!airDropList?.list?.length ? (
                  stableSort(airDropList?.list, getComparator(order, orderBy))
                    .slice(0, rowsPerPage)
                    .map((row, index) => (
                      <RewardHistoryRow
                        key={index}
                        idx={index}
                        row={row}
                        onRowClick={handleRowClick}
                        onUserEdit={handleUserEdit}
                        onRecordDelete={handleRecordDelete}
                        isSelected={isSelected}
                        page={page}
                        perPage={rowsPerPage}
                        order={order}
                      />
                    ))
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      {<NoRecordFound>{FetchData.loading ? 'Loading records...' : 'No records found.'}</NoRecordFound>}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 500]}
            component="div"
            count={airDropList?.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
        {/* </PageContainer> */}
      </div>
      <div>
        <Paper className={classes.paper}>
          {/* <RewardHistoryToolbar */}
          {/* // selected={selected}
        // setSelected={setSelected}
        // onUserAdd={setOpenUserDialog}
        // filterOptions={filterOptions}
        // setFilterOptions={setFilterOptions}
        // searchTerm={searchTerm}
        // setSearchTerm={setSearchTerm} */}
          {/* /> */}
        </Paper>

        {/* Add Record model */}
        {/* <ConfirmDialog
          open={openConfirmDialog}
          title={`Add Reward`}
          content={'Are you sure, you want to block this user?'}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        /> */}
        {openConfirmDialog && <AddRewardModal open={openConfirmDialog} onCloseDialog={handleCancelDelete} />}
      </div>
    </div>
  );
};

export default Airdrop;
