import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const headCells = [
  {
    id: "Sr No",
    numeric: false,
    disablePadding: false,
    label: "Sr No",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "online_offline_business",
    numeric: false,
    disablePadding: false,
    label: "Purchase Date",
  },
  {
    id: "online_offline_business",
    numeric: false,
    disablePadding: false,
    label: "Paid Amount",
  },
  {
    id: "online_offline_business",
    numeric: false,
    disablePadding: false,
    label: "Token Quantity",
  },
  {
    id: "interested_for_advertise_business_page",
    numeric: false,
    disablePadding: false,
    label: "Released Token",
  },
  {
    id: "interested_for_advertise_business_page",
    numeric: false,
    disablePadding: false,
    label: "Next Release Date",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Release Status",
  },
];

function UserTableHead({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const onSortOrderChange = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
      // className={classes.head}
      >
        {headCells.map((headCell) => (
          <TableCell
            className="like-padding"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

UserTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default React.memo(UserTableHead);
