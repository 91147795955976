import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles,
  Button,
  TableHead,
  TableBody
} from "@material-ui/core";
import { useSelector } from "react-redux";
import Axios from "axios";
import { API_URL } from "constant/constant";
import moment from "moment";
import NoRecordFound from "routes/UserModules/Users/NoRecordFound";
const useStyles = makeStyles(theme => ({
  mainParentBox: {
    padding: "20px"
  },
  parentSwitch: {
    "& .headingBox": {
      "& button": {},
      "& .active": {
        color: "#fff",
        background: "#3f51b5"
      },
      "& .inactive": {
        color: "#000"
      }
    }
  }
}));
export default function LeaderBoard() {
  const classes = useStyles();
  const token = useSelector(state => state.admin.authUser.access_token);
  const [usersList, setUsersList] = useState([]);
  const [tabView, setTabView] = useState("top_creator");
  const topUsersData = async endpoint => {
    try {
      const response = await Axios({
        method: "GET",
        url: `${API_URL}admin/user/${endpoint}`,
        headers: {
          Authorization: token
        }
      });
      if (response.status === 200) {
        setUsersList(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    topUsersData(tabView);
  }, [tabView]);

  return (
    <div>
      <Paper>
        <Box className={classes.mainParentBox}>
          <Box className={classes.parentSwitch}>
            <Box className="headingBox">
              <Button
                onClick={() => {
                  setTabView("top_creator");
                }}
                className={tabView === "top_creator" ? "active" : "inactive"}
                style={{ marginRight: "10px" }}
              >
                Top Creators
              </Button>
              <Button
                onClick={() => {
                  setTabView("top_engager");
                }}
                className={tabView === "top_engager" ? "active" : "inactive"}
              >
                Top Engagers
              </Button>
            </Box>
          </Box>
          <Box mt={2}>
            <TableContainer>
              <Table>
                <TableHead style={{ background: "#cecece5c" }}>
                  <TableCell>Sr No.</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Points Earned</TableCell>

                  <TableCell>Date of Joining</TableCell>
                </TableHead>
                <TableBody>
                  {usersList &&
                    usersList.map((data, i) => {
                      const { user_data } = data;
                      return (
                        <TableRow>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>
                            {user_data?.email ? user_data?.email : "N/A"}
                          </TableCell>
                          <TableCell>
                            {user_data?.name ? user_data?.name : "N/A"}
                          </TableCell>
                          <TableCell>
                            {data?.creator_points
                              ? Number(data?.creator_points)?.toFixed(2)
                              : data?.engagor_points
                              ? Number(data?.engagor_points)?.toFixed(2)
                              : "N/A"}
                          </TableCell>

                          <TableCell>
                            {data?.user_data?.created_at &&
                              moment(data?.user_data?.created_at).format("lll")}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {usersList && usersList.length == 0 && (
              <NoRecordFound>No records found!</NoRecordFound>
            )}
          </Box>
        </Box>
      </Paper>
    </div>
  );
}
