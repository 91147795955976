import React from "react";
import CmtList from "../../../../../@coremat/CmtList";
import PostItem from "./PostItem";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { postsList, user } from "@fake-db/apps/wall";
import { AuhMethods } from "services/auth";

const PostsList = ({ postData, type }) => {
  // const { feedPosts } = useSelector(({ wallApp }) => wallApp);
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux

  const feedPosts = postsList; // FROM FAKE DATABASE
  const userPostsData = useSelector(state => state.usersModule.userPostsData);

  const loadMoreData = () => {
    // dispatch(
    //   AuhMethods['usersModule'].userMorePostsAPI({
    //     token: token,
    //     userId: '1',
    //     page: '1',
    //     perPage: '10',
    //     type: 'latest',
    //   }),
    // ); // page, perPage, type
  };

  return (
    <>
      <CmtList
        data={userPostsData}
        renderRow={(item, index) => (
          <Box mb={6} key={index}>
            <PostItem item={item} postData={postData} type={type} />
          </Box>
        )}
        onEndReached={loadMoreData}
      />
      {userPostsData && userPostsData.length === 0 && <h4>No data found!</h4>}
    </>
  );
};

export default PostsList;
