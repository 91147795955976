import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow, toTitleCase } from '@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CmtAvatar from '@coremat/CmtAvatar';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sentMailToUser, updateUserStatus } from 'redux/actions/Users';
import NoRecordFound from 'routes/UserModules/Chat/Sidebar/NoRecordFound';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getUserActions = user => {
  const actions = [
    { action: 'redeemHistory', label: 'Redeem History', icon: <Visibility /> },
    // { action: 'edit', label: 'Edit', icon: <Edit /> },
    // { action: 'email', label: 'Email', icon: <Mail /> },
  ];
  // User Management Actions
  // if (user.status === 'active') {
  //   actions.push({ action: 'suspend', label: 'Suspend', icon: <Block /> });
  // } else {
  //   actions.push({
  //     action: 'activate',
  //     label: 'Reactivate',
  //     icon: <CheckCircleOutline />,
  //   });
  // }

  // actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  // actions.push({ action: 'block', label: 'Block', icon: <Block /> });
  return actions;
};

const RedeemListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onRedeemHistory }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [usersFetched, setUsersFetched] = useState(false);

  const FetchData = useSelector(state => state?.common);

  const onUserMenuClick = menu => {
    if (menu.action === 'redeemHistory') {
      onRedeemHistory(row);
    } else if (menu.action === 'block') {
      onUserDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  const userActions = getUserActions(row);

  return (
    <>
      {!!row.length ? (
        row.map((value, index) => (
          <TableRow
            hover
            // onClick={event => onRowClick(event, value.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={value.id}
            selected={isItemSelected}>
            <TableCell>{value?.tx_id === null ? 'N/A' : value?.tx_id === 'undefined' ? 'N/A' : value?.tx_id}</TableCell>
            <TableCell>{value?.redeem_user_detail?.detail?.email}</TableCell>
            <TableCell>{value?.redeem_user_detail?.username}</TableCell>
            <TableCell>{toTitleCase(value?.redeem_coin_detail?.coin_symbol)}</TableCell>
            <TableCell>{value?.amount}</TableCell>
            <TableCell> {timeFromNow(value?.created_at)} </TableCell>

            {/* <TableCell align="center" onClick={event => event.stopPropagation()}>
      <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} />
    </TableCell> */}
          </TableRow>
        ))
      ) : (
        <TableRow style={{ height: 53 * 6 }}>
          <TableCell colSpan={7} rowSpan={10}>
            {<NoRecordFound>{FetchData.loading ? 'Loading records...' : 'No Records Found'}</NoRecordFound>}{' '}
          </TableCell>
        </TableRow>
      )
      // (
      //   <TableCell colSpan={4}>
      //     <NoRecordFound>No record found...........</NoRecordFound>
      //   </TableCell>
      // )
      }
    </>
  );
};

export default React.memo(RedeemListRow);
