import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import { setAuthUser } from "../../../redux/actions/Admin";
import React from "react";
import axios from "../../config";

//Login api
const AdminAuth = {
  onLogin: ({ email, password }) => {
    return (dispatch) => {
      try {
        dispatch(fetchStart());
        axios
          .post("admin/login", {
            email: email,
            password: password,
          })
          .then(({ data }) => {
            dispatch(fetchSuccess("Logged in successfully"));
            if (data) {
              setTimeout(() => {
                dispatch(setAuthUser(data.data));
              }, 1000);
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(async (error) => {
            await dispatch(fetchError(error.response.data.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return (dispatch) => {
      dispatch(fetchStart());
      setTimeout(() => {
        dispatch(fetchSuccess());
        localStorage.removeItem("user");
        dispatch(setAuthUser(null));
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default AdminAuth;
