import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody
} from "@material-ui/core";
import UserTableToolbar from "./UserTableToolbar";
import UserTableHead from "./UserTableHead";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { API_URL } from "constant/constant";
import UserFilter from "./UserFilter";
import Axios from "axios";
import NoRecordFound from "../../SubAdmin/PageList/NoRecordFound";
const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const Escrow = () => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [from_date, handleFromChange] = useState();
  const [to_date, handleToChange] = useState();
  const [status, setstatus] = useState();
  const token = useSelector(state => state.admin.authUser.access_token);

  const handleSearchTextChange = e => {
    let data = e.target.value.replace(/^\s/, "");
    setSearchText(data);
  };

  const handleChangeStatus = (action, label, id, row) => {
    if (label == "View" || label == "Edit") {
      history.push({
        pathname: "/view-escrow",
        search: id?.toString()
      });
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const [requestList, setRequestList] = useState([]);
  const [pageListMetaData, setPageListMetaData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getEscrowRequestsHandler = async () => {
    try {
      setIsLoading(true);
      const response = await Axios({
        method: "GET",
        url: `${API_URL}admin/influencer_apply/request_list`,
        headers: {
          Authorization: token
        },
        params: {
          page: page,
          perPage: rowsPerPage,
          keyword: searchText,
          from_date: from_date,
          to_date: to_date,
          status: status
        }
      });
      if (response.status == 200) {
        setRequestList(response.data.data);
        setPageListMetaData(response.data.meta);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEscrowRequestsHandler();
  }, [page, rowsPerPage, searchText, from_date, to_date, status]);

  return (
    <div>
      <Paper className={classes.paper}>
        <UserTableToolbar count={pageListMetaData?.total} />

        <UserFilter
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          from_date={from_date}
          handleFromChange={handleFromChange}
          to_date={to_date}
          handleToChange={handleToChange}
          status={status}
          setstatus={setstatus}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <UserTableHead />
            <TableBody>
              {requestList.length > 0 ? (
                <>
                  {requestList.map((row, index) => (
                    <UserListRow
                      key={index}
                      page={page}
                      idx={index}
                      row={row}
                      changeStatus={handleChangeStatus}
                    />
                  ))}
                </>
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    <NoRecordFound>
                      {isLoading ? "Loading records..." : "No records found."}
                    </NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={pageListMetaData?.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
    </div>
  );
};

export default Escrow;

Escrow.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
