// Needs to Remove this class
import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUsers, setCurrentUser } from "redux/actions/Users";
import AddEditUser from "./AddEditUser";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { useDebounce } from "@jumbo/utils/commonHelper";
import useStyles from "./index.style";
import UserDetailView from "./UserDetailView";
import NoRecordFound from "./NoRecordFound";
// import { users, recentActivities } from '../../../@fake-db';
import { getDateText } from "../../../@jumbo/utils/dateHelper";
import { push } from "connected-react-router";
import { useHistory } from "react-router-dom";
import FlaggedReportTableToolbar from "./FlaggedReportTableToolbar";
import FlaggedReportTableHead from "./FlaggedReportTableHead";
import FlaggedReportListRow from "./FlaggedReportListRow";
import { AuhMethods } from "services/auth";
import ReportsManagementServicesAuth from "services/auth/ReportsManagement";
import { MoreVertIcon } from "@material-ui/icons/MoreVert";
import FlagReportsDashboard from "./FlagReportsDashboard";
import FlagReportFilter from "./FlagReportFilter";
import moment from "moment";

// const FlagReposts = () => {
const FlagReposts = ({
  method = "reportsManagement",
  variant = "default",
  wrapperVariant = "default",
}) => {
  const history = useHistory();

  const classes = useStyles();

  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(
    window.localStorage.getItem("pageReport")
      ? Number(window.localStorage.getItem("pageReport"))
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [flageReportId, setFlageReportId] = useState(null);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [searchText, setSearchText] = React.useState("");
  const dispatch = useDispatch();
  const [to_date, handleToChange] = useState();
  const [from_date, handleFromChange] = useState();
  const [status, setstatus] = useState();
  // const reportsList = useSelector(state => state?.reportsManagement?.reportsList);
  const token = useSelector((state) => state.admin.authUser.access_token); //Fetch data from Redux
  const reportsManagementData = useSelector(
    ({ reportsManagement }) => reportsManagement.reportsList
  );
  const reportListMeta = useSelector(
    ({ reportsManagement }) => reportsManagement.reportListMeta
  );
  const FetchData = useSelector((state) => state?.common);

  const getUsersListByDate = () => {
    return reportsManagementData.map((activity) => {
      activity.category = getDateText(activity.date);
      return activity;
    });
  };
  const users = getUsersListByDate();

  const handleSearchTextChange = (e) => {
    let data = e.target.value
      .trim()
      .split(/ +/)
      .join(" ");

    setSearchText(data);

    dispatch(
      AuhMethods["walletManagement"].reportsListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText,
      })
    );
  };
  useEffect(() => {
    window.localStorage.setItem("pageReport", page);
    dispatch(
      AuhMethods[method].reportsListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchTerm,
        status,
        order,
        // from_date,
        // to_date
        from_date: from_date ? moment(from_date).format("YYYY-MM-DD") : "",
        to_date: to_date ? moment(to_date).format("YYYY-MM-DD:23:59:59") : "",
      })
    ); //token, page, perPage, keyword
  }, [searchTerm, page, rowsPerPage, status, from_date, to_date]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id, row) => {
    // history.push({ pathname: '/PostDetail', state: { currentId: id } });
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = (user) => {
    //View User Profile button action
    // dispatch(setCurrentUser(user));
    // setOpenViewDialog(true);
    history.push({
      pathname: "/profile",
      state: { id: user?.post_data?.user_data?.user_id },
    });
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleUserEdit = (user) => {
    dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleUserDelete = (user) => {
    // setSelectedUser(user);
    // setOpenConfirmDialog(true);
    setFlageReportId(user?.post_id);
    setOpenConfirmDialog(true);
  };

  const handleUserPostView = (user) => {
    history.push({
      pathname: "/PostDetail",
      state: { currentId: user?.post_id },
    });
  };
  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    // dispatch(deleteUser(selectedUser.id));
    let params = {
      token: token,
      page: page,
      perPage: rowsPerPage,
      keyword: searchText,
      flageReportId: flageReportId,
      id: flageReportId,
      order: order,
      action: 2,
    };
    dispatch(AuhMethods["reportsManagement"].deleteFlagReportPost(params));

    // dispatch(ReportsManagementServicesAuth.deleteFlagReportPost(params));
    // flageReportId
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // const showFlagReportsTypeListView = () => {
  //   history.push('/flagReportsType');
  // };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <FlaggedReportTableToolbar
          selected={selected}
          setSelected={setSelected}
          // onFlagReportsType={showFlagReportsTypeListView}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <FlagReportsDashboard
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={searchTerm}
          count={reportListMeta}
          // dailyData={dailyData}
        />
        <div my={1}>
          <FlagReportFilter
            selected={selected}
            setSelected={setSelected}
            onUserAdd={setOpenUserDialog}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            searchText={searchText}
            handleSearchTextChange={handleSearchTextChange}
            count={reportListMeta?.total}
            actionHandleIcon={<MoreVertIcon />}
            actions={""}
            actionHandler={""}
            from_date={from_date}
            handleFromChange={handleFromChange}
            to_date={to_date}
            handleToChange={handleToChange}
            status={status}
            setstatus={setstatus}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <FlaggedReportTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {Array.isArray(users) && !!users.length ? (
                // stableSort(users, getComparator(order, orderBy))
                users.map((row, index) => (
                  <FlaggedReportListRow
                    key={index}
                    idx={index}
                    page={page}
                    row={row}
                    onRowClick={handleRowClick}
                    onUserEdit={handleUserEdit}
                    onUserDelete={handleUserDelete}
                    onUserView={handleUserView}
                    onUserPostView={handleUserPostView}
                    isSelected={isSelected}
                  />
                ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={reportListMeta.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {/* {openUserDialog && <AddEditUser open={openUserDialog} onCloseDialog={handleCloseUserDialog} />}
      {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />} */}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete ${selectedUser.name}`}
        content={"Are you sure, you want to delete this post?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default FlagReposts;
