import React from "react";
import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCard from "../../../../@coremat/CmtCard";
import CmtCardContent from "../../../../@coremat/CmtCard/CmtCardContent";
import Box from "@material-ui/core/Box";

const Biography = ({ userDetail }) => {
  const { bio } = userDetail;
  return (
    <CmtCard>
      <CmtCardHeader title="Biography" />
      <CmtCardContent>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
          {bio}
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Biography;
