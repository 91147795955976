import React, { useEffect, useContext } from "react";
import Communities from "./Communities";

import makeStyles from "@material-ui/core/styles/makeStyles";

import PerfectScrollbar from "react-perfect-scrollbar";
// import { getWallHeight } from "../../../../../@jumbo/constants/AppConstants";
import AppContext from "../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext";
import { Typography, Grid, Button, Box } from "@material-ui/core";
import TokenDetails from "./TokenDetails";
// import GridContainer from "@jumbo/components/GridContainer";
import moment from "moment";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  perfectScrollbarActives: {
    height: props => `calc(100vh - ${props.height}px)`,
    "& .Cmt-media-object": {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
      "&:hover": {
        backgroundColor: "transparent",
        transform: "none",
        boxShadow: "none",
        "& .MuiAvatar-root": {
          transform: "none",
          boxShadow: "none"
        }
      }
    }
  },
  titleRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    padding: 24,
    // marginBottom: 24,
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      zIndex: 1,
      width: 36,
      height: 4,
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const Activities = ({ influencerDetails }) => {
  const { showFooter } = useContext(AppContext);
  const classes = useStyles();
  const history = useHistory();
  //   height: getWallHeight(200, showFooter),
  // });

  // useEffect(() => {}, [influencerDetails]);

  return (
    <div>
      <Typography component="div" variant="h3" className={classes.titleRoot}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {" "}
          <span>Influencer Detail</span>
        </div>
      </Typography>
      {/* <Button component="div" variant="h3" className={classes.titleRoot}>
          Token Detail
        </Button> */}
      <PerfectScrollbar className={classes.perfectScrollbarActives}>
        {/* <Communities influencerDetails={influencerDetails} /> */}
        <Grid container>
          <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails
              header={"Influencer Name"}
              influencerDetails={influencerDetails?.user_data?.name}
              coppyUrl={influencerDetails?.user_data?.name}
              type="non-copy"
            />
          </Grid>
          <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails
              header={"User Name"}
              influencerDetails={influencerDetails?.user_data?.username}
              coppyUrl={influencerDetails?.user_data?.username}
              type="non-copy"
            />
          </Grid>
          <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails
              header={"Hiring Charge"}
              influencerDetails={influencerDetails?.hiring_charge}
              coppyUrl={influencerDetails?.hiring_charge}
              type="non-copy"
            />
          </Grid>
          <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails
              header={"Verification"}
              influencerDetails={
                influencerDetails?.is_verify == "0"
                  ? "Pending"
                  : influencerDetails?.is_verify == "1"
                  ? "Completed"
                  : "Rejected"
              }
              coppyUrl={influencerDetails?.user_data?.name}
              type="non-copy"
            />
          </Grid>
          <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
            <TokenDetails
              header={"Amount Paid"}
              influencerDetails={
                !influencerDetails?.is_paid ? "Pending" : "Completed"
              }
              coppyUrl={influencerDetails?.user_data?.name}
              type="non-copy"
            />
          </Grid>
        </Grid>
        <Typography component="div" variant="h3" className={classes.titleRoot}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            <span>Social Media URL</span>
          </div>
        </Typography>
        <Grid container>
          {influencerDetails.facebook_profile_url && (
            <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
              <TokenDetails
                header={"Facebook Profile  "}
                influencerDetails={
                  influencerDetails.facebook_profile_url &&
                  `${influencerDetails.facebook_profile_url.slice(0, 15)}${
                    influencerDetails.facebook_profile_url.length > 15
                      ? "..."
                      : ""
                  }`
                }
                coppyUrl={influencerDetails.facebook_profile_url}
                type="copy"
              />
            </Grid>
          )}
          {influencerDetails.instagram_profile_url && (
            <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
              <TokenDetails
                header={"Instagram Profile  "}
                influencerDetails={
                  influencerDetails.instagram_profile_url &&
                  `${influencerDetails.instagram_profile_url.slice(0, 15)}${
                    influencerDetails.instagram_profile_url.length > 15
                      ? "..."
                      : ""
                  }`
                }
                coppyUrl={influencerDetails.instagram_profile_url}
                type="copy"
              />
            </Grid>
          )}
          {influencerDetails.linkedin_profile_url && (
            <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
              <TokenDetails
                header={"Linkedin Profile  "}
                influencerDetails={
                  influencerDetails.linkedin_profile_url &&
                  `${influencerDetails.linkedin_profile_url.slice(0, 15)}${
                    influencerDetails.linkedin_profile_url.length > 15
                      ? "..."
                      : ""
                  }`
                }
                coppyUrl={influencerDetails.linkedin_profile_url}
                type="copy"
              />
            </Grid>
          )}
          {influencerDetails.pinterest_profile_url && (
            <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
              <TokenDetails
                header={"Pinterest Profile  "}
                influencerDetails={
                  influencerDetails.pinterest_profile_url &&
                  `${influencerDetails.pinterest_profile_url.slice(0, 15)}${
                    influencerDetails.pinterest_profile_url.length > 15
                      ? "..."
                      : ""
                  }`
                }
                coppyUrl={influencerDetails.pinterest_profile_url}
                type="copy"
              />
            </Grid>
          )}
          {influencerDetails.reddit_profile_url && (
            <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
              <TokenDetails
                header={"Reddit Profile  "}
                influencerDetails={
                  influencerDetails.reddit_profile_url &&
                  `${influencerDetails.reddit_profile_url.slice(0, 15)}${
                    influencerDetails.reddit_profile_url.length > 15
                      ? "..."
                      : ""
                  }`
                }
                coppyUrl={influencerDetails.reddit_profile_url}
                type="copy"
              />
            </Grid>
          )}
          {influencerDetails.snapchat_profile_url && (
            <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
              <TokenDetails
                header={"Snapchat Profile  "}
                influencerDetails={
                  influencerDetails.snapchat_profile_url &&
                  `${influencerDetails.snapchat_profile_url.slice(0, 15)}${
                    influencerDetails.snapchat_profile_url.length > 15
                      ? "..."
                      : ""
                  }`
                }
                coppyUrl={influencerDetails.snapchat_profile_url}
                type="copy"
              />
            </Grid>
          )}
          {influencerDetails.telegram_profile_url && (
            <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
              <TokenDetails
                header={"Telegram Profile  "}
                influencerDetails={
                  influencerDetails.telegram_profile_url &&
                  `${influencerDetails.telegram_profile_url.slice(0, 15)}${
                    influencerDetails.telegram_profile_url.length > 15
                      ? "..."
                      : ""
                  }`
                }
                coppyUrl={influencerDetails.telegram_profile_url}
                type="copy"
              />
            </Grid>
          )}
          {influencerDetails.tumblr_profile_url && (
            <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
              <TokenDetails
                header={"Tumbler Profile  "}
                influencerDetails={
                  influencerDetails.tumblr_profile_url &&
                  `${influencerDetails.tumblr_profile_url.slice(0, 15)}${
                    influencerDetails.tumblr_profile_url.length > 15
                      ? "..."
                      : ""
                  }`
                }
                coppyUrl={influencerDetails.tumblr_profile_url}
                type="copy"
              />
            </Grid>
          )}
          {influencerDetails.twitter_profile_url && (
            <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
              <TokenDetails
                header={"Twitter Profile  "}
                influencerDetails={
                  influencerDetails.twitter_profile_url &&
                  `${influencerDetails.twitter_profile_url.slice(0, 15)}${
                    influencerDetails.twitter_profile_url.length > 15
                      ? "..."
                      : ""
                  }`
                }
                coppyUrl={influencerDetails.twitter_profile_url}
                type="copy"
              />
            </Grid>
          )}
          {influencerDetails.youtube_profile_url && (
            <Grid item xs={6} md={6} className={classes.wallRightSidebar}>
              <TokenDetails
                header={"Youtube Profile  "}
                influencerDetails={
                  influencerDetails.youtube_profile_url &&
                  `${influencerDetails.youtube_profile_url.slice(0, 15)}${
                    influencerDetails.youtube_profile_url.length > 15
                      ? "..."
                      : ""
                  }`
                }
                coppyUrl={influencerDetails.youtube_profile_url}
                type="copy"
              />
            </Grid>
          )}
        </Grid>
      </PerfectScrollbar>
    </div>
  );
};

export default Activities;
