import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import { Button, Chip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import { API_URL } from "constant/constant";
import axios from "axios";
import { fetchError } from "redux/actions";
import { DownLoadCSVFunction } from "customHook/debounse";

const filterOptionsList = [
  { label: "Active", value: "active" },
  { label: "Suspended", value: "suspended" }
];

const UserTableToolbar = ({ selected, filterOptions, count }) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token);
  const onDeleteCLick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const DownLoadeCSV = async count => {
    try {
      await axios({
        method: "GET",
        url: `${API_URL}admin/advertisement/list`,
        headers: {
          Authorization: token
        },
        params: {
          perPage: count,
          page: 1
        }
      }).then(({ data }) => {
        if (data.data) {
          let arrayOfObjects = data.data;

          if (!arrayOfObjects.length) {
            return;
          }
          DownLoadCSVFunction(arrayOfObjects, "advertisement_lists");
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const numSelected = selected.length;

  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h4"
            id="tableTitle"
            component="div"
          >
            Advertisement List{" "}
            {/* <Button color="primary" onClick={() => onUserAdd(true)}>
              Add New User
            </Button> */}
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={onDeleteCLick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <React.Fragment>
            <div className={classes.chipsRoot}>
              {filterOptionsList.map(
                (option, index) =>
                  filterOptions.includes(option.value) && (
                    <Chip key={index} label={option.label} />
                  )
              )}
            </div>

            <div>
              <Button
                variant="contained"
                color="secondary"
                style={{ width: "260px" }}
                onClick={() => DownLoadeCSV(count)}
              >
                Download CSV
              </Button>
            </div>
          </React.Fragment>
        )}
      </Toolbar>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete users`}
        content={"Are you sure, you want to  delete selected users?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchText: PropTypes.string,
  handleSearchTextChange: PropTypes.func,
  onUserAdd: PropTypes.func
};

export default React.memo(UserTableToolbar);
