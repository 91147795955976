import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import axios from "../../config";
import { getPageVerificationRequest } from "redux/actions/UsersModule";
import { reject } from "lodash";
import { AuhMethods } from "..";
import {
  setPageListData,
  getPageCategoriesList,
  pageFollowingData,
  pageFollowerData,
  setPageProfileData,
  pageStoryListData,
  setPagePostsData,
  setPageLikeCommentsData,
  setPageLikeCommentsMeta,
  appendPageMorePostsData,
  pageMorePostsMetaData
} from "redux/actions/BusinessPageManagement";

//Users Module apis
const BusinessModuleServicesAuth = {
  //Page List API
  pageListAPI: ({
    token,
    page,
    perPage,
    keyword,
    order,
    status,
    from_date,
    to_date
  }) => {
    const pageData = page != undefined ? "&page=" + (page + 1) : "";
    const keywordData = keyword != undefined ? "&keyword=" + keyword : "";
    const statusData = status != undefined ? "&status=" + status : "";

    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `/admin/pages/list?order=${order}&` +
              keywordData +
              "&perPage=" +
              perPage +
              pageData +
              statusData +
              "&from_date=" +
              from_date +
              "&to_date=" +
              to_date,
            // {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(setPageListData(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // PAGE FOLLOWERS
  pageFollowerList: (token, order, perPage, page, userId) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/business_pages/followers/${userId}?order=${order}&` +
              "&perPage=" +
              perPage +
              `&page=${page}`,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(pageFollowerData(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // USER FOLLOWING
  pageFollowingList: (token, userId, perPage, page, order) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/business_pages/following/${userId}?order=${order}&` +
              "&perPage=" +
              perPage +
              `&page=${page}`,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(pageFollowingData(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // //Change page Status API
  changePageStatusAPI: (token, params, from) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put("/admin/business_pages/change_status", params, {
            withCredentials: true
          })
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data.message));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  // //page Profile API
  pageProfileAPI: ({ token, userId }) => {
    // const idData = userId != undefined ? userId : '30048';
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(`admin/pages/detail/${userId}`, {}, { withCredentials: true })
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(setPageProfileData(data.data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Business Page Posts API
  PagePostsAPI: ({ token, userId, page, perPage, type }) => {
    // const typeData = type != undefined ? '&type=' + type : '';

    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .post(
            `admin/business_pages/posts?sortBy=${type}&page=${page}&perPage=${perPage}`,
            { business_page_id: userId }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(setPagePostsData(data));
            } else {
              dispatch(fetchError(data.message));
            }
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(
                AuhMethods["businessModule"].pageListAPI({
                  token: token,
                  page: page,
                  perPage: perPage,
                  keyword: "",
                  order: "desc"
                })
              );
              {
                page == 1
                  ? dispatch(setPagePostsData(data.data))
                  : dispatch(appendPageMorePostsData(data.data));
              }
              dispatch(pageMorePostsMetaData(data.meta));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //User Posts API
  pageMorePostsAPI: ({ token }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            "admin/business_pages/posts/1?perPage=10&page=1&type=created_at",
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(appendPageMorePostsData(data.data));
              dispatch(pageMorePostsMetaData(data.meta));
            } else {
              dispatch(fetchError(data.message));
            }
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  BusinessPageVerificationAPI: ({ token, params }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .delete(`admin/pages/delete/${params.id}`)
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data?.message));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // GET Stories API
  getPageStoriesAPI: ({ token, userId, commentId, page, perPage }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/business_pages/story_list?user_id=${userId?.userId}`, // + commentId + '?' + 'perPage=' + perPage + '&page=' + page,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(pageStoryListData(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //deleteUserReportPost
  HandleBusinessPagePost: (token, postId, page, perPage, uAction, userId) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put(
            `/admin/business_pages/post_delete`, // + commentId + '?' + 'perPage=' + perPage + '&page=' + page,
            { post_id: postId, action: uAction },
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data) {
              let msg =
                uAction == "0"
                  ? "inactive"
                  : uAction == "1"
                  ? "active"
                  : "delete";
              dispatch(fetchSuccess(`Post ${msg} successfully`));
              setTimeout(() => {
                dispatch(
                  BusinessModuleServicesAuth.PagePostsAPI({
                    token: token,
                    page: page,
                    perPage: perPage,
                    keyword: "",
                    order: "desc",
                    type: ""
                  })
                );
              }, 500);
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  getPageVerificationListAPI: ({
    token,
    page,
    perPage,
    keyword,
    order,
    status,
    from_date,
    to_date
  }) => {
    const pageData = page != undefined ? page + 1 : "";
    const keywordData = keyword != undefined ? "&keyword=" + keyword : "";
    const statusData = status != undefined ? "&status=" + status : "";

    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/business_pages/page_verification/request/list?${order}${keywordData}&page=${pageData}` +
              statusData +
              "&from_date=" +
              from_date +
              "&to_date=" +
              to_date
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(getPageVerificationRequest(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response.data.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // changePageRequestStatus
  changePageRequestStatus: ({
    token,
    id,
    status,
    page,
    perPage,
    keyword,
    order
  }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        let datas = {
          id: id,
          status: status
        };
        dispatch(fetchStart());
        axios
          .post(`admin/business_pages/page_verification/request`, datas)
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data?.message));
              // dispatch(getPageVerificationRequest(data));
              setTimeout(() => {
                dispatch(
                  AuhMethods["businessModule"].getPageVerificationListAPI({
                    token: token,
                    page: page,
                    perPage: perPage,
                    keyword: keyword,
                    order: order
                  })
                );
              }, 500);
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response.data.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  PageVerificationProcessAPI: (token, params) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/pages/active_inactive?id=${params.id}&status=${params.status}` // + commentId + '?' + 'perPage=' + perPage + '&page=' + page,
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data.message));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //PageListApi

  pageCategoriesListAPI: ({ token, page, perPage, keyword, order }) => {
    const pageData = page != undefined ? "&page=" + (page + 1) : "";
    const keywordData = keyword != undefined ? "&keyword=" + keyword : "";

    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/business_pages/categories?${order}${keywordData}&page=${pageData}`
          )
          .then(({ data }) => {
            if (data) {
              // dispatch(fetchSuccess(data.message));
              dispatch(fetchSuccess());
              dispatch(getPageCategoriesList(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response.data.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  addPageCategoriesAPI: (token, params) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .post("admin/business_pages/add_category", params)
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data.message));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },
  editPageCatagoriesAPI: (token, id, params, page, perPage, order, type) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put("admin/business_pages/edit_category", params)
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));

              setTimeout(() => {
                dispatch(
                  AuhMethods["businessModule"].pageCategoriesListAPI({
                    token,
                    page: page,
                    perPage: perPage,
                    order: order
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },
  // Posts Comments API
  PageLikeComentsListAPI: ({ token, commentId, page, perPage, itemType }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            "admin/business_pages/post_data/" +
              commentId +
              "?&type=" +
              itemType +
              "&perPage=" +
              perPage +
              "&page=" +
              page,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(setPageLikeCommentsData(data.data));
              dispatch(setPageLikeCommentsMeta(data.meta));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  deletePageCategoriesAPI: ({ token, id }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .delete(`/admin/business_pages/delete_category/${id}`)
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));
              setTimeout(() => {
                dispatch(
                  AuhMethods["businessModule"].pageCategoriesListAPI({
                    token: token,
                    page: "0",
                    perPage: "60"
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
            // resolve(data)
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  }
};
export default BusinessModuleServicesAuth;
