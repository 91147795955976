import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Dialog,
  DialogTitle,
  Typography
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
import UserTableHead from "./UserTableHead";
import UserTableToolbar from "./UserTableToolbar";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "redux/actions/Users";
import AddEditUser from "./AddEditUser";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import UserDetailView from "./UserDetailView";
import NoRecordFound from "./NoRecordFound";
import { useHistory } from "react-router-dom";
import { AuhMethods } from "services/auth";
import moment from "moment";
import axios from "axios";
import { API_URL } from "constant/constant";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UserDashboard from "./UserDashboard";
import UserFilter from "./UserFilter";
import { MoreVertIcon } from "@material-ui/icons/MoreVert";

const dailyData = [
  { name: "Total Influencer", amount: "123" },
  { name: "Total Pending Influencer", amount: "123" },
  { name: "Total Verified Influencer", amount: "123" },
  { name: "Total Rejected Influencer", amount: "23" }
];
const schema = yup.object().shape({
  payInr: yup.string().required("This field is required."),
  amountInr: yup.string().required("This field is required."),
  amountTaral: yup.string().required("This field is required.")
});
const PageList = ({
  method = "usersModule",
  variant = "default",
  wrapperVariant = "default"
}) => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      payInr: "",
      amountInr: "",
      amountTaral: ""
    },
    resolver: yupResolver(schema)
  });
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [verifyToggle, setVerifyToggle] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [influencerList, setInfluencerList] = React.useState([]);
  const [pageListMeta, setpageListMeta] = React.useState({});
  const [openRejectedDialog, setOpenRejectedDialog] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const FetchData = useSelector(state => state.common);
  const [from_date, handleFromChange] = useState();
  const [to_date, handleToChange] = useState();
  const [status, setstatus] = useState();

  const token = useSelector(state => state.admin.authUser.access_token);
  const dispatch = useDispatch();

  const AdvertisementListAPI = async ({
    token,
    page,
    perPage,
    keyword,
    order,
    status,
    from_date,
    to_date
  }) => {
    const pageData = page != undefined ? page + 1 : "";
    const keywordData = keyword != undefined ? keyword : "";
    const statusData = status != undefined ? "&is_verify=" + status : "";
    from_date =
      from_date != undefined
        ? "&from_date=" + moment(from_date).format("YYYY-MM-DD")
        : "";
    to_date =
      to_date != undefined
        ? "&to_date=" + moment(to_date).format("YYYY-MM-DD:23:59:59")
        : "";

    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "GET",
        url:
          `${API_URL}admin/influencer_apply/list?order=${order}&` +
          // pageData +
          statusData +
          from_date +
          to_date,
        headers: {
          Authorization: token
        },
        params: {
          keyword,
          perPage,
          page: pageData
        }
      })
        // return res;
        .then(({ data }) => {
          //console.log("data -----", data);
          if (data.data) {
            dispatch(fetchSuccess());
            setInfluencerList(data.data);
            setpageListMeta(data.meta);
            // dispatch(setUsersListData(data));
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  useEffect(() => {
    AdvertisementListAPI({
      token: token,
      page: page,
      perPage: rowsPerPage,
      keyword: searchText,
      order,
      status,
      from_date,
      to_date
    });
  }, [page, rowsPerPage, searchText, status, from_date, to_date]);
  const handleReject = () => {
    setOpenRejectedDialog(false);
  };

  const handleUserStatus = async () => {
    let params;
    if (selectedUser.action === "Approve") {
      params = {
        id: selectedUser.id,
        action: 1,
        total_inr: getValues("payInr"),
        inr: getValues("amountInr"),
        taral: getValues("amountTaral")
      };
    }
    if (selectedUser.action !== "Approve") {
      if (rejectMessage.trim() === "") {
        return;
      }
      params = {
        id: selectedUser.id,
        action: 2,
        message: rejectMessage
      };
    }

    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      // console.log("id row ------------- id", selectedUser.id, selectedUser);
      const res = await axios({
        method: "PUT",
        url: `${API_URL}admin/influencer_apply/changeStatus`,
        headers: {
          Authorization: token
        },
        data: params
      });
      AdvertisementListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText,
        order,
        status,
        from_date,
        to_date
      });

      handleCancelDelete();
      handleReject()
        // return res
        .then(({ data }) => {
          if (data.data) {
            dispatch(fetchSuccess(data.message));
            // setAdvertisementList(data.data);
            // dispatch(setUsersListData(data));
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      console.log(" error ", error);
    }

    setVerifyToggle(false);
  };
  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleSearchTextChange = e => {
    let data = e.target.value.replace(/^\s/, "");
    setSearchText(data);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentUser(null));
  };
  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelVerify = () => {
    setVerifyToggle(false);
  };
  const handleConfirmVerify = () => {
    let params = {
      business_page_id: selectedUser.id,
      action: selectedUser.verified === "0" ? "1" : "0"
    };
    dispatch(
      AuhMethods["businessModule"].BusinessPageVerificationAPI({
        token,
        params
      })
    );
    setTimeout(
      () =>
        dispatch(
          AuhMethods[method].pageListAPI({
            token: token,
            page: page,
            perPage: rowsPerPage,
            keyword: searchText
          })
        ),
      1000
    );
    setVerifyToggle(false);
  };

  const handleChangeStatus = (action, label, id, row) => {
    let data = {
      action: action,
      label: label,
      id: id,
      row: row,
      name: row?.user_data?.name
    };
    // console.log(data, "row ------------- id", action, id, label, id, row);
    setSelectedUser(data);
    if (action == "Approve") {
      setOpenConfirmDialog(true);
    }
    if (action == "rejected") {
      setOpenRejectedDialog(true);
    }
    if (action == "View") {
      // setOpenViewDialog(true);
      history.push({
        pathname: "/view-influencer-details",
        search: id.toString()
      });
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserTableToolbar count={pageListMeta.total} />
        <UserDashboard count={pageListMeta} />
        <UserFilter
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          from_date={from_date}
          handleFromChange={handleFromChange}
          to_date={to_date}
          handleToChange={handleToChange}
          status={status}
          setstatus={setstatus}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <UserTableHead />
            <TableBody>
              {influencerList.length > 0 ? (
                influencerList.map((row, index) => (
                  <UserListRow
                    key={index}
                    page={page}
                    idx={index}
                    row={row}
                    changeStatus={handleChangeStatus}
                  />
                ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={pageListMeta.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openUserDialog && (
        <AddEditUser
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}
      {openViewDialog && (
        <UserDetailView
          open={openViewDialog}
          onCloseDialog={handleCloseViewDialog}
        />
      )}
      <ConfirmDialog
        type="reject"
        open={openRejectedDialog}
        title={`Confirm to reject ${
          selectedUser?.name ? selectedUser.name : ""
        }`}
        content={`Are you sure, you want to reject this influencer?`}
        onClose={handleReject}
        onConfirm={handleUserStatus}
        rejectMessage={rejectMessage}
        setRejectMessage={setRejectMessage}
      />

      <ConfirmDialog
        open={verifyToggle}
        title={`234345 Confirm to ${
          selectedUser.verified == "1" ? "Unverify" : "Verify"
        } ${selectedUser?.name ? selectedUser?.name : ""}`}
        content={`Are you sure, you want to ${
          selectedUser.verified == "1" ? "unverify" : "verify"
        } this user?`}
        onClose={handleCancelVerify}
        onConfirm={handleConfirmVerify}
      />
      {openConfirmDialog && (
        <Dialog
          open={openConfirmDialog}
          onClose={handleCancelDelete}
          aria-labelledby="confirm-dialog-title"
          className={classes.dialogRoot}
        >
          <DialogTitle
            id="confirm-dialog-title"
            className={classes.dialogTitleRoot}
          >
            Fee Tenure Influencer
            {/* Are You  */}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              <form onSubmit={handleSubmit(handleUserStatus)}>
                <Box mb={2}>
                  <Typography
                    component="div"
                    variant="body1"
                    className={classes.titleRoot}
                  >
                    Pay Amount in INR
                  </Typography>
                  <Controller
                    name="payInr"
                    control={control}
                    render={({ value }) => {
                      return (
                        <TextField
                          name="payInr"
                          label={"Total in INR"}
                          fullWidth
                          type={"number"}
                          onChange={event => {
                            setValue("payInr", event.target.value);
                          }}
                          value={getValues("payInr")}
                          margin="normal"
                          variant="outlined"
                          className={classes.textFieldRoot}
                        />
                      );
                    }}
                  />
                  <p style={{ color: "red" }}>{errors.payInr?.message}</p>
                </Box>
                <Box mb={2} className="inputPasswordCustom">
                  <Typography
                    component="div"
                    variant="body1"
                    className={classes.titleRoot}
                  >
                    Pay Amount in INR + Taral
                  </Typography>
                </Box>

                <Box mb={2} className="inputPasswordCustom">
                  <Box className="inputPasswordCustom1">
                    <Controller
                      name="amountInr"
                      control={control}
                      render={() => (
                        <TextField
                          type={"number"}
                          label="Amount In Inr"
                          fullWidth
                          value={getValues("amountInr")}
                          onChange={event => {
                            setValue("amountInr", event.target.value);
                            setConfirmPassword(event.target.value);
                            setConfirmPasswordError("");
                          }}
                          helperText={confirmPasswordError}
                          margin="normal"
                          variant="outlined"
                          className={classes.textFieldRoot}
                        />
                      )}
                    />

                    <p style={{ color: "red" }}>{errors.amountInr?.message}</p>
                    <Controller
                      name="amountTaral"
                      control={control}
                      render={() => (
                        <TextField
                          type={"number"}
                          label="Amount In Taral"
                          fullWidth
                          value={getValues("amountTaral")}
                          onChange={event => {
                            setValue("amountTaral", event.target.value);
                          }}
                          helperText={confirmPasswordError}
                          margin="normal"
                          variant="outlined"
                          className={classes.textFieldRoot}
                        />
                      )}
                    />

                    <p style={{ color: "red" }}>
                      {errors.amountTaral?.message}
                    </p>
                  </Box>
                </Box>

                <DialogActions classes={{ root: classes.dialogActionsRoot }}>
                  {" "}
                  <Button
                    onClick={handleCancelDelete}
                    color="primary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    // onClick={handleUserStatus}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </DialogActions>
                {/* </Box> */}
              </form>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default PageList;
