import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "redux/actions/Users";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import NoRecordFound from "../../UserModules/Users/NoRecordFound";
import { getDateText } from "../../../@jumbo/utils/dateHelper";
import useStyles from "@coremat/CmtAvatar/index.style";
import LockFundsRow from "./LockFundsRow";
import LockFundsHeader from "./LockFundsHeader";
import LockFundsToolbar from "./LockFundsToolbar";
import { AuhMethods } from "services/auth";

const LockFundsHistory = () => {
  const classes = useStyles;
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser] = useState({ name: "" });
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchText, setSearchText] = useState();
  const FetchData = useSelector(state => state?.common);

  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token);

  let lockedFundList = useSelector(
    state => state?.walletManagement?.LockedFundsData
  );
  let lockedFundsMetaData = useSelector(
    state => state?.walletManagement?.LockedFundsMetaData
  );

  const getUsersListByDate = () => {
    return (
      Array.isArray(lockedFundList) &&
      lockedFundList.map(activity => {
        activity.category = getDateText(activity.date);
        return activity;
      })
    );
  };
  const users = getUsersListByDate();
  useEffect(() => {
    dispatch(
      AuhMethods["walletManagement"].lockedFundList({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText
      })
    );
  }, [rowsPerPage, page, searchText]);

  const handleSearchTextChange = e => {
    let data = e.target.value
      .trim()
      .split(/ +/)
      .join(" ");

    setSearchText(data);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteUser(selectedUser.id));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <LockFundsToolbar
          selected={selected}
          setSelected={setSelected}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          handleSearchTextChange={handleSearchTextChange}
          searchText={searchText}
        />
        <TableContainer className={`${classes.container} customTableHeight`}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <LockFundsHeader
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {!!users.length ? (
                stableSort(users, getComparator(order, orderBy))
                  .slice(0, rowsPerPage)
                  .map((row, index) => (
                    <LockFundsRow
                      key={index}
                      page={page}
                      idx={index}
                      row={row}
                      isSelected={isSelected}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={lockedFundsMetaData?.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete ${selectedUser.name}`}
        content={"Are you sure, you want to block this user?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default LockFundsHistory;
