import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getUsers, setCurrentUser } from 'redux/actions/Users';
// import AddEditUser from './AddEditUser';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { useDebounce } from '@jumbo/utils/commonHelper';
// import UserDetailView from './UserDetailView';
import NoRecordFound from '../../UserModules/Users/NoRecordFound';
import { usersModule, recentActivities } from '../../../@fake-db';
import { getDateText } from '../../../@jumbo/utils/dateHelper';
import { push } from 'connected-react-router';
import { useHistory, useLocation } from 'react-router-dom';
import UserListRow from 'routes/UserModules/Users/UserListRow';
import UserTableHead from 'routes/UserModules/Users/UserTableHead';
import UserTableToolbar from 'routes/UserModules/Users/UserTableToolbar';
import useStyles from '@coremat/CmtAvatar/index.style';
import RedeemTableToolbar from './RedeemTableToolbar';
import RedeemTableHeader from './RedeemTableHeader';
import RedeemListRow from './RedeemListRow';
import { AuhMethods } from 'services/auth';

const RedeemHistory = () => {
  const history = useHistory();

  const classes = useStyles;
  // const { users } = useSelector(({ usersReducer }) => usersReducer);
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const location = useLocation();

  const [usersFetched, setUsersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [userId] = React.useState(location?.state?.id);

  const token = useSelector(state => state.admin.authUser.access_token);
  const ReedemHistory = useSelector(state => state?.walletManagement?.ReedemHistory);
  const FetchData = useSelector(state => state?.common);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      AuhMethods['walletManagement'].getWalletReedemBalanceListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: '',
        params: userId,
      }),
    );
  }, [userId]);

  const getUsersListByDate = () => {
    return (
      Array.isArray(ReedemHistory) &&
      ReedemHistory.map(activity => {
        activity.category = getDateText(activity.date);
        return activity;
      })
    );
  };
  const users = getUsersListByDate();

  // useEffect(() => {
  //   dispatch(
  //     getUsers(filterOptions, debouncedSearchTerm, () => {
  //       setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
  //       setUsersFetched(true);
  //     }),
  //   );
  // }, [dispatch, filterOptions, debouncedSearchTerm]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    // history.push('/profile');
    // const selectedIndex = selected.indexOf(id);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, id);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    // }
    // setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserView = user => {
    //View User Profile button action
    // dispatch(setCurrentUser(user));
    // setOpenViewDialog(true);
    history.push('/profile');
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleUserEdit = user => {
    dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteUser(selectedUser.id));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <RedeemTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
            <RedeemTableHeader
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {!!users.length ? (
                // stableSort(users, getComparator(order, orderBy))
                Array.isArray(users) &&
                users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <RedeemListRow
                    key={index}
                    row={row}
                    // onRowClick={handleRowClick}
                    onUserEdit={handleUserEdit}
                    onUserDelete={handleUserDelete}
                    onRedeemHistory={handleUserView}
                    isSelected={isSelected}
                  />
                ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {<NoRecordFound>{FetchData.loading ? 'Loading records...' : 'No Records Found'}</NoRecordFound>}{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        /> */}
      </Paper>

      {/* {openUserDialog && <AddEditUser open={openUserDialog} onCloseDialog={handleCloseUserDialog} />}
      {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />} */}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete ${selectedUser.name}`}
        content={'Are you sure, you want to block this user?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default RedeemHistory;
