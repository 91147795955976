import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import React from "react";

const headCells = [
  {
    id: "Sr No",
    numeric: false,
    disablePadding: false,
    label: "Sr No"
  },
  {
    id: "Page Name",
    numeric: false,
    disablePadding: false,
    label: "Page Name"
  },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "genre",
    numeric: false,
    disablePadding: false,
    label: "Genre"
  },
  {
    id: "createdat",
    numeric: false,
    disablePadding: false,
    label: "Created At"
  },
  { id: "Status", numeric: false, disablePadding: false, label: "Status" }

  // {
  //   id: 'lastLoginAt',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Last Login',
  // },
];

function UserTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className="like-padding"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

UserTableHead.propTypes = {};

export default React.memo(UserTableHead);
