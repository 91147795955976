import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid } from "@material-ui/core";
import CmtSearch from "@coremat/CmtSearch";

const UserFilter = ({ searchText, handleSearchTextChange }) => {
  return (
    <React.Fragment>
      <Toolbar>
        <Grid container spacing={2} style={{ margin: "12px 0" }}>
          <Grid item lg={6} sm={6} md={3} xs={12}>
            <CmtSearch
              onlyIcon
              placeholder="Search by interest..."
              border={true}
              value={searchText}
              onChange={handleSearchTextChange}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </React.Fragment>
  );
};

export default UserFilter;
