import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
} from "@material-ui/core";
import UserTableToolbar from "./UserTableToolbar";
import UserTableHead from "./UserTableHead";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
// import UserTableToolbar from "./UserTableToolbar";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtAvatar from "@coremat/CmtAvatar";
import { useDropzone } from "react-dropzone";
import Button from "@material-ui/core/Button";
import CmtList from "@coremat/CmtList";
import IconButton from "@material-ui/core/IconButton";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { emailNotValid, requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { alpha, makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { isValidEmail } from "@jumbo/utils/commonHelper";
import { addNewUser, updateUser } from "redux/actions/Users";
import { inherits } from "util";
import { Typography } from "@material-ui/core";
import { AuhMethods } from "services/auth";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
// import RejectDialog from "../RejectDialog";
import { usersModule } from "@fake-db";
import { useHistory } from "react-router-dom";
// import ConfirmDialog from 'commanComponent/confirm';
// import GeneralInfo from '../../BusinessPage/PageDetails/GeneralInfo/index';
import axios from "axios";
import { API_URL } from "constant/constant";
import AuthWrapper from "@jumbo/components/Common/authComponents/AuthWrapper";

// import { useHistory } from "react-router-dom";
// import { AuhMethods } from "services/auth";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import NoRecordFound from "../../BusinessPage/PageList/NoRecordFound";
import Activities from "./ICOList/Activities/index";
import UserDashboard from "./UserDashboard";
import UserFilter from "./UserFilter";
import { MoreVertIcon } from "@material-ui/icons/MoreVert";
import moment from "moment";
const bgImage = "/images/auth/auth-bg-pattern.png";

const dailyData = [
  { name: "Total Investor", amount: "123" },
  { name: "Total Revenue", amount: "123" },
  { name: "Total Sold Token", amount: "23" },
  // { name: "Total Investment in ETH", amount: "23" },
  // { name: "Total Investment in BNB", amount: "23" },
];
const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "relative",
  },
  head: {
    backgroundColor: "black",
    color: "white",
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  authContent: {
    // padding: 30,
    "& .btnB": {
      margin: "0 15px",
    },
    "& .inputPasswordCustom1": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
    },
    [theme.breakpoints.up("md")]: {
      order: 1,
      width: (props) => (props.variant === "default" ? "50%" : "100%"),
    },
    [theme.breakpoints.up("xl")]: {
      padding: 10,
    },
  },

  authWrap: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: (props) =>
      props.variant === "bgColor" ? `URL(${bgImage})` : "",
    backgroundPosition: (props) =>
      props.variant === "bgColor" ? "center center" : "",
    backgroundRepeat: (props) =>
      props.variant === "bgColor" ? "no-repeat" : "",
    backgroundSize: (props) => (props.variant === "bgColor" ? "cover" : ""),
    position: "relative",
    // padding: 20,
    // [theme.breakpoints.up("sm")]: {
    //   padding: 40,
    // },
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1,
      width: "100%",
      height: "100%",
      backgroundColor: (props) =>
        props.variant === "bgColor"
          ? alpha(theme.palette.primary.main, 0.5)
          : "",
    },
  },
  authCard: {
    position: "relative",
    zIndex: 3,
    // maxWidth: (props) =>
    //   props.variant === "default"
    //     ? "850px"
    //     : props.variant === "100%"
    //     ? "100%"
    //     : "550px",
    width: "100%",
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: (props) => (props.variant === "default" ? "1050px" : "750px"),
    },
  },
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = (number) => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return (
    <NumberFormat
      {...other}
      onValueChange={onNumberChange}
      value={phoneNumber}
      format="(###) ###-####"
    />
  );
}

const labels = [
  { title: "Posts", slug: "posts" },
  { title: "Likes", slug: "likes" },
  { title: "Comments", slug: "comments" },
];

const FundType = [
  { title: "Locked", type: "locked" },
  { title: "Unlocked", type: "unlocked" },
];

const splitName = (user) => {
  if (user) {
    const [fName, mName, lName] = user.name.split(" ");
    return [fName, lName ? mName + " " + lName : mName];
  }

  return ["", ""];
};

const BostSubscription = () => {
  const classes = useStyles();
  const history = useHistory();
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [filterOptions, setFilterOptions] = React.useState([]);
  const token = useSelector((state) => state.admin.authUser.access_token);
  const FetchData = useSelector((state) => state.common);
  const [from_date, handleFromChange] = useState();
  const [to_date, handleToChange] = useState();
  const [status, setstatus] = useState();
  const [icoList, setICOList] = React.useState([]);
  // const [searchText, setSearchText] = React.useState("");
  const [pageListMeta, setpageListMeta] = React.useState(10);
  // const [order, setOrder] = React.useState("desc");
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  console.log("selectedUser data -------- ", icoList);
  const dispatch = useDispatch();

  const [ruleData, setRuleData] = useState([]);
  const GetVestingRulesDetails = async (ico_id) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      // dispatch(fetchStart());
      const res = await axios({
        method: "GET",
        url: `${API_URL}admin/business_pages/ico/${ico_id}/get_vesting_rules`,
        headers: {
          Authorization: token,
        },
      }).then(({ data }) => {
        if (data.data) {
          console.log("data ----- vesting", data);
          dispatch(fetchSuccess());
          setRuleData(data.data.rules);
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      // dispatch(fetchError(error.response.data.message));
    }
    // };
  };
  React.useEffect(() => {
    let ico_id = window.location.search.split("?")[1];
    if (ico_id) {
      GetVestingRulesDetails(ico_id);
    }
  }, [window.location.search]);
  const handleSearchTextChange = (e) => {
    let data = e.target.value.replace(/^\s/, "");
    setSearchText(data);
    // dispatch(AuhMethods[method].usersListAPI({ token: token, page: page, perPage: rowsPerPage, keyword: searchText })); //token, page, perPage, keyword
  };

  const handlePageChange = (event, newPage) => {
    // console.log(newPage, " ------- newPage ----- newPage ");
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleUserEdit = (user) => {
    // dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleRowClick = (event, id) => {
    history.push({
      pathname: "/profile",
      // state: { id: id },
    });
  };

  const handleChangeStatus = (action, label, id) => {
    // console.log(
    //   " -------------   action >>>>>",
    //   action,
    //   " -------------   lable >>>>>",
    //   label,
    //   " -------------   id >>>>>"
    // );
    let data = {
      action: action,
      label: label,
      id: id,
    };
    setSelectedUser(data);
    // setOpenConfirmDialog(true);
    if (label == "View" || label == "Edit") {
      history.push({
        pathname: "/add-subscription",
        search: "12689",
        hash: label,
      });
    }
    if (label == "Enable") {
      setOpenConfirmDialog(true);
      // history.push({
      //   pathname: "/add-subscription",
      //   search: "12689",
      //   hash: label,
      // });
    }
  };

  const handleUserView = (user) => {
    setSelectedUser(user);
    // setOpenVerificationDialog(true);
  };

  const onVerify = (data) => {
    // setVerifyToggle(true);
    setSelectedUser(data);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmDelete = (e) => {
    setOpenConfirmDialog(false);
    // const params = {
    //   user_id: selectedUser.user_id,
    //   action: selectedUser?.status === '0' ? '1' : '0',
    //   page: page,
    //   perPage: rowsPerPage,
    //   keyword: searchText,
    //   order: order,
    // };

    // dispatch(AuhMethods[method].changeUserStatusAPI(token, params, 'UsersList'));
    // setTimeout(
    //   () =>
    //     // dispatch(AuhMethods[method].usersListAPI({ token: token, page: page, perPage: rowsPerPage, keyword: searchText })),
    //     1000,
    // );
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const TaralICODetails = async ({
    token,
    page,
    perPage,
    keyword,
    order,
    status,
    from_date,
    to_date,
  }) => {
    const pageData = page != undefined ? page + 1 : "";
    const keywordData = keyword != undefined ? keyword : "";
    const statusData = status != undefined ? "&status=" + status : "";
    from_date =
      from_date != undefined
        ? "&from_date=" + moment(from_date).format("YYYY-MM-DD")
        : "";
    to_date =
      to_date != undefined
        ? "&to_date=" + moment(to_date).format("YYYY-MM-DD:23:59:59")
        : "";
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "POST",
        url: `${API_URL}admin/business_pages/ico/ico_history`,
        // keywordData +
        // "&perPage=" +
        // perPage +
        // pageData +
        // statusData +
        // from_date +
        // to_date,
        headers: {
          Authorization: token,
        },
        data: {
          keyword,
          perPage,
          page: pageData,
        },
      })
        // return res;
        .then(({ data }) => {
          //console.log("data -----", data);
          if (data.data) {
            dispatch(fetchSuccess());
            setICOList(data.data);
            setpageListMeta(data.meta);
            // dispatch(setUsersListData(data));
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  useEffect(() => {
    TaralICODetails({
      token: token,
      page: page,
      perPage: rowsPerPage,
      keyword: searchText,
      order,
      status,
      from_date,
      to_date,
    });
  }, [page, rowsPerPage, searchText]);

  return (
    <div>
      {icoList.length > 0 && (
        <Box className={classes.authWrap}>
          <Box className={classes.authCard}>
            <Box className={classes.authContent}>
              {/* <GridContainer>
              <Grid item xs={12} md={12} className={classes.wallRightSidebar}> */}
              <Activities userId={icoList[0]} />
              {/* </Grid>
            </GridContainer> */}
            </Box>
          </Box>
        </Box>
      )}
      <Paper className={classes.paper}>
        <UserTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={pageListMeta.total}
        />

        {/* <UserDashboard
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={pageListMeta}
          dailyData={dailyData}
        />
        <UserFilter
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={"12"}
          actionHandleIcon={<MoreVertIcon />}
          actions={""}
          actionHandler={""}
          from_date={from_date}
          handleFromChange={handleFromChange}
          to_date={to_date}
          handleToChange={handleToChange}
          status={status}
          setstatus={setstatus}
        /> */}
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <UserTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={icoList.length}
            />
            <TableBody>
              {icoList.length > 0 ? (
                <>
                  {icoList.map((row, index) => (
                    <UserListRow
                      key={index}
                      page={page}
                      idx={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onUserEdit={handleUserEdit}
                      changeStatus={handleChangeStatus}
                      onUserView={handleUserView}
                      isSelected={isSelected}
                      onVerify={onVerify}
                      VestingRuleData={ruleData}
                      callBack={() =>
                        TaralICODetails({
                          token: token,
                          page: page,
                          perPage: rowsPerPage,
                          keyword: searchText,
                          order,
                          status,
                          from_date,
                          to_date,
                        })
                      }
                    />
                  ))}
                </>
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={pageListMeta.total}
          // rowsPerPage={rowsPerPage}
          // page={page}
          // onPageChange={handlePageChange}
          // onPageChange={() =>
          //   TaralICODetails({
          //     token: token,
          //     page: page,
          //     perPage: rowsPerPage,
          //     keyword: searchText,
          //     order,
          //   })
          // }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {/* openConfirmDialog */}
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${selectedUser?.status == "1" ? "Disable" : "Enable"} ${
          selectedUser.planName ? selectedUser.planName : ""
        }`}
        content={`Are you sure, you want to ${
          selectedUser?.status == "1" ? "disable" : "enable"
        } this plan?`}
        // title={`Confirm  ${selectedUser?.detail?.is_enalbed == '1' ? 'Block' : 'Unblock'}`}
        // content={`Are you sure, you want to this user?`}

        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default BostSubscription;

BostSubscription.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
