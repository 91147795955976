import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";

const headCells = [
  {
    id: "Sr No",
    numeric: false,
    disablePadding: false,
    label: "Sr No"
  },
  {
    id: "name1",
    numeric: false,
    disablePadding: false,
    label: "Influencer Name"
  },
  {
    id: "name2",
    numeric: false,
    disablePadding: false,
    label: "User Name"
  },
  {
    id: "name3",
    numeric: false,
    disablePadding: false,
    label: "Escrow Ammount"
  },
  {
    id: "name4",
    numeric: false,
    disablePadding: false,
    label: "Start Date"
  },
  {
    id: "name5",
    numeric: false,
    disablePadding: false,
    label: "Admin Comission"
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" }
];

function UserTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className="like-padding"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default UserTableHead;
