import React, { useEffect, useState } from 'react';
import Stories from 'react-insta-stories';
import CmtCarousel from '../../../@coremat/CmtCarousel';
import CmtImage from '../../../@coremat/CmtImage';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import useStyles from './MediaViewer.style';
import { IMAGE_URL } from 'constant/constant';
import CardMedia from '@material-ui/core/CardMedia';

let ddf = [
  {
    url: 'https://picsum.photos/100/100',
    duration: 5000,
    header: {
      heading: 'Mohit Karekar',
      subheading: 'Posted 30m ago',
      profileImage: 'https://picsum.photos/100/100',
    },
  },
];
const renderRow = (data, index) => {
  if (
    data?.metaData?.type.startsWith('image') ||
    data?.metaData?.post_type.startsWith('image') ||
    data?.post_type == 'image'
  ) {
    return <CmtImage key={index} src={`${IMAGE_URL}${data?.url}`} alt={data?.name} />;
  } else {
    return (
      <Box className="embed-responsive">
        {/* <iframe key={index} src={data?.preview || `${IMAGE_URL}${data?.url}`} title={data?.name} /> */}
        <video controls onClick={({ target: video }) => (video.paused ? video.play() : video.pause())}>
          <source src={`${IMAGE_URL}${data?.url}`} type="video/mp4" muted />
        </video>
      </Box>
    );
  }
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MediaViewer = ({ position, medias, handleClose }) => {
  const [viewer, setViewer] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();
  useEffect(() => {}, [viewer]);
  useEffect(() => {
    // let datad = [];
    // let ddr =
    //   Array.isArray(medias?.user_stories) &&
    //   medias?.user_stories.forEach(data => {
    //     let gg = {
    //       url: `${IMAGE_URL}${data?.url}`,
    //       duration: 3000,
    //       type: data.type,
    //       header: {
    //         heading: medias?.story_user_detail?.name,
    //         subheading: `${medias?.story_user_detail?.name}`,
    //         profileImage: `${IMAGE_URL}${medias?.story_user_detail?.profile_url}`,
    //       },
    //     };
    //     datad.push(gg);
    //   });
    // setViewer(datad);
    if (position > -1) setOpen(true);
    else {
      setOpen(false);
    }
  }, [position]);

  return (
    <Dialog className={classes.dialogRoot} fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
      <Box className={classes.mediaViewerRoot}>
        <IconButton className={classes.cancelBtn} onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        {position >= 0 ? (
          <Box className={classes.carouselRoot} style={{ display: 'flex', alignItems: 'center' }}>
            <CmtCarousel
              settings={{
                dots: true,
                initialSlide: position,
                arrows: true,
                infinite: false,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
              }}
              slickGoTo={position}
              style={{ width: '100%' }}
              data={medias}
              renderRow={renderRow}
            />
            {/* {Array.isArray(viewer) && (
              <Stories stories={viewer} defaultInterval={1500} width={500} height={768} onAllStoriesEnd={handleClose} />
            )} */}
          </Box>
        ) : null}
      </Box>
      {/* <Box className={classes.carouselRoot}>
        <CmtCarousel
          data={medias}
          dotPosition="bottom-left"
          className={classes.containerStyle}
          settings={{
            slidesToShow: 1,
            slidesToScroll: 1,
          }}
          renderRow={(items, index) =>
            items.post_type === 'image' ? (
              <CmtImage
                key={index}
                src={`${IMAGE_URL}` + items?.url} //TODO: SET BASE URL OF IMAGE
                alt={items?.post_type}
                onClick={() => {
                  // postDetail(item);
                }}
              />
            ) : (
              <CardMedia
                component="video"
                className={`${classes.media} videoStyles`}
                image={`${IMAGE_URL}` + items?.url}
                controls
                onClick={() => {
                  // postDetail(item);
                }}
              />
            )
          }
        />
      </Box> */}
    </Dialog>
  );
};

export default MediaViewer;
