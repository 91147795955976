import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./index.style";
import { API_URL } from "constant/constant";
import axios from "axios";
import { fetchError } from "redux/actions";
import { DownLoadCSVFunction } from "customHook/debounse";

const UserTableToolbar = ({ count }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token);

  const DownLoadeCSV = async count => {
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}admin/influencer_apply/request_list`,
        headers: {
          Authorization: token
        },
        params: {
          perPage: count,
          page: 1
        }
      })
        // return res;
        .then(({ data }) => {
          //console.log("data -----", data);
          if (data.data) {
            let arrayOfObjects = data.data;

            /* ********************************* B ********************************* */

            if (!arrayOfObjects.length) {
              return;
            }

            DownLoadCSVFunction(arrayOfObjects, "escrow_amount_requests");
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <React.Fragment>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          Escrow List
        </Typography>

        <React.Fragment>
          <div>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: "260px" }}
              onClick={() => DownLoadeCSV(count)}
            >
              Download CSV File
            </Button>
          </div>
        </React.Fragment>
      </Toolbar>
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {};

export default React.memo(UserTableToolbar);
