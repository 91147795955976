import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React from 'react';
import { Label } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    marginLeft: 30,
  },
}));

const headCells = [
  {
    id: 'flagName',
    numeric: false,
    disablePadding: false,
    label: 'Flag Name',
  },
  // { id: 'postDateAndTime', numeric: false, disablePadding: false, label: 'Post date & time' },
  // { id: 'postType,', numeric: false, disablePadding: false, label: 'Post type' },
  // { id: 'reportedBy', numeric: false, disablePadding: false, label: 'Reported by' },
  // {
  //   id: 'reportType',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Report type',
  // },
  // { id: 'reportDateAndTime', numeric: false, disablePadding: true, label: 'Report date & time' },
  // { id: 'status', numeric: false, disablePadding: false, label: 'Status' },

  // { id: 'emailUsage', numeric: true, disablePadding: false, label: 'Email Usage' },
];

function FlagTypeTableHead({ classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };
  const styleClasses = useStyles();

  return (
    <TableHead>
      <TableRow>
        {/* {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            >
             {headCell.label}
          </TableCell>
        ))} */}

        <TableCell component="th" id={1} scope="row">
          <Box display="flex" alignItems="center">
            {/* <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_pic} alt={row.name} />
          </Box> */}
            <div>
              <Typography className={styleClasses.titleRoot} component="div" variant="h4">
                Flag Name
              </Typography>
            </div>
          </Box>
        </TableCell>

        <TableCell align="left">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

FlagTypeTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default React.memo(FlagTypeTableHead);
