import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import useStyles from "./index.style";

const UserTableToolbar = ({ selected }) => {
  const classes = useStyles();
  const numSelected = selected.length;
  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h4"
            id="tableTitle"
            component="div"
          >
            ICO List
          </Typography>
        )}
      </Toolbar>
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {
  selected: PropTypes.array
};

export default React.memo(UserTableToolbar);
