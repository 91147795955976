import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React from 'react';

const headCells = [
  { id: 'SrNo', numeric: false, disablePadding: false, label: 'Sr No' },

  { id: 'username', numeric: false, disablePadding: false, label: 'User Name' },
  { id: 'lockedAmount', numeric: false, disablePadding: false, label: 'Locked Amount' },
  { id: 'coin', numeric: false, disablePadding: true, label: 'Coin' },
  { id: 'lockedAt', numeric: false, disablePadding: false, label: 'Locked at' },

  { id: 'unlockedAt', numeric: false, disablePadding: false, label: 'Unlocked at' },
  { id: 'rewardName', numeric: false, disablePadding: false, label: 'Reward Name' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

function LockFundsHeader({ classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}> */}
            {headCell.label}
            {/* {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
        {/* <TableCell align="center">Actions</TableCell> */}
      </TableRow>
    </TableHead>
  );
}

LockFundsHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default React.memo(LockFundsHeader);
