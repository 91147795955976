import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { alpha } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import IntlMessages from "../../../@jumbo/utils/IntlMessages"; //'../../../utils/IntlMessages';
import { AuhMethods } from "../../../services/auth";
import ContentLoader from "../../../@jumbo/components/ContentLoader";
import AuthWrapper from "../../../@jumbo/components/Common/authComponents/AuthWrapper";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import { requiredMessage } from "@jumbo/constants/ErrorMessages";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up("md")]: {
      order: 1,
      width: props => (props.variant === "default" ? "50%" : "100%")
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  },
  alertRoot: {
    marginBottom: 10
  }
}));

const UpdateEmail = ({
  method = "accountSettings",
  variant = "default",
  wrapperVariant = "default"
}) => {
  const authUser = useSelector(state => state.admin.authUser); //Fetch data from Redux
  const [token] = useState(authUser.access_token);
  const [email, setEmail] = useState(authUser.email);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    let timeOutStopper = null;

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }); //[send_forget_password_email]);

  const onSubmit = () => {
    if (!email) {
      setEmailError(requiredMessage);
    } else {
      dispatch(AuhMethods[method].editEmailAPI({ email, token }));
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <div className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="appModule.updateEmail" />
        </Typography>

        <form>
          <div className={"mb-5"}>
            <AppTextInput
              fullWidth
              size="0"
              type="text"
              variant="outlined"
              label={<IntlMessages id="appModule.email" />}
              value={email}
              onChange={e => {
                setEmail(e.target.value);
                setEmailError("");
              }}
              helperText={emailError}
            />
          </div>
          <div className={"mb-5"}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              <IntlMessages id="appModule.updateEmail" />
            </Button>
          </div>
        </form>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default UpdateEmail;
