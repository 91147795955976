import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MoreHoriz, Close, Done, ViewAgenda } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const UserListRow = ({ row, isSelected, page, idx, changeStatus }) => {
  // console.log("row------", row);
  const classes = useStyles();

  // *********** option for Page ***********************
  const getUserActions = () => {
    const actions = [
      // row.status === "0"
      //   ? { action: "enable", label: "Enable", icon: <Done /> }
      //   : row.status === "1"
      //   ? { action: "Disable", label: "Disable", icon: <Close /> }
      //   : row.status === "2"
      //   ? { action: "accepted", label: "APPROVE", icon: <Done /> }
      //   : {},
      { action: "rejected", label: "View", icon: <VisibilityIcon /> },
      // { action: "rejected", label: "Edit", icon: <EditIcon /> },
    ];
    // if (row.status === "0") {
    //   actions.push({ action: "rejected", label: "REJECT", icon: <Close /> });
    // }

    return actions;
  };

  const onUserMenuClick = (menu, row) => {
    // console.log(row, "selectedUser ----- menu", menu);
    changeStatus(menu.action, menu.label, row?.id, row);
  };

  const labelId = `enhanced-table-checkbox-${row.user_id}`;
  const isItemSelected = isSelected(row.user_id);
  const userActions = getUserActions(row.status, row.verified);

  // console.log("selectedUser ----- userActions", userActions);
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.user_id}
      selected={isItemSelected}
    >
      <TableCell>{page * 10 + idx + 1}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar
              size={40}
              src={`${IMAGE_URL}${row.user_data.profile_url}`}
              alt={row.user_data.name}
            />
            {console.log(
              "testings-----------",
              `${IMAGE_URL}${row.user_data.profile_url}`
            )}
          </Box>
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.user_data.name === null ? "N/A" : row.user_data.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.boostplan_data.name === null
                ? "N/A"
                : row.boostplan_data.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row.pay_type === "1" ? "INR" : "INR + Taral"}
        </Typography>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {row.pay_type === "1" ? (
            row.total_inr
          ) : (
            <>
              {row.inr}inr {row.taral} Taral
            </>
          )}
        </Typography>
      </TableCell>
      <TableCell>
        {moment(row.created_at).format("lll")}
        {/* {row.online_offline_business === 0 ? "Offline" : "Online"} */}
      </TableCell>
      {/*  <TableCell>
        {row.planType}
      {row.interested_for_advertise_business_page === 0 ? "No" : "Yes"} 
      </TableCell>*/}

      <TableCell>
        {row.status === "1"
          ? "Active"
          : row.status === "0"
          ? "Inactive"
          : "Unverified"}
      </TableCell>

      <TableCell
        align="center"
        // onClick={(event) => event.stopPropagation()}
      >
        <CmtDropdownMenu
          row={row}
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
