import React, { useEffect } from "react";
import GridContainer from "../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import NewsFeed from "./NewsFeed";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../../redux/actions/WallApp";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { user } from "@fake-db/apps/wall";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { AuhMethods } from "services/auth";

const useStyles = makeStyles((theme) => ({
  wallLeftSidebar: {
    "@media screen and (min-width: 960px) and (max-width: 1020px)": {
      flexBasis: "40%",
      maxWidth: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  wallMainContent: {
    "@media screen and (min-width: 960px) and (max-width: 1020px)": {
      flexBasis: "60%",
      maxWidth: "60%",
    },
  },
  wallRightSidebar: {
    "@media screen and (max-width: 1020px)": {
      display: "none",
    },
  },
}));

const PostDetail = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const [currentId, setCurrentId] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchText, setSearchText] = useState("");
  // const { userDetail } = useSelector(({ wallApp }) => wallApp);
  const reportsManagementData = useSelector(({ reportsManagement }) =>
    reportsManagement.reportsList.find((x) => x.post_id === currentId)
  );
  const reportListMeta = useSelector(
    ({ reportsManagement }) => reportsManagement.reportListMeta
  );
  const token = useSelector((state) => state.admin.authUser.access_token); //Fetch data from Redux

  useEffect(() => {}, [reportsManagementData]);
  useEffect(() => {
    setCurrentId(location?.state?.currentId);
    let order = "";
    let from_date = "";
    let to_date = "";
    let status = "";
    dispatch(
      AuhMethods["reportsManagement"].reportsListAPI({
        // token: token,
        // page: page,
        // perPage: rowsPerPage,
        // keyword: searchText,
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText,
        status,
        order,
        // from_date,
        // to_date
        from_date: from_date ? from_date : "",
        to_date: to_date ? to_date : "",
      })
    );

    // dispatch(
    //   AuhMethods[method].populerPostsAPI({
    //     token: token,
    //     categoryId: tabValue,
    //     keyword: searchText,
    //     page: page,
    //     perPage: perPage,
    //   }),
    // );
  }, [location]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (page != reportListMeta.pages) {
        // dispatch();
        // AuhMethods[method].populerPostsAPI({
        //   token: token,
        //   categoryId: tabValue,
        //   keyword: searchText,
        //   page: page + 1,
        //   perPage: perPage,
        // }),
        setPage(page + 1);
      }
    }
  };

  return (
    <React.Fragment>
      {reportsManagementData && (
        <GridContainer>
          {/* <Grid item xs={12} sm={7} md={6} className={classes.wallMainContent}> */}
          <Grid item xs={12} sm={7} md={12} className={classes.wallMainContent}>
            <NewsFeed
              reportsManagementData={reportsManagementData}
              handleScroll={handleScroll}
            />
          </Grid>
        </GridContainer>
      )}
    </React.Fragment>
  );
};

export default PostDetail;
