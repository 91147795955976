import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import moment from "moment";
import Axios from "axios";
import { API_URL } from "constant/constant";
import { useDispatch } from "react-redux";
import { fetchSuccess } from "../../../../redux/actions";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import AddRewardModal from "../AddRewardModal";
function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}
const RewardHistoryRow = ({ data, index, token, callback }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const activeInactiveRewardHandler = async status => {
    try {
      const response = await Axios({
        method: "GET",
        url: `${API_URL}/admin/rewards_setting/active_inactive`,
        headers: {
          Authorization: token
        },
        params: {
          status: status,
          id: data.id
        }
      });
      if (response.status == 200) {
        setOpen(false);
        dispatch(fetchSuccess(response?.data?.message));
        callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          {data?.type ? capitalizeFirstLetter(data?.type) : "N/A"}
        </TableCell>
        <TableCell>{data?.creator_points}</TableCell>
        <TableCell>{data?.user_points}</TableCell>
        <TableCell>{data.is_active === "1" ? "Active" : "Inactive"}</TableCell>
        <TableCell align="left" onClick={event => event.stopPropagation()}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenEdit(true)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: "20px" }}
            onClick={() => setOpen(true)}
          >
            {data.is_active === "1" ? "Inactive" : "Active"}
          </Button>
        </TableCell>
      </TableRow>
      {open && (
        <ConfirmDialog
          open={open}
          title={`${data?.is_active === "0" ? "Active" : "Inactive"} Reward`}
          content={`Are you sure, you want to ${
            data?.is_active === "0" ? "active" : "inactive"
          } this reward?`}
          onClose={() => setOpen(false)}
          onConfirm={() =>
            activeInactiveRewardHandler(data.is_active === "1" ? "0" : "1")
          }
        />
      )}
      {openEdit && (
        <AddRewardModal
          open={openEdit}
          token={token}
          data={data}
          callback={callback}
          onCloseDialog={() => setOpenEdit(false)}
        />
      )}
    </>
  );
};

export default React.memo(RewardHistoryRow);
