import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    marginLeft: 30
  }
}));

const GenreListRow = ({ row, isSelected, onFlagEdit, onFladDelete }) => {
  const classes = useStyles();

  const onFlagEditClick = () => {
    onFlagEdit(row);
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row?.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row?.multipleir ? `${row?.multipleir}X` : "N/A"}</TableCell>
      <TableCell>{row?.is_reward_give === "1" ? "Yes" : "No"}</TableCell>
      <TableCell align="left" onClick={event => event.stopPropagation()}>
        <Button variant="contained" color="primary" onClick={onFlagEditClick}>
          Edit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: "20px" }}
          onClick={onFladDelete}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(GenreListRow);
