import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";

import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";

import { alpha, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import {
  passwordMisMatch,
  requiredMessage,
} from "@jumbo/constants/ErrorMessages";
import IntlMessages from "@jumbo/utils/IntlMessages";
import ContentLoader from "@jumbo/components/ContentLoader";
import AuthWrapper from "@jumbo/components/Common/authComponents/AuthWrapper";
import { AuhMethods } from "services/auth";

const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: (props) => (props.variant === "default" ? "50%" : "100%"),
      order: 1,
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
  },
}));

const schema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("This field is required.")
    .min(
      8,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  // .test(
  //   "passwordRequirements",
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
  //   (value) =>
  //     [
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //     ].every((pattern) => pattern.test(value))
  // ),
  newPassword: yup
    .string()
    .required("This field is required.")
    .min(
      8,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .test(
      "passwordRequirements",
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      (value) =>
        [
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        ].every((pattern) => pattern.test(value))
    ),
});

const UpdatePassword = ({
  method = "admin",
  variant = "default",
  wrapperVariant = "default",
}) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const state = useSelector((state) => state);
  const token = useSelector((state) => state.admin.authUser.access_token);

  useEffect(() => {}, [state]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setConfirmPasswordError(passwordMisMatch);
    } else {
      dispatch(
        AuhMethods["accountSettings"].changePasswordAPI({ data, token })
      );
    }
    setValue("oldPassword", "");
    setValue("newPassword", "");
    setValue("confirmPassword", "");
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Update Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <Controller
              name="oldPassword"
              control={control}
              render={({ value }) => {
                return (
                  <TextField
                    name="oldPassword"
                    label={<IntlMessages id="appModule.oldPassword" />}
                    fullWidth
                    type={"password"}
                    onChange={(event) => {
                      setOldPassword(event.target.value);
                      setValue("oldPassword", event.target.value);
                    }}
                    value={getValues("oldPassword")}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                );
              }}
            />
            <p style={{ color: "red" }}>{errors.oldPassword?.message}</p>
          </Box>
          <Box mb={2} className="inputPasswordCustom">
            <Controller
              name="newPassword"
              control={control}
              render={() => (
                <TextField
                  type={"password"}
                  label={<IntlMessages id="appModule.newPassword" />}
                  fullWidth
                  value={getValues("newPassword")}
                  onChange={(event) => {
                    setNewPassword(event.target.value);
                    setValue("newPassword", event.target.value);
                  }}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              )}
            />

            <p style={{ color: "red" }}>{errors.newPassword?.message}</p>
          </Box>

          <Box mb={2} className="inputPasswordCustom">
            <Controller
              name="confirmPassword"
              control={control}
              // rules={{ required: true }}

              render={() => (
                <TextField
                  type={"password"}
                  label={<IntlMessages id="appModule.confirmPassword" />}
                  fullWidth
                  value={getValues("confirmPassword")}
                  onChange={(event) => {
                    setValue("confirmPassword", event.target.value);
                    setConfirmPassword(event.target.value);
                    setConfirmPasswordError("");
                  }}
                  helperText={confirmPasswordError}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              )}
            />

            <p style={{ color: "red" }}>{errors.confirmPassword?.message}</p>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Button type="submit" variant="contained" color="primary">
              <IntlMessages id="appModule.addSubscription" />
            </Button>
          </Box>
        </form>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default UpdatePassword;
