import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUsers, setCurrentUser } from "redux/actions/Users";
import moment from "moment";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { useDebounce } from "@jumbo/utils/commonHelper";
// import UserDetailView from './UserDetailView';
import NoRecordFound from "../../UserModules/Users/NoRecordFound";
import { usersModule, recentActivities } from "../../../@fake-db";
import { getDateText } from "../../../@jumbo/utils/dateHelper";
import { push } from "connected-react-router";
import { useHistory } from "react-router-dom";
import UserListRow from "routes/UserModules/Users/UserListRow";
import UserTableHead from "routes/UserModules/Users/UserTableHead";
import UserTableToolbar from "routes/UserModules/Users/UserTableToolbar";
import useStyles from "@coremat/CmtAvatar/index.style";
import WalletTableToolbar from "./WalletTableToolbar";
import WalletTableHeader from "./WalletTableHeader";
import WalletListRow from "./WalletListRow";
import ManageFundsModal from "./ManageFundsModal";
import { AuhMethods } from "services/auth";
import { user } from "@fake-db/apps/profile";
import { userDetails } from "@fake-db/dashboards/widgets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WalletDashboard from "./WalletDashboard";
import WalletFilter from "./WalletFilter";

const dailyData = [
  { name: "Total Wallet", amount: "123" },
  { name: "Total Pending Wallet", amount: "123" },
  { name: "Total Verified Wallet", amount: "123" },
  { name: "Total Blocked Wallet", amount: "23" }
];

const WalletDetails = () => {
  const history = useHistory();

  const classes = useStyles;
  // const { users } = useSelector(({ usersReducer }) => usersReducer);
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [searchText, setSearchText] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openReedemDialog, setOpenReedemDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [from_date, handleFromChange] = useState();
  const [to_date, handleToChange] = useState();
  const [status, setstatus] = useState();
  const [fundsData, setdFundsData] = useState("");
  // const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const token = useSelector(state => state.admin.authUser.access_token);
  const walletUserList = useSelector(
    state => state?.walletManagement?.WalletUserList?.data
  );

  const walletUserListMeta = useSelector(
    state => state?.walletManagement?.WalletUserListMeta
  );
  const FetchData = useSelector(state => state?.common);

  const dispatch = useDispatch();

  const getUsersListByDate = () => {
    return (
      Array.isArray(walletUserList) &&
      walletUserList.map(activity => {
        activity.category = getDateText(activity.date);
        return activity;
      })
    );
  };
  const users = getUsersListByDate();

  useEffect(() => {
    dispatch(
      AuhMethods["walletManagement"].getWalletListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText,
        order,
        status,
        from_date: from_date ? moment(from_date).format("YYYY-MM-DD") : "",
        to_date: to_date ? moment(to_date).format("YYYY-MM-DD:23:59:59") : ""
      })
    ); //token, page, perPage, keyword
    // setInterval(()=>updateGreeting(), 1000);
  }, [page, rowsPerPage, fundsData, searchText, from_date, to_date, status]);

  // useEffect(() => {
  //   dispatch(
  //     getUsers(filterOptions, debouncedSearchTerm, () => {
  //       setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
  //       setUsersFetched(true);
  //     }),
  //   );
  // }, [dispatch, filterOptions, debouncedSearchTerm]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentUser(null));
  };
  const handleCloseReedemDialog = () => {
    setOpenReedemDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    history.push("/redeemHistory");

    // const selectedIndex = selected.indexOf(id);
    // let newSelected = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, id);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    // }

    // setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRedeemListView = user => {
    //View User Profile button action
    // dispatch(setCurrentUser(user));
    // setOpenViewDialog(true);

    if (user?.id) {
      history.push({
        pathname: "/redeemHistory",
        state: { id: user?.user_id }
      });
    }
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleManageFundsView = user => {
    setdFundsData(user);
    // dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };
  const handleReedemView = user => {
    setdFundsData(user);
    // dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleUserDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteUser(selectedUser.id));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleSearchTextChange = e => {
    let data = e.target.value
      .trim()
      .split(/ +/)
      .join(" ");

    setSearchText(data);

    dispatch(
      AuhMethods["walletManagement"].getWalletListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText
      })
    );
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <WalletTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenReedemDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleSearchTextChange={handleSearchTextChange}
          searchText={searchText}
        />

        <WalletDashboard
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={walletUserListMeta}
          dailyData={dailyData}
        />
        <WalletFilter
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={walletUserListMeta?.total}
          actionHandleIcon={<MoreVertIcon />}
          actions={""}
          actionHandler={""}
          from_date={from_date}
          handleFromChange={handleFromChange}
          to_date={to_date}
          handleToChange={handleToChange}
          status={status}
          setstatus={setstatus}
        />

        <TableContainer className={`${classes.container} customTableHeight`}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <WalletTableHeader
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {!!users.length ? (
                // stableSort(users, getComparator(order, orderBy))
                Array.isArray(users) &&
                users
                  .slice(0, rowsPerPage)
                  .map((row, index) => (
                    <WalletListRow
                      key={index}
                      row={row}
                      page={page}
                      idx={index}
                      onRowClick={handleRowClick}
                      onManageFunds={handleManageFundsView}
                      onUserDelete={handleUserDelete}
                      onRedeemHistory={handleRedeemListView}
                      handleSearchTextChange={handleSearchTextChange}
                      searchText={searchText}
                      isSelected={isSelected}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={walletUserListMeta?.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {/* {openConfirmDialog && <AddRewardModal open={openConfirmDialog} onCloseDialog={handleCancelDelete} />} */}
      {openUserDialog && (
        <ManageFundsModal
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
          fundsData={fundsData}
          page={page}
          rowsPerPage={rowsPerPage}
          searchText={searchText}
        />
      )}

      {/* {openReedemDialog && (
        <ReedemLimitModal open={openReedemDialog} onCloseDialog={handleCloseReedemDialog} fundsData={fundsData} />
      )} */}

      {/* {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />} */}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete ${selectedUser.name}`}
        content={"Are you sure, you want to block this user?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default WalletDetails;
