import moment from "moment";
import { useState, useEffect } from "react";

export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

function mapElementToColumns(fieldNames) {
  fieldNames.forEach((el, i) => {
    if (el == "release_history") {
      fieldNames.splice(i, 1);
    }
  });
  fieldNames.forEach((el, i) => {
    if (el == "vesting_rules") {
      fieldNames.splice(i, 1);
    }
  });
  fieldNames.forEach((el, i) => {
    if (el == "desired_amount_currency") {
      fieldNames.splice(i, 1);
    }
  });
  fieldNames.forEach((el, i) => {
    if (el == "updated_at") {
      fieldNames.splice(i, 1);
    }
  });
  return function(element) {
    let fields = fieldNames.map((n, i) => {
      // console.log(
      //   " fieldNames  ---->>>>>>",
      //   n == "white_list_accounts" ? element[n]?.score : ""
      // );
      return element[n]
        ? typeof element[n] == "object"
          ? n == "detail"
            ? JSON.stringify(element[n]?.email)
            : n == "white_list_accounts"
            ? JSON.stringify(element[n]?.score)
            : n == "user_data"
            ? JSON.stringify(element[n]?.email)
            : n == "boostplan_data"
            ? JSON.stringify(element[n]?.name)
            : "NA"
          : JSON.stringify(element[n])
        : '""';
    });
    return fields.join(",");
  };
}
export var DownLoadCSVFunction = async (arrayOfObjects, name) => {
  let jsontxt = JSON?.stringify(arrayOfObjects);
  // console.log(
  //   arrayOfObjects?.length,
  //   "arrayOfObjects",
  //   Array.isArray(mythings)
  // );
  let csvContent;
  let mythings = JSON.parse(jsontxt);
  if (!Array.isArray(mythings)) throw "Oooops, stranger things happen!";
  let fieldnames = Object.keys(mythings[0]);
  let csvtxt = await mythings.map(mapElementToColumns(fieldnames)).join("\n");
  csvContent = fieldnames + "\n" + csvtxt;

  const blob = new Blob([csvContent], {
    type: "text/csv;charset=UTF-8",
  });
  var csvURL = window.URL.createObjectURL(blob);
  var tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute(
    "download",
    `${name}_` + moment().format("DD-MM-YYYY") + ".csv"
  );
  tempLink.click();
};
