import {
  fetchError,
  fetchStart,
  fetchSuccess,
  storyListData
} from "../../../redux/actions";
import axios from "../../config";
import {
  setUserProfileData,
  setUsersListData,
  setUserPostsData,
  deleteUserPost,
  editUserStatusFromUserList,
  editUserStatusFromProfile,
  userMorePostsMetaData,
  appendUserMorePostsData,
  setPostCommentsData,
  setCommentsMeta,
  setVerfiy,
  usersFollowerData,
  usersFollowingData,
  getGenreList,
  getVerificationRequest
} from "redux/actions/UsersModule";
import { reject } from "lodash";
import { AuhMethods } from "..";
import ContentManagementServicesAuth from "../ContentManagement";

//Users Module apis
const UsersModuleServicesAuth = {
  //Users List API
  usersListAPI: ({
    token,
    page,
    perPage,
    keyword,
    order,
    status,
    from_date,
    to_date
  }) => {
    const pageData = page !== undefined ? "&page=" + (page + 1) : "";
    const keywordData = keyword !== undefined ? "&keyword=" + keyword : "";
    const statusData = status !== undefined ? "&status=" + status : "";

    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/user/list?order=${order}&` +
              keywordData +
              "&perPage=" +
              perPage +
              pageData +
              statusData +
              "&from_date=" +
              from_date +
              "&to_date=" +
              to_date,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(setUsersListData(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // USER FOLLOWERS
  userFollowerList: (token, order, perPage, page, userId) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/user/followers/${userId}?order=${order}&` +
              "&perPage=" +
              perPage +
              `&page=${page}`,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(usersFollowerData(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // USER FOLLOWING
  userFollowingList: (token, order, perPage, page, userId) => {
    const pageData = page != undefined ? "&page=" + (page + 1) : "";
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/user/following/${userId}?order=${order}&` +
              "&perPage=" +
              perPage +
              `&page=${page}`,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(usersFollowingData(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Change User Status API
  changeUserStatusAPI: (token, params, from) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put("admin/user/change_status", params, { withCredentials: true })
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data.message));

              setTimeout(() => {
                {
                  from == "Profile"
                    ? dispatch(editUserStatusFromProfile(params?.user_id))
                    : dispatch(editUserStatusFromUserList(params?.user_id));

                  dispatch(
                    AuhMethods["usersModule"].usersListAPI({
                      token: token,
                      page: params?.page,
                      perPage: params?.perPage,
                      keyword: params?.keyword,
                      order: params?.order,
                      from_date: params?.from_date,
                      to_date: params?.to_date
                    })
                  );
                }
              }, 1000);
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  //User Profile API
  userProfileAPI: ({ token, userId }) => {
    // const idData = userId != undefined ? userId : '30048';
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get("admin/user/profile/" + userId, {}, { withCredentials: true })
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(setUserProfileData(data.data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //User Posts API
  userPostsAPI: ({
    token,
    userId,
    page,
    perPage,
    type,
    from_date,
    to_date
  }) => {
    const typeData = type != undefined ? "&type=" + type : "";

    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            "admin/post/user_posts/" +
              userId +
              "?" +
              "perPage=" +
              perPage +
              "&page=" +
              page +
              typeData,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(
                AuhMethods["usersModule"].usersListAPI({
                  token: token,
                  page: page,
                  perPage: perPage,
                  keyword: "",
                  order: "desc",

                  from_date: from_date,
                  to_date: to_date
                })
              );
              {
                page == 1
                  ? dispatch(setUserPostsData(data.data))
                  : dispatch(appendUserMorePostsData(data.data));
              }
              dispatch(userMorePostsMetaData(data.meta));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // Delete Post API
  deletePostAPI: (token, params, postData) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put("admin/post/delete", params, { withCredentials: true })
          .then(({ data }) => {
            if (postData) {
              postData["token"] = token;
              dispatch(UsersModuleServicesAuth.userPostsAPI(postData));
            }
            if (data.message) {
              dispatch(fetchSuccess(data.message));
              dispatch(deleteUserPost(params.post_id));
            } else {
              dispatch(fetchError(data.message));
            }
            // resolve(data)
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  },

  //User Posts API
  userMorePostsAPI: ({ token, userId, page, perPage, type }) => {
    const typeData = type != undefined ? "&type=" + type : "";

    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            "admin/post/user_posts/1?perPage=10&page=1&type=latest",
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.data) {
              dispatch(fetchSuccess());
              dispatch(appendUserMorePostsData(data.data));
              dispatch(userMorePostsMetaData(data.meta));
            } else {
              dispatch(fetchError(data.message));
            }
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // Posts Comments API
  postCommentsAPI: ({ token, commentId, page, perPage, itemType }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            "admin/post/post_data/" +
              commentId +
              "?&type=" +
              itemType +
              "&perPage=" +
              perPage +
              "&page=" +
              page,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(setPostCommentsData(data.data));
              dispatch(setCommentsMeta(data.meta));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  // User Verify unverify api

  usersVerifyAPI: ({ token, params }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put("admin/user/change_verified_status", params)
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data?.message));
              dispatch(setVerfiy(data));
              setTimeout(() => {
                // , , , keyword, orde
                dispatch(
                  AuhMethods["usersModule"].usersListAPI({
                    token: token,
                    page: params?.page,
                    perPage: params?.perPage,
                    keyword: params?.keyword,
                    order: params?.order || "desc",
                    from_date: params?.from_date ? params?.from_date : "",
                    to_date: params?.to_date ? params?.from_date : ""
                  })
                );
              }, 500);
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // GET Stories API
  getStoriesAPI: ({ token, userId, perPage, page }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/post/story_list?user_id=${userId?.userId}` +
              "perPage=" +
              perPage +
              "&page=" +
              page,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(storyListData(data));
              // dispatch(setCommentsMeta(data.meta));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // delete user post
  deleteUserPostAPI: (token, post_id, page, uAction) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put(
            `admin/post/delete_content`, // + commentId + '?' + 'perPage=' + perPage + '&page=' + page,
            { post_id: post_id, action: uAction },
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data) {
              let msg =
                uAction == "0"
                  ? "inactive"
                  : uAction == "1"
                  ? "active"
                  : "delete";
              dispatch(fetchSuccess(`Post ${msg} successfully`));
              setTimeout(() => {
                dispatch(
                  ContentManagementServicesAuth.getContentPostsListAPI({
                    token: token,
                    page: page,
                    perPage: 10,
                    sortBy: ""
                    // keyword: searchText,
                  })
                );
              }, 500);
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //deleteUserReportPost
  deleteUserReportPost: (token, postId, page, perPage, uAction, userId) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put(
            `admin/post/delete_content`, // + commentId + '?' + 'perPage=' + perPage + '&page=' + page,
            { post_id: postId, action: uAction },
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data) {
              let msg = uAction.toLowerCase();
              dispatch(fetchSuccess(`Post ${msg} successfully`));
              setTimeout(() => {
                dispatch(
                  AuhMethods["reportsManagement"].reportsListAPI({
                    token: token,
                    page: 0,
                    perPage: 10,
                    keyword: ""
                  })
                );
              }, 500);

              // dispatch(AuhMethods['contentManagement'].postDetailAPI(token, postId, itemType, perPage));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  // delete user post
  deleteUserPostDashAPI: (
    token,
    post_id,
    page,
    perPage,
    uAction,
    userId,
    type
  ) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put(
            `admin/post/delete_content`, // + commentId + '?' + 'perPage=' + perPage + '&page=' + page,
            { post_id: post_id, action: uAction },
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data?.message) {
              let msg =
                uAction == "0"
                  ? "inactive"
                  : uAction == "1"
                  ? "active"
                  : "delete";
              dispatch(fetchSuccess(`Post ${msg.toLowerCase()} successfully`));
              dispatch(
                AuhMethods["usersModule"].userPostsAPI({
                  token,
                  userId,
                  page,
                  perPage,
                  type
                })
              ); //usersModule

              setTimeout(() => {
                dispatch(
                  ContentManagementServicesAuth.getContentPostsListAPI({
                    token: token,
                    page: page,
                    perPage: 10,
                    sortBy: ""
                    // keyword: searchText,
                  })
                );
              }, 500);
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  //Verification list api

  GetVerificationListAPI: ({
    token,
    page,
    perPage,
    keyword,
    order,
    status,
    from_date,
    to_date
  }) => {
    const pageData = page != undefined ? "&page=" + (page + 1) : "";
    const keywordData = keyword != undefined ? "&keyword=" + keyword : "";
    const statusData = status != undefined ? "&status=" + status : "";

    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/user/verification_request_list?${order}${keywordData}&page=${pageData}` +
              statusData +
              "&from_date=" +
              from_date +
              "&to_date=" +
              to_date
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());

              // dispatch(fetchSuccess(data.message));
              dispatch(getVerificationRequest(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response.data.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  VerificationProcessAPI: (token, params, page) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put(
            `admin/user/change_request_status`, // + commentId + '?' + 'perPage=' + perPage + '&page=' + page,
            params
          )
          .then(({ data }) => {
            if (data) {
              dispatch(
                fetchSuccess(`User ${data.message.toLowerCase()} successfully`)
              );
              setTimeout(() => {
                dispatch(
                  AuhMethods["usersModule"].GetVerificationListAPI({
                    token: token,
                    page: page,
                    perPage: 10,
                    sortBy: "",
                    from_date: "",
                    to_date: ""
                    // keyword: searchText,
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //GenreListApi

  GenreListAPI: ({ token, page, perPage, keyword, order }) => {
    const pageData = page != undefined ? "&page=" + (page + 1) : "";
    const keywordData = keyword != undefined ? "&keyword=" + keyword : "";

    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(`admin/user/genres?${order}${keywordData}&page=${pageData}`)
          .then(({ data }) => {
            if (data) {
              // dispatch(fetchSuccess(data.message));
              dispatch(fetchSuccess());
              dispatch(getGenreList(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response.data.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  addGenreTypeAPI: (token, data) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .post("admin/user/add_genre", data)
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));

              // dispatch(addContentType(data.data));
              setTimeout(() => {
                dispatch(
                  AuhMethods["usersModule"].GenreListAPI({
                    token,
                    page: "0",
                    perPage: "60"
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },
  updateGenreTypeAPI: (token, data) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put("admin/user/edit_genre", data)
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));

              // dispatch(addContentType(data.data));
              setTimeout(() => {
                dispatch(
                  AuhMethods["usersModule"].GenreListAPI({
                    token,
                    page: "0",
                    perPage: "60"
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },
  deleteGenreTypeAPI: ({ token, id }) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .delete(`admin/user/delete_genre/${id}`)
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));
              setTimeout(() => {
                dispatch(
                  AuhMethods["usersModule"].GenreListAPI({
                    token: token,
                    page: "0",
                    perPage: "60"
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
            // resolve(data)
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  }
};
export default UsersModuleServicesAuth;
