import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "redux/actions/Users";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import NoRecordFound from "./NoRecordFound";
import { getDateText } from "../../../@jumbo/utils/dateHelper";
import ContentTypeTableToolbar from "./ContentTypeTableToolbar";
import ContentTypeTableHead from "./ContentTypeTableHead";
import { AuhMethods } from "services/auth";
import GenreListRow from "./GenreListRow";
import AddEditGenreList from "./AddEditGenreList";

const Genre = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [selectedName, setName] = useState([]);
  const [users, setUser] = useState([]);
  const GenreList = useSelector(state => state?.usersModule?.GenreList?.data);
  const token = useSelector(state => state.admin.authUser.access_token);
  const FetchData = useSelector(state => state?.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      AuhMethods["usersModule"].GenreListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: "",
        order: "desc"
      })
    );
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (GenreList) {
      const activity = GenreList?.map(item => {
        item.category = getDateText(item.updated_at);
        return item;
      });
      setUser(activity);
    }
  }, [GenreList]);

  const handleCloseUserDialog = () => {
    setOpenFlagDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFlagEdit = user => {
    setName(user);
    setOpenFlagDialog(true);
    setEditFlag(true);
  };

  const handleFlagAdd = user => {
    // dispatch(setCurrentUser(user));

    setOpenFlagDialog(true);
    setEditFlag(false);
  };

  const handleFlagDelete = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(
      AuhMethods["usersModule"].deleteGenreTypeAPI({
        token: token,
        id: selectedUser.id,
        page: page,
        perPage: rowsPerPage,
        order: "desc"
      })
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ContentTypeTableToolbar onFlagAdd={handleFlagAdd} />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <ContentTypeTableHead />
            <TableBody>
              {Array.isArray(users) && users.length > 0 ? (
                users.map((row, index) => (
                  <GenreListRow
                    key={index}
                    row={row}
                    onFlagEdit={handleFlagEdit}
                    onFladDelete={() => handleFlagDelete(row)}
                    isSelected={isSelected}
                  />
                ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {GenreList?.meta?.total > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 500]}
            component="div"
            count={GenreList?.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      </Paper>

      {openFlagDialog && (
        <AddEditGenreList
          open={openFlagDialog}
          onCloseDialog={handleCloseUserDialog}
          isEdit={editFlag}
          selectedData={selectedName}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={"Are you sure, you want to delete this Genre type?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default Genre;
