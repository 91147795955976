import React, { useEffect, useContext } from "react";
import Communities from "./Communities";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getWallHeight } from "../../../../@jumbo/constants/AppConstants";
import AppContext from "../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext";

const useStyles = makeStyles(theme => ({
  perfectScrollbarActives: {
    height: props => `calc(100vh - ${props.height}px)`,
    "& .Cmt-media-object": {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
      "&:hover": {
        backgroundColor: "transparent",
        transform: "none",
        boxShadow: "none",
        "& .MuiAvatar-root": {
          transform: "none",
          boxShadow: "none"
        }
      }
    }
  },
  titleRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    paddingBottom: 24,
    marginBottom: 24,
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      zIndex: 1,
      width: 36,
      height: 4,
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const Activities = userId => {
  const { showFooter } = useContext(AppContext);
  const classes = useStyles({
    height: getWallHeight(200, showFooter)
  });

  useEffect(() => {}, [userId]);

  return (
    <PerfectScrollbar className={classes.perfectScrollbarActives}>
      <Communities userId={userId?.userId} />
    </PerfectScrollbar>
  );
};

export default Activities;
