import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MoreHoriz, Close, Done } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box } from "@material-ui/core";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CmtVideo from "@coremat/CmtVideo";
import { IMAGE_URL } from "constant/constant";

const UserListRow = ({ row, isSelected, page, idx, changeStatus }) => {
  // *********** option for Page ***********************
  const getUserActions = () => {
    const actions = [
      { action: "View", label: "View", icon: <VisibilityIcon /> }
    ];
    if (row.is_verify === "0") {
      actions.push(
        {
          action: "Approve",
          label: "Approve",
          icon: <Done />
        },
        { action: "rejected", label: "REJECT", icon: <Close /> }
      );
    }

    return actions;
  };

  const onUserMenuClick = (menu, hdsg, row) => {
    // console.log("row ------------- id", menu, hdsg, row);
    changeStatus(menu.action, menu.label, hdsg.id, hdsg);
  };

  const labelId = `enhanced-table-checkbox-${row.user_id}`;
  const isItemSelected = isSelected(row.user_id);
  const userActions = getUserActions(row.status, row.verified);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.user_id}
      selected={isItemSelected}
    >
      <TableCell>{page * 10 + idx + 1}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          {row.image_url && (
            <Box mr={{ xs: 4, md: 5 }}>
              <CmtAvatar
                size={40}
                src={`${IMAGE_URL + row?.image_url}`}
                alt={row.name}
              />
            </Box>
          )}
          {row.video_url && (
            <Box>
              <CmtVideo
                size={10}
                height={"50px"}
                width={"70px"}
                src={`${IMAGE_URL + row?.video_url}`}
                alt={row.name}
                keyAuto={false}
              />
            </Box>
          )}
          {/* <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.name === null ? "N/A" : row.name}
            </Typography>
          </div> */}
        </Box>
      </TableCell>
      <TableCell>{row.user_id}</TableCell>
      {/* <TableCell>{row.influencerName}</TableCell>
      <TableCell>{row.category}</TableCell> */}
      <TableCell>{row.count_view}</TableCell>
      <TableCell>
        {row.is_verify === "1" && row.pay_type === "1"
          ? "Paid"
          : row.is_verify === "1" && row.status === "2"
          ? "Paid"
          : row.is_verify === "1" && row.status === "0"
          ? "Pending"
          : row.is_verify === "2"
          ? "--"
          : "N/A"}
      </TableCell>
      <TableCell>
        {row.is_verify === "1" && row.pay_type === "1"
          ? "INR"
          : row.is_verify === "1" && row.status === "2"
          ? "INR + TARAL"
          : row.is_verify === "1" && row.status === "0"
          ? "Pending"
          : row.is_verify === "2"
          ? "--"
          : "N/A"}
      </TableCell>
      <TableCell>
        {row.is_verify === "0"
          ? "Pending"
          : row.is_verify === "1"
          ? "Verified"
          : row.is_verify === "2"
          ? "Rejected"
          : "N/A"}
      </TableCell>
      <TableCell>{moment(row.created_at).format("lll")}</TableCell>

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
          row={row}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
