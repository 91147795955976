import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import useStyles from "./index.style";

const TrenchesToolbar = ({ setOpenAdd }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          Tranches
        </Typography>

        <div>
          <Button
            variant="contained"
            color="secondary"
            style={{ width: "150px" }}
            onClick={() => setOpenAdd(true)}
          >
            Add Tranche
          </Button>
        </div>
      </Toolbar>
    </React.Fragment>
  );
};

TrenchesToolbar.propTypes = {};

export default React.memo(TrenchesToolbar);
