import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import UserInfo from './UserInfo';
import PropTypes from 'prop-types';

const CommentItem = ({ item, classes, itemType }) => {
  // const [isLiked, setIsLiked] = useState(item.liked); //todo
  // useEffect(() => {}, [item]);
  return (
    <Box mb={6}>
      {itemType === 'comments' && <UserInfo user={item?.commented_user_data} date={item?.created_at} classes={classes} />}
      {(itemType === 'likes' || itemType === 'dislikes') && (
        <UserInfo user={item?.like_user_data} date={item?.created_at} classes={classes} />
      )}
      {/* {itemType === 'dislikes' && <UserInfo user={item.like_user_data} date={item.created_at} classes={classes} />}  */}
      <Box ml={14}>
        {itemType === 'comments' && (
          <Box mt={2} component="p" color="text.secondary">
            {item.comment}
          </Box>
        )}
        {/* <Box mt={3} display="flex" alignItems="center">
          <Button size="small" variant="contained" color="primary" onClick={() => setIsLiked(!isLiked)}>
            {isLiked ? 'Unlike' : 'Like'}
          </Button>
          <Box ml={2.5}>
            <Button size="small" variant="contained" color="secondary">
              Comment
            </Button>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default CommentItem;

CommentItem.prototype = {
  item: PropTypes.object.isRequired,
};
