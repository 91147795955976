import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import axios from "axios";
import { API_URL } from "constant/constant";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const CreateWhiteList = ({
  open,
  onCloseDialog,
  score,
  setScore,
  row,
  WTUpdate,
  setWTUpdate,
  isEditScore
}) => {
  const classes = useStyles();
  const currentUser = useSelector(
    ({ usersReducer }) => usersReducer?.currentUser
  );
  const token = useSelector(state => state.admin.authUser.access_token);
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  const handledCreateWhiteListScore = async () => {
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "POST",
        url: `${API_URL}admin/whiteList/create`,

        headers: {
          Authorization: token
        },
        data: {
          id: row?.user_id,
          score: score
        }
      })
        // return res;
        .then(({ data }) => {
          dispatch(fetchSuccess(data.message));
          setScore("");
          onCloseDialog();
          setWTUpdate(!WTUpdate);
          if (data.message) {
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };
  const handledEditScore = async () => {
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "POST",
        url: `${API_URL}admin/whiteList/create`,

        headers: {
          Authorization: token
        },
        data: {
          id: row?.user_id,
          score: score
        }
      })
        // return res;
        .then(({ data }) => {
          dispatch(fetchSuccess(data.message));
          setScore("");
          onCloseDialog();
          setWTUpdate(!WTUpdate);
          if (data.message) {
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentUser ? "Edit User Details" : "Create New Whitelist"}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        ></Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant="outlined"
            type="number"
            label="Enter User Score"
            value={score}
            onChange={e => {
              setScore(e.target.value);
              setEmailError("");
            }}
            helperText={emailError}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (isEditScore) {
                  handledCreateWhiteListScore();
                } else {
                  handledEditScore();
                }
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateWhiteList;

CreateWhiteList.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
