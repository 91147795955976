import React, { useEffect, useState } from 'react';
import Stories from 'react-insta-stories';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import useStyles from '../../../../routes/UserModules/Chat/MediaViewer.style';
import { IMAGE_URL } from 'constant/constant';
import CmtImage from '@coremat/CmtImage';

let ddf = [
  {
    url: 'https://picsum.photos/100/100',
    duration: 5000,
    header: {
      heading: 'Mohit Karekar',
      subheading: 'Posted 30m ago',
      profileImage: 'https://picsum.photos/100/100',
    },
  },
];
const renderRow = (data, index) => {
  if (data?.metaData?.type.startsWith('image') || data?.metaData?.post_type.startsWith('image')) {
    return <CmtImage key={index} src={data?.preview} alt={data?.name} />;
  } else {
    return (
      <Box className="embed-responsive">
        <iframe key={index} src={data?.preview || IMAGE_URL + data?.url} title={data?.name} />
      </Box>
    );
  }
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContentStory = ({ position, medias, handleClose }) => {
  const [viewer, setViewer] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (position > -1) setOpen(true);
    else {
      setOpen(false);
    }
  }, [position]);

  return (
    <Dialog className={classes.dialogRoot} fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
      <Box className={classes.mediaViewerRoot}>
        <IconButton className={classes.cancelBtn} onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        {position >= 0 ? (
          <Box className={classes.carouselRoot} style={{ display: 'flex', alignItems: 'center' }}>
            {/* <CmtCarousel
              settings={{
                dots: false,
                initialSlide: position,
                arrows: true,
                infinite: false,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
              }}
              slickGoTo={position}
              style={{ width: '100%' }}
              data={medias}
              renderRow={renderRow}
            /> */}
            {/* {Array.isArray(medias) && ( */}
            <Stories
              //   type="video"
              stories={medias}
              defaultInterval={1500}
              width={500}
              height={768}
              onAllStoriesEnd={handleClose}
            />
            {/* )} */}
          </Box>
        ) : null}
      </Box>
    </Dialog>
  );
};

export default ContentStory;
