import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import React from "react";

const headCells = [
  {
    id: "Sr No",
    numeric: false,
    disablePadding: false,
    label: "Sr No",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "User Name",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Plan Name",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Pay Type",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "online_offline_business",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
  },
  // {
  //   id: "interested_for_advertise_business_page",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Intrusted for advertise"
  // },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

function UserTableHead({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const onSortOrderChange = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className="like-padding"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

UserTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default React.memo(UserTableHead);
