import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
} from "@material-ui/core";
import UserTableToolbar from "./UserTableToolbar";
import UserTableHead from "./UserTableHead";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
// import UserTableToolbar from "./UserTableToolbar";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtAvatar from "@coremat/CmtAvatar";
import { useDropzone } from "react-dropzone";
import Button from "@material-ui/core/Button";
import CmtList from "@coremat/CmtList";
import IconButton from "@material-ui/core/IconButton";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { emailNotValid, requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { isValidEmail } from "@jumbo/utils/commonHelper";
import { addNewUser, updateUser } from "redux/actions/Users";
import { inherits } from "util";
import { Typography } from "@material-ui/core";
import { AuhMethods } from "services/auth";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
// import RejectDialog from "../RejectDialog";
import { usersModule } from "@fake-db";
import { useHistory } from "react-router-dom";
// import ConfirmDialog from 'commanComponent/confirm';
import axios from "axios";
import moment from "moment";
import { API_URL } from "constant/constant";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import NoRecordFound from "../../../BusinessPage/PageList/NoRecordFound";

// import { useHistory } from "react-router-dom";
// import { AuhMethods } from "services/auth";
import UserDashboard from "./UserDashboard";
import UserFilter from "./UserFilter";
import { MoreVertIcon } from "@material-ui/icons/MoreVert";

const dailyData = [
  { name: "Total Subscription", amount: "123" },
  { name: "Total Active Subscription", amount: "123" },
  { name: "Total Inactive Subscription", amount: "123" },
  // { name: "Total Admin Page", amount: "23" },
];
const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "relative",
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = (number) => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return (
    <NumberFormat
      {...other}
      onValueChange={onNumberChange}
      value={phoneNumber}
      format="(###) ###-####"
    />
  );
}

const labels = [
  { title: "Posts", slug: "posts" },
  { title: "Likes", slug: "likes" },
  { title: "Comments", slug: "comments" },
];

const FundType = [
  { title: "Locked", type: "locked" },
  { title: "Unlocked", type: "unlocked" },
];

const splitName = (user) => {
  if (user) {
    const [fName, mName, lName] = user.name.split(" ");
    return [fName, lName ? mName + " " + lName : mName];
  }

  return ["", ""];
};
const BostPlanHistory = () => {
  const classes = useStyles();
  const history = useHistory();
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [from_date, handleFromChange] = useState();
  const [to_date, handleToChange] = useState();
  const [status, setstatus] = useState();
  const FetchData = useSelector((state) => state.common);
  // console.log(FetchData, "selectedUser ------ selectedUser", selectedUser);
  const [boostPlanList, setboostPlanList] = React.useState([]);
  // const [searchText, setSearchText] = React.useState("");
  const [pageListMeta, setpageListMeta] = React.useState(10);
  const token = useSelector((state) => state.admin.authUser.access_token);
  const dispatch = useDispatch();

  const handleSearchTextChange = (e) => {
    let data = e.target.value.replace(/^\s/, "");
    setSearchText(data);
    // dispatch(AuhMethods[method].usersListAPI({ token: token, page: page, perPage: rowsPerPage, keyword: searchText })); //token, page, perPage, keyword
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleUserEdit = (user) => {
    // dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleRowClick = (event, id) => {
    history.push({
      pathname: "/profile",
      // state: { id: id },
    });
  };

  const handleChangeStatus = (action, label, id, row) => {
    let data = {
      action: action,
      label: label,
      id: id,
      row: row,
    };
    setSelectedUser(data);
    // setOpenConfirmDialog(true);
    if (label == "View" || label == "Edit") {
      history.push({
        pathname: "/add-subscription",
        search: id.toString(),
        hash: label,
      });
    }
    if (label == "Disable") {
      setOpenConfirmDialog(true);
    }
    if (label == "Enable") {
      setOpenConfirmDialog(true);
    }
  };

  const handleUserView = (user) => {
    // setSelectedUser(user);
    // setOpenVerificationDialog(true);
  };

  const onVerify = (data) => {
    // setVerifyToggle(true);
    // setSelectedUser(data);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const HandleBoostPlanAPI = async ({
    token,
    page,
    perPage,
    keyword,
    order,
    from_date,
    to_date,
    status,
  }) => {
    const pageData = page != undefined ? page + 1 : "";
    keyword = keyword != undefined ? keyword : "";
    const statusData = status != undefined ? "&status=" + status : "";
    from_date =
      from_date != undefined
        ? "&from_date=" + moment(from_date).format("YYYY-MM-DD")
        : "";
    to_date =
      to_date != undefined
        ? "&to_date=" + moment(to_date).format("YYYY-MM-DD:23:59:59")
        : "";
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "GET",
        url:
          `${API_URL}admin/boost_plan/subscription_plan_list?order=${order}&` +
          statusData +
          from_date +
          to_date,
        headers: {
          Authorization: token,
        },
        params: {
          keyword,
          perPage,
          page: pageData,
        },
      })
        // return res;
        .then(({ data }) => {
          // //console.log("data -----", data);
          if (data.data) {
            dispatch(fetchSuccess());
            setboostPlanList(data.data);
            setpageListMeta(data.meta);
            // dispatch(setUsersListData(data));
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  useEffect(() => {
    HandleBoostPlanAPI({
      token: token,
      page: page,
      perPage: rowsPerPage,
      keyword: searchText,
      order,
      from_date,
      to_date,
      status,
    });
  }, [page, rowsPerPage, searchText, from_date, to_date, status]);

  const handleConfirmDelete = async (e) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }

    let data = {
      id: selectedUser.id,
      action: selectedUser?.row?.status == "1" ? 0 : 1,
    };
    // console.log(selectedUser.id, " ---- selectedUser ----", selectedUser);
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "PUT",
        url: `${API_URL}admin/boost_plan/change_status`,
        headers: {
          Authorization: token,
        },
        data,
        // params: {
        // keyword,
        // perPage,
        // page: pageData,
        // },
      })
        // return res;
        .then(({ data }) => {
          // console.log("data ----- Testing", data);
          if (data.message) {
            dispatch(fetchSuccess(data.message));
            setOpenConfirmDialog(false);
            HandleBoostPlanAPI({
              token: token,
              page: page,
              perPage: rowsPerPage,
              keyword: searchText,
              order,
              from_date,
              to_date,
              status,
            });
            // setboostPlanList(data.data);
            // setpageListMeta(data.meta);
            // // dispatch(setUsersListData(data));
          } else {
            setOpenConfirmDialog(false);
            dispatch(fetchError(data.message));
            HandleBoostPlanAPI({
              token: token,
              page: page,
              perPage: rowsPerPage,
              keyword: searchText,
              order,
              from_date,
              to_date,
              status,
            });
          }
        });
    } catch (error) {
      setOpenConfirmDialog(false);
      dispatch(fetchError(error.message));
    }

    setOpenConfirmDialog(false);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div>
      <Paper className={classes.paper}>
        <UserTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={pageListMeta?.total}
          from_date={from_date}
          handleFromChange={handleFromChange}
          to_date={to_date}
          handleToChange={handleToChange}
          status={status}
          setstatus={setstatus}
        />
        {/* <UserDashboard
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={pageListMeta}
          dailyData={dailyData}
        /> */}
        {/* <UserFilter
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={"12"}
          actionHandleIcon={<MoreVertIcon />}
          actions={""}
          actionHandler={""}
          from_date={from_date}
          handleFromChange={handleFromChange}
          to_date={to_date}
          handleToChange={handleToChange}
          status={status}
          setstatus={setstatus}
        /> */}
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <UserTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={boostPlanList.length}
            />
            <TableBody>
              {boostPlanList.length > 0 ? (
                <>
                  {boostPlanList.map((row, index) => (
                    <UserListRow
                      key={index}
                      page={page}
                      idx={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onUserEdit={handleUserEdit}
                      changeStatus={handleChangeStatus}
                      onUserView={handleUserView}
                      isSelected={isSelected}
                      onVerify={onVerify}
                    />
                  ))}
                </>
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={pageListMeta?.total}
          rowsPerPage={10}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={10}
        />
      </Paper>

      {/* openConfirmDialog */}
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${
          selectedUser?.row?.status == "1" ? "Disable" : "Enable"
        } ${selectedUser?.row?.name ? selectedUser?.row?.name : ""}`}
        content={`Are you sure, you want to ${
          selectedUser?.row?.status == "1" ? "disable" : "enable"
        } this plan?`}
        // title={`Confirm  ${selectedUser?.detail?.is_enalbed == '1' ? 'Block' : 'Unblock'}`}
        // content={`Are you sure, you want to this user?`}

        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default BostPlanHistory;

BostPlanHistory.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
