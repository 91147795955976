import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Box, Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./index.style";
import { API_URL } from "constant/constant";
import axios from "axios";
import { fetchError } from "redux/actions";
import moment from "moment";
import { DownLoadCSVFunction } from "customHook/debounse";

const UserTableToolbar = ({
  selected,
  count,
  from_date,
  handleFromChange,
  to_date,
  handleToChange
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token);
  const DownLoadeCSV = async count => {
    try {
      await axios({
        method: "GET",
        url: `${API_URL}admin/boost_plan/subscription_plan_list`,
        headers: {
          Authorization: token
        },
        params: {
          perPage: count,
          page: 1
        }
      })
        // return res;
        .then(({ data }) => {
          if (data.data) {
            let arrayOfObjects = data.data;

            if (!arrayOfObjects.length) {
              return;
            }

            DownLoadCSVFunction(arrayOfObjects, "subscription_plan_history");
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  const numSelected = selected.length;
  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h4"
            id="tableTitle"
            component="div"
          >
            Boost Plan History
          </Typography>
        )}

        <React.Fragment>
          <Box display="flex">
            <TextField
              label="Start Date"
              variant="outlined"
              size="small"
              type="date"
              style={{ marginRight: "12px" }}
              defaultValue={from_date && from_date}
              onChange={e => handleFromChange(e.target.value)}
              disableFuture
              values={from_date}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              label="End Date"
              variant="outlined"
              size="small"
              type="date"
              style={{ marginRight: "12px" }}
              onChange={e => handleToChange(e.target.value)}
              disableFuture
              values={moment(to_date).format("lll")}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>
          <div>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: "260px" }}
              onClick={() => DownLoadeCSV(count)}
            >
              Download CSV File
            </Button>
          </div>
        </React.Fragment>
      </Toolbar>
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchText: PropTypes.string,
  handleSearchTextChange: PropTypes.func,
  onUserAdd: PropTypes.func
};

export default React.memo(UserTableToolbar);
