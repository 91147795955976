import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import React from "react";

const headCells = [
  {
    id: "trenchName",
    numeric: false,
    disablePadding: false,
    label: "Trench Name"
  },
  {
    id: "thresHoldValue",
    numeric: false,
    disablePadding: false,
    label: "Threshold Amount"
  },
  {
    id: "rewardAmount",
    numeric: false,
    disablePadding: false,
    label: "Reward Amount"
  }
];

function TrenchesTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="left">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default TrenchesTableHead;
