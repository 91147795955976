import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtAvatar from "@coremat/CmtAvatar";
import { useDropzone } from "react-dropzone";
import Button from "@material-ui/core/Button";
import CmtList from "@coremat/CmtList";
import IconButton from "@material-ui/core/IconButton";
// import Switch from '@mui/material/Switch';
// import { styled } from '@mui/material/styles';

import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { emailNotValid, requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { isValidEmail } from "@jumbo/utils/commonHelper";
import { addNewUser, updateUser } from "redux/actions/Users";
import { inherits } from "util";
import { AuhMethods } from "services/auth";
import { Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { toTitleCase } from "@jumbo/utils/dateHelper";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "relative",
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

// const IOSSwitch = styled(() => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple />)(({ theme }) => ({
//   width: 42,
//   height: 26,
//   padding: 0,
//   '& .MuiSwitch-switchBase': {
//     padding: 0,
//     margin: 2,
//     transitionDuration: '300ms',
//     '&.Mui-checked': {
//       transform: 'translateX(16px)',
//       color: '#fff',
//       '& + .MuiSwitch-track': {
//         backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
//         opacity: 1,
//         border: 0,
//       },
//       '&.Mui-disabled + .MuiSwitch-track': {
//         opacity: 0.5,
//       },
//     },
//     '&.Mui-focusVisible .MuiSwitch-thumb': {
//       color: '#33cf4d',
//       border: '6px solid #fff',
//     },
//     '&.Mui-disabled .MuiSwitch-thumb': {
//       color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
//     },
//     '&.Mui-disabled + .MuiSwitch-track': {
//       opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
//     },
//   },
//   '& .MuiSwitch-thumb': {
//     boxSizing: 'border-box',
//     width: 22,
//     height: 22,
//   },
//   '& .MuiSwitch-track': {
//     borderRadius: 26 / 2,
//     backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
//     opacity: 1,
//     transition: theme.transitions.create(['background-color'], {
//       duration: 500,
//     }),
//   },
// }));

const AddEditContentType = ({
  open,
  onCloseDialog,
  isedit,
  selectedId,
  selectedName,
  page,
  perPage,
  order,
  setValue,
  setDes,
  Active,
  setverifyvalue,
  setinfluencervalue,
}) => {
  const classes = useStyles();
  //   const currentUser = useSelector(({ usersReducer }) => usersReducer.currentUser);
  const currentUser = {};
  const [settingValue, setSettingValue] = useState(isedit ? setValue : "");
  const [previousSettingValue, setPreviousSettingValue] = useState(setValue);

  const [verify_user_value, setverify_user_value] = useState(
    isedit ? setverifyvalue : ""
  );
  const [influencer_user_value, setinfluencer_user_value] = useState(
    isedit ? setinfluencervalue : ""
  );
  const [amount, setAmount] = useState("");
  const [settingName, setSettingName] = useState("");
  const [description, setDescription] = useState(isedit ? setDes : "");
  const [descriptionError, setDescriptionError] = useState("");
  const [status, setStatus] = useState(Active);
  const [firstNameError, setFirstNameError] = useState("");
  const [valueError, setValueError] = useState("");
  const [colour, setColour] = useState(
    Active === 1 ? "primary" : "textSecondary"
  );
  const [InactiveColor, setColourInactive] = useState(
    Active === 0 ? "primary" : "textSecondary"
  );
  const token = useSelector((state) => state.admin.authUser.access_token); //Fetch data from Redux

  const dispatch = useDispatch();

  const handleStatus = (value) => {
    setStatus(value);
    setColour("primary");
    setColourInactive("textSecondary");
  };
  const handleStatusInactive = (value) => {
    setStatus(value);
    setColourInactive("primary");
    setColour("textSecondary");
  };

  const onSubmitClick = () => {
    if (!settingValue || settingValue.length === 0) {
      setFirstNameError(requiredMessage);
    } else {
      if (isedit) {
        let params = {
          key_name: selectedName,
          value: settingValue,
          influencer_user_value: influencer_user_value,
          verify_user_value: verify_user_value,
          description: description,
          status: status,
        };
        dispatch(
          AuhMethods["AppSettings"].addOrEditSettingTypeAPI(
            token,
            "",
            params,
            page,
            perPage,
            order
          )
        );
        setSettingValue("");
      } else {
        let params = {
          key_name: settingName,
          value: settingValue,
          // influencer_user_value: influencer_user_value,
          // verify_user_value: verify_user_value,
        };
        dispatch(
          AuhMethods["AppSettings"].addOrEditSettingTypeAPI(
            token,
            "",
            params,
            page,
            perPage,
            order
          )
        );
        setSettingValue("");
      }

      onCloseDialog();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      isedit={isedit}
      className={`${classes.dialogRoot} dialogCustom`}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {" "}
        <span>{isedit ? "Edit Setting" : "Create Setting"}</span>
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {isedit ? (
        <DialogContent dividers style={{ width: "350px" }}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            mb={{ xs: 6, md: 5 }}
          />

          <Box display="flex" justifyContent="flax-start" mb={4}>
            <Typography
              className={classes.title}
              variant="h5"
              id="tableTitle"
              component="div"
              style={{ marginBottom: 20 }}
            >
              Setting Type: {toTitleCase(selectedName)}
            </Typography>
          </Box>
          <Box mb={{ xs: 6, md: 5 }}>
            <AppTextInput
              style={{ marginBottom: 12 }}
              fullWidth
              variant="outlined"
              label="Value"
              type="Number"
              value={settingValue}
              onChange={(e) => {
                let tt = e.target.value.trim();
                if (tt) {
                  setSettingValue(e.target.value);
                  setFirstNameError("");
                } else {
                  setSettingValue(e.target.value);
                  if (e.target.value == "") {
                    setFirstNameError("This field must be  number");
                  } else {
                    setFirstNameError("No white space allowed");
                  }
                }
              }}
              helperText={firstNameError}
            />
            {verify_user_value && (
              <AppTextInput
                style={{ marginBottom: 12 }}
                fullWidth
                variant="outlined"
                label="Verify user value"
                type="Number"
                value={verify_user_value}
                onChange={(e) => {
                  let tt = e.target.value.trim();
                  if (tt) {
                    setverify_user_value(e.target.value);
                    setFirstNameError("");
                  } else {
                    setverify_user_value(e.target.value);
                    if (e.target.value == "") {
                      setFirstNameError("This field must be  number");
                    } else {
                      setFirstNameError("No white space allowed");
                    }
                  }
                }}
                helperText={firstNameError}
              />
            )}
            {influencer_user_value && (
              <AppTextInput
                style={{ marginBottom: 12 }}
                fullWidth
                variant="outlined"
                label="Influencer user value"
                type="Number"
                value={influencer_user_value}
                onChange={(e) => {
                  let tt = e.target.value.trim();
                  if (tt) {
                    setinfluencer_user_value(e.target.value);
                    setFirstNameError("");
                  } else {
                    setinfluencer_user_value(e.target.value);
                    if (e.target.value == "") {
                      setFirstNameError("This field must be  number");
                    } else {
                      setFirstNameError("No white space allowed");
                    }
                  }
                }}
                helperText={firstNameError}
              />
            )}
          </Box>

          <Box
          // justifyContent="flex-end"
          //  mb={4} style={{ marginBottom: 32 }}
          >
            {/* <GridContainer
            // style={{ marginBottom: 12, marginLeft: 0, marginRight: 0 }}
            > */}
            <Grid
              mb={{ xs: 6, md: 10 }}
              style={{ width: "100%", height: "100px" }}
            >
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Description..."
                fullWidth
                type="Number"
                style={{ width: "100%", padding: "16px", height: "71px" }}
                label="description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setDescriptionError("");
                }}
                helperText={valueError}
              />
            </Grid>
            {/* </GridContainer> */}
          </Box>

          <Box display="flex" justifyContent="flax-start" mb={4}>
            <Typography
              className={classes.title}
              variant="h5"
              id="tableTitle"
              component="div"
              style={{ marginBottom: 20 }}
            >
              Change Status
            </Typography>
          </Box>

          <Box
          // justifyContent="flex-end"
          //  mb={4} style={{ marginBottom: 32 }}
          >
            {/* <GridContainer
            // style={{ marginBottom: 12, marginLeft: 0, marginRight: 0 }}
            > */}
            <Grid
              mb={{ xs: 6, md: 10 }}
              style={{ width: "100%", height: "100px", marginLeft: "2px" }}
            >
              <Button
                color={InactiveColor}
                variant="contained"
                onClick={() => handleStatusInactive(0)}
                style={{ marginRight: "10px" }}
              >
                Inactive{" "}
              </Button>
              <Button
                color={colour}
                variant="contained"
                onClick={() => handleStatus(1)}
              >
                Active
              </Button>
            </Grid>
            {/* </GridContainer> */}
          </Box>

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                // disabled={settingValue === previousSettingValue || settingValue === '' || firstNameError}
                disabled={settingValue === "" || firstNameError}
                onClick={onSubmitClick}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent dividers>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            mb={{ xs: 6, md: 5 }}
          />
          <Box mb={{ xs: 6, md: 5 }}>
            <AppTextInput
              style={{ marginBottom: 12 }}
              fullWidth
              variant="outlined"
              label="Name"
              value={settingName}
              onChange={(e) => {
                let tt = e.target.value.trim();
                if (tt) {
                  setSettingName(e.target.value);
                  setFirstNameError("");
                } else {
                  setSettingName(e.target.value);
                  if (e.target.value == "") {
                    setFirstNameError("This field is required");
                  } else {
                    setFirstNameError("No white space allowed");
                  }
                }
              }}
              helperText={firstNameError}
            />
          </Box>
          <Box mb={{ xs: 6, md: 5 }}>
            <AppTextInput
              style={{ marginBottom: 12 }}
              fullWidth
              variant="outlined"
              label="Value"
              type="Number"
              value={settingValue}
              onChange={(e) => {
                let tt = e.target.value.trim();
                if (tt) {
                  setSettingValue(e.target.value);
                  setFirstNameError("");
                } else {
                  setSettingValue(e.target.value);
                  if (e.target.value == "") {
                    setFirstNameError("This field is required");
                  } else {
                    setFirstNameError("No white space allowed");
                  }
                }
              }}
              helperText={firstNameError}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  (settingName === "" && settingValue === "") || firstNameError
                }
                onClick={onSubmitClick}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AddEditContentType;

AddEditContentType.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
