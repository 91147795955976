import React, { useContext, useEffect } from "react";
import CoverPic from "./CoverPic";
import Box from "@material-ui/core/Box";
import SocialStats from "./SocialStats";
import FollowingInfo from "./FollowingInfo";
import Interests from "./Interests";
import Friends from "./Friends";
import Pictures from "./Pictures";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getWallHeight } from "@jumbo/constants/AppConstants";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppContext from "@jumbo/components/contextProvider/AppContextProvider/AppContext";
import { user } from "@fake-db/apps/wall";
import Biography from "./Biography";

const useStyles = makeStyles(() => ({
  perfectScrollbarGerneralInfo: {
    height: props => `calc(100vh - ${props.height}px)`
  }
}));

const GeneralInfo = width => {
  const pageProfileData = useSelector(
    state => state?.businessPageManagement?.userProfileData
  );

  const { showFooter } = useContext(AppContext);

  const userDetail = user;
  const classes = useStyles({
    height: getWallHeight(width, showFooter)
  });

  return (
    <PerfectScrollbar className={classes.perfectScrollbarGerneralInfo}>
      {pageProfileData && (
        <Box>
          <Box mb={11}>
            <CoverPic
              userDetail={pageProfileData}
              cover_pic={userDetail?.cover_pic}
            />
          </Box>
          <Box mb={5}>
            <SocialStats userDetail={pageProfileData} />
          </Box>
          <Box mb={5}>
            <FollowingInfo userDetail={pageProfileData} />
          </Box>
          <Box mb={5}>
            <Biography userDetail={pageProfileData} />
          </Box>
        </Box>
      )}
    </PerfectScrollbar>
  );
};

export default GeneralInfo;
