import React, { useEffect, useState } from "react";
import { TextField, TextareaAutosize, Checkbox } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ContentLoader from "@jumbo/components/ContentLoader";
import AuthWrapper from "@jumbo/components/Common/authComponents/AuthWrapper";
// import { AuhMethods } from "services/auth";
import { alpha } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtAvatar from "@coremat/CmtAvatar";
import { useDropzone } from "react-dropzone";
import Button from "@material-ui/core/Button";
import CmtList from "@coremat/CmtList";
import IconButton from "@material-ui/core/IconButton";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { emailNotValid, requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useForm, Controller } from "react-hook-form";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { isValidEmail } from "@jumbo/utils/commonHelper";
import { addNewUser, updateUser } from "redux/actions/Users";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { AuhMethods } from "services/auth";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
// import RejectDialog from "../RejectDialog";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { usersModule } from "@fake-db";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordMisMatch } from "@jumbo/constants/ErrorMessages";
import * as yup from "yup";
import axios from "axios";
import { API_URL } from "constant/constant";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
// import ConfirmDialog from 'commanComponent/confirm';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import AssignResponsibilityCheckbox from "./AssignResponsibilityCheckbox";
const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "relative",
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  authContent: {
    padding: 30,
    "& .btnB": {
      margin: "0 15px",
    },
    "& .inputPasswordCustom1": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
    },
    [theme.breakpoints.up("md")]: {
      order: 1,
      width: (props) => (props.variant === "default" ? "50%" : "100%"),
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  textFieldRoot1: {
    maxWidth: "530px",
    minWidth: "330px",
    height: "fit-content !important",
    minHeight: "230px",
    width: "100%",
    padding: "15px",
    borderColor: alpha(theme.palette.common.dark, 0.12),
    // "& .MuiOutlinedInput-notchedOutline": {

    // },
  },
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);
  // useEffect(() => {
  // }, []);

  const onNumberChange = (number) => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return (
    <NumberFormat
      {...other}
      onValueChange={onNumberChange}
      value={phoneNumber}
      format="(###) ###-####"
    />
  );
}

const labels = [
  { title: "Posts", slug: "posts" },
  { title: "Likes", slug: "likes" },
  { title: "Comments", slug: "comments" },
];

const FundType = [
  { title: "Locked", type: "locked" },
  { title: "Unlocked", type: "unlocked" },
];

const splitName = (user) => {
  if (user) {
    const [fName, mName, lName] = user.name.split(" ");
    return [fName, lName ? mName + " " + lName : mName];
  }

  return ["", ""];
};

const schema = yup.object().shape({
  name: yup
    .string()
    .required("This field is required.")
    .min(4, "Must Contain 4 Characters, "),
  email: yup
    .string()
    .email("Please insert valid email")
    .required("Email required."),
  // .min(4, "Must Contain 4 Characters, "),
  // phone: yup.string().required("This field is required."),
  // .min(4, "Must Contain 4 Characters, "),
  // description: yup
  //   .string()
  //   .required("This field is required.")
  //   .min(8, "Must Contain 8 Characters ")
  //   .max(256, "Must be less than 256 Characters"),
  // featureList: yup.string().required("This field is required."),
  // planAmount: yup.string().required("This field is required."),
  // phone: yup.string().required("This field is required."),
  // planAmountInTaral: yup.string().required("This field is required."),
});
const permissions = [
  {
    id: "1",
    title: "Dashboard",
  },
  {
    id: "2",
    title: "User Management",
  },
  {
    id: "3",
    title: "Business Page Management",
  },
  {
    id: "4",
    title: "ICO Management",
  },
  {
    id: "5",
    title: "Plan Management",
  },
  {
    id: "6",
    title: "Advertisement Management",
  },
  {
    id: "7",
    title: "Influencer Management",
  },
  {
    id: "8",
    title: "Reward Management",
  },
  {
    id: "9",
    title: "Flag Report Management",
  },
  {
    id: "10",
    title: "Content Management",
  },
  {
    id: "11",
    title: "Wallet Management",
  },
  {
    id: "12",
    title: "Account Setting",
  },
  {
    id: "13",
    title: "App Setting",
  },
  {
    id: "14",
    title: "Subadmin Management",
  },
];
const AddBostSubscription = () => {
  const classes = useStyles();
  //   const currentUser = useSelector(({ usersReducer }) => usersReducer.currentUser);
  const currentUser = {};

  let types = window.location;

  const type = types.hash.split("#")[1];
  const idds = types.search.split("?")[1];

  const [boostPlan, setboostPlan] = useState("");
  const [permitionList, setPermitionList] = useState([]);
  const [permitions, setpermitions] = useState([]);
  // console.log("set permitions---------- permitions", permitions);
  const [phones, setPhones] = useState([{ phone: "", label: "home" }]);
  const [phoneError, setPhoneError] = useState("");
  const token = useSelector((state) => state.admin.authUser.access_token);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
    },
    resolver: yupResolver(schema),
  });
  const history = useHistory();

  const dispatch = useDispatch();

  // const onSubmit = async (data) => {
  //   // if (type !== "Edit") {
  //     AddNewBoost(data);
  //   // } else {
  //   //   UpdateNewBoost(data);
  //   // }
  // };
  const UpdateNewBoost = async (data) => {
    // const pageData = page != undefined ? page + 1 : "";
    // const keywordData = keyword != undefined ? keyword : "";
    console.log(data, "tokens ------CreateBoostPlanAPI", token);
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "PUT",
        url: `${API_URL}admin/boost_plan/update`,
        headers: {
          Authorization: token,
        },
        data: {
          id: idds,
          name: data.name,
          description: data.description,
          total_count: data.featureList,
          total_inr: data.planAmount,
          inr: data.phone,
          taral: data.planAmountInTaral,
        },
        // params: {
        //   keyword,
        //   perPage,
        //   page: pageData,
        // },
      })
        // return res;
        .then(({ data }) => {
          //console.log("data -----", data);
          if (data.data) {
            dispatch(fetchSuccess());
            history.goBack();
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  const onSubmit = async (data) => {
    // const pageData = page != undefined ? page + 1 : "";
    // const keywordData = keyword != undefined ? keyword : "";
    // console.log(data, "tokens ------CreateBoostPlanAPI");
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "POST",
        url: `${API_URL}admin/subadmin/add`,
        headers: {
          Authorization: token,
        },
        data: {
          name: data.name,
          email: data.email,
          permissions: permitions,
          url: window.location.origin,
          // total_inr: data.planAmount,
          // inr: data.phone,
          // taral: data.planAmountInTaral,
        },
        // params: {
        //   keyword,
        //   perPage,
        //   page: pageData,
        // },
      })
        // return res;
        .then(({ data }) => {
          //console.log("data -----", data);
          if (data.message) {
            dispatch(fetchSuccess(data.message));
            history.goBack();
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };

  useEffect(() => {
    if (currentUser) {
      // const [fName, lName] = splitName(currentUser);
      // setFirstName(fName);
      // setLastName(lName);
      // setProfile_pic(currentUser.profile_pic);
      // setEmail(currentUser.email);
      // setCompany(currentUser.company);
      // setDesignation(currentUser.designation);
      setPhones(currentUser.phones);
    }
  }, [currentUser]);

  const onPhoneNoAdd = (number, index) => {
    const updatedList = [...phones];
    updatedList[index].phone = number;
    setPhones(updatedList);
    setPhoneError("");
  };

  const onPhoneRowRemove = (index) => {
    const updatedList = [...phones];
    updatedList.splice(index, 1);
    setPhones(updatedList);
  };

  const onPhoneRowAdd = () => {
    setPhones(phones.concat({ phone: "", label: "home" }));
  };

  // const onPhoneNoAdd = (number, index) => {
  //   const updatedList = [...phones];
  //   updatedList[index].phone = number;
  //   setPhones(updatedList);
  //   setPhoneError('');
  // };
  const ViewAPIHandlet = async (data) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      // const res = await axios({
      //   method: "GET",
      //   url: `${API_URL}admin/permission/list`,
      //   // headers: {
      //   //   Authorization: token,
      //   // },
      //   // params: {
      //   //   id: data,
      //   // },
      // })
      const res = await axios
        .get(`${API_URL}admin/permission/list`)
        // return res;
        .then(({ data }) => {
          console.log("data -----", data);
          if (data.data) {
            dispatch(fetchSuccess());
            setPermitionList(data.data);
            // setValue("description", data.data.description);
            // setValue("featureList", data.data.total_count);
            // setValue("planAmount", data.data.total_inr);
            // setValue("phone", data.data.inr);
            // setValue("planAmountInTaral", data.data.taral);
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  useEffect(() => {
    ViewAPIHandlet();
  }, []);
  console.log("window.location--------", window.location);
  return (
    <AuthWrapper variant={"100%"}>
      <div>
        <Box className={classes.authContent}>
          <Typography
            component="div"
            variant="h1"
            className={classes.titleRoot}
          >
            Add Subadmin
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={2}>
              {/* <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                {<IntlMessages id="appModule.name" />}
              </Typography> */}
              <Controller
                name="name"
                control={control}
                render={({ value }) => {
                  return (
                    <TextField
                      disabled={type === "View"}
                      name="name"
                      label={<IntlMessages id="appModule.name" />}
                      fullWidth
                      type={"text"}
                      onChange={(event) => {
                        // setOldPassword(event.target.value);
                        setValue("name", event.target.value);
                      }}
                      value={getValues("name")}
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldRoot}
                      error={errors.name?.message}
                      helperText={errors.name?.message}
                    />
                  );
                }}
              />
              {/* <p style={{ color: "red" }}>{errors.name?.message}</p> */}
            </Box>
            <Box mb={2} className="inputPasswordCustom">
              {/* <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                {<IntlMessages id="appModule.email" />}
              </Typography> */}
              <Controller
                name="email"
                control={control}
                render={() => (
                  <TextField
                    disabled={type === "View"}
                    type={"email"}
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    value={getValues("email")}
                    onChange={(event) => {
                      // setNewPassword(event.target.value);
                      setValue("email", event.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    error={errors.email?.message}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Box>
            <Box mb={2} className="inputPasswordCustom">
              {/* <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                {<IntlMessages id="appModule.phone" />}
              </Typography> */}
              {/* <AppTextInput
                fullWidth
                variant="outlined"
                label="Phone"
                value={getValues("phone")}
                onChange={(event) => {
                  // setNewPassword(event.target.value);
                  setValue("phone", event.target.value);
                }}
                // onChange={(number) => onPhoneNoAdd(number, 12)}
                helperText={phoneError}
                InputProps={{
                  inputComponent: PhoneNumberInput,
                  inputProps: { value: getValues("phone") },
                }}
              /> */}
              {/* <Controller
                name="phone"
                control={control}
                render={() => (
                  <TextField
                    disabled={type === "View"}
                    type={"number"}
                    label={<IntlMessages id="appModule.phone" />}
                    fullWidth
                    value={getValues("phone")}
                    onChange={(event) => {
                      // setNewPassword(event.target.value);
                      setValue("phone", event.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    error={errors.phone?.message}
                    helperText={errors.phone?.message}
                  />
                )}
              /> */}
            </Box>

            <Box mb={2} className="inputPasswordCustom">
              <Typography
                component="div"
                variant="h1"
                className={classes.titleRoot}
              >
                {<IntlMessages id="appModule.permission" />}
              </Typography>

              <Box className="inputPasswordCustom1">
                {permitionList &&
                  permitionList.map((data, index) => (
                    <div>
                      {/* <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={gilad}
                            // onChange={handleChange}
                            name={data.title}
                          />
                        }
                        label={data.title.toUpperCase()}
                      />
                    </FormGroup> */}
                      <AssignResponsibilityCheckbox
                        name={data.title}
                        label={data.title}
                        assigned={data}
                        index={index}
                        permitions={permitions}
                        setpermitions={setpermitions}
                      />
                      {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checkedB}
                            onChange={handleChange}
                            name={"checkedB"}
                          />
                        }
                        label={"Read Only"}
                      /> */}
                      <br />
                    </div>
                  ))}
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={5}
            >
              <Button
                onClick={() => history.goBack()}
                variant="contained"
                color="primary"
                className="btnB"
              >
                <IntlMessages id="appModule.backButton" />
              </Button>
              {type !== "View" && (
                <Button type="submit" variant="contained" color="primary">
                  <IntlMessages
                    id={type !== "Edit" ? "appModule.add" : "appModule.edit"}
                  />
                </Button>
              )}
            </Box>
          </form>
          <ContentLoader />
        </Box>
      </div>
    </AuthWrapper>
  );
};

export default AddBostSubscription;

AddBostSubscription.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
