import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SamplePage from "./Pages/SamplePage";
import Error404 from "./Pages/404";
import Login from "./Auth/Login";
import Dashboards from "./Dashboards";
import UsersModule from "./UserModules/Users";
import Wall from "./UserModules/Profile";
import FlagReposts from "./ReportsManagement/FlagReposts";
import PostDetail from "./ReportsManagement/PostDetail";
import FlagReportsType from "./ReportsManagement/FlagReportsType";
import ContentDetails from "./ContentManagement/ContentDetails";
import ContentTypes from "./ContentManagement/ContentTypes";
import Airdrop from "./RewardManagement/Airdrop";
import RewardManagement from "./RewardManagement/Rewards";
import WalletDetails from "./WalletModules/WalletDetails";
import RedeemHistory from "./WalletModules/RedeemHistory";
import LockFundsHistory from "./WalletModules/LockFundsHistory";
import AccountSettings from "./AccountSettings";
import UpdateEmail from "./AccountSettings/UpdateEmail";
import UpdatePassword from "./AccountSettings/UpdatePassword";
import HashTags from "./ContentManagement/HashTags";
import InterestType from "./UserModules/Interest";
import AppsSettings from "./AppSettings/Interest";
import EditContent from "./ContentManagement/UpdateContent/EditContent";
import UpdateContent from "./ContentManagement/UpdateContent";
import Genre from "./UserModules/Genre";
import Verification from "./UserModules/Verification";
import createpage from "./BusinessPage/CreatePageSection";
import taralPageDetail from "./BusinessPage/AdminBusinessPage/PageDetails";
import PageList from "./BusinessPage/PageList";
import CryptoPageList from "./CryptoPage/PageList";
import PageCategories from "./BusinessPage/PageCategories";
import CryptoPageCategories from "./CryptoPage/PageCategories";
import PageDetails from "./BusinessPage/PageDetails";
import pageVerifaction from "./BusinessPage/pageVerification";
import CryptoPageVerifaction from "./CryptoPage/pageVerification";
import IcoList from "./CryptoPage/ICOManagement/ICOList";
import TaralIcoDetails from "./CryptoPage/ICOManagement";
import VestingSetting from "./CryptoPage/ICOManagement/ICOList/VestingSetting";
import BostPlanHistory from "./CryptoPage/BostSubscription/BostPlanHistory";
import BostSubscription from "./CryptoPage/BostSubscription";
import AddBostSubscription from "./CryptoPage/BostSubscription/AddBostSubscription";
import SubAdminList from "./SubAdmin/PageList";
import LogReports from "./SubAdmin/LogReports";
import AddSubAdminList from "./SubAdmin/AddSubAdmin";
import AdvertisementList from "./AdvertisementManagement/PageList";
import AdvertisementDetails from "./AdvertisementManagement/PageDetails";
import InfluencerList from "./InfluencerManagement/PageList";
import Escrow from "./InfluencerManagement/Escrow/Escrow";
import EscrowView from "./InfluencerManagement/Escrow/EscrowView";
import InfluencerDetails from "./InfluencerManagement/PageDetails";
import UpdateVerification from "./UserModules/Verification/UpdateVerification";
import LeaderBoard from "./LeaderBoard";
import InterestManagement from "./ContentManagement/InterestManagement";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ admin }) => admin);

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ admin }) => admin);
  const location = useLocation();

  if (location.pathname === "" || location.pathname === "/") {
    return <Redirect to={"/dashboard"} />;
  } else if (authUser && location.pathname === "/signin") {
    return <Redirect to={"/dashboard"} />;
  }
  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/sample-page" component={SamplePage} />
        <RestrictedRoute path="/dashboard" component={Dashboards} />
        <Route path="/signin" component={Login} />
        {/* Users Management */}
        <RestrictedRoute path="/users" component={UsersModule} />
        <RestrictedRoute path="/interest" component={InterestType} />
        <RestrictedRoute path="/verification" component={Verification} />
        <RestrictedRoute path="/leader-board" component={LeaderBoard} />
        <RestrictedRoute path="/page-management" component={PageList} />
        <RestrictedRoute
          path="/update-verification"
          component={UpdateVerification}
        />
        <RestrictedRoute path="/genre" component={Genre} />
        <RestrictedRoute path="/profile" component={Wall} />
        <RestrictedRoute exact path="/create-page" component={createpage} />
        <RestrictedRoute
          exact
          path="/taral-page-detail"
          component={taralPageDetail}
        />

        <RestrictedRoute
          exact
          path="/pageCategories"
          component={PageCategories}
        />
        <RestrictedRoute exact path="/pageDetails" component={PageDetails} />
        <RestrictedRoute path="/verify-page" component={pageVerifaction} />
        <RestrictedRoute exact path="/crypto-Page" component={CryptoPageList} />
        <RestrictedRoute
          exact
          path="/crypto-page-categories"
          component={CryptoPageCategories}
        />
        <RestrictedRoute
          path="/verify-crypto-page"
          component={CryptoPageVerifaction}
        />
        <RestrictedRoute path="/ico-list" component={IcoList} />
        <RestrictedRoute path="/vesting-setting" component={VestingSetting} />
        <RestrictedRoute
          path="/taral-ico-details"
          component={TaralIcoDetails}
        />
        <RestrictedRoute path="/boost-plan" component={BostSubscription} />
        <RestrictedRoute
          path="/boost-plan-history"
          component={BostPlanHistory}
        />
        <RestrictedRoute
          path="/add-subscription"
          component={AddBostSubscription}
        />
        <RestrictedRoute path="/sub-admin" component={SubAdminList} />
        <RestrictedRoute path="/log-reports" component={LogReports} />
        <RestrictedRoute path="/add-sub-admin" component={AddSubAdminList} />
        {/* ***************** advertisement management ******************* */}
        <RestrictedRoute
          path="/advertisement-list"
          component={AdvertisementList}
        />
        <RestrictedRoute
          path="/view-advertisement"
          component={AdvertisementDetails}
        />
        <RestrictedRoute path="/influencer-list" component={InfluencerList} />
        <RestrictedRoute
          path="/interest-dashboard"
          component={InterestManagement}
        />
        <RestrictedRoute
          path="/view-influencer-details"
          component={InfluencerDetails}
        />

        {/* ***************** Escrow Influencer ********************* */}
        <RestrictedRoute path="/escrow-list" component={Escrow} />
        <RestrictedRoute path="/view-escrow" component={EscrowView} />

        {/* Reward Management */}
        <RestrictedRoute
          path="/rewardManagement"
          component={RewardManagement}
        />
        <RestrictedRoute path="/airdrop" component={Airdrop} />
        {/* Reports Management  */}
        <RestrictedRoute path="/reportsManagement" component={FlagReposts} />
        <RestrictedRoute path="/postDetail" component={PostDetail} />
        <RestrictedRoute path="/flagReportsType" component={FlagReportsType} />

        {/* Content Management */}
        <RestrictedRoute path="/contentDetails" component={ContentDetails} />
        <RestrictedRoute path="/contentTypes" component={ContentTypes} />
        <RestrictedRoute path="/hashTags" component={HashTags} />
        <RestrictedRoute path="/updateContent" component={UpdateContent} />
        <RestrictedRoute path="/editor" component={EditContent} />

        {/* Wallet Management */}
        <RestrictedRoute path="/walletDetails" component={WalletDetails} />
        <RestrictedRoute path="/redeemHistory" component={RedeemHistory} />
        <RestrictedRoute
          path="/lockFundsHistory"
          component={LockFundsHistory}
        />

        {/* Account Settings */}
        <RestrictedRoute path="/accountSettings" component={AccountSettings} />
        <RestrictedRoute path="/appsSettings" component={AppsSettings} />
        <RestrictedRoute path="/updateEmail" component={UpdateEmail} />
        <RestrictedRoute path="/updatePassword" component={UpdatePassword} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
