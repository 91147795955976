import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { timeFromNow } from "@jumbo/utils/dateHelper";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const LockFundsRow = ({ row, isSelected, idx, page }) => {
  const classes = useStyles();

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  return (
    <TableRow
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell>{page * 10 + idx + 1}</TableCell>

      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_pic} alt={row.name} />
          </Box>
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row?.user_data?.username}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row?.amount}</TableCell>
      <TableCell>{row?.coin_data?.coin_symbol}</TableCell>
      <TableCell>{timeFromNow(row?.locked_at)}</TableCell>
      <TableCell>{timeFromNow(row?.unlocked_at)}</TableCell>

      <TableCell> {row?.reward_data?.name} </TableCell>

      <TableCell>{row?.status}</TableCell>
    </TableRow>
  );
};

export default React.memo(LockFundsRow);
