import {
  Box,
  makeStyles,
  Typography,
  Avatar,
  IconButton,
  Button
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useHistory } from "react-router-dom";
import { handleUploadFiles } from "services/config";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import axios from "axios";
import { API_URL, IMAGE_URL } from "constant/constant";

const useStyles = makeStyles(theme => ({
  LastCustomizeContainer: {
    padding: "20px 0px",
    "& .btnBottomBox": {
      marginTop: "-87px",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0px"
      },
      "& .MuiButton-containedPrimary": {
        padding: "8px 40px",
        borderRadius: "8px"
      },
      "& .MuiButton-containedSecondary": {
        padding: "8px 50px",
        borderRadius: "8px",
        marginLeft: "10px"
      }
    }
  },
  cover: {
    position: "relative",
    "& .MuiAvatar-root": {
      borderRadius: "20px",
      border: "2px dashed rgba(255, 255, 255, 0.4)",
      height: "200px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        height: "100px",
        borderRadius: "15px"
      }
    },
    "& button": {
      position: "absolute",
      right: "16px",
      bottom: "13px",
      background: "#e9aa4f",
      "& svg": {
        color: "black"
      }
    }
  },

  BoxImg: {
    background: "rgb(30 29 29 / 50%)",
    border: "2px dashed rgba(255, 255, 255, 0.4)",
    backdropFilter: "blur(30px)",
    borderRadius: "20px",
    height: "200px",
    width: "100%",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      height: "120px",
      borderRadius: "15px"
    },
    "& svg": {
      color: "#FFFFFF99",
      fontSize: "40px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "25px"
      }
    }
  },
  BoxImgValidation: {
    background: "rgb(30 29 29 / 50%)",
    border: "2px dashed #ff0000",

    backdropFilter: "blur(30px)",
    borderRadius: "20px",
    height: "200px",
    width: "100%",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      height: "120px",
      borderRadius: "15px"
    },
    "& svg": {
      color: "#FFFFFF99",
      fontSize: "40px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "25px"
      }
    }
  },
  ProfileBoxImg: {
    background: "rgb(30 29 29 / 50%)",
    border: "2px dashed rgba(255, 255, 255, 0.4)",
    backdropFilter: "blur(30px)",
    borderRadius: "100%",
    height: "200px ",
    width: "200px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      height: "120px",
      width: "120px"
    },
    "& svg": {
      color: "#FFFFFF99",
      fontSize: "40px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "25px"
      }
    }
  },
  ProfileBoxImgValidation: {
    background: "rgb(30 29 29 / 50%)",
    border: "2px dashed #ff0000",
    backdropFilter: "blur(30px)",
    borderRadius: "100%",
    height: "200px ",
    width: "200px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      height: "120px",
      width: "120px"
    },
    "& svg": {
      color: "#FFFFFF99",
      fontSize: "40px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "25px"
      }
    }
  },

  profile: {
    position: "relative",
    bottom: "80px",
    left: "90px",
    width: "200px",
    [theme.breakpoints.down("xs")]: {
      bottom: "50px"
    },
    "& .MuiAvatar-root": {
      border: "2px dashed rgba(255, 255, 255, 0.4)",
      height: "200px ",
      width: "200px",
      [theme.breakpoints.down("xs")]: {
        height: "120px",
        width: "120px"
      }
    },
    "& button": {
      position: "absolute",
      left: "150px",
      bottom: "2px",
      background: "#e9aa4f",
      "& svg": {
        color: "black"
      }
    }
  }
}));
export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function() {
    cb(reader.result);
  };
  reader.onerror = function(err) {
    console.log("Error: ", err);
  };
};
export default function LastCustomise({ valuesData, actionType }) {
  const classes = useStyles();
  const history = useHistory();
  const token = useSelector(state => state.admin.authUser.access_token);
  const [profileImage, setProfileImage] = useState("");
  const [profileImage1, setProfileImage1] = useState("");
  const [profileImage64, setProfileImage64] = useState("");
  const [profileImage641, setProfileImage641] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [upLoading, setUploading] = useState({
    cover: false,
    profile: false
  });
  const dispatch = useDispatch();
  const uploadImageHandler = async image => {
    try {
      setUploading({
        ...upLoading,
        profile: true
      });
      const response = await handleUploadFiles(image, token, "BUSINESS_PAGE");
      if (response) {
        setProfileImage(response);
      }
      setUploading({
        ...upLoading,
        profile: false
      });
    } catch (error) {
      console.log(error);
      setUploading({
        ...upLoading,
        profile: false
      });
    }
  };
  const uploadCoverHandler = async image => {
    try {
      setUploading({
        ...upLoading,
        cover: true
      });
      const response = await handleUploadFiles(image, token, "BUSINESS_PAGE");
      if (response) {
        setProfileImage1(response);
      }
      setUploading({
        ...upLoading,
        cover: false
      });
    } catch (error) {
      console.log(error);
      setUploading({
        ...upLoading,
        cover: false
      });
    }
  };

  const handleFormSubmit = async values => {
    if (profileImage64 === "" && profileImage641 === "") {
      return;
    }
    setIsLoading(true);
    dispatch(fetchStart());
    try {
      let data = {
        name: valuesData.pageName,
        genre: valuesData.category,
        country_code: valuesData.countryCode,
        bio: valuesData.General,
        country: valuesData.country,
        web_site: valuesData.website,
        phone_number: valuesData.phone,
        state: valuesData.state,
        city: valuesData.province,
        zip_code: valuesData.postcode,
        cover_pic: profileImage,
        profile_pic: profileImage1,
        email: valuesData.email,
        biography: valuesData.status,
        page_title: valuesData.pageName
      };
      const apiUrl =
        actionType === "edit" ? "admin/pages/update" : "admin/pages/create";
      await axios({
        method: "POST",
        url: `${API_URL}${[apiUrl]}`,
        headers: {
          Authorization: token
        },
        data: data
      }).then(({ data }) => {
        setIsLoading(false);
        dispatch(fetchSuccess(data.message));
        history.push("/page-management");
      });
    } catch (error) {
      setIsLoading(false);
      dispatch(fetchError("error.message"));
      console.log("error", error);
    }
  };

  useEffect(() => {
    setProfileImage(valuesData?.cover_pic ? valuesData?.cover_pic : "");
    setProfileImage1(valuesData?.profile_pic ? valuesData?.profile_pic : "");
    setProfileImage64(
      valuesData?.cover_pic ? `${IMAGE_URL}` + valuesData?.cover_pic : ""
    );
    setProfileImage641(
      valuesData?.profile_pic ? `${IMAGE_URL}` + valuesData?.profile_pic : ""
    );
  }, [valuesData]);

  return (
    <Box className={classes.LastCustomizeContainer}>
      <Typography variant="h5">Customise your page</Typography>
      <Typography variant="body2">
        Your profile picture is one of the first things people see. try using
        your logo or a simple image people can easily associate with you.
      </Typography>

      <Box>
        <Box mt={2} className={classes.cover}>
          <label htmlFor="raised-button-file">
            <figure style={{ margin: "0px" }}>
              {profileImage64 === "" ? (
                <>
                  {" "}
                  <Box my={2} className={classes.BoxImg}>
                    <Box align="center">
                      <CloudUploadIcon />
                      <Typography variant="h6" style={{ color: "#FFFFFF99" }}>
                        Upload Cover Pic
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  {" "}
                  <Avatar
                    className="imgsection1"
                    src={profileImage64 ? profileImage64 : "images/camera1.png"}
                  />
                </>
              )}
            </figure>
          </label>
          {profileImage64 === "" ? (
            ""
          ) : (
            <IconButton
              onClick={() => setProfileImage64("")}
              disabled={isLoading}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>

        <>
          <input
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            accept="image/*"
            onChange={e => {
              uploadImageHandler(e.target.files[0]);
              getBase64(e.target.files[0], result => {
                setProfileImage64(result);
              });
            }}
            disabled={isLoading}
          />
        </>
      </Box>
      <Box>
        <Box className={classes.profile}>
          <label htmlFor="raised-button-file1">
            <figure style={{ margin: "0px" }}>
              {profileImage641 === "" ? (
                <>
                  {" "}
                  <Box my={2} className={classes.ProfileBoxImg}>
                    <Box align="center">
                      <CloudUploadIcon />
                      <Typography variant="h6" style={{ color: "#FFFFFF99" }}>
                        Upload Profile Pic
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  {" "}
                  <Avatar
                    className="imgsection1"
                    src={
                      profileImage641 ? profileImage641 : "images/camera1.png"
                    }
                  />
                </>
              )}
            </figure>
          </label>
          {profileImage641 === "" ? (
            ""
          ) : (
            <IconButton
              onClick={() => setProfileImage641("")}
              disabled={isLoading}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>

        <>
          <input
            style={{ display: "none" }}
            id="raised-button-file1"
            multiple
            type="file"
            accept="image/*"
            onChange={e => {
              uploadCoverHandler(e.target.files[0]);
              getBase64(e.target.files[0], result => {
                setProfileImage641(result);
              });
            }}
            disabled={isLoading}
          />
        </>
      </Box>
      <Box align="center" className="btnBottomBox">
        <Button
          variant="contained"
          color="primary"
          onClick={handleFormSubmit}
          disabled={isLoading || upLoading.cover || upLoading.profile}
        >
          Finish
        </Button>
      </Box>
    </Box>
  );
}
