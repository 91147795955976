import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import {
  Button,
  Dialog,
  Box,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import { useForm, Controller } from "react-hook-form";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
import UserTableHead from "./UserTableHead";
import UserTableToolbar from "./UserTableToolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
// import { setCurrentUser } from "redux/actions/Users";
import AddEditUser from "./AddEditUser";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import UserDetailView from "./UserDetailView";
import NoRecordFound from "./NoRecordFound";
import axios from "axios";
import moment from "moment";
import { API_URL } from "constant/constant";
import { useHistory } from "react-router-dom";
import { AuhMethods } from "services/auth";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import * as yup from "yup";
import UserDashboard from "./UserDashboard";
import UserFilter from "./UserFilter";
import { MoreVertIcon } from "@material-ui/icons/MoreVert";

const dailyData = [
  { name: "Total Advertisement", amount: "123" },
  { name: "Total Active Advertisement", amount: "123" },
  { name: "Total Inactive Advertisement", amount: "123" },
  { name: "Total Expire Advertisement", amount: "23" },
];

const schema = yup.object().shape({
  payInr: yup.string().required("This field is required."),
  amountInr: yup.string().required("This field is required."),
  amountTaral: yup.string().required("This field is required."),
  min: yup.string().required("This field is required."),
  max: yup.string().required("This field is required."),
});
const PageList = ({
  method = "usersModule",
  variant = "default",
  wrapperVariant = "default",
}) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      payInr: "",
      amountInr: "",
      amountTaral: "",
      min: "",
      max: "",
    },
    resolver: yupResolver(schema),
  });
  const classes = useStyles();
  const [orderBy, setOrderBy] = React.useState("name");
  const [valuessss, setValue1] = React.useState({
    payInr: "",
    amountInr: "",
    amountTaral: "",
    min: "",
    max: "",
  });
  // console.log("valuessss", valuessss);
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [advertisement, setAdvertisementList] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [verifyToggle, setVerifyToggle] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageListMeta, setpageListMeta] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openRejectedDialog, setOpenRejectedDialog] = useState(false);
  // const [openViewDialog, setOpenViewDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [from_date, handleFromChange] = useState();
  const [to_date, handleToChange] = useState();
  const [status, setstatus] = useState();
  const [filterOptions, setFilterOptions] = React.useState([]);
  const FetchData = useSelector((state) => state.common);
  // console.log("to_date ----->.", to_date);
  const token = useSelector((state) => state.admin.authUser.access_token);

  // const pageListMeta = useSelector(
  //   (state) => state.businessPageManagement.pageListMeta
  // );
  const pageList = useSelector(
    (state) => state.businessPageManagement.pageList
  );
  const dispatch = useDispatch();

  const AdvertisementListAPI = async ({
    token,
    page,
    perPage,
    keyword,
    order,
    status,
    from_date,
    to_date,
  }) => {
    const pageData = page != undefined ? page + 1 : "";
    const keywordData = keyword != undefined ? keyword : "";
    const statusData = status != undefined ? "&status=" + status : "";
    from_date =
      from_date != undefined
        ? "&from_date=" + moment(from_date).format("YYYY-MM-DD")
        : "";
    to_date =
      to_date != undefined
        ? "&to_date=" + moment(to_date).format("YYYY-MM-DD:23:59:59")
        : "";

    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "GET",
        url:
          `${API_URL}admin/advertisement/list?order=${order}&` +
          // keywordData +
          // "&perPage=" +
          // perPage +
          // pageData +
          statusData +
          from_date +
          to_date,
        headers: {
          Authorization: token,
        },
        params: {
          keyword,
          perPage,
          page: pageData,
        },
      })
        // return res;
        .then(({ data }) => {
          //console.log("data -----", data);
          if (data.data) {
            dispatch(fetchSuccess());
            setAdvertisementList(data.data);
            setpageListMeta(data.meta);
            // dispatch(setUsersListData(data));
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  useEffect(() => {
    AdvertisementListAPI({
      token: token,
      page: page,
      perPage: rowsPerPage,
      keyword: searchText,
      order,
      status,
      from_date,
      to_date,
    });
  }, [page, rowsPerPage, searchText, status, from_date, to_date]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    // dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleRowClick = (event, id) => {
    history.push({
      pathname: "/pageDetails",
      state: { id: id },
    });
  };

  const handleSearchTextChange = (e) => {
    let data = e.target.value.replace(/^\s/, "");
    setSearchText(data);
  };

  const handlePageChange = (event, newPage) => {
    // console.log(newPage, " ------- newPage ----- newPage ");
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleUserView = (user) => {
    history.push({
      pathname: "/crypto-page-details",
      state: { id: user.id },
    });
  };
  const onVerify = (data) => {
    setVerifyToggle(true);
    setSelectedUser(data);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    // dispatch(setCurrentUser(null));
  };

  const handleUserEdit = (user) => {
    // dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleUserBlock = (user) => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };
  const handleChangeStatus = (action, label, id, row) => {
    let data = {
      action: action,
      label: label,
      id: id,
      row: row,
    };
    // console.log(data, "row ------------- id", action, id, label, id, row);
    setSelectedUser(data);
    if (action == "Approve") {
      setOpenConfirmDialog(true);
    }
    if (action == "rejected") {
      setOpenRejectedDialog(true);
    }
    if (action == "View") {
      setOpenViewDialog(true);
    }
  };
  const handleUserStatus1 = (e) => {
    setOpenConfirmDialog(false);
    const params = {
      business_page_id: selectedUser.id,
      action: selectedUser.status === "0" ? "1" : "0",
    };

    dispatch(
      AuhMethods["businessModule"].PageVerificationProcessAPI(
        token,
        params,
        "pageList"
      )
    );
    setTimeout(
      () =>
        dispatch(
          AuhMethods["businessModule"].pageListAPI({
            token: token,
            page: page,
            perPage: rowsPerPage,
            keyword: searchText,
            order,
          })
        ),
      1000
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelVerify = () => {
    setVerifyToggle(false);
  };
  const handleReject = () => {
    setOpenRejectedDialog(false);
  };
  const handleUserStatus = async () => {
    // console.log(
    //   getValues("payInr"),
    //   getValues("amountInr"),
    //   getValues("amountTaral"),
    //   "id row ------------- id",
    //   selectedUser
    // );
    let params;
    if (selectedUser.action === "Approve") {
      params = {
        id: selectedUser.id,
        action: selectedUser.action === "Approve" && 1,
        min: getValues("min"),
        max: getValues("max"),
        total_inr: getValues("payInr"),
        // total_inr: getValues("amountInr"),
        inr: getValues("amountInr"),
        // inr: getValues("payInr"),
        taral: getValues("amountTaral"),
      };
    }
    if (selectedUser.action !== "Approve") {
      params = {
        id: selectedUser.id,
        action: 2,
      };
    }

    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      // console.log("id row ------------- id", selectedUser.id, selectedUser);
      const res = await axios({
        method: "PUT",
        url: `${API_URL}admin/advertisement/changeStatus`,
        headers: {
          Authorization: token,
        },
        data: params,
      });
      AdvertisementListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText,
        order,
        status,
        from_date,
        to_date,
      });

      handleCancelDelete();
      handleReject()
        // return res
        .then(({ data }) => {
          if (data.data) {
            dispatch(fetchSuccess(data.message));
            // setAdvertisementList(data.data);
            // dispatch(setUsersListData(data));
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      console.log(" error ", error);
    }
    // dispatch(
    //   AuhMethods["businessModule"].BusinessPageVerificationAPI({
    //     token,
    //     params,
    //   })
    // );
    // setTimeout(
    //   () =>
    //     dispatch(
    //       AuhMethods[method].pageListAPI({
    //         token: token,
    //         page: page,
    //         perPage: rowsPerPage,
    //         keyword: searchText,
    //       })
    //     ),
    //   1000
    // );
    setVerifyToggle(false);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={pageListMeta.total}
        />
        <UserDashboard
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={pageListMeta}
          dailyData={dailyData}
        />
        <UserFilter
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={"12"}
          actionHandleIcon={<MoreVertIcon />}
          actions={""}
          actionHandler={""}
          from_date={from_date}
          handleFromChange={handleFromChange}
          to_date={to_date}
          handleToChange={handleToChange}
          status={status}
          setstatus={setstatus}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <UserTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={advertisement.length}
            />
            <TableBody>
              {advertisement.length > 0 ? (
                advertisement.map((row, index) => (
                  <UserListRow
                    key={index}
                    page={page}
                    idx={index}
                    row={row}
                    onRowClick={handleRowClick}
                    onUserEdit={handleUserEdit}
                    changeStatus={handleChangeStatus}
                    onUserView={handleUserView}
                    isSelected={isSelected}
                    onVerify={onVerify}
                  />
                ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={pageListMeta.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openUserDialog && (
        <AddEditUser
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}
      {openViewDialog && (
        <UserDetailView
          viewDetails={selectedUser?.row}
          open={openViewDialog}
          onCloseDialog={handleCloseViewDialog}
        />
      )}

      <ConfirmDialog
        open={openRejectedDialog}
        title={`Confirm to reject ${
          selectedUser?.row?.name ? selectedUser.row.name : ""
        }`}
        content={`Are you sure, you want to reject this Advertisement?`}
        onClose={handleReject}
        onConfirm={handleUserStatus}
      />

      {/* <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${selectedUser.status == "1" ? "Block" : "Unblock"} ${
          selectedUser.name ? selectedUser.name : ""
        }`}
        content={`Are you sure, you want to ${
          selectedUser.status == "1" ? "block" : "unblock"
        } this user?`}
        onClose={handleCancelDelete}
        onConfirm={}
      /> */}
      {openConfirmDialog && (
        <Dialog
          open={openConfirmDialog}
          onClose={handleCancelDelete}
          aria-labelledby="confirm-dialog-title"
          className={classes.dialogRoot}
        >
          <DialogTitle
            id="confirm-dialog-title"
            className={classes.dialogTitleRoot}
          >
            Fee Tenure for Advertisement
            {/* Are You  */}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              <form onSubmit={handleSubmit(handleUserStatus)}>
                <Box mb={2}>
                  <Typography
                    component="div"
                    variant="body1"
                    className={classes.titleRoot}
                  >
                    Pay Amount in INR
                    {/* {<IntlMessages id="appModule.planName" />} */}
                  </Typography>
                  <Controller
                    name="payInr"
                    control={control}
                    render={({ value }) => {
                      return (
                        <TextField
                          name="payInr"
                          label={"Total in INR"}
                          fullWidth
                          type={"number"}
                          onChange={(event) => {
                            // setOldPassword(event.target.value);
                            setValue("payInr", event.target.value);
                          }}
                          value={getValues("payInr")}
                          margin="normal"
                          variant="outlined"
                          className={classes.textFieldRoot}
                        />
                      );
                    }}
                  />
                  <p style={{ color: "red" }}>{errors.payInr?.message}</p>
                </Box>
                <Box mb={2} className="inputPasswordCustom">
                  <Typography
                    component="div"
                    variant="body1"
                    className={classes.titleRoot}
                  >
                    Pay Amount in INR + Taral
                  </Typography>
                </Box>

                <Box mb={2} className="inputPasswordCustom">
                  <Box className="inputPasswordCustom1">
                    <Controller
                      name="amountInr"
                      control={control}
                      // rules={{ required: true }}

                      render={() => (
                        <TextField
                          type={"number"}
                          label="Amount In Inr"
                          // label={
                          //   <IntlMessages id="appModule.planAmountInInr" />
                          // }
                          fullWidth
                          value={getValues("amountInr")}
                          onChange={(event) => {
                            setValue("amountInr", event.target.value);
                            setConfirmPassword(event.target.value);
                            setConfirmPasswordError("");
                          }}
                          helperText={confirmPasswordError}
                          margin="normal"
                          variant="outlined"
                          className={classes.textFieldRoot}
                        />
                      )}
                    />

                    <p style={{ color: "red" }}>{errors.amountInr?.message}</p>
                    <Controller
                      name="amountTaral"
                      control={control}
                      // rules={{ required: true }}

                      render={() => (
                        <TextField
                          type={"number"}
                          label="Amount In Taral"
                          // label={
                          // <IntlMessages id="appModule.planAmountInTaral" />
                          // }
                          fullWidth
                          value={getValues("amountTaral")}
                          onChange={(event) => {
                            setValue("amountTaral", event.target.value);
                            // setConfirmPassword(event.target.value);
                            // setConfirmPasswordError("");
                          }}
                          helperText={confirmPasswordError}
                          margin="normal"
                          variant="outlined"
                          className={classes.textFieldRoot}
                        />
                      )}
                    />

                    <p style={{ color: "red" }}>
                      {errors.amountTaral?.message}
                    </p>
                  </Box>
                </Box>
                <Box mb={2} className="inputPasswordCustom">
                  <Typography
                    component="div"
                    variant="body1"
                    className={classes.titleRoot}
                  >
                    Advertisement to Reach
                  </Typography>
                </Box>

                <Box mb={2} className="inputPasswordCustom">
                  <Box className="inputPasswordCustom1">
                    <Controller
                      name="min"
                      control={control}
                      // rules={{ required: true }}

                      render={() => (
                        <TextField
                          type={"number"}
                          label="Min"
                          // label={
                          //   <IntlMessages id="appModule.planAmountInInr" />
                          // }
                          fullWidth
                          value={getValues("min")}
                          onChange={(event) => {
                            setValue("min", event.target.value);
                            setConfirmPassword(event.target.value);
                            setConfirmPasswordError("");
                          }}
                          helperText={confirmPasswordError}
                          margin="normal"
                          variant="outlined"
                          className={classes.textFieldRoot}
                        />
                      )}
                    />

                    <p style={{ color: "red" }}>{errors.min?.message}</p>
                    <Controller
                      name="max"
                      control={control}
                      // rules={{ required: true }}

                      render={() => (
                        <TextField
                          type={"number"}
                          label="Max"
                          // label={
                          // <IntlMessages id="appModule.planAmountInTaral" />
                          // }
                          fullWidth
                          value={getValues("max")}
                          onChange={(event) => {
                            setValue("max", event.target.value);
                            // setConfirmPassword(event.target.value);
                            // setConfirmPasswordError("");
                          }}
                          helperText={confirmPasswordError}
                          margin="normal"
                          variant="outlined"
                          className={classes.textFieldRoot}
                        />
                      )}
                    />

                    <p style={{ color: "red" }}>{errors.max?.message}</p>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mb={5}
                >
                  {" "}
                  <Button
                    onClick={handleCancelDelete}
                    color="primary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    // onClick={handleUserStatus}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Send Conformation
                  </Button>
                </Box>
              </form>
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions classes={{ root: classes.dialogActionsRoot }}>
          
          </DialogActions> */}
        </Dialog>
      )}
    </div>
  );
};

export default PageList;
