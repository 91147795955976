import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Box,
  Grid
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import axios from "axios";
import { API_URL } from "constant/constant";
import { useDispatch } from "react-redux";
import { fetchError, fetchSuccess } from "redux/actions";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));
const labels = [
  { title: "TARAL", value: "taral" },
  // { title: "BNB", value: "bnb" },
  { title: "INR", value: "inr" },
  // { title: "ETH", value: "ETH" },
  { title: "Other", value: "other" }
];
export default function AddTrenchModal({
  open,
  onClose,
  callBack,
  token,
  type,
  data
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [thresholdValue, setThresholdValue] = useState("");
  const [formData, setFormData] = useState([
    {
      type: "",
      reward: ""
    }
  ]);

  const addEditTrenchAPIHandler = async () => {
    try {
      if (Number(thresholdValue) <= 0) {
        dispatch(fetchError("Please enter a valid threshold value."));
        return;
      }
      if (
        formData[formData?.length - 1]?.type === "" &&
        formData[formData?.length - 1]?.reward == ""
      ) {
        dispatch(fetchError("Please enter valid data."));
        return;
      }
      setIsAdding(true);
      const dataToSend =
        type == "add"
          ? {
              rewards_data: formData,
              value: thresholdValue
            }
          : {
              id: data.id,
              rewards_data: formData,
              value: thresholdValue
            };
      const response = await axios({
        method: type == "add" ? "POST" : "PUT",
        url: `${API_URL}admin/rewards_setting/${
          type == "add" ? "create" : "update_trunche"
        }`,
        headers: {
          Authorization: token
        },
        data: dataToSend
      });
      if (response.status === 200) {
        dispatch(fetchSuccess(response.data.message));
        callBack();
        onClose();
      }
      setIsAdding(false);
    } catch (error) {
      console.log(error);
      setIsAdding(false);
    }
  };

  useEffect(() => {
    if (data) {
      setThresholdValue(data?.value);
      setFormData(JSON.parse(data?.rewards_data));
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isAdding) {
          onClose();
        }
      }}
      className={`${classes.dialogRoot} dialogCustom`}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {`${type == "add" ? "Add" : "Edit"} Trench`}
        {/* <CloseIcon onClick={onCloseDialog} /> */}
        <IconButton
          aria-label="close"
          onClick={() => {
            if (!isAdding) {
              onClose();
            }
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000"
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3} style={{ marginBottom: "12px" }}>
          <Grid item lg={12} xs={12}>
            <AppTextInput
              fullWidth
              type="number"
              variant="outlined"
              label="Threshold Amount"
              value={thresholdValue}
              onChange={e => setThresholdValue(e.target.value)}
              // helperText={firstNameError}
            />
          </Grid>
        </Grid>

        {formData &&
          formData.map((item, i) => {
            return (
              <DynamicFileds
                item={item}
                index={i}
                setFormData={setFormData}
                formData={formData}
              />
            );
          })}

        <Box display="flex" justifyContent="flex-end" mb={4} mt={2}>
          <Button
            onClick={() => {
              if (!isAdding) {
                onClose();
              }
            }}
          >
            Cancel
          </Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={addEditTrenchAPIHandler}
              disabled={isAdding}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const DynamicFileds = ({ index, setFormData, formData, item }) => {
  const handleChange = (i, e) => {
    let newFormValues = [...formData];
    newFormValues[i][e.target.name] = e.target.value;
    setFormData(newFormValues);
  };

  const addFormFields = () => {
    setFormData([
      ...formData,
      {
        type: "",
        reward: ""
      }
    ]);
  };

  const removeFormFields = i => {
    let newFormValues = [...formData];
    newFormValues.splice(i, 1);
    setFormData(newFormValues);
  };
  return (
    <Grid container spacing={1} key={`multiField${index}`}>
      <Grid item lg={5} xs={5}>
        <AppSelectBox
          fullWidth
          data={labels}
          label="Reward Type"
          valueKey="value"
          variant="outlined"
          labelKey="title"
          name="type"
          value={item.type || ""}
          onChange={e => {
            handleChange(index, e);
          }}
        />
      </Grid>
      <Grid item lg={6} xs={6}>
        {" "}
        <AppTextInput
          fullWidth
          type={item.type === "other" ? "text" : "number"}
          variant="outlined"
          label="Reward Amount"
          name="reward"
          value={item.reward || ""}
          onChange={e => {
            handleChange(index, e);
          }}
        />
      </Grid>
      <Grid item lg={1} xs={1}>
        <IconButton
          onClick={() => {
            if (index === formData.length - 1) {
              if (item.reward !== "" && item.type !== "") {
                addFormFields();
              }
            } else {
              removeFormFields(index);
            }
          }}
        >
          {index === formData.length - 1 ? <AddIcon /> : <RemoveIcon />}
        </IconButton>
      </Grid>
    </Grid>
  );
};
