import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { user } from "@fake-db/apps/wall";
import { AuhMethods } from "services/auth";
import { storyListData } from "redux/actions";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    paddingBottom: 24,
    marginBottom: 24,
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      zIndex: 1,
      width: 36,
      height: 4,
      backgroundColor: theme.palette.primary.main
    }
  },
  comGridRoot: {
    borderRadius: 4,
    overflow: "hidden",
    marginBottom: 20
  },
  comThumbRoot: {
    overflow: "hidden",
    position: "relative",
    cursor: "pointer"
  },
  comThumb: {
    "& img": {
      width: "100%",
      display: "block"
    }
  },
  comThumbContent: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backgroundImage:
      "linear-gradient(180deg,rgba(0,0,0,.01) 0,rgba(0,0,0,.65))",
    padding: 8,
    fontSize: 12,
    color: theme.palette.common.white
  },
  textLink: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    cursor: "pointer"
  }
}));

const Communities = userId => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector(state => state.admin.authUser.access_token);
  const story = useSelector(
    state => state.businessPageManagement.PageStoryList
  );

  useEffect(() => {
    if (userId) {
      getStories();
    }
    return () => {
      dispatch(storyListData([]));
    };
  }, [userId]);

  const getStories = () => {
    dispatch(AuhMethods["businessModule"].getPageStoriesAPI({ token, userId }));
  };

  return (
    <Box mb={{ xs: 6, lg: 8, xl: 10 }}>
      <Typography component="div" variant="h3" className={classes.titleRoot}>
        Token Detail
      </Typography>
      <Box className={classes.comGridRoot}></Box>
      {/* <Box className={classes.textLink}>View All</Box> */}
    </Box>
  );
};

export default Communities;
