import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
import UserTableHead from "./UserTableHead";
import UserTableToolbar from "./UserTableToolbar";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "redux/actions/Users";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import UserDetailView from "./UserDetailView";
import NoRecordFound from "./NoRecordFound";
import { getDateText } from "../../../@jumbo/utils/dateHelper";
import { useHistory } from "react-router-dom";
import { AuhMethods } from "services/auth";

const PageVerification = () => {
  const history = useHistory();
  const classes = useStyles();
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [filterOptions, setFilterOptions] = React.useState([]);
  const token = useSelector((state) => state.admin.authUser.access_token);
  const FetchData = useSelector((state) => state.common);

  const pageVerificationList = useSelector(
    (state) => state.usersModule.pageVerificationList
  );
  const dispatch = useDispatch();

  const getUsersListByDate = () => {
    return (
      Array.isArray(pageVerificationList.data) &&
      pageVerificationList.data.map((activity) => {
        activity.category = getDateText(activity.date);
        return activity;
      })
    );
  };
  const usersList = getUsersListByDate();

  useEffect(() => {}, [pageVerificationList]);

  useEffect(() => {
    getVerificationPage();
  }, [page, rowsPerPage, searchText]);

  const getVerificationPage = () => {
    dispatch(
      AuhMethods["businessModule"].getPageVerificationListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText,
        order,
      })
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleRowClick = (event, id) => {
    history.push({
      pathname: "/profile",
      state: { id: id },
    });
  };

  const handleSearchTextChange = (e) => {
    let data = e.target.value.replace(/^\s/, "");
    setSearchText(data);
    // dispatch(AuhMethods[method].usersListAPI({ token: token, page: page, perPage: rowsPerPage, keyword: searchText })); //token, page, perPage, keyword
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleUserView = (user) => {
    setSelectedUser(user);
    // setOpenVerificationDialog(true);
  };

  const onVerify = (data) => {
    // setVerifyToggle(true);
    setSelectedUser(data);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleUserEdit = (user) => {
    dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleChangeStatus = (action, label, id) => {
    let data = {
      action: action,
      label: label,
      id: id,
    };
    setSelectedUser(data);
    setOpenConfirmDialog(true);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleConfirmAction = () => {
    let status =
      selectedUser.action === "pending"
        ? 0
        : selectedUser.action === "accepted"
        ? 1
        : selectedUser.action === "rejected"
        ? 2
        : "";
    dispatch(
      AuhMethods["businessModule"].changePageRequestStatus({
        token: token,
        id: selectedUser.id,
        status: status,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText,
        order,
      })
    );
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setSelectedUser({});
    setOpenConfirmDialog(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <UserTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={usersList.length}
            />
            <TableBody>
              {/* {Array.isArray(pageVerificationList.data) && pageVerificationList.data.length > 0 ? (
                usersList.map((row, index) => (
                  <UserListRow
                    key={index}
                    page={page}
                    idx={index}
                    row={row}
                    onRowClick={handleRowClick}
                    onUserEdit={handleUserEdit}
                    changeStatus={handleChangeStatus}
                    onUserView={handleUserView}
                    isSelected={isSelected}
                    onVerify={onVerify}
                  />
                ))
              ) : ( */}
              <TableRow style={{ height: 53 * 6 }}>
                <TableCell colSpan={7} rowSpan={10}>
                  {
                    <NoRecordFound>
                      {FetchData.loading
                        ? "Loading records..."
                        : "No records found."}
                    </NoRecordFound>
                  }
                </TableCell>
              </TableRow>
              {/* )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={pageVerificationList?.meta?.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
      {openViewDialog && (
        <UserDetailView
          open={openViewDialog}
          onCloseDialog={handleCloseViewDialog}
        />
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm Action`}
        content={`Are you sure, you want to ${selectedUser.label} this verification page?`}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmAction}
      />
    </div>
  );
};

export default PageVerification;
