import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
import CmtList from '@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { usersModule } from '@fake-db';
import FollowList from './FollowList';
import { AuhMethods } from 'services/auth';

const FollowingRecord = ({ open, onCloseDialog, commentId, itemType, data }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [perPage] = React.useState(10);
  const token = useSelector(state => state.admin.authUser.access_token);
  const comments = useSelector(state => state?.usersModule?.postCommentsData);

  useEffect(() => {
    // dispatch(AuhMethods['usersModule'].postCommentsAPI({ token, commentId, page, perPage, itemType }));
  }, []);

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Box ml={1}>{itemType}</Box>
            </Box>
          </Box>
        </Box>
        {/* Cross button */}
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box px={6} py={5}>
        {/* Comment List  */}
        {data.length > 0 && (
          <CmtList
            data={data}
            renderRow={(item, index) => <FollowList key={index} item={item} itemType={itemType} classes={classes} />}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default FollowingRecord;
FollowingRecord.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
