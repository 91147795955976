import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";

const headCells = [
  {
    id: "Sr No",
    numeric: false,
    disablePadding: false,
    label: "Sr No"
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name"
  },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "userName", numeric: false, disablePadding: false, label: "User Name" },
  {
    id: "whitelist",
    numeric: false,
    disablePadding: false,
    label: "Whitelist Status"
  },
  { id: "score", numeric: false, disablePadding: false, label: "Score" }
];

function UserTableHeadWhiteList() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className="like-padding"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default UserTableHeadWhiteList;
