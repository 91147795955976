import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch, useSelector } from "react-redux";
import CmtList from "@coremat/CmtList";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import ClearIcon from "@material-ui/icons/Clear";
import useStyles from "./index.style";
import CommentItem from "../PostsList/PagePostItem/CommentItem";
import { AuhMethods } from "services/auth";

const CommentListView = ({ open, onCloseDialog, commentId, itemType }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page] = React.useState(1);
  const [perPage] = React.useState(10);
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  const LikeCommentsData = useSelector(
    state => state?.businessPageManagement?.PageLikeCommentsData
  );
  useEffect(() => {
    dispatch(
      AuhMethods["businessModule"].PageLikeComentsListAPI({
        token,
        commentId,
        page,
        perPage,
        itemType
      })
    );
  }, []);

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Box ml={1} style={{ textTransform: "capitalize" }}>
                {itemType}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box px={6} py={5}>
        {LikeCommentsData.length > 0 && (
          <CmtList
            data={LikeCommentsData}
            renderRow={(item, index) => (
              <CommentItem
                key={index}
                item={item}
                itemType={itemType}
                classes={classes}
              />
            )}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default CommentListView;

CommentListView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
