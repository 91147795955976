import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  TextField,
  Button,
  FormHelperText
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import axios from "axios";
import useDebounce from "../../../customHook/debounse";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API_URL } from "constant/constant";
import { fetchError } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  PageContainer: {
    "& .formBox": {
      padding: "20px 0px",

      "& .MuiSelect-root": {
        paddingLeft: "18px"
      }
    },
    "& .MuiFormHelperText-root.Mui-error": { marginLeft: "14px" },
    "& .CheckBoxOn": {
      padding: "20px 0px",
      display: "flex",
      alignItems: "center",
      "& p": {
        color: "#FFFFFF99"
      },
      "& span": {
        color: "#FDC763"
      }
    }
  }
}));

const schema = yup.object().shape({
  pageName: yup
    .string()
    .required("This field is required.")
    .min(4, "Must Contain 4 Characters, "),
  category: yup.string().required("This field is required.")
});

export default function CreatePage({
  setTabButton,
  handleNext,
  setValuesData,
  valuesData,
  actionType
}) {
  const classes = useStyles();
  const [categoryData, setCategoryData] = useState([]);
  const [search, setsearch] = useState("");
  const [categoriess, setcategoriess] = useState("");
  const dispatch = useDispatch();
  let deb = useDebounce(search, 1000);
  const [isbusinessPageAvailable, setBusinessPageAvailable] = useState("");
  const [isbusinessPagenot, setBusinessPagenot] = useState("");
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      pageName: "",
      category: ""
    },
    resolver: yupResolver(schema)
  });
  const token = useSelector(state => state.admin.authUser.access_token);

  const onSubmit = async data => {
    setValuesData({
      ...valuesData,
      pageName: getValues("pageName"),
      category: getValues("category")
    });
    if (isbusinessPageAvailable === "Page name available") {
      setTabButton("step2");
      handleNext();
    }
  };
  const getCategoryList = async () => {
    try {
      await axios({
        method: "GET",
        url: `${API_URL}admin/user/genres`,
        headers: {
          Authorization: token
        }
      }).then(({ data }) => {
        if (data.data) {
          setCategoryData(data.data);
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
  const postAPIHandlerIdInPath = async () => {
    try {
      if (valuesData?.pageName !== "" && valuesData?.pageName === search) {
        return;
      }
      const res = await axios({
        method: "GET",
        url: `${API_URL}admin/pages/check_page/${search}`,
        headers: {
          Authorization: token
        }
      });
      if (res.data.status) {
        setBusinessPageAvailable("");
        setBusinessPagenot("Page is not available");
      }
    } catch (error) {
      console.log(error);
      setBusinessPagenot("");
      setBusinessPageAvailable("Page name available");
    }
  };
  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    if (search !== "") {
      postAPIHandlerIdInPath();
    }
  }, [deb]);
  useEffect(() => {
    if (valuesData.pageName) {
      setBusinessPageAvailable("Page name available");
      setValue("pageName", valuesData?.pageName ? valuesData?.pageName : "");
      setValue("category", valuesData?.category ? valuesData?.category : "");
      setcategoriess(valuesData?.category ? valuesData?.category : "");
    }
  }, [valuesData]);
  return (
    <Box className={classes.PageContainer}>
      <Typography variant="h6"> Create your page</Typography>
      <Typography variant="body2">
        A page is a space where people can publicity connect with your business,
        personal brands or Organization.
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Box className="formBox">
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                Enter Page Name
              </Typography>
              <Controller
                name="planName"
                control={control}
                render={({ value }) => {
                  return (
                    <TextField
                      placeholder="About status to be shown"
                      name="pageName"
                      fullWidth
                      type={"text"}
                      onChange={event => {
                        setsearch(event.target.value);
                        setValue("pageName", event.target.value);
                      }}
                      value={getValues("pageName")}
                      variant="outlined"
                      className={classes.textFieldRoot}
                      error={isbusinessPagenot !== "" ? true : false}
                      disabled={actionType === "edit"}
                    />
                  );
                }}
              />
              {search !== "" && isbusinessPagenot !== "" && (
                <FormHelperText error>{isbusinessPagenot}</FormHelperText>
              )}
              {search !== "" && isbusinessPageAvailable !== "" && (
                <FormHelperText
                  success
                  style={{ color: "green", marginLeft: "14px" }}
                >
                  {isbusinessPageAvailable}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                Category
              </Typography>
              <Controller
                name="planName"
                control={control}
                render={({ value }) => {
                  return (
                    <>
                      <Select
                        fullWidth
                        name="category"
                        placeholder="Select category"
                        onChange={event => {
                          setValue("category", event.target.value);
                          setcategoriess(event.target.value);
                        }}
                        value={getValues("category")}
                        variant="outlined"
                        className={classes.textFieldRoot}
                        error={categoriess == "" && errors.category?.message}
                        helperText={errors.category?.message}
                        InputProps={{
                          className: classes.TextBox
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {categoryData.lenght !== 0 &&
                          categoryData.map(item => {
                            return (
                              <MenuItem
                                key={item.name + item.id}
                                value={item.id}
                              >
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>

                      <FormHelperText error>
                        {categoriess == "" && errors.category?.message}
                      </FormHelperText>
                    </>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Button
          variant="contained"
          color="primary"
          style={{ padding: "8px 50px" }}
          type="submit"
        >
          Next
        </Button>
      </form>
    </Box>
  );
}
