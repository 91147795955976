import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MoreHoriz, Close, Done } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const UserListRow = ({ row, isSelected, page, idx, changeStatus }) => {
  const classes = useStyles();

  // *********** option for Page ***********************
  const getUserActions = () => {
    const actions = [
      row?.status === "0"
        ? { action: "accepted", label: "APPROVE", icon: <Done /> }
        : row?.status === "1"
        ? { action: "rejected", label: "REJECT", icon: <Close /> }
        : row?.status === "2"
        ? { action: "accepted", label: "APPROVE", icon: <Done /> }
        : {}
    ];
    if (row?.status === "0") {
      actions.push({ action: "rejected", label: "REJECT", icon: <Close /> });
    }

    return actions;
  };

  const onUserMenuClick = menu => {
    changeStatus(menu.action, menu.label, row?.id);
  };

  const labelId = `enhanced-table-checkbox-${row?.user_id}`;
  const isItemSelected = isSelected(row?.user_id);
  const userActions = getUserActions(row?.status, row?.verified);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?.user_id}
      selected={isItemSelected}
    >
      <TableCell>{page * 10 + idx + 1}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row?.profile_url} alt={row?.name} />
          </Box>
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row?.name === null ? "N/A" : row?.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>
        {" "}
        {row?.online_offline_business === 0 ? "Offline" : "Online"}
      </TableCell>
      <TableCell>
        {" "}
        {row?.interested_for_advertise_business_page === 0 ? "No" : "Yes"}
      </TableCell>

      <TableCell>
        {row?.status === "0"
          ? "Pending"
          : row?.status === "1"
          ? "Verified"
          : "Unverified"}
      </TableCell>

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
