import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import React from "react";
import axios from "../../config";

import { resolve } from "path";
import { reject } from "lodash";
import { AuhMethods } from "..";
import {
  addContentType,
  getContentTypeData,
  getContentPostsList,
  getContentUserList,
  getHashTagsList,
  postDetailAPIsuccess,
  getInterestTypeData,
  getContentTitle,
  getContentFilterPostsList,
} from "redux/actions/ContentManagement";

//Content Management apis
const ContentManagementServicesAuth = {
  getContentUserListAPI: ({
    token,
    page,
    perPage,
    keyword,
    order,
    sortBy,
    params,
  }) => {
    const perPageData = perPage != "" ? "perPage=" + perPage : "";
    const pageData = "&page=" + (page + 1);
    const keywordData = keyword != "" ? "&keyword=" + keyword : "";

    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          // .get(`admin/post/user_list?${perPageData}${pageData}${keywordData}${order}${sortBy}`)
          .get(`admin/post/user_list`, {
            params: {
              keyword: keyword,
            },
          })
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(getContentUserList(data));
              if (Array.isArray(data?.data) && data?.data.length > 0) {
                let uId = data?.data[0].user_id;
                let userId = {
                  userId: uId,
                };
                dispatch(
                  AuhMethods["usersModule"].getStoriesAPI({ token, userId })
                );
              }
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  getContentFilterPostsListAPI: ({
    token,
    page,
    perPage,
    keyword,
    order,
    sortBy,
    params,
  }) => {
    const filterData = params != undefined ? params : "";

    const perPageData = perPage != "" ? "perPage=" + perPage : "";
    const pageData = "&page=" + (page + 1);
    const keywordData = keyword != "" ? "&keyword=" + keyword : "";
    let sorts = sortBy ? sortBy : "";
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .post(
            `admin/post/list?&sortBy=${sorts}&page=${page}&perPage=${perPage}`,
            filterData
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(getContentFilterPostsList(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  getContentPostsListAPI: ({
    token,
    page,
    perPage,
    keyword,
    order,
    sortBy,
    params,
  }) => {
    const filterData = params != undefined ? params : "";

    const perPageData = perPage != "" ? "perPage=" + perPage : "";
    const pageData = "&page=" + (page + 1);
    const keywordData = keyword != "" ? "&keyword=" + keyword : "";
    let sorts = sortBy ? sortBy : "";
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        if (page == 1) {
          dispatch(fetchStart());
        }
        axios
          .post(
            `admin/post/list?&sortBy=${sorts}&page=${page}&perPage=${perPage}`,
            filterData
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              dispatch(getContentPostsList(data));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Content Type API  && interest type api
  contentTypesListAPI: ({ token, page, perPage, order, type }) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/post/categories?type=${type}&order=${
              order ? order : "asc"
            }&perPage=${perPage}&page=${page + 1}`
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data?.message));
              dispatch(fetchSuccess());
              if (type === "area_of_interest") {
                dispatch(
                  getInterestTypeData({ list: data.data, meta: data.meta })
                );
              } else {
                dispatch(
                  getContentTypeData({ list: data.data, meta: data.meta })
                );
              }

              // dispatch(AuhMethods['contentManagement'].reportsListAPI({ token, page, perPage }));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Add Content Type API && interest type api
  addContentTypeAPI: (token, params, page, perPage, order, type) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .post(`admin/post/add_category?type=${type}`, params)
          .then(({ data }) => {
            if (data.message) {
              if (params?.type === "area_of_interest") {
                dispatch(fetchSuccess("Interest added successfully"));
              } else {
                dispatch(fetchSuccess(data?.message));
              }
              setTimeout(() => {
                dispatch(
                  AuhMethods["contentManagement"].contentTypesListAPI({
                    token,
                    page: page,
                    perPage: perPage,
                    order: order,
                    type: type,
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },

  //Update Content Type API && interest type api
  editContentTypeAPI: (token, id, params, page, perPage, order, type) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put("admin/post/edit_category", params)
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));

              setTimeout(() => {
                dispatch(
                  AuhMethods["contentManagement"].contentTypesListAPI({
                    token,
                    page: page,
                    perPage: perPage,
                    order: order,
                    type: type,
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },

  // Delete Content Type API && interest type api
  deleteContentTypeAPI: ({ token, id, page, perPage, order, type }) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .delete(
            `admin/post/delete_category/${id}`,
            {},
            { withCredentials: true }
          )
          .then(({ data }) => {
            if (data.message) {
              if (type === "area_of_interest") {
                dispatch(fetchSuccess("Interest is deleted successfully"));
              } else {
                dispatch(fetchSuccess(data?.message));
              }

              setTimeout(
                () =>
                  dispatch(
                    AuhMethods["contentManagement"].contentTypesListAPI({
                      token: token,
                      page: page,
                      perPage: perPage,
                      order: order,
                      type: type,
                    })
                  ),
                1000
              );
            } else {
              dispatch(fetchError(data?.message));
            }
            // resolve(data)
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  },

  //HashTags List API

  hashTagsListAPI: ({ token, page, perPage }) => {
    // let params = { perPage: 50 };
    // params = params ? "?" + new URLSearchParams(params).toString() : "";
    const pageData = page ? page + 1 : "";
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(
            `admin/post/hash_tags?order=desc&perPage=${perPage}&page=${pageData}`
          )
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data?.message));
              {
              }
              dispatch(fetchSuccess());
              dispatch(getHashTagsList(data));
              console.log("page: =====> 358", data);
              // dispatch(AuhMethods['contentManagement'].reportsListAPI({ token, page, perPage }));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  //Add  HashTags API
  addHashTagsAPI: (token, name) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .post("admin/post/add_hash_tag", { name })
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));

              // dispatch(addContentType(data.data));
              setTimeout(() => {
                dispatch(
                  AuhMethods["contentManagement"].hashTagsListAPI({
                    token,
                    page: "0",
                    perPage: "60",
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },

  // Delete Hash Tags API
  deleteHashTagsAPI: ({ token, id }) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .delete(`admin/post/delete_hash_tag/${id}`)
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));
              setTimeout(() => {
                dispatch(
                  AuhMethods["contentManagement"].hashTagsListAPI({
                    token: token,
                    page: "0",
                    perPage: "60",
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
            // resolve(data)
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  },

  // Post Detail API
  postDetailAPI: (token, id, type, perPage) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(`admin/post/post_data/${id}?&type=${type}&perPage=${perPage}`)
          .then(({ data }) => {
            if (data?.data) {
              dispatch(fetchSuccess());
              dispatch(postDetailAPIsuccess(data));
            } else {
              dispatch(fetchError(data?.message));
            }
            // resolve(data)
          })

          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
            // reject(error?.response?.data?.message)
          });
      } catch (error) {
        reject(error?.message);
        dispatch(fetchError(error.message));
      }
    };
  },

  getContentTitleListAPI: ({ token, page, perPage }) => {
    let params = { perPage: 50 };
    params = params ? "?" + new URLSearchParams(params).toString() : "";
    const pageData = page != undefined ? "&page=" + (page + 1) : "";
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .get(`admin/content/list`)
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess(data?.message));
              {
              }
              dispatch(fetchSuccess());
              dispatch(getContentTitle(data));
              // dispatch(AuhMethods['contentManagement'].reportsListAPI({ token, page, perPage }));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  updateContentTitleAPI: (token, data, params, page, perPage, order) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      try {
        dispatch(fetchStart());
        axios
          .put("admin/content/update", data)
          .then(({ data }) => {
            if (data.message) {
              dispatch(fetchSuccess(data?.message));

              setTimeout(() => {
                dispatch(
                  AuhMethods["contentManagement"].getContentTitleListAPI({
                    token,
                    page: page,
                    perPage: perPage,
                    order: order,
                  })
                );
              }, 1000);
            } else {
              dispatch(fetchError(data?.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },
};
export default ContentManagementServicesAuth;
