// AccountSettings

import React, { useEffect } from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getUsers } from "redux/actions/Users";
import { useHistory } from "react-router-dom";
import useStyles from "@coremat/CmtAvatar/index.style";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import { Email } from "@material-ui/icons";

const buttons = [
  {
    title: {
      text: "Edit Email",
      color: "#D36F1A"
    },
    icon: <Email />,
    backgroundColor: "#FFF1D2",
    link: "/contentTypes"
  },
  {
    title: {
      text: "Change Password",
      color: "#384CC4"
    },
    icon: <ImportContactsIcon />,
    backgroundColor: "#EAEDFF",
    link: "https://docs-jumbo.g-axon.work/settings/change-logs"
  }
];

const AccountSettings = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleClick = (item, index) => {
    if (index === 1) {
      history.push("/updatePassword");
    } else {
      history.push("/updateEmail");
    }
  };

  return (
    <div className={classes.root}>
      <List className={classes.list} disablePadding>
        {buttons.map((button, index) => (
          <ListItem
            key={index}
            alignItems="flex-start"
            button
            onClick={e => {
              handleClick(e, index);
            }}
            target="_blank"
            style={{
              backgroundColor: button.backgroundColor,
              color: button.title.color
            }}
            className={classes.listItem}
          >
            <ListItemIcon style={{ color: button.title.color }}>
              {button.icon}
            </ListItemIcon>
            <ListItemText
              primary={button.title.text}
              secondary={button.subTitle ? button.subTitle.text : ""}
              secondaryTypographyProps={{
                style: button.subTitle ? { color: button.subTitle.color } : {}
              }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default AccountSettings;
