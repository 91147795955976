import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CreatePost from '../NewsFeed/CreatePost';
import PostsList from '../NewsFeed/PostsList';
import { useDispatch } from 'react-redux';
import { getFeedPosts } from '../../../../redux/actions/WallApp';
import { getWallHeight } from '../../../../@jumbo/constants/AppConstants';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppContext from '../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext';
import PostsTabOptions from '../NewsFeed/PostsTabOptions';
import UsersList from './UsersList';

const useStyles = makeStyles(() => ({
  perfectScrollbarNewsFeed: {
    height: props => `calc(100vh - ${props.height}px)`,
  },
}));

const GeneralInfo = width => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const classes = useStyles({
    height: getWallHeight(width, showFooter),
  });
  const [tabValue, setTabValue] = useState('latestPosts');

  // useEffect(() => {
  //   // dispatch(getFeedPosts());
  // }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <PerfectScrollbar className={classes.perfectScrollbarNewsFeed}>
      {/* <Box mb={6}> */}
      {/* <CreatePost /> */}
      {/* <PostsTabOptions /> */}

      {/* <PostsTabOptions
        classes={classes}
        userDetail={{ name: 'abc' }}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
      /> */}
      {/* </Box> */}
      <div className="customScrollStyle">
        {' '}
        <UsersList />
      </div>
    </PerfectScrollbar>
  );
};

export default GeneralInfo;
