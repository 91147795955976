import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody
} from "@material-ui/core";
import UserTableToolbar from "./UserTableToolbar";
import UserTableHead from "./UserTableHead";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { API_URL } from "constant/constant";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import NoRecordFound from "../../BusinessPage/PageList/NoRecordFound";
import UserDashboard from "./UserDashboard";
import UserFilter from "./UserFilter";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const BostSubscription = () => {
  const classes = useStyles();
  const history = useHistory();
  const [order] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const FetchData = useSelector(state => state.common);
  const [from_date, handleFromChange] = useState();
  const [to_date, handleToChange] = useState();
  const [status, setstatus] = useState();
  const [boostPlanList, setboostPlanList] = React.useState([]);
  const [pageListMeta, setpageListMeta] = React.useState(10);
  const token = useSelector(state => state.admin.authUser.access_token);
  const dispatch = useDispatch();

  const handleSearchTextChange = e => {
    let data = e.target.value.replace(/^\s/, "");
    setSearchText(data);
  };
  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeStatus = (action, label, id, row) => {
    let data = {
      action: action,
      label: label,
      id: id,
      row: row
    };
    setSelectedUser(data);
    // setOpenConfirmDialog(true);
    if (label === "View" || label === "Edit") {
      history.push({
        pathname: "/add-subscription",
        search: id.toString(),
        hash: label
      });
    }
    if (label === "Disable") {
      setOpenConfirmDialog(true);
    }
    if (label === "Enable") {
      setOpenConfirmDialog(true);
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const HandleBoostPlanAPI = async ({
    token,
    page,
    perPage,
    keyword,
    order,
    from_date,
    to_date,
    status
  }) => {
    const pageData = page !== undefined ? page + 1 : "";
    keyword = keyword !== undefined ? keyword : "";
    const statusData = status !== undefined ? "&status=" + status : "";
    from_date =
      from_date !== undefined
        ? "&from_date=" + moment(from_date).format("YYYY-MM-DD")
        : "";
    to_date =
      to_date !== undefined
        ? "&to_date=" + moment(to_date).format("YYYY-MM-DD:23:59:59")
        : "";
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      await axios({
        method: "GET",
        url:
          `${API_URL}admin/boost_plan/list?order=${order}&` +
          statusData +
          from_date +
          to_date,
        headers: {
          Authorization: token
        },
        params: {
          keyword,
          perPage,
          page: pageData
        }
      })
        // return res;
        .then(({ data }) => {
          // //console.log("data -----", data);
          if (data.data) {
            dispatch(fetchSuccess());
            setboostPlanList(data.data);
            setpageListMeta(data.meta);
            // dispatch(setUsersListData(data));
          } else {
            dispatch(fetchError(data.message));
          }
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  useEffect(() => {
    HandleBoostPlanAPI({
      token: token,
      page: page,
      perPage: rowsPerPage,
      keyword: searchText,
      order,
      from_date,
      to_date,
      status
    });
  }, [page, rowsPerPage, searchText, from_date, to_date, status]);

  const handleConfirmDelete = async e => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }

    let data = {
      id: selectedUser.id,
      action: selectedUser?.row?.status === "1" ? 0 : 1
    };
    // console.log(selectedUser.id, " ---- selectedUser ----", selectedUser);
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "PUT",
        url: `${API_URL}admin/boost_plan/change_status`,
        headers: {
          Authorization: token
        },
        data
        // params: {
        // keyword,
        // perPage,
        // page: pageData,
        // },
      })
        // return res;
        .then(({ data }) => {
          // console.log("data ----- Testing", data);
          if (data.message) {
            dispatch(fetchSuccess(data.message));
            setOpenConfirmDialog(false);
            HandleBoostPlanAPI({
              token: token,
              page: page,
              perPage: rowsPerPage,
              keyword: searchText,
              order,
              from_date,
              to_date,
              status
            });
            // setboostPlanList(data.data);
            // setpageListMeta(data.meta);
            // // dispatch(setUsersListData(data));
          } else {
            setOpenConfirmDialog(false);
            dispatch(fetchError(data.message));
            HandleBoostPlanAPI({
              token: token,
              page: page,
              perPage: rowsPerPage,
              keyword: searchText,
              order,
              from_date,
              to_date,
              status
            });
          }
        });
    } catch (error) {
      setOpenConfirmDialog(false);
      dispatch(fetchError(error.message));
    }

    setOpenConfirmDialog(false);
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <UserTableToolbar count={boostPlanList.length} />
        <UserDashboard count={pageListMeta} />
        <UserFilter
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          from_date={from_date}
          handleFromChange={handleFromChange}
          to_date={to_date}
          handleToChange={handleToChange}
          status={status}
          setstatus={setstatus}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <UserTableHead />
            <TableBody>
              {boostPlanList.length > 0 ? (
                <>
                  {boostPlanList.map((row, index) => (
                    <UserListRow
                      key={index}
                      page={page}
                      idx={index}
                      row={row}
                      changeStatus={handleChangeStatus}
                    />
                  ))}
                </>
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={pageListMeta.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${
          selectedUser?.row?.status === "1" ? "Disable" : "Enable"
        } ${selectedUser?.row?.name ? selectedUser?.row?.name : ""}`}
        content={`Are you sure, you want to ${
          selectedUser?.row?.status === "1" ? "disable" : "enable"
        } this plan?`}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default BostSubscription;

BostSubscription.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
