import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
import UserListRowWhiteList from "./UserListRowWhiteList";
import UserTableHead from "./UserTableHead";
import UserTableToolbar from "./UserTableToolbar";
import UserTableHeadWhiteList from "./UserTableHeadWhiteList";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "redux/actions/Users";
import AddEditUser from "./AddEditUser";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { useDebounce } from "@jumbo/utils/commonHelper";
import useStyles from "./index.style";
import UserDetailView from "./UserDetailView";
import NoRecordFound from "./NoRecordFound";
import { useHistory } from "react-router-dom";
import { AuhMethods } from "services/auth";
import moment from "moment";
import UserDashboard from "./UserDashboard";
import UserFilter from "./UserFilter";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import axios from "axios";
import { API_URL } from "constant/constant";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import CreateWhiteList from "./CreateWhiteList";
const dailyData = [
  { name: "Total User", amount: "123" },
  { name: "Total Pending User", amount: "123" },
  { name: "Total Verified User", amount: "123" },
  { name: "Total Blocked User", amount: "23" }
];
const UsersModule = ({ method = "usersModule" }) => {
  const history = useHistory();

  const classes = useStyles();
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [pageW, setPageW] = React.useState(0);
  const [searchTextW, setSearchTextW] = React.useState("");
  const [verifyToggle, setVerifyToggle] = useState(false);
  const [whiteListedUser, setWhiteListedUser] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPageW, setRowsPerPageW] = React.useState(10);
  const [pageListMeta, setpageListMeta] = React.useState(10);
  const [score, setScore] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [WTUpdate, setWTUpdate] = useState(false);
  const [userWhiteList, setUserWhiteList] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [handlePopupTrue, setHandlePopupTrue] = useState(false);
  const [isEditScore, setIsEditScore] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [from_date, handleFromChange] = useState();
  const [to_date, handleToChange] = useState();
  const [status, setstatus] = useState();
  const [from_dateW, handleFromChangeW] = useState();
  const [to_dateW, handleToChangeW] = useState();
  const [statusW, setstatusW] = useState();
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [selectedRow, setselectedRow] = React.useState({});
  const [whiteListData, setWhiteListData] = React.useState({});
  const [changeWhiteListStatus, setchangeWhiteListStatus] = React.useState(
    false
  );
  const search = useDebounce(searchText, 800);
  const FetchData = useSelector(state => state?.common);
  const token = useSelector(state => state.admin.authUser.access_token);
  const userListMeta = useSelector(
    ({ usersModule }) => usersModule?.userListMeta
  ); //Fetch data from Redux
  const usersList = useSelector(state => state?.usersModule?.usersList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      AuhMethods[method].usersListAPI({
        token: token,
        page: page,
        perPage: rowsPerPage,
        keyword: searchText,
        order,
        status,
        from_date: from_date ? moment(from_date).format("YYYY-MM-DD") : "",
        to_date: to_date ? moment(to_date).format("YYYY-MM-DD:23:59:59") : ""
      })
    ); //token, page, perPage, keyword
  }, [
    page,
    rowsPerPage,
    search,
    from_date,
    to_date,
    status,
    userWhiteList,
    WTUpdate
  ]);
  /*****************/

  const WhiteListAPIHandler = async ({
    token,
    page,
    perPage,
    keyword,
    from_date,
    to_date
  }) => {
    const pageData = page != undefined ? page + 1 : "";
    from_date =
      from_date != undefined
        ? "&from_date=" + moment(from_date).format("YYYY-MM-DD")
        : "";
    to_date =
      to_date != undefined
        ? "&to_date=" + moment(to_date).format("YYYY-MM-DD:23:59:59")
        : "";

    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "GET",
        url: `${API_URL}admin/whiteList/list`,
        headers: {
          Authorization: token
        },
        params: {
          keyword,
          perPage,
          page: pageData
        }
      }).then(({ data }) => {
        if (data.data) {
          dispatch(fetchSuccess());
          setWhiteListedUser(data.data);
          setpageListMeta(data.meta);
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
    // };
  };
  useEffect(() => {
    WhiteListAPIHandler({
      token: token,
      page: pageW,
      perPage: rowsPerPageW,
      keyword: searchTextW,
      order,
      statusW,
      from_dateW,
      to_dateW
    });
  }, [
    pageW,
    rowsPerPageW,
    searchTextW,
    statusW,
    from_dateW,
    to_dateW,
    userWhiteList
  ]);

  /*****************/

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentUser(null));
  };
  const handleCloseUserDialog1 = () => {
    setHandlePopupTrue(false);
    dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {};

  const handleRowClick = (event, id) => {
    history.push({
      pathname: "/profile",
      state: { id: id }
    });
  };

  const handleSearchTextChange = e => {
    let data = e.target.value.replace(/^\s/, "");
    setSearchText(data);
  };

  const handlePageChangeW = (event, newPage) => {
    setPageW(newPage);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleRowsPerPageChangeW = event => {
    setRowsPerPageW(parseInt(event.target.value));
    setPageW(0);
  };

  const handleUserView = user => {
    history.push({
      pathname: "/profile",
      state: { id: user.user_id }
    });
  };
  const onVerify = data => {
    setVerifyToggle(true);
    setSelectedUser(data);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleUserEdit = user => {
    dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleUserBlock = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = e => {
    setOpenConfirmDialog(false);
    const params = {
      user_id: selectedUser.user_id,
      action: selectedUser?.status === "0" ? "1" : "0",
      page: page,
      perPage: rowsPerPage,
      keyword: searchText,
      order: order
    };

    dispatch(
      AuhMethods[method].changeUserStatusAPI(token, params, "UsersList")
    );
    setTimeout(() => 1000);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelVerify = () => {
    setVerifyToggle(false);
  };
  const handleConfirmVerify = () => {
    let params = {
      user_id: selectedUser?.user_id,
      action: selectedUser?.verified === "0" ? "1" : "0",
      page: page,
      perPage: rowsPerPage,
      keyword: searchText
    };
    dispatch(AuhMethods["usersModule"].usersVerifyAPI({ token, params }));
    setVerifyToggle(false);
  };
  const isSelected = id => selected.indexOf(id) !== -1;

  const onWhitelistEdit = data => {
    setIsEditScore(true);
    setScore(data.score);
    setselectedRow(data);
  };
  const onWhitelistUpdate = data => {
    setWhiteListData(data);
    setchangeWhiteListStatus(true);
  };

  const handleChangeWhitelist = async () => {
    try {
      dispatch(fetchStart());
      const res = await axios({
        method: "PUT",
        url: `${API_URL}admin/whiteList/changeStatus`,

        headers: {
          Authorization: token
        },
        params: {
          id: whiteListData?.id
        }
      }).then(({ data }) => {
        dispatch(fetchSuccess(data.message));
        setScore("");
        setchangeWhiteListStatus(false);
        WhiteListAPIHandler({
          token: token,
          page: pageW,
          perPage: rowsPerPageW,
          keyword: searchTextW,
          order,
          statusW,
          from_dateW,
          to_dateW
        });
        if (data.message) {
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={userListMeta?.total}
          userWhiteList={userWhiteList}
          setUserWhiteList={setUserWhiteList}
          status={status}
          from_date={from_date}
          to_date={to_date}
        />
        <UserDashboard
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          count={userListMeta}
          dailyData={dailyData}
        />
        {userWhiteList ? (
          <>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="sticky enhanced table"
              >
                <UserTableHeadWhiteList
                  userWhiteList={userWhiteList}
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={usersList?.length}
                />
                <TableBody>
                  {!!whiteListedUser.length ? (
                    whiteListedUser.map((row, index) => (
                      <UserListRowWhiteList
                        userWhiteList={userWhiteList}
                        key={index}
                        page={page}
                        idx={index}
                        row={row}
                        onRowClick={handleRowClick}
                        onUserEdit={handleUserEdit}
                        onUserBlock={handleUserBlock}
                        onUserView={handleUserView}
                        isSelected={isSelected}
                        onVerify={onVerify}
                        onWhitelistEdit={onWhitelistEdit}
                        onWhitelistUpdate={onWhitelistUpdate}
                      />
                    ))
                  ) : (
                    <TableRow style={{ height: 53 * 6 }}>
                      <TableCell colSpan={7} rowSpan={10}>
                        {
                          <NoRecordFound>
                            {FetchData.loading
                              ? "Loading records..."
                              : "No records found."}
                          </NoRecordFound>
                        }
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={pageListMeta?.total}
              rowsPerPage={rowsPerPageW}
              page={pageW}
              onPageChange={handlePageChangeW}
              onRowsPerPageChange={handleRowsPerPageChangeW}
            />
          </>
        ) : (
          <>
            {" "}
            <UserFilter
              selected={selected}
              setSelected={setSelected}
              onUserAdd={setOpenUserDialog}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              searchText={searchText}
              handleSearchTextChange={handleSearchTextChange}
              count={userListMeta?.total}
              actionHandleIcon={<MoreVertIcon />}
              actions={""}
              actionHandler={""}
              from_date={from_date}
              handleFromChange={handleFromChange}
              to_date={to_date}
              handleToChange={handleToChange}
              status={status}
              setstatus={setstatus}
            />
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="sticky enhanced table"
              >
                <UserTableHead
                  userWhiteList={userWhiteList}
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={usersList?.length}
                />
                <TableBody>
                  {!!usersList.length ? (
                    usersList.map((row, index) => (
                      <UserListRow
                        userWhiteList={userWhiteList}
                        key={index}
                        page={page}
                        idx={index}
                        row={row}
                        onRowClick={handleRowClick}
                        onUserEdit={handleUserEdit}
                        onUserBlock={handleUserBlock}
                        onUserView={handleUserView}
                        isSelected={isSelected}
                        onVerify={onVerify}
                        setHandlePopupTrue={setHandlePopupTrue}
                        handlePopupTrue={handlePopupTrue}
                        score={score}
                        setScore={setScore}
                        selectedRow={selectedRow}
                        setselectedRow={setselectedRow}
                      />
                    ))
                  ) : (
                    <TableRow style={{ height: 53 * 6 }}>
                      <TableCell colSpan={7} rowSpan={10}>
                        {
                          <NoRecordFound>
                            {FetchData.loading
                              ? "Loading records..."
                              : "No records found."}
                          </NoRecordFound>
                        }
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 500]}
              component="div"
              count={userListMeta?.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </>
        )}
      </Paper>

      {handlePopupTrue && (
        <CreateWhiteList
          open={handlePopupTrue}
          onCloseDialog={handleCloseUserDialog1}
          score={score}
          setScore={setScore}
          row={selectedRow}
          setWTUpdate={setWTUpdate}
          WTUpdate={WTUpdate}
          isEditScore={isEditScore}
        />
      )}
      {openUserDialog && (
        <AddEditUser
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}
      {openViewDialog && (
        <UserDetailView
          open={openViewDialog}
          onCloseDialog={handleCloseViewDialog}
        />
      )}

      <ConfirmDialog
        open={verifyToggle}
        title={`Confirm to ${
          selectedUser?.verified == "1" ? "Unverify" : "Verify"
        } ${selectedUser.name ? selectedUser.name : ""}`}
        content={`Are you sure, you want to ${
          selectedUser?.verified == "1" ? "unverify" : "verify"
        } this user?`}
        onClose={handleCancelVerify}
        onConfirm={handleConfirmVerify}
      />
      {whiteListData?.status && changeWhiteListStatus && (
        <ConfirmDialog
          open={changeWhiteListStatus}
          title={`Confirm to ${
            whiteListData?.status == "active" ? "DIACTIVATE" : "ACTIVATE"
          } ${
            whiteListData?.user_data?.name ? whiteListData?.user_data?.name : ""
          } whitelist`}
          content={`Are you sure, you want to ${
            whiteListData?.status == "active" ? "DIACTIVATE" : "ACTIVATE"
          } this user whitelist?`}
          onClose={() => setchangeWhiteListStatus(false)}
          onConfirm={handleChangeWhitelist}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${selectedUser?.status == "1" ? "Block" : "Unblock"} ${
          selectedUser.name ? selectedUser.name : ""
        }`}
        content={`Are you sure, you want to ${
          selectedUser?.status == "1" ? "block" : "unblock"
        } this user?`}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default UsersModule;
