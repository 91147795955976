import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import UserInfo from './UserInfo';
import PropTypes from 'prop-types';
import CommentUserInfo from './CommentUserInfo';

const CommentItemPost = ({ item, classes, itemType }) => {
  // const [isLiked, setIsLiked] = useState(item.liked);

  useEffect(() => {}, [item]);
  return (
    <Box mb={6}>
      {/* <UserInfo user={item.owner} date={item.date} classes={classes} /> */}
      {/* <CommentUserInfo user={item?.commented_user_data} date={item?.created_at} classes={classes} /> */}
      {itemType === 'likes' && <CommentUserInfo user={item?.like_user_data} date={item.created_at} classes={classes} />}
      {itemType === 'dislikes' && <CommentUserInfo user={item?.like_user_data} date={item.created_at} classes={classes} />}
      {itemType === 'comments' && (
        <CommentUserInfo user={item.commented_user_data} date={item.created_at} classes={classes} />
      )}
      {/* {itemType === 'views' && <CommentUserInfo user={item.commented_user_data} date={item.created_at} classes={classes} />} */}
      {itemType === 'comments' && (
        <Box ml={14}>
          <Box mt={2} component="p" color="text.secondary">
            {item?.comment}
          </Box>
          {/* <Box mt={3} display="flex" alignItems="center">
          <Button size="small" variant="contained" color="primary" onClick={() => setIsLiked(!isLiked)}>
            {isLiked ? 'Unlike' : 'Like'}
          </Button>
          <Box ml={2.5}>
            <Button size="small" variant="contained" color="secondary">
              Comment
            </Button>
          </Box>
        </Box> */}
        </Box>
      )}
    </Box>
  );
};

export default CommentItemPost;

CommentItemPost.prototype = {
  item: PropTypes.object.isRequired,
};
