import React from "react";
import {
  Grid,
  Box,
  FormHelperText,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

const SettingFields = ({ data, handleChange, index, teamsDataError }) => {
  // console.log(
  //   data.date,
  //   "moment(data.date).unix() > moment().unix()",
  //   data.date ? moment(data.date).unix() > moment().unix() : true
  // );
  return (
    <div>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={6}>
          <Box className="inputfield">
            <Typography
              component="div"
              variant="h3"
              style={{ paddingBottom: 10 }}
            >
              Release Date
            </Typography>
            <KeyboardDatePicker
              style={{ border: "1px solid black", padding: "9px" }}
              disablePast={
                data.date ? moment(data.date).unix() > moment().unix() : true
              }
              disabled={
                moment(data.date).unix() <
                moment()
                  .startOf("Days")
                  .unix()
              }
              value={data.date}
              inputFormat="MM/DD/YYYY"
              renderInput={(params) => <TextField {...params} />}
              onChange={(e) => handleChange(e, index, "date")}
              animateYearScrolling
            />
            <FormHelperText error>
              {(teamsDataError &&
                data.date == undefined &&
                "Please enter releasing date") ||
                (teamsDataError && data.date == "" && "Enter releasing date")}
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="inputfield">
            <Typography
              component="div"
              variant="h3"
              style={{ paddingBottom: 10 }}
            >
              Set Percentage{" "}
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="number"
              placeholder="Enter Percentage"
              fullWidth
              disabled={
                moment(data.date).unix() <
                moment()
                  .startOf("Days")
                  .unix()
              }
              name="percent"
              value={data.percent}
              autoComplete="off"
              required
              error={
                (teamsDataError && data.percent == undefined) ||
                (teamsDataError && data.percent == "")
              }
              onChange={(e) => handleChange(e, index, "percent")}
              helperText={
                (teamsDataError &&
                  data.percent == undefined &&
                  "Please enter  percentage") ||
                (teamsDataError &&
                  data.percent == "" &&
                  "Please enter  percentage")
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SettingFields;
