import React, { useEffect } from 'react';
import CmtCard from '../../../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../../../@coremat/CmtCard/CmtCardHeader';
import Box from '@material-ui/core/Box';
import UserInfo from './UserInfo';
import CmtCardContent from '../../../../../../@coremat/CmtCard/CmtCardContent';
import PostStats from './PostStats';
import CmtList from '../../../../../../@coremat/CmtList';
// import CommentItem from '../CommentListView/CommentItem/CommentItem';
import AddComment from './AddComment';
import Attachments from './Attachments';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CommentItem from './CommentItem';

const useStyles = makeStyles(() => ({}));

const PostItem = ({ item }) => {
  const classes = useStyles();

  const attachments = item.post_data.post_meta_data;
  const itemArray = [];

  useEffect(() => {
    itemArray.push(item);
  }, [item]);

  return (
    <CmtCard>
      {/* Same user detail upper post */}
      <div className="postIts">
        <CmtCardHeader title={<UserInfo user={item} />} />
      </div>

      {/* <CmtCardHeader title={<UserInfo item={report_user_data} />} /> */}
      <CmtCardContent>
        <Box>
          <Box mb={2} component="p">
            {item.post_data.user_data.biography}
          </Box>
          Post status :{' '}
          {item?.post_data?.status == 0 ? 'Inactivated' : item?.post_data?.status == 1 ? 'Activated' : 'Deleted'}
          {attachments.length > 0 && <Attachments attachments={attachments} />}
          <PostStats item={item} />
          {/* Comment on post  */}
          {Array.isArray(itemArray) && itemArray.length > 0 && (
            <CmtList
              data={itemArray}
              renderRow={(item, index) => <CommentItem key={index} item={item} classes={classes} />}
            />
          )}
          {/* <AddComment postId={id} /> */}
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default PostItem;

PostItem.prototype = {
  item: PropTypes.object.isRequired,
};
