import React from "react";
// import PropertiesGraph from "./PropertiesGraph";
import { makeStyles } from "@material-ui/core";
import { StatisticsCard } from "../../../../@jumbo/components/Common";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    color: theme.palette.common.white,
  },
  titleRoot: {
    fontSize: 12,
    fontWeight: 400,
    textTransform: "uppercase",
    letterSpacing: 0.4,
  },
}));

const TotalMembers = ({
  backgroundColor,
  title,
  titleProps,
  amount,
  description,
  children,
  item,
  // onAddRecord,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <StatisticsCard
      className={classes.cardRoot}
      backgroundColor="#7D38DF"
      title={title}
      titleProps={{
        variant: "inherit",
        component: "h4",
        className: classes.titleRoot,
      }}
      amount={amount ? amount : "0"}
      description=""
    >
      {/* <PropertiesGraph /> */}
    </StatisticsCard>
  );
};

export default TotalMembers;

// import React from "react";
// import CmtCardHeader from "../../../../@coremat/CmtCard/CmtCardHeader";
// import CmtAdvCard from "../../../../@coremat/CmtAdvCard";
// import CmtAdvCardContent from "../../../../@coremat/CmtAdvCard/CmtAdvCardContent";
// import makeStyles from "@material-ui/core/styles/makeStyles";
// import Box from "@material-ui/core/Box";
// import { Button } from "@material-ui/core";
// import { height } from "@amcharts/amcharts4/.internal/core/utils/Utils";

// const useStyles = makeStyles((theme) => ({
//   cardHeader: {
//     padding: 16,
//     minWidth: "140px",
//     // color: "#fafafa",
//   },
//   cardBody: {
//     position: "relative",
//   },
//   cardContent: {
//     padding: 16,
//     position: "absolute",
//     left: 0,
//     top: 0,
//     width: "100%",
//     zIndex: 1,
//   },
//   chartTitle: {
//     marginBottom: 6,
//   },
//   chartSubTitle: {
//     fontWeight: theme.typography.fontWeightRegular,
//     textTransform: "capitalize",
//     textAlign: "center",
//   },
//   buttonStyle: {
//     marginLeft: 10,
//   },
//   center: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     margin: "0 auto",
//     marginTop: "64px",
//   },
// }));

// // = ({ row, isSelected, onRowClick, onUserEdit, onRecordDelete, onDownloadReport })
// const DashboardCard = ({
//   backgroundColor,
//   title,
//   titleProps,
//   amount,
//   description,
//   children,
//   item,
//   // onAddRecord,
//   ...rest
// }) => {
//   const classes = useStyles();
//   return (
//     <CmtAdvCard backgroundColor={backgroundColor} {...rest}>
//       <CmtCardHeader
//         className={classes.cardHeader}
//         title={title}
//         titleProps={titleProps}
//         style={{ color: "#fafafa" }}
//       />
//       <Box className={classes.cardBody} height={80}>
//         <CmtAdvCardContent
//           className={classes.cardContent}
//           title={amount}
//           titleProps={{
//             variant: "h2",
//             component: "div",
//             className: classes.chartTitle,
//           }}
//           subTitle={description}
//           subTitleProps={{
//             className: classes.chartSubTitle,
//           }}
//           columnSizes={[12, 0]}
//           style={{
//             textAlign: "center",
//           }}
//         />
//         {children}
//       </Box>
//     </CmtAdvCard>
//   );
// };

// export default DashboardCard;
