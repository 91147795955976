import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { getComparator, stableSort } from "@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUsers, setCurrentUser } from "redux/actions/Users";
import AddEditContentType from "./AddEditContentType";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { useDebounce } from "@jumbo/utils/commonHelper";
import useStyles from "./index.style";
import NoRecordFound from "./NoRecordFound";
import { usersModule, recentActivities } from "../../../@fake-db";
import { getDateText } from "../../../@jumbo/utils/dateHelper";
import { useHistory } from "react-router-dom";
import ContentTypeTableToolbar from "./ContentTypeTableToolbar";
import ContentTypeTableHead from "./ContentTypeTableHead";
import ContentListRow from "./ContentListRow";
import { AuhMethods } from "services/auth";
import { setConstantValue } from "typescript";

const AppsSettings = () => {
  const history = useHistory();

  const classes = useStyles();
  // const { users } = useSelector(({ usersReducer }) => usersReducer);
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [searchText, setSearchText] = useState("");
  const [selectedName, setName] = useState([]);
  const [selectedId, setId] = useState([]);
  const [setValue, setSettingValue] = useState([]);
  const [editValue, setEditValue] = useState({
    setverifyvalue: "",
    setinfluencervalue: "",
  });
  // const [ setValue, setSettingValue ] = useState([]);
  const [Description, setDescription] = useState([]);
  const [Active, setActive] = useState();
  // const [users, setUser] = useState([]);

  const AppSettingType = useSelector(
    (state) => state.appSetting.AppSettingList
  );
  const token = useSelector((state) => state.admin.authUser.access_token);
  const AppSettingListMeta = useSelector(
    (state) => state.appSetting.AppSettingListMeta
  );
  const FetchData = useSelector((state) => state.common);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      AuhMethods["AppSettings"].settingListAPI({
        token,
        page: page,
        perPage: rowsPerPage,
        order: order,
      })
    );
  }, [page, rowsPerPage]);

  // useEffect(() => {
  //   if (AppSettingType?.AppSettingList) {
  //     const activity = AppSettingType?.AppSettingList?.map(item => {
  //       item.category = getDateText(item.updated_at);
  //       return item;
  //     });
  //     setUser(activity);
  //   }
  // }, [AppSettingType?.AppSettingList]);

  const getUsersListByDate = () => {
    return (
      Array.isArray(AppSettingType) &&
      AppSettingType.map((activity) => {
        activity.category = getDateText(activity.date);
        return activity;
      })
    );
  };
  const users = getUsersListByDate();

  const handleCloseUserDialog = () => {
    setOpenFlagDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFlagEdit = (user) => {
    // dispatch(setCurrentUser(user));
    // dispatch(setCurrentUser(user));

    setId(user.id);
    setName(user.key_name);
    setSettingValue(user.value);
    setEditValue({
      setverifyvalue: user.verify_user_value,
      setinfluencervalue: user.influencer_user_value,
    });
    // setName(newdata?.name);
    setDescription(user.description);
    setActive(user.status);
    setOpenFlagDialog(true);
    setEditFlag(true);
  };

  const handleFlagAdd = (user) => {
    setOpenFlagDialog(true);
    setEditFlag(false);
  };

  const handleFlagDelete = (user) => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(
      AuhMethods["contentManagement"].deleteContentTypeAPI({
        token: token,
        id: selectedUser.id,
        page: page,
        perPage: rowsPerPage,
        order: order,
        type: "area_of_interest",
      })
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ContentTypeTableToolbar
          selected={selected}
          setSelected={setSelected}
          onFlagAdd={handleFlagAdd}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          data={AppSettingType}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <ContentTypeTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {users ? (
                users.map((row, index) => (
                  <ContentListRow
                    key={index}
                    row={row}
                    onFlagEdit={handleFlagEdit}
                    onFladDelete={() => handleFlagDelete(row)}
                    isSelected={isSelected}
                  />
                ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No Records Found"}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {AppSettingListMeta.total > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={AppSettingListMeta.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      </Paper>

      {openFlagDialog && (
        <AddEditContentType
          open={openFlagDialog}
          onCloseDialog={handleCloseUserDialog}
          isedit={editFlag}
          selectedName={selectedName}
          setValue={setValue}
          setinfluencervalue={
            editValue?.setinfluencervalue ? editValue?.setinfluencervalue : ""
          }
          setverifyvalue={
            editValue?.setverifyvalue ? editValue?.setverifyvalue : ""
          }
          Active={Active}
          setDes={Description}
          selectedId={selectedId}
          page={page}
          perPage={rowsPerPage}
          order={"asc"}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={"Are you sure, you want to delete this interest type?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default AppsSettings;
