import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CreatePost from './CreatePost';
import PostsList from './PostsList';
import { useDispatch } from 'react-redux';
import { getFeedPosts } from '../../../../redux/actions/WallApp';
import { getWallHeight } from '../../../../@jumbo/constants/AppConstants';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppContext from '../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext';
import PostsTabOptions from './PostsTabOptions';
// const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux

const useStyles = makeStyles(() => ({
  perfectScrollbarNewsFeed: {
    height: props => `calc(100vh - ${props.height}px)`,
  },
}));

const NewsFeed = ({ width, onChangeTab, handleScroll, postData, type }) => {
  // const NewsFeed = width => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const classes = useStyles({
    height: getWallHeight(width, showFooter),
  });
  const [tabValue, setTabValue] = useState('latest');

  useEffect(() => {
    // dispatch(getFeedPosts());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    onChangeTab(newValue);
  };

  const onScroll = e => {
    let el = document.getElementById('kk');
    if (parseInt(el.scrollHeight - el.scrollTop) === parseInt(el.clientHeight)) {
      handleScroll(e);
    }
  };

  return (
    <PerfectScrollbar id="kk" onScroll={onScroll} className={classes.perfectScrollbarNewsFeed}>
      {/* <Box mb={6}> */}
      {/* <CreatePost /> */}
      {/* <PostsTabOptions /> */}

      <PostsTabOptions
        classes={classes}
        userDetail={{ name: 'abc' }}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
      />
      {/* </Box> */}
      <PostsList postData={postData} type={type} />
    </PerfectScrollbar>
  );
};

export default NewsFeed;
