import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid } from "@material-ui/core";
import useStyles from "./index.style";
import GridContainer from "@jumbo/components/GridContainer";
import DashboardCard from "../../../../@jumbo/components/Common/DashboardCard/index";

const UserDashboard = ({ count }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Toolbar>
        <GridContainer>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={<span style={{ color: "#fafafa" }}>Total Pages</span>}
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count.totalPages ? count.totalPages : "0"}
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={
                <span style={{ color: "#fafafa" }}>Total Active Pages</span>
              }
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              description=""
              amount={count.totalActivePage ? count.totalActivePage : "0"}
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={
                <span style={{ color: "#fafafa" }}>Total Block Pages</span>
              }
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count.totalBlockPage ? count.totalBlockPage : "0"}
              description=""
              item="0"
            />
          </Grid>
        </GridContainer>
      </Toolbar>
    </React.Fragment>
  );
};

UserDashboard.propTypes = {};

export default React.memo(UserDashboard);
