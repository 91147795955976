import React, {useEffect, useState} from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import UserListRow from "./UserListRow";
import UserTableHead from "./UserTableHead";
import UserTableToolbar from "./UserTableToolbar";

import {useDispatch, useSelector} from "react-redux";
import {setCurrentUser} from "redux/actions/Users";
import AddEditUser from "./AddEditUser";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import UserDetailView from "./UserDetailView";
import NoRecordFound from "./NoRecordFound";

import {useHistory} from "react-router-dom";
import {AuhMethods} from "services/auth";

const PageList = ({
  method = "usersModule",
  variant = "default",
  wrapperVariant = "default"
}) => {
  const history = useHistory();

  const classes = useStyles();
  const [ orderBy, setOrderBy ] = React.useState("name");
  const [ order, setOrder ] = React.useState("desc");
  const [ page, setPage ] = React.useState(0);
  const [ searchText, setSearchText ] = React.useState("");
  const [ verifyToggle, setVerifyToggle ] = useState(false);

  const [ rowsPerPage, setRowsPerPage ] = React.useState(10);
  const [ selected, setSelected ] = React.useState([]);
  const [ openViewDialog, setOpenViewDialog ] = useState(false);
  const [ openUserDialog, setOpenUserDialog ] = useState(false);
  const [ openConfirmDialog, setOpenConfirmDialog ] = useState(false);
  const [ selectedUser, setSelectedUser ] = useState({name: ""});

  const [ filterOptions, setFilterOptions ] = React.useState([]);
  const FetchData = useSelector(state => state.common);

  const token = useSelector(state => state.admin.authUser.access_token);

  const pageListMeta = useSelector(
    state => state.businessPageManagement.pageListMeta
  );
  const pageList = useSelector(state => state.businessPageManagement.pageList);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(
        AuhMethods["businessModule"].pageListAPI({
          token: token,
          page: page,
          perPage: rowsPerPage,
          keyword: searchText,
          order
        })
      );
    },
    [ page, rowsPerPage, searchText ]
  );

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {
    // if (event.target.checked) {
    //   const newSelected = users.map(n => n.id);
    //   setSelected(newSelected);
    //   return;
    // }
    // setSelected([]);
  };

  const handleRowClick = (event, id) => {
    history.push({
      pathname: "/pageDetails",
      state: {id: id}
    });
  };

  const handleSearchTextChange = e => {
    let data = e.target.value.replace(/^\s/, "");
    setSearchText(data);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleUserView = user => {
    history.push({
      pathname: "/crypto-page-details",
      state: {id: user.id}
    });
  };
  const onVerify = data => {
    setVerifyToggle(true);
    setSelectedUser(data);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleUserEdit = user => {
    dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleUserBlock = user => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleUserStatus = e => {
    setOpenConfirmDialog(false);
    const params = {
      business_page_id: selectedUser.id,
      action: selectedUser.status === "0" ? "1" : "0"
    };

    dispatch(
      AuhMethods["businessModule"].PageVerificationProcessAPI(
        token,
        params,
        "pageList"
      )
    );
    setTimeout(
      () =>
        dispatch(
          AuhMethods["businessModule"].pageListAPI({
            token: token,
            page: page,
            perPage: rowsPerPage,
            keyword: searchText,
            order
          })
        ),
      1000
    );
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelVerify = () => {
    setVerifyToggle(false);
  };
  const handleConfirmVerify = () => {
    let params = {
      business_page_id: selectedUser.id,
      action: selectedUser.verified === "0" ? "1" : "0"
    };
    dispatch(
      AuhMethods["businessModule"].BusinessPageVerificationAPI({
        token,
        params
      })
    );
    setTimeout(
      () =>
        dispatch(
          AuhMethods[method].pageListAPI({
            token: token,
            page: page,
            perPage: rowsPerPage,
            keyword: searchText
          })
        ),
      1000
    );
    setVerifyToggle(false);
  };
  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <UserTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={pageList.length}
            />
            <TableBody>
              {/* {!!pageList.length ? (
                pageList
                  .slice(0, rowsPerPage)
                  .map((row, index) => (
                    <UserListRow
                      key={index}
                      page={page}
                      idx={index}
                      row={row}
                      onRowClick={handleUserView}
                      onUserEdit={handleUserEdit}
                      onUserBlock={handleUserBlock}
                      onUserView={handleUserView}
                      isSelected={isSelected}
                      onVerify={onVerify}
                    />
                  ))
              ) : ( */}
              {!!pageList.length && (
                <TableRow style={{height: 53 * 6}}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading ? (
                          "Loading records..."
                        ) : (
                          "No records found."
                        )}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
              {/* )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[ 10, 50, 100, 500 ]}
          component="div"
          count={pageListMeta.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openUserDialog && (
        <AddEditUser
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}
      {openViewDialog && (
        <UserDetailView
          open={openViewDialog}
          onCloseDialog={handleCloseViewDialog}
        />
      )}

      <ConfirmDialog
        open={verifyToggle}
        title={`Confirm to ${selectedUser.verified == "1"
          ? "Unverify"
          : "Verify"} ${selectedUser.name ? selectedUser.name : ""}`}
        content={`Are you sure, you want to ${selectedUser.verified == "1"
          ? "unverify"
          : "verify"} this user?`}
        onClose={handleCancelVerify}
        onConfirm={handleConfirmVerify}
      />

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${selectedUser.status == "1"
          ? "Block"
          : "Unblock"} ${selectedUser.name ? selectedUser.name : ""}`}
        content={`Are you sure, you want to ${selectedUser.status == "1"
          ? "block"
          : "unblock"} this user?`}
        onClose={handleCancelDelete}
        onConfirm={handleUserStatus}
      />
    </div>
  );
};

export default PageList;
