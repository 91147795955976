import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React from 'react';
import { Label } from '@material-ui/icons';

const headCells = [
  {
    id: 'Sr No',
    numeric: false,
    disablePadding: false,
    label: 'Sr No',
  },
  {
    id: 'postedBy',
    numeric: false,
    disablePadding: false,
    label: 'Posted by',
  },
  { id: 'postDateAndTime', numeric: false, disablePadding: false, label: 'Post date & time' },
  { id: 'postType,', numeric: false, disablePadding: false, label: 'Post type' },
  { id: 'reportedBy', numeric: false, disablePadding: false, label: 'Reported by' },
  {
    id: 'reportType',
    numeric: false,
    disablePadding: false,
    label: 'Report type',
  },
  { id: 'reportDateAndTime', numeric: false, disablePadding: true, label: 'Report date & time' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Post status' },
  { id: 'Actions', numeric: false, disablePadding: false, label: 'Actions' },
];

function FlaggedReportTableHead({ classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}>
            {headCell.label}
          </TableCell>
        ))}
        {/* <TableCell align="center">Actions</TableCell> */}
      </TableRow>
    </TableHead>
  );
}

FlaggedReportTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default React.memo(FlaggedReportTableHead);
