import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { AuhMethods } from "services/auth";
import FollowRecord from "@jumbo/components/Common/FollowRecord/FollowingRecord";
import { usersFollowerData } from "redux/actions/UsersModule";
import FollowingRecord from "@jumbo/components/Common/FollowRecord/FollowingRecord";

const useStyles = makeStyles(theme => ({
  socialStatsRoot: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    paddingBottom: 22
  },
  socialStatsItem: {
    paddingLeft: 12,
    paddingRight: 12,
    cursor: "pointer",
    width: "33.33%",
    "&:not(:first-child)": {
      borderLeft: `1px solid ${theme.palette.borderColor.main}`
    }
  }
}));

const SocialStats = ({ userDetail }) => {
  const [itemType, setItemType] = useState("");
  const [followerDialogue, setFollowerDialogue] = useState(false);
  const [followingDialogue, setFollowingDialogue] = useState(false);
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  const followerData = useSelector(
    state => state?.businessPageManagement?.pageFollower
  );
  const followingData = useSelector(
    state => state?.businessPageManagement?.pageFollowing
  ); //Fetch data from Redux
  const {
    total_followers,
    total_following,
    rewards,
    reward_points
  } = userDetail;

  const classes = useStyles();
  const dispatch = useDispatch();

  const getFollowers = () => {
    setItemType("Followers");
    let order = "desc";
    let userId = userDetail?.user_id;
    dispatch(
      AuhMethods["businessModule"].pageFollowerList(token, order, 10, 1, userId)
    );
    setFollowerDialogue(true);
  };
  const getFollowing = () => {
    setItemType("Following");
    let order = "desc";
    let userId = userDetail?.user_id;
    dispatch(
      AuhMethods["businessModule"].pageFollowingList(
        token,
        userId,
        10,
        1,
        order
      )
    );
    setFollowingDialogue(true);
  };

  const handleCloseViewDialog = () => {
    setItemType("");
    setFollowerDialogue(false);
    setFollowingDialogue(false);
    dispatch(usersFollowerData([]));
  };
  return (
    <Box className={`${classes.socialStatsRoot} socialStstsCustom`}>
      <Box className={classes.socialStatsItem}>
        <Typography component="div" variant="h3">
          {total_followers}
        </Typography>
        <Box
          component="span"
          color="text.secondary"
          fontSize={12}
          onClick={getFollowers}
        >
          Followers
        </Box>
      </Box>
      <Box className={classes.socialStatsItem}>
        <Typography component="div" variant="h3">
          {total_following}
        </Typography>
        <Box
          component="span"
          color="text.secondary"
          fontSize={12}
          onClick={getFollowing}
        >
          Following
        </Box>
      </Box>
      <Box className={classes.socialStatsItem}>
        <Typography component="div" variant="h3">
          {reward_points ? reward_points : 0}
        </Typography>
        <Box component="span" color="text.secondary" fontSize={12}>
          Reward Points
        </Box>
      </Box>
      {followerDialogue &&
        Array.isArray(followerData?.data) &&
        followerData?.data.length > 0 && (
          <FollowRecord
            open={followerDialogue}
            onCloseDialog={handleCloseViewDialog}
            itemType={itemType}
            data={followerData?.data}
          />
        )}
      {followingDialogue &&
        Array.isArray(followingData?.data) &&
        followingData?.data.length > 0 && (
          <FollowingRecord
            open={followingDialogue}
            onCloseDialog={handleCloseViewDialog}
            itemType={itemType}
            data={followingData?.data}
          />
        )}
    </Box>
  );
};

export default SocialStats;

SocialStats.prototype = {
  userDetail: PropTypes.object.isRequired
};
