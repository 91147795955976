import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import useStyles from "./index.style";
import DashboardCard from "../../../../@jumbo/components/Common/DashboardCard/index";
import GridContainer from "@jumbo/components/GridContainer";

const UserDashboard = ({ selected, count }) => {
  const classes = useStyles();

  const numSelected = selected.length;
  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        <GridContainer>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={<span style={{ color: "#fafafa" }}>Total Users</span>}
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={
                count?.totalInActiveUser + count?.totalActiveUser
                  ? count?.totalInActiveUser + count?.totalActiveUser
                  : "0"
              }
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={
                <span style={{ color: "#fafafa" }}>Total Active Users</span>
              }
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalActiveUser ? count?.totalActiveUser : "0"}
              description=""
              item="0"
            />
          </Grid>
          <Grid item lg={3} sm={6} md={3} xs={12}>
            <DashboardCard
              className={classes.cardRoot}
              backgroundColor="#3f51b58c"
              title={
                <span style={{ color: "#fafafa" }}>Total Inactive Users</span>
              }
              titleProps={{
                variant: "inherit",
                component: "h4",
                className: classes.titleRoot
              }}
              amount={count?.totalInActiveUser ? count?.totalInActiveUser : "0"}
              description=""
              item="0"
            />
          </Grid>
        </GridContainer>
      </Toolbar>
    </React.Fragment>
  );
};

UserDashboard.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchText: PropTypes.string,
  handleSearchTextChange: PropTypes.func,
  onUserAdd: PropTypes.func
};

export default React.memo(UserDashboard);
