import {alpha, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative",
    "& .MuiDialog-paperWidthSm": {
      width: "100%"
    }
  },
  userInfoRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    padding: "20px 24px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  avatarView: {
    [theme.breakpoints.down("sm")]: {
      "& .Cmt-avatar-size": {
        width: 40,
        height: 40
      }
    }
  },
  titleRoot: {
    fontSize: 16,
    color: theme.palette.common.dark,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up("md")]: {
      fontSize: 18
    }
  },
  subTitleRoot: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary
  },
  labelRoot: {
    backgroundColor: alpha(theme.palette.common.dark, 0.05),
    color: theme.palette.text.disabled,
    padding: "4px 10px",
    borderRadius: 4,
    textTransform: "capitalize"
  }
}));

export default useStyles;
