import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import GridContainer from "../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import GeneralInfo from "./GeneralInfo";
import Activities from "./Activities";
import NewsFeed from "./NewsFeed";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { user } from "@fake-db/apps/wall";
import { AuhMethods } from "services/auth";
import { useLocation } from "react-router";

const useStyles = makeStyles(theme => ({
  wallLeftSidebar: {
    "@media screen and (min-width: 960px) and (max-width: 1020px)": {
      flexBasis: "40%",
      maxWidth: "40%"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  wallMainContent: {
    "@media screen and (min-width: 960px) and (max-width: 1020px)": {
      flexBasis: "60%",
      maxWidth: "60%"
    }
  },
  wallRightSidebar: {
    "@media screen and (max-width: 1020px)": {
      display: "none"
    }
  }
}));

const Wall = ({ method = "usersModule" }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  const location = useLocation();
  const [userId] = React.useState(location?.state?.id);
  const [page, setPage] = React.useState(1);
  const [postData, setPostData] = React.useState({});
  const [perPage] = React.useState(10);
  const [type, setType] = React.useState("latest"); //most_liked
  const postUserMetaData = useSelector(
    state => state?.usersModule?.morePostMetaData
  );
  const history = useHistory();
  const userDetail = user;
  useEffect(() => {
    if (location?.state?.id) {
      dispatch(AuhMethods[method].userProfileAPI({ token, userId }));
      dispatch(
        AuhMethods[method].userPostsAPI({ token, userId, page, perPage, type })
      ); //usersModule
    } else {
      history.goBack();
    }
    let data = {
      userId: userId,
      page: page,
      perPage: perPage,
      type: type
    };
    setPostData(data);
  }, []);

  const handleTabChange = type => {
    setPage(1);
    setType(type);
    dispatch(
      AuhMethods[method].userPostsAPI({ token, userId, page, perPage, type })
    );
  };

  const handleScroll = e => {
    const bottom =
      parseInt(e.target.scrollHeight - e.target.scrollTop) ===
      parseInt(e.target.clientHeight);

    if (bottom) {
      if (page != postUserMetaData.pages) {
        const pageValue = page + 1;
        dispatch(
          AuhMethods[method].userPostsAPI({
            token,
            userId,
            page: pageValue,
            perPage,
            type
          })
        ); // page, perPage, type
        setPage(page + 1);
      }
    }
  };

  return (
    <React.Fragment>
      {userDetail && (
        <GridContainer>
          <Grid item xs={12} sm={5} md={3} className={classes.wallLeftSidebar}>
            <GeneralInfo />
          </Grid>
          <Grid item xs={12} sm={7} md={6} className={classes.wallMainContent}>
            <NewsFeed
              onChangeTab={handleTabChange}
              handleScroll={handleScroll}
              postData={postData}
              type={type}
            />
          </Grid>
          <Grid item xs={12} md={3} className={classes.wallRightSidebar}>
            <Activities userId={userId} />
          </Grid>
        </GridContainer>
      )}
    </React.Fragment>
  );
};

export default Wall;
