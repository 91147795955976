import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";
import { AuhMethods } from "services/auth";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const FundType = [
  { title: "Locked", type: "locked" },
  { title: "Unlocked", type: "unlocked" }
];

const ManageFundsModal = ({
  open,
  onCloseDialog,
  fundsData,
  page,
  rowsPerPage,
  searchText
}) => {
  const classes = useStyles();
  const currentUser = {};
  const [type, setType] = useState("");
  const [typeError, setTypeError] = useState("");
  const [amount, setAmount] = useState(0);
  const token = useSelector(state => state.admin.authUser.access_token);
  const [isDisables, setIsDisables] = useState(false);
  const [amountError, setAmountError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    // amount type
    if (type == "locked") {
      if (amount > fundsData?.balance) {
        //disable here
        setIsDisables(true);
      } else {
        setIsDisables(false);
      }
    } else if ("unlocked") {
      if (amount > fundsData?.locked_balance) {
        //disable here
        setIsDisables(true);
      } else {
        setIsDisables(false);
      }
    }
  }, [currentUser, amount]);

  const onSubmitClick = () => {
    if (!type) {
      setTypeError(requiredMessage);
    } else if (!amount) {
      setAmountError(requiredMessage);
    } else {
      //Create Reward API
      const updateData = {
        amount: amount,
        wallet_id: fundsData.id,
        type: type
      };
      dispatch(
        AuhMethods["walletManagement"].updateUserWalletFundAPI({
          token: token,
          params: updateData,
          page: page,
          rowsPerPage: rowsPerPage,
          searchText: searchText
        })
      );
      onCloseDialog();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      className={`${classes.dialogRoot} dialogCustom`}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {" "}
        <span>{"Manage Funds"} </span>
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000"
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        ></Box>

        <Box display="flex" justifyContent="flax-start" mb={4}>
          <Typography
            className={classes.title}
            variant="h5"
            id="tableTitle"
            component="div"
            style={{ marginBottom: 20 }}
          >
            Available Balance: {fundsData?.balance}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flax-start" mb={4}>
          <Typography
            className={classes.title}
            variant="h5"
            id="tableTitle"
            component="div"
            style={{ marginBottom: 20 }}
          >
            Locked Balance : {fundsData?.locked_balance}
          </Typography>
        </Box>

        <Box justifyContent="flex-end" mb={4} style={{ marginBottom: 32 }}>
          <GridContainer style={{ marginBottom: 12, marginLeft: 0 }}>
            <Grid mb={{ xs: 6, md: 10 }} style={{ width: "90%" }}>
              <AppSelectBox
                fullWidth
                data={FundType}
                label="Type"
                valueKey="type"
                variant="outlined"
                labelKey="title"
                value={type}
                required={true}
                onChange={e => {
                  setType(e.target.value);
                  setTypeError("");
                }}
                helperText={typeError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <GridContainer style={{ marginBottom: 12, marginLeft: 0 }}>
            <Grid mb={{ xs: 6, md: 5 }} style={{ width: "94%" }}>
              <AppTextInput
                fullWidth
                type="number"
                variant="outlined"
                label="Amount"
                value={amount}
                onChange={e => {
                  setAmount(e.target.value);
                  setAmountError("");
                }}
                helperText={amountError}
              />
            </Grid>
          </GridContainer>
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmitClick}
              disabled={isDisables}
            >
              {type === "locked" ? "Lock Amount" : "Unlock Amount"}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ManageFundsModal;

ManageFundsModal.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
