import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "redux/actions/Users";
import AddEditContentType from "./AddEditContentType";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import useStyles from "./index.style";
import NoRecordFound from "./NoRecordFound";
import { getDateText } from "../../../@jumbo/utils/dateHelper";
import { useHistory } from "react-router-dom";
import ContentTypeTableToolbar from "./ContentTypeTableToolbar";
import ContentTypeTableHead from "./ContentTypeTableHead";
import ContentListRow from "./ContentListRow";
import { AuhMethods } from "services/auth";

const UpdateContent = () => {
  const history = useHistory();

  const classes = useStyles();
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedName] = useState([]);
  const [selectedId] = useState([]);
  const [setValue] = useState([]);

  const TitleList = useSelector(state => state?.contentManagement?.TitleList);
  const token = useSelector(state => state.admin.authUser.access_token);
  const FetchData = useSelector(state => state?.common);
  // const TitleListMeta = useSelector(state => state?.contentManagement?.TitleListMeta);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      AuhMethods["contentManagement"].getContentTitleListAPI({
        token,
        page: page,
        perPage: "",
        order: order,
        type: ""
      })
    );
  }, [page, rowsPerPage]);

  const getUsersListByDate = () => {
    return (
      Array.isArray(TitleList) &&
      TitleList.map(activity => {
        activity.category = getDateText(activity.date);
        return activity;
      })
    );
  };
  const users = getUsersListByDate();

  const handleCloseUserDialog = () => {
    setOpenFlagDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleFlagEdit = row => {
    if (row) {
      history.push({
        pathname: "/Editor",
        state: {
          row: row
        }
      });
    }

    setEditFlag(true);
  };

  const handleFlagAdd = user => {
    setOpenFlagDialog(true);
    setEditFlag(false);
  };

  const handleFlagDelete = user => {
    setOpenConfirmDialog(true);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ContentTypeTableToolbar
          selected={selected}
          setSelected={setSelected}
          onFlagAdd={handleFlagAdd}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          data={TitleList}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <ContentTypeTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {users ? (
                users.map((row, index) => (
                  <ContentListRow
                    key={index}
                    row={row}
                    onFlagEdit={handleFlagEdit}
                    onFladDelete={() => handleFlagDelete(row)}
                    isSelected={isSelected}
                  />
                ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {
                      <NoRecordFound>
                        {FetchData.loading
                          ? "Loading records..."
                          : "No records found."}
                      </NoRecordFound>
                    }
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {openFlagDialog && (
        <AddEditContentType
          open={openFlagDialog}
          onCloseDialog={handleCloseUserDialog}
          isedit={editFlag}
          selectedName={selectedName}
          setValue={setValue}
          selectedId={selectedId}
          page={page}
          perPage={rowsPerPage}
          order={order}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={"Are you sure, you want to delete this interest type?"}
        onClose={handleCancelDelete}
        // onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default UpdateContent;
