import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import GridContainer from "@jumbo/components/GridContainer";
import Activities from "./Activities";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from "axios";
import { API_URL } from "constant/constant";
import AuthWrapper from "@jumbo/components/Common/authComponents/AuthWrapper";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";

const useStyles = makeStyles(theme => ({
  wallLeftSidebar: {
    "@media screen and (min-width: 960px) and (max-width: 1020px)": {
      flexBasis: "40%",
      maxWidth: "40%"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  wallMainContent: {
    "@media screen and (min-width: 960px) and (max-width: 1020px)": {
      flexBasis: "60%",
      maxWidth: "60%"
    }
  },
  wallRightSidebar: {
    "@media screen and (max-width: 1020px)": {
      display: "none"
    }
  },
  authContent: {
    // padding: 30,
    "& .btnB": {
      margin: "0 15px"
    },
    "& .inputPasswordCustom1": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start"
    },
    [theme.breakpoints.up("md")]: {
      order: 1,
      width: props => (props.variant === "default" ? "50%" : "100%")
    },
    [theme.breakpoints.up("xl")]: {
      padding: 10
    }
  }
}));

const PageDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(state => state.admin.authUser.access_token);
  const [influencerDetails, setinfluencerDetails] = useState({});

  const InfluencerDetails = async () => {
    try {
      let id = window.location.search.split("?")[1];
      dispatch(fetchStart());
      await axios({
        method: "GET",
        url: `${API_URL}admin/influencer_apply/details`,
        headers: {
          Authorization: token
        },
        params: {
          id
        }
      }).then(({ data }) => {
        if (data.data) {
          dispatch(fetchSuccess());
          setinfluencerDetails(data.data);
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };
  useEffect(() => {
    InfluencerDetails();
  }, []);

  return (
    <React.Fragment>
      <AuthWrapper variant={"default"}>
        <Box className={classes.authContent}>
          <GridContainer>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              className={classes.wallRightSidebar}
            >
              <Activities influencerDetails={influencerDetails} />
            </Grid>
          </GridContainer>
        </Box>
      </AuthWrapper>
    </React.Fragment>
  );
};

export default PageDetails;
