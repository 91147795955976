// FilterOptionsHeader
import React from 'react';
import Box from '@material-ui/core/Box';
// import CmtAvatar from '../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles';
import CmtImage from '@coremat/CmtImage';
import CmtSearch from '@coremat/CmtSearch';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, NativeSelect, Select } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
// import CmtImage from '../../../@coremat/CmtImage';

const tabs = [
  { id: 1, title: 'Latest Posts', slug: 'latestPosts' },
  { id: 2, title: 'Most Liked Post', slug: 'mostLikedPosts' },
];

const useStyles = makeStyles(theme => ({
  mainViewStyle: {
    backgroundColor: theme.palette.common.white,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },

  headerRoot: {
    position: 'relative',
    margin: '-30px -15px 0 -15px',
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 30,
    paddingBottom: 20,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 56,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: -50,
      marginRight: -50,
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: -65,
      marginRight: -65,
      paddingLeft: 65,
      paddingRight: 65,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: -88,
      marginRight: -88,
      paddingLeft: 88,
      paddingRight: 88,
    },
  },
  headerBgImg: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    minHeight: 370,
    zIndex: 0,
    [theme.breakpoints.up('sm')]: {
      minHeight: 270,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: alpha(theme.palette.primary.main, 0.5),
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  headerContent: {
    position: 'relative',
    zIndex: 3,
  },
  titleRoot: {
    color: theme.palette.common.white,
    marginBottom: 4,
  },
  subTitleRoot: {
    color: alpha(theme.palette.common.black, 0.74),
  },
  followerList: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    marginRight: -15,
    [theme.breakpoints.up('md')]: {
      marginLeft: -24,
      marginRight: -24,
    },
  },
  followerListItem: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
    cursor: 'pointer',
    color: alpha(theme.palette.common.white, 0.74),
    fontSize: 12,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    '&:not(:first-child)': {
      borderLeft: `solid 1px ${alpha(theme.palette.common.white, 0.38)}`,
    },
  },
  followerListTitle: {
    color: theme.palette.common.white,
    marginBottom: 3,
  },
  tabsList: {
    position: 'relative',
    minHeight: 10,
    '& .MuiTabs-indicator': {
      backgroundColor: alpha(theme.palette.common.white, 0.4),
    },
  },
  tabItem: {
    maxWidth: 'none',
    minWidth: 10,
    minHeight: 10,
    padding: '5px 10px',
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
  },
  searchStyle: {
    // backgroundColor: 'red', //todo
  },
}));

const FilterOptionsHeader = ({ userDetail, tabValue, onFilter, search, onSearch }) => {
  const { name } = userDetail;
  const classes = useStyles();

  // const [searchUser, setSearchUser] = React.useState('');
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });
  // const [keyword, setKeyword] = useState('')

  // const submitHandler = (e) => {
  //   e.preventDefault()
  //   if (keyword.trim()) {
  //     history.push(`/search/${keyword}`)
  //   } else {
  //     history.push('/')
  //   }
  // }

  const handleChange = event => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      mb={4}
      className={classes.mainViewStyle}>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center">
        {/* <Box ml={{ sm: 'auto' }} mt={{ xs: 3, sm: 0 }} style={{ marginBottom: '10px' }}> */}
        {/* <Box> */}
        {/* <React.Fragment> */}
        <CmtSearch
          className={classes.searchStyle}
          // onChange={e => setSearchUser(e.target.value)}
          onChange={event => onSearch(event.target.value)}
          value={search}
          border={false}
          placeholder={'Search User...'}
        />
        {/* <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon /> */}

        {/* </React.Fragment> */}
      </Box>

      <Box ml={{ sm: 'auto' }} mt={{ xs: 3, sm: 0 }} style={{ marginBottom: '10px' }}>
        {/* <Box style={{ marginRight: '20px' }}> */}
        {/* <React.Fragment> */}
        <div>
          <Button color="primary" variant="contained" style={{ width: '100px', marginRight: 20 }} onClick={() => onFilter()}>
            Filter
          </Button>
        </div>
        {/* </React.Fragment> */}
      </Box>
    </Box>
  );
};

export default FilterOptionsHeader;

FilterOptionsHeader.prototype = {
  userDetail: PropTypes.object.isRequired,
  tabValue: PropTypes.string.isRequired,
  onFilter: PropTypes.func,
};
