import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
import CmtList from '@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { usersModule } from '@fake-db';
// import CommentItem from '../PostsList/PostItem/CommentItem';
import { AuhMethods } from 'services/auth';
import CommentItemPost from '../PostsList/PostItem/CommentItemPost';

const CommentListView = ({ open, onCloseDialog, commentId, itemType, comments }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const postAction = useSelector(state => state?.usersModule);

  // const { currentUser } = usersModule  //= useSelector(({ usersReducer }) => usersReducer);
  // const { owner, date, content, attachments, comments, id } = item;
  // const { name, email, status, phones, company, designation, profile_pic, starred } = currentUser;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Box ml={1} style={{ textTransform: 'capitalize' }}>
                {itemType}
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Cross button */}
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box px={6} py={5}>
        {/* Comment List  */}
        {Array.isArray(comments?.data) && comments?.data.length > 0 && (
          <CmtList
            data={comments?.data}
            renderRow={(item, index) => <CommentItemPost key={index} item={item} classes={classes} itemType={itemType} />}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default CommentListView;
// UserDetailView
CommentListView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
