import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CmtCard from "../../../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../../../@coremat/CmtCard/CmtCardHeader";
import Box from "@material-ui/core/Box";
import UserInfo from "./UserInfo";
import CmtCardContent from "../../../../../../@coremat/CmtCard/CmtCardContent";
import PostStats from "./PostStats";
import Attachments from "./Attachments";
import PropTypes from "prop-types";
import { AuhMethods } from "services/auth";
const PostItem = ({ item, page }) => {
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  const dispatch = useDispatch();
  const { post_meta_data } = item;

  useEffect(() => {}, []);
  const postAction = actionType => {
    let uAction = item?.status === 0 ? 1 : 0;
    switch (actionType) {
      case "delete":
        dispatch(
          AuhMethods["usersModule"].deleteUserPostAPI(
            token,
            item?.id,
            page,
            uAction
          )
        );
        break;

      default:
        break;
    }
  };
  return (
    <CmtCard>
      <CmtCardHeader title={<UserInfo user={item} />} />
      <CmtCardContent>
        <Box>
          <Box mb={2} component="p">
            {item?.description}
            <br />
            Post status :
            {item?.status === 0
              ? "Inactive"
              : item?.status === 1
              ? "Active"
              : item?.status === 2
              ? "Deleted"
              : "N/A"}
            <br />
            {item?.status === 2 && (
              <>Deleted by : {item?.deleted_by ? item?.deleted_by : "N/A"}</>
            )}
          </Box>
          {post_meta_data.length > 0 && (
            <Attachments attachments={post_meta_data} />
          )}
          <PostStats item={item} postAction={postAction} />
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default PostItem;

PostItem.prototype = {
  item: PropTypes.object.isRequired
};
