import {
  UPDATE_WALLET_fUNDS_DATA,
  GET_WALLET_USER_LIST_DATA,
  GET_LOCKED_FUNDS_DATA,
  GET_REDEEM_HISTORY,
} from '../../@jumbo/constants/ActionTypes';

export const setWalletListData = data => {
  return dispatch => {
    dispatch({
      type: GET_WALLET_USER_LIST_DATA,
      payload: data,
    });
  };
};

export const getRedeemHistory = data => {
  return dispatch => {
    dispatch({
      type: GET_REDEEM_HISTORY,
      payload: data,
    });
  };
};

export const getLockedfundList = data => {
  return dispatch => {
    dispatch({
      type: GET_LOCKED_FUNDS_DATA,
      payload: data,
    });
  };
};

export const updateWalletFunds = data => {
  return dispatch => {
    dispatch({
      type: UPDATE_WALLET_fUNDS_DATA,
      payload: data,
    });
  };
};
