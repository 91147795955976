import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import GeneralInfo from './GeneralInfo';
import Activities from './Activities';
import NewsFeed from './NewsFeed';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AuhMethods } from 'services/auth';
import { useLocation } from 'react-router';

const useStyles = makeStyles(theme => ({
  wallLeftSidebar: {
    '@media screen and (min-width: 960px) and (max-width: 1020px)': {
      flexBasis: '40%',
      maxWidth: '40%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  wallMainContent: {
    '@media screen and (min-width: 960px) and (max-width: 1020px)': {
      flexBasis: '60%',
      maxWidth: '60%',
    },
  },
  wallRightSidebar: {
    '@media screen and (max-width: 1020px)': {
      display: 'none',
    },
  },
}));

const PageDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(state => state.admin.authUser.access_token);
  const location = useLocation();
  const [userId] = React.useState(location?.state?.id);
  const [page, setPage] = React.useState(1);
  const [postData, setPostData] = React.useState({});
  const [perPage] = React.useState(10);
  const [type, setType] = React.useState('created_at'); //
  const [tabValue, setTabValue] = React.useState('');
  const postUserMetaData = useSelector(state => state?.businessPageManagement?.pagePostsMetaData);

  const pagePostsData = useSelector(state => state?.businessPageManagement?.pagePostsData);

  const history = useHistory();
  useEffect(() => {
    if (location?.state?.id) {
      dispatch(AuhMethods['businessModule'].pageProfileAPI({ token, userId }));
      dispatch(AuhMethods['businessModule'].PagePostsAPI({ token, userId, page, perPage, type })); //usersModule
    } else {
      history.goBack();
    }
    let data = {
      userId: userId,
      page: page,
      perPage: perPage,
      type: type,
    };
    setPostData(data);
  }, []);

  const handleTabChange = type => {
    setPage(1);
    setType(type);
    dispatch(AuhMethods['businessModule'].PagePostsAPI({ token, userId, page, perPage, type }));
  };

  const handleScroll = e => {
    if (Array.isArray(postUserMetaData)) {
      if (page != postUserMetaData[0]?.pages) {
        dispatch(AuhMethods['businessModule'].PagePostsAPI({ token, userId, tabValue, perPage, type }));
        setPage(page + 1);
      }
    }
  };

  return (
    <React.Fragment>
      {pagePostsData && (
        <GridContainer>
          <Grid item xs={12} sm={5} md={3} className={classes.wallLeftSidebar}>
            <GeneralInfo />
          </Grid>
          <Grid item xs={12} sm={7} md={6} className={classes.wallMainContent}>
            <NewsFeed onChangeTab={handleTabChange} handleScroll={handleScroll} postData={pagePostsData} type={type} />
          </Grid>
          <Grid item xs={12} md={3} className={classes.wallRightSidebar}>
            <Activities userId={userId} />
          </Grid>
        </GridContainer>
      )}
    </React.Fragment>
  );
};

export default PageDetails;
