import React, { useState } from "react";
import { Toolbar, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./index.style";
import { API_URL } from "constant/constant";
import axios from "axios";
import { fetchError } from "redux/actions";
import { DownLoadCSVFunction } from "customHook/debounse";
import AddEditContentType from "../AddEditContentType";

const UserTableToolbar = ({ count }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token);
  const [openAdd, setOpenAdd] = useState(false);
  return (
    <React.Fragment>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          Interest Dashboard
        </Typography>

        <React.Fragment>
          <div>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: "260px" }}
              onClick={() => setOpenAdd(true)}
            >
              Add Interest
            </Button>
          </div>
        </React.Fragment>
      </Toolbar>
      {openAdd && (
        <AddEditContentType
          open={openAdd}
          onCloseDialog={() => setOpenAdd(false)}
          type="add"
        />
      )}
    </React.Fragment>
  );
};

export default UserTableToolbar;
