import React, { useContext, useEffect, useState } from 'react';

import PostsList from './PostsList';
import { useDispatch } from 'react-redux';

import { getWallHeight } from '../../../../@jumbo/constants/AppConstants';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppContext from '../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext';
import PostsTabOptions from './PostsTabOptions';

const useStyles = makeStyles(() => ({
  perfectScrollbarNewsFeed: {
    height: props => `calc(100vh - ${props.height}px)`,
  },
}));

const NewsFeed = ({ width, onChangeTab, handleScroll, postData, type }) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const classes = useStyles({
    height: getWallHeight(width, showFooter),
  });
  const [tabValue, setTabValue] = useState('created_at');

  useEffect(() => {
    // dispatch(getFeedPosts());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    onChangeTab(newValue);
  };

  const onScroll = e => {
    //console.log('eeeeeeeeeeeeee22 : ', listInnerRef, ' ++++++'); //document.getElementById(`scroll+${contentPostList.length - 5}`)
    // if (document.getElementById(`scroll+${contentPostList.length - 5}`)) {
    //    alert('Hello');
    // }
    // console.log('eeeeeeeeeeeeee : ', document.getElementById(`scroll+${contentPostList.length - 5}`));
    let el = document.getElementById('kk');
    // console.log(
    //   el.offsetTop,
    //   'el.scrollHeight: ',
    //   el.scrollHeight,
    //   ' el.scrollTop: ',
    //   el.scrollTop,
    //   ' el.clientHeight: ',
    //   el.clientHeight,
    // );
    let hit = (el.scrollHeight * 70) / 100;
    if (parseInt(el.scrollHeight - el.scrollTop) === parseInt(el.clientHeight)) {
      // if (parseInt(el.scrollHeight - el.scrollTop) === parseInt(el.clientHeight)) {
      handleScroll();
    }
  };

  // const onScroll = e => {
  //   let el = document.getElementById('kk');
  //   if (parseInt(el.scrollHeight - el.scrollTop) === parseInt(el.clientHeight)) {
  //     handleScroll(e);
  //   }
  // };

  return (
    <PerfectScrollbar id="kk" onScroll={onScroll} className={classes.perfectScrollbarNewsFeed}>
      {/* <Box mb={6}> */}
      {/* <CreatePost /> */}
      {/* <PostsTabOptions /> */}

      <PostsTabOptions
        classes={classes}
        userDetail={{ name: 'abc' }}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
      />
      {/* </Box> */}
      <PostsList postData={postData} type={type} />
    </PerfectScrollbar>
  );
};

export default NewsFeed;
