import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  Block,
  CheckCircleOutline,
  MoreHoriz,
  Visibility
} from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const getUserActions = (is_block, is_verify) => {
  const actions = [{ action: "view", label: "View", icon: <Visibility /> }];
  actions.push({
    action: "active",
    label: is_block !== "active" ? "Active" : "Diactivate",
    icon: is_block == "active" ? <Block /> : <CheckCircleOutline />
  });
  return actions;
};

const UserListRowWhiteList = ({
  row,
  isSelected,
  onUserView,
  page,
  idx,
  onWhitelistEdit,
  onWhitelistUpdate
}) => {
  const classes = useStyles();
  const onUserMenuClick = menu => {
    if (menu.action === "view") {
      onUserView(row);
    } else if (menu.action === "editScore") {
      onWhitelistEdit(row);
    } else if (menu.action === "active") {
      onWhitelistUpdate(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row?.user_id}`;
  const isItemSelected = isSelected(row?.user_id);
  const userActions = getUserActions(row?.status, row?.verified);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?.user_id}
      selected={isItemSelected}
    >
      <TableCell>{page * 10 + idx + 1}</TableCell>

      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar
              size={40}
              src={row?.user_data?.profile_url}
              alt={row?.user_data?.name}
            />
          </Box>
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row?.user_data?.name === null ? "N/A" : row?.user_data?.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>
        {row?.user_data?.email === null ? "N/A" : row?.user_data?.email}
      </TableCell>
      <TableCell>
        {" "}
        {row?.user_data?.username ? row?.user_data?.username : "N/A"}{" "}
      </TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>
        {row?.status}
      </TableCell>
      <TableCell>{row?.score}</TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRowWhiteList);
