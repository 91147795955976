import React, { useEffect, forwardRef } from "react";
import CmtList from "../../../../../@coremat/CmtList";
import PostItem from "./PostItem";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { postsList, user } from "@fake-db/apps/wall";

const PostsList = forwardRef((page, ref) => {
  // const { feedPosts } = useSelector(({ wallApp }) => wallApp);

  const contentPostList = useSelector(
    state => state?.contentManagement?.ContentPostsData
  );
  const feedPosts = contentPostList;
  useEffect(() => {}, [feedPosts]);
  const callback = entries => {
    var postData = [];
    postData = entries;
    postData.forEach(element => {
      let child = element.target.childNodes[0];

      child.play().then(() => {
        //if this video is not in viewport then pause it
        if (!child.paused && !element.isIntersecting) {
          child.pause();
        }
      });
    });
  };

  const observer = new IntersectionObserver(callback, {
    threshold: 0.6
  });

  useEffect(() => {
    let videos = document.querySelectorAll(".videos");
    videos.forEach(video => {
      observer.observe(video);
    });
    return () => {
      observer.disconnect();
    };
  }, [feedPosts]);
  return (
    <div className="cmtList_kk">
      <CmtList
        data={feedPosts}
        renderRow={(item, index) => (
          <Box
            mb={6}
            key={index}
            ref={ref}
            id={index == feedPosts.length - 5 ? `scroll+${index}` : null}
          >
            <PostItem item={item} page={page} />
          </Box>
        )}
      />
    </div>
  );
});

export default PostsList;
