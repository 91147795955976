import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import React from "react";

const headCells = [
  {
    id: "Sr No",
    numeric: false,
    disablePadding: false,
    label: "Sr No"
  },
  {
    id: "Page Name",
    numeric: false,
    disablePadding: false,
    label: "User Id"
  },
  {
    id: "Page Count",
    numeric: false,
    disablePadding: false,
    label: "Profile"
  },
  {
    id: "Created By",
    numeric: false,
    disablePadding: false,
    label: "Influencer Name"
  },
  {
    id: "Created By",
    numeric: false,
    disablePadding: false,
    label: "User Name"
  },
  // { id: "Verified", numeric: false, disablePadding: false, label: "Category" },

  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Hiring Charge"
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status"
  },
  {
    id: "lastLoginAt",
    numeric: false,
    disablePadding: false,
    label: "Create date & Time"
  }
];

function UserTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className="like-padding"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default UserTableHead;
