import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { AuhMethods } from "services/auth";
import AppCheckbox from "@jumbo/components/Common/formElements/AppCheckBox";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const AddEditGenreList = ({ open, onCloseDialog, isEdit, selectedData }) => {
  const classes = useStyles();
  const [GenreName, setGenreName] = useState("");
  const [multiplier, setMultiplier] = useState("");
  const [isRewarded, setIsRewarded] = useState(false);
  const [reportId, setReportId] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [typeError, setTypeError] = useState("");
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  useEffect(() => {
    if (selectedData && isEdit) {
      setGenreName(selectedData.name ? selectedData.name : "");
      setMultiplier(selectedData.multipleir ? selectedData.multipleir : "");
      setIsRewarded(
        selectedData.is_reward_give ? selectedData.is_reward_give : ""
      );
      setReportId(selectedData.id ? selectedData.id : "");
    }
  }, [selectedData, isEdit]);

  const onSubmitClick = () => {
    if (!GenreName) {
      setFirstNameError(requiredMessage);
      return;
    }
    if (multiplier === "") {
      setTypeError("This filed is required!");
      return;
    }
    //Create Reward API
    isEdit
      ? dispatch(
          AuhMethods["usersModule"].updateGenreTypeAPI(token, {
            genre_id: reportId,
            name: GenreName,
            multipleir: multiplier,
            is_reward_give: isRewarded ? "1" : "0"
          })
        )
      : dispatch(
          AuhMethods["usersModule"].addGenreTypeAPI(token, {
            name: GenreName,
            multipleir: multiplier,
            is_reward_give: isRewarded ? "1" : "0"
          })
        );
    onCloseDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      isedit={isEdit}
      className={`${classes.dialogRoot} dialogCustom`}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {isEdit ? "Edit Flag" : "Create Genre"}
        {/* <CloseIcon onClick={onCloseDialog} /> */}
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000"
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        ></Box>{" "}
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant="outlined"
            label="Name"
            value={GenreName}
            onChange={e => {
              if (e.target.value.length <= 40) {
                setGenreName(e.target.value.replace(/^\s/, ""));
              }
              setFirstNameError("");
            }}
            helperText={firstNameError}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            type="number"
            fullWidth
            variant="outlined"
            label="Multiplier"
            value={multiplier}
            onChange={e => {
              setMultiplier(e.target.value);
              setTypeError("");
            }}
            helperText={typeError}
          />
        </Box>
        <Box>
          <AppCheckbox
            style={{ marginBottom: 12 }}
            checked={isRewarded}
            label="Is Rewarded"
            onChange={e => setIsRewarded(e.target.checked)}
            color="secondary"
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditGenreList;

AddEditGenreList.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
