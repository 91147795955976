import {
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as yup from "yup";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

const useStyles = makeStyles(theme => ({
  CustomiseContainer: {
    "& .MuiFormHelperText-root.Mui-error": { marginLeft: "14px" },
    "& .MuiSelect-root": {
      paddingLeft: "18px"
    },
    "& .mainGridbox": {
      padding: "20px 0px"
    },
    "& h6": {
      padding: "40px 0px 20px"
    },
    "& .radioBox": {
      padding: "40px 0px",
      "& h6": {
        padding: "0px"
      }
    },
    "& .subRadioBox": {
      display: "flex",
      alignItems: "center",
      padding: "0px",
      justifyContent: "space-between",
      "& p": {
        color: "#FFFFFF99"
      },
      "& .MuiFormControlLabel-root": {
        marginRight: "80px",
        [theme.breakpoints.down("xs")]: {
          marginRight: "0px"
        }
      }
    }
  },
  input: {
    "&::placeholder": {
      color: "#fff"
    }
  },
  titleRoot12: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    paddingBottom: 24,
    marginBottom: 24,
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      zIndex: 1,
      width: 36,
      height: 4,
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const schema = yup.object().shape({
  General: yup.string().required("This field is required."),
  email: yup.string().required("This field is required."),
  phone: yup
    .string()
    .required("This field is required.")
    .min(10, "Phone no . minimum 10 Characters ")
    .max(13, "Must Contain 13 Characters "),
  province: yup.string().required("This field is required."),
  postcode: yup.string().required("This field is required."),
  state: yup.string().required("This field is required."),
  country: yup.string().required("This field is required.")
});

export default function CustomisePage({
  setTabButton,
  handleNext,
  setValuesData,
  valuesData
}) {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [showStates, setShowStates] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      General: "",
      email: "",
      phone: "",
      province: "",
      state: "",
      postcode: "",
      country: ""
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    axios.get("/json/countries.json").then(function(response) {
      setCountries(response.data.countries);
      axios.get("/json/states.json").then(function(response) {
        setStates(response.data.states);
      });
    });
  }, []);
  const changeCountryList = name => {
    const selectted = countries?.filter(cont => {
      return cont.name === name;
    });
    const contId = selectted[0]?.id;

    const allState = states?.filter(state => {
      return state.country_id === contId;
    });
    setShowStates(allState);
  };
  const changeCountry = e => {
    const name = e.target.value;
    changeCountryList(name);
    let SelectID = countries?.find(data => data.name == name);
  };

  const onSubmit = values => {
    setValuesData({
      ...valuesData,
      General: getValues("General"),
      email: getValues("email"),
      phone: getValues("phone"),
      province: getValues("province"),
      state: getValues("state"),
      postcode: getValues("postcode"),
      country: getValues("country")
    });
    setTabButton("step3");
    handleNext();
  };

  useEffect(() => {
    if (valuesData.General) {
      setValue("General", valuesData?.General ? valuesData?.General : "");
      setValue("email", valuesData?.email ? valuesData?.email : "");
      setValue("phone", valuesData?.phone ? valuesData?.phone : "");
      setShowStates([{ name: valuesData?.state }]);
      setValue("state", valuesData?.state ? valuesData?.state : "");
      setValue("postcode", valuesData?.postcode ? valuesData?.postcode : "");
      setValue("country", valuesData?.country ? valuesData?.country : "");
      setValue("province", valuesData?.province ? valuesData?.province : "");
    }
  }, [valuesData]);

  return (
    <Box className={classes.CustomiseContainer}>
      <Typography variant="h5">Customise your page</Typography>
      <Typography variant="body2">
        Please add more page details that help people to connect you.
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="mainGridbox">
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              {" "}
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                {" "}
                General
              </Typography>
              <Controller
                name="General"
                control={control}
                render={({ value }) => {
                  return (
                    <TextField
                      id="General"
                      placeholder="Enter General"
                      name="General"
                      fullWidth
                      type={"text"}
                      onChange={event => {
                        setValue("General", event.target.value);
                      }}
                      value={getValues("General")}
                      variant="outlined"
                      className={classes.textFieldRoot}
                      error={errors.General?.message}
                      helperText={errors.General?.message}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className="mainGridbox">
          <Typography
            component="div"
            variant="h3"
            className={classes.titleRoot12}
          >
            Contact Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                {" "}
                Email
              </Typography>
              <Controller
                name="email"
                control={control}
                render={({ value }) => {
                  return (
                    <TextField
                      placeholder="Enter email"
                      name="email"
                      fullWidth
                      type={"text"}
                      onChange={event => {
                        setValue("email", event.target.value);
                      }}
                      value={getValues("email")}
                      variant="outlined"
                      className={classes.textFieldRoot}
                      error={errors.email?.message}
                      helperText={errors.email?.message}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Phone Number</Typography>

              <Controller
                name="phone"
                control={control}
                render={({ value }) => {
                  return (
                    <TextField
                      placeholder="Enter phone number"
                      name="phone"
                      fullWidth
                      type={"number"}
                      onChange={event => {
                        setValue("phone", event.target.value);
                      }}
                      value={getValues("phone")}
                      variant="outlined"
                      className={classes.textFieldRoot}
                      error={errors.phone?.message}
                      helperText={errors.phone?.message}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className="mainGridbox">
          <Typography
            component="div"
            variant="h3"
            className={classes.titleRoot12}
          >
            Address
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                Select Country
              </Typography>
              <Controller
                name="country"
                control={control}
                render={({ value }) => {
                  return (
                    <>
                      <Select
                        fullWidth
                        name="country"
                        placeholder="Select country"
                        onChange={event => {
                          changeCountry(event);
                          setValue("country", event.target.value);
                        }}
                        value={getValues("country")}
                        variant="outlined"
                        className={classes.textFieldRoot}
                        error={errors.country?.message}
                        helperText={errors.country?.message}
                        InputProps={{
                          className: classes.TextBox
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {countries.lenght !== 0 &&
                          countries.map(item => {
                            return (
                              <MenuItem
                                key={item.name + item.id}
                                value={item.name}
                              >
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>

                      <FormHelperText error>
                        {errors.country?.message}
                      </FormHelperText>
                    </>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                State
              </Typography>
              <Controller
                name="state"
                control={control}
                render={({ value }) => {
                  return (
                    <>
                      <Select
                        fullWidth
                        name="state"
                        placeholder="Select state"
                        onChange={event => {
                          setValue("state", event.target.value);
                        }}
                        value={getValues("state")}
                        variant="outlined"
                        className={classes.textFieldRoot}
                        error={errors.state?.message}
                        helperText={errors.state?.message}
                        InputProps={{
                          className: classes.TextBox
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {showStates.lenght !== 0 &&
                          showStates.map(item => {
                            return (
                              <MenuItem
                                key={item.name + item.id}
                                value={item.name}
                              >
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>

                      <FormHelperText error>
                        {errors.state?.message}
                      </FormHelperText>
                    </>
                  );
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                Province
              </Typography>
              <Controller
                name="province"
                control={control}
                render={({ value }) => {
                  return (
                    <TextField
                      placeholder="Enter Your province"
                      name="province"
                      fullWidth
                      type={"text"}
                      onChange={event => {
                        setValue("province", event.target.value);
                      }}
                      value={getValues("province")}
                      variant="outlined"
                      className={classes.textFieldRoot}
                      error={errors.province?.message}
                      helperText={errors.province?.message}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography
                component="div"
                variant="body1"
                className={classes.titleRoot}
              >
                {" "}
                Post Code
              </Typography>
              <Controller
                name="postcode"
                control={control}
                render={({ value }) => {
                  return (
                    <TextField
                      placeholder="Enter post code"
                      name="postcode"
                      fullWidth
                      type={"number"}
                      onChange={event => {
                        setValue("postcode", event.target.value);
                      }}
                      value={getValues("postcode")}
                      variant="outlined"
                      className={classes.textFieldRoot}
                      error={errors.postcode?.message}
                      helperText={errors.postcode?.message}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box align="center" mt={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ padding: "7px 50px" }}
          >
            Next
          </Button>
        </Box>
      </form>
    </Box>
  );
}
