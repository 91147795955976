import {
  SAVE_PAGE_CATEGORIES_LIST_DATA,
  SAVE_PAGE_LIST_DATA,
  SAVE_PAGE_POSTS_DATA,
  PAGE_STORY_LIST_DATA,
  PAGE_PROFILE_LIST_DATA,
  PAGE_MORE_POSTS_META_DATA,
  APPEND_PAGE_POSTS_DATA,
  PAGE_FOLLOWERS_DATA,
  USERS_FOLLOWING_DATA,
  PAGE_FOLLOWING_DATA,
  SAVE_PAGE_POST_COMMENTS_META,
  SAVE_PAGE_POST_COMMENTS_DATA,
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  pageList: [],
  pageListMeta: {},
  pageCategoryList: [],
  pageCategoryListMeta: [],
  pagePostsData: [],
  pagePostsMetaData: [],

  PageStoryList: [],
  pageMorePostMetaData: [],
  pageFollower: [],
  pageFollower: [],
  pageFollowing: [],

  pagePostsData: [],
  PageLikeCommentsData: [],
  PageLikeCommentsMeta: [],
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_PAGE_LIST_DATA: {
      return {
        ...state,
        pageList: action.payload.data,
        pageListMeta: action.payload.meta,
      };
    }
    case SAVE_PAGE_CATEGORIES_LIST_DATA: {
      return {
        ...state,
        pageCategoryList: action.payload.data,
        pageCategoryListMeta: action.payload.meta,
      };
    }

    case PAGE_PROFILE_LIST_DATA: {
      return {
        ...state,
        userProfileData: action.payload,
      };
    }
    // case SAVE_PAGE_POSTS_DATA: {
    //   return {
    //     ...state,
    //     pagePostsData: action.payload,
    //   };
    // }

    case SAVE_PAGE_POSTS_DATA: {
      var apiData = [];
      if (action.payload.meta.page === 1) {
        apiData = [...action.payload.data];
      } else {
        apiData = [...state.pagePostsData, ...action.payload.data];
      }
      return {
        ...state,
        // ContentPostsData: [...state.ContentPostsData, ...action.payload.data],
        pagePostsData: apiData,
        pagePostsMetaData: [action.payload.meta],
      };
    }

    // case SAVE_USER_MORE_POSTS_DATA: {
    //   return {
    //     ...state,
    //     // userPostsData: action.payload,
    //     userPostsData: [...state.userPostsData, action.payload],
    //   };
    // }

    case APPEND_PAGE_POSTS_DATA: {
      return {
        ...state,
        // userPostsData: action.payload,
        pagePostsData: [...state.pagePostsData, ...action.payload],
      };
    }

    case PAGE_MORE_POSTS_META_DATA: {
      return {
        ...state,
        pageMorePostMetaData: action.payload,
      };
    }

    // case DELETE_USER_POST: {
    //   return {
    //     ...state,
    //     userPostsData: {
    //       message: state.userPostsData.message,
    //       data: state.userPostsData.data.filter(post => post.id !== action.payload),
    //       meta: state.userPostsData.meta,
    //     },
    //   };
    // }

    // case EDIT_USER_LIST: {
    //   return {
    //     ...state,
    //     usersList: state.usersList.map(user =>
    //       user.user_id === action.payload.id
    //         ? user.detail.is_enalbed == '1'
    //           ? (user.detail.is_enalbed = '0')
    //           : (user.detail.is_enalbed = '1')
    //         : user,
    //     ),
    //   };
    // }

    // case SET_VERIFY_USER: {
    //   return {
    //     ...state,
    //     verifiedUserList: action.payload,
    //   };
    // }

    // case EDIT_PROFILE_DETAIL: {
    //   return {
    //     ...state,
    //     usersList:
    //       Array.isArray(state.userList) &&
    //       state.userList.map(user =>
    //         user.user_id === action.payload.id ? (user.status == '1' ? (user.status = '0') : (user.status = '1')) : user,
    //       ),
    //   };
    // }

    case SAVE_PAGE_POST_COMMENTS_DATA: {
      return {
        ...state,
        PageLikeCommentsData: action.payload,
      };
    }
    case SAVE_PAGE_POST_COMMENTS_META: {
      return {
        ...state,
        PageLikeCommentsMeta: action.payload,
      };
    }

    case PAGE_FOLLOWING_DATA: {
      return {
        ...state,
        pageFollowing: action.payload,
      };
    }
    case PAGE_FOLLOWERS_DATA: {
      return {
        ...state,
        pageFollower: action.payload,
      };
    }

    //   case SAVE_USER_PROFILE_DATA: {
    //     return {
    //       ...state,
    //       userProfileData: action.payload,
    //     };
    //   }
    //   case SAVE_USER_POSTS_DATA: {
    //     return {
    //       ...state,
    //       userPostsData: action.payload,
    //     };
    //   }
    // case SAVE_USER_MORE_POSTS_DATA: {
    //   return {
    //     ...state,
    //     // userPostsData: action.payload,
    //     userPostsData: [...state.userPostsData, action.payload],
    //   };
    // }

    case PAGE_STORY_LIST_DATA: {
      return { ...state, error: '', message: '', loading: false, PageStoryList: action.payload };
    }

    default:
      return state;
  }
};
