import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import useStyles from "./index.style";

const ContentTypeTableToolbar = ({ onFlagAdd }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          Genre Types
        </Typography>

        <React.Fragment>
          <div>
            <Button
              color="primary"
              style={{ width: "200px", marginRight: "20px" }}
              onClick={() => onFlagAdd()}
            >
              Add Genre Type
            </Button>
          </div>
        </React.Fragment>
      </Toolbar>
    </React.Fragment>
  );
};

ContentTypeTableToolbar.propTypes = {};

export default React.memo(ContentTypeTableToolbar);
