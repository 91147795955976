import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import React from "react";

const headCells = [
  {
    id: "SrNo",
    numeric: false,
    disablePadding: false,
    label: "Sr No."
  },
  {
    id: "rewardEventeType",
    numeric: false,
    disablePadding: false,
    label: "Event Type"
  },
  {
    id: "creatorReward",
    numeric: false,
    disablePadding: false,
    label: "Creator Reward"
  },

  {
    id: "engagerReward",
    numeric: false,
    disablePadding: false,
    label: "Engager Reward"
  },

  { id: "status", numeric: false, disablePadding: false, label: "Status" }
];

function RewardHistoryHeader() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="left">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

RewardHistoryHeader.propTypes = {};

export default React.memo(RewardHistoryHeader);
