import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Switch } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./app.css";
import configureStore, { history } from "./redux/store";
import AppWrapper from "./@jumbo/components/AppWrapper";
import AppContextProvider from "./@jumbo/components/contextProvider/AppContextProvider";
import { PersistGate } from "redux-persist/lib/integration/react";
import Routes from "./routes";
import SocialContext from "./context/SocialAuth";

export const { store, persistor } = configureStore();

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <SocialContext>
          <AppContextProvider>
            <AppWrapper>
              <Switch>
                <Routes />
              </Switch>
            </AppWrapper>
          </AppContextProvider>
        </SocialContext>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default App;
