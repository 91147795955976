import {
  SAVE_USERS_LIST_DATA,
  SAVE_USER_PROFILE_DATA,
  SAVE_USER_POSTS_DATA,
  DELETE_USER_POST,
  EDIT_USER_LIST,
  EDIT_PROFILE_DETAIL,
  SAVE_POST_COMMENTS_DATA,
  SAVE_COMMENTS_META,
  USER_MORE_POSTS_META_DATA,
  APPEND_USER_POSTS_DATA,
  SET_VERIFY_USER,
  USERS_FOLLOWING_DATA,
  USERS_FOLLOWERS_DATA,
  GET_GENRE_LIST,
  GET_VERIFICATION_REQUEST,
  GET_PAGE_VERIFICATION_REQUEST_LIST
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  usersList: [],
  userListMeta: {},
  userProfileData: {},
  userPostsData: {},
  postCommentsData: [],
  postCommentsMeta: {},
  // userMorePostsData: [],
  morePostMetaData: [],
  verifiedUserList: [],
  userFollower: [],
  userFollowing: [],
  GenreList: [],
  verificationRequest: [],
  pageVerificationList: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_USERS_LIST_DATA: {
      return {
        ...state,
        usersList: action.payload.data,
        userListMeta: action.payload.meta
      };
    }
    case SAVE_USER_PROFILE_DATA: {
      return {
        ...state,
        userProfileData: action.payload
      };
    }
    case SAVE_USER_POSTS_DATA: {
      return {
        ...state,
        userPostsData: action.payload
      };
    }

    case APPEND_USER_POSTS_DATA: {
      return {
        ...state,
        // userPostsData: action.payload,
        userPostsData: [...state.userPostsData, ...action.payload]
      };
    }

    case USER_MORE_POSTS_META_DATA: {
      return {
        ...state,
        morePostMetaData: action.payload
      };
    }

    case DELETE_USER_POST: {
      return {
        ...state,
        userPostsData: {
          message: state.userPostsData.message,
          data: state.userPostsData.data.filter(
            post => post.id !== action.payload
          ),
          meta: state.userPostsData.meta
        }
      };
    }

    case EDIT_USER_LIST: {
      return {
        ...state,
        usersList: state.usersList.map(user =>
          user.user_id === action.payload.id
            ? user.detail.is_enalbed === "1"
              ? (user.detail.is_enalbed = "0")
              : (user.detail.is_enalbed = "1")
            : user
        )
      };
    }

    case SET_VERIFY_USER: {
      return {
        ...state,
        verifiedUserList: action.payload
      };
    }

    case EDIT_PROFILE_DETAIL: {
      return {
        ...state,
        usersList:
          Array.isArray(state.userList) &&
          state.userList.map(user =>
            user.user_id === action.payload.id
              ? user.status === "1"
                ? (user.status = "0")
                : (user.status = "1")
              : user
          )
      };
    }

    case SAVE_POST_COMMENTS_DATA: {
      return {
        ...state,
        postCommentsData: action.payload
      };
    }
    case SAVE_COMMENTS_META: {
      return {
        ...state,
        postCommentsMeta: action.payload
      };
    }

    case USERS_FOLLOWING_DATA: {
      return {
        ...state,
        userFollower: action.payload
      };
    }
    case USERS_FOLLOWERS_DATA: {
      return {
        ...state,
        userFollower: action.payload
      };
    }
    case GET_GENRE_LIST: {
      return {
        ...state,
        GenreList: action.payload
      };
    }
    case GET_VERIFICATION_REQUEST: {
      return {
        ...state,
        verificationRequest: action.payload
      };
    }

    case GET_PAGE_VERIFICATION_REQUEST_LIST: {
      return {
        ...state,
        pageVerificationList: action.payload
      };
    }

    default:
      return state;
  }
};
