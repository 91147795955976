import {
  ADD_CONTENT_TYPE,
  GET_CONTENT_TYPE,
  GET_CONTENT_POSTS_DATA,
  GET_CONTENT_FILTER_POSTS_DATA,
  CLEAR_CONTENT_POSTS_FILTER,
  GET_CONTENT_USER_LIST,
  GET_HASHTAGS_LIST,
  ADD_HASHTAGS_TYPE,
  EDIT_HASHTAGS_TYPE,
  POST_DETAIL_SUCCESS,
  GET_INTEREST_TYPE,
  GET_CONTENT_TITLE_LIST,
} from "@jumbo/constants/ActionTypes";

export const getContentTypeData = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_CONTENT_TYPE,
      payload: data,
    });
  };
};
export const getInterestTypeData = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_INTEREST_TYPE,
      payload: data,
    });
  };
};

export const addContentType = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_CONTENT_TYPE,
      payload: data,
    });
  };
};

export const clearContentFilter = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CONTENT_POSTS_FILTER,
      payload: [],
    });
  };
};

export const getContentPostsList = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_CONTENT_POSTS_DATA,
      payload: data,
    });
  };
};

export const getContentFilterPostsList = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_CONTENT_FILTER_POSTS_DATA,
      payload: data,
    });
  };
};

export const getContentUserList = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_CONTENT_USER_LIST,
      payload: data,
    });
  };
};

//hash tags

export const getHashTagsList = (data) => {
  console.log("page: =====> 358", data);
  return (dispatch) => {
    dispatch({
      type: GET_HASHTAGS_LIST,
      payload: data,
    });
  };
};

export const addHashTagsType = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_HASHTAGS_TYPE,
      payload: data,
    });
  };
};

export const editHahTagsType = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_HASHTAGS_TYPE,
      payload: data,
    });
  };
};

export const postDetailAPIsuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: POST_DETAIL_SUCCESS,
      payload: data,
    });
  };
};

export const getContentTitle = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_CONTENT_TITLE_LIST,
      payload: data,
    });
  };
};
