import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  withWidth,
} from '@material-ui/core';
// import useStyles from '../index.style';
import MailCell from './MailCell';
import CmtList from '../../../../../@coremat/CmtList';
import { getMailsList } from '../../../../../redux/actions/MailApp';
import ListHeader from './ListHeader';
import PropTypes from 'prop-types';
import { getMailContainerHeight } from '../../../../../@jumbo/constants/AppConstants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppContext from '../../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext';
import ListEmptyResult from '../../../../../@coremat/CmtList/ListEmptyResult';
import EmptyMailsResult from './EmptyMailsResult';
import List from '@material-ui/core/List';
import GridContainer from '@jumbo/components/GridContainer';
import { DatePicker } from '@material-ui/pickers';
import { map } from 'lodash';
import moment from 'moment';
import { setPostCategoriesData } from 'redux/actions/Dashboard';
import { getContentTypeData } from 'redux/actions/ContentManagement';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.default,
  },
}));

const MailsList = ({ width, onClickSendMail, onClickForwardMail, viewMode, contentTypesList, dispatchData }) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const { loading } = useSelector(({ common }) => common);
  const [checkedMails, setCheckedMails] = useState(false);
  const [payloadData, setPayloadData] = useState({
    filterData: [],
    dateData: {},
    deleteData: {},
  });
  const [dateValue, setDateValue] = useState();

  const type = viewMode;

  // const classes = useStyles({
  //   height: getMailContainerHeight(width, showFooter),
  // });
  const [selectedDate, handleDateChange] = useState(new Date());
  const [isChecked, setIsChecked] = useState({});

  const classes = useStyles();

  let categoriesArray = [];

  const checkPayload = async () => {
    let data = [];
    await contentTypesList?.list?.forEach(elem => {
      if (elem.isChecked === true) {
        data.push(elem.id);
      }
    });
    payloadData.filterData = data;
  };
  useEffect(() => {
    checkPayload();
    // contentTypesList.forEach(element => {});
  }, [contentTypesList?.list]);

  const onChange = data => {
    if (data.type === 'category') {
      let filterData = [...payloadData.filterData];
      let contentTypesList2 = [...contentTypesList?.list];
      let index = filterData.findIndex(id => id === data.id);
      if (index === -1) {
        filterData.push(data.id);
      } else {
        filterData.splice(index, 1);
      }

      if (data?.checked) {
        contentTypesList2.forEach(elem => {
          if (elem.id == data.id) {
            elem.isChecked = true;
          }
        });
        // dispatch(getContentTypeData({ data: contentTypesList2 }));
      } else {
        contentTypesList2.forEach(elem => {
          if (elem.id == data.id) {
            elem.isChecked = false;
          }
        });
        dispatch(getContentTypeData({ list: contentTypesList2, meta: contentTypesList.meta }));
      }
      // let data =
      // if (payloadData.filter_data.includes(data.id))
      setPayloadData({ ...payloadData, filterData: filterData });
      dispatchData({ filterData: filterData, type: data.type });
    } else if (data.type === 'date_range') {
      setPayloadData({
        ...payloadData,
        dateData: { ...payloadData.dateData, [data.name]: moment(data.event._d).format('YYYY-MM-DD') },
      });
      let filterData = { dateData: { ...payloadData.dateData, [data.name]: moment(data.event._d).format('YYYY-MM-DD') } };
      dispatchData({ filterData: filterData.dateData, type: data.type });
    } else if (data.type === 'deleted') {
      dispatchData({ filterData: { deleted: data.event.target.checked ? 'yes' : 'no' }, type: data.type });
    }
    // dispatchData({ id: filterData, type: data.type });
  };
  return (
    <Box className={classes.inBuildAppMainContent} style={{ flex: 1 }}>
      {/* {totalMails > 0 && <ListHeader checkedMails={checkedMails} setCheckedMails={setCheckedMails} />} */}
      {/* {totalMails === 0 && <Box style={{ height: 39 }} />} */}
      <PerfectScrollbar className={classes.perfectScrollbarMailCon} style={{ flex: 1 }}>
        {/* <CmtList 
          data={mailsList}
          renderRow={(item, index) => (
            <MailCell
              key={index}
              mail={item}
              labelsList={labelsList}
              checkedMails={checkedMails}
              onChangeCheckedMails={onChangeCheckedMails}
              onClickSendMail={onClickSendMail}
              onClickForwardMail={onClickForwardMail}
              viewMode={viewMode}
            />
          )}
         
          ListEmptyComponent={
            <ListEmptyResult loader={loading}>
              <EmptyMailsResult />
            </ListEmptyResult>
          }
        /> */}
        {type === 'date_range' ? (
          <div style={{ flex: 1, height: 200, width: 250, marginLeft: '20px', marginTop: '10px' }}>
            <GridContainer>
              {/* style={{flex: 1, width: '100%', backgroundColor:'green'}} */}
              {/* <div style={{flex: 1, backgroundColor: 'gray', width: '100%', display: 'flax', justifyContent:'center',alignItems:'center'}}> */}
              <Grid item md={30}>
                <DatePicker
                  label="Start date"
                  name="from_date"
                  disableFuture
                  value={payloadData?.dateData?.from_date}
                  onChange={event => onChange({ name: 'from_date', type: type, event: event })}
                  animateYearScrolling
                />
              </Grid>
              {/* </div> */}
            </GridContainer>
            <GridContainer>
              <Grid item md={30}>
                <DatePicker
                  autoOk
                  label="End date"
                  clearable
                  animateYearScrolling
                  disableFuture
                  name="to_date"
                  // value={selectedDate}
                  // onChange={handleDateChange}
                  value={payloadData?.dateData?.to_date}
                  onChange={event => onChange({ name: 'to_date', type: type, event: event })}
                />
              </Grid>
            </GridContainer>
          </div>
        ) : (
          //deletedPosts

          <div style={{ flex: 1 }}>
            {type === 'deleted' ? (
              <List className={classes.root}>
                {[1].map(value => {
                  const labelId = `Show-${value}`;

                  return (
                    <ListItem key={value} role={undefined} dense button onClick={console.log('handleToggle(value)')}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          // checked={checked.indexOf(value) !== -1}
                          onChange={event => onChange({ type: type, event: event })}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`Show`} />
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <List className={classes.root}>
                {Array.isArray(contentTypesList?.list) &&
                  contentTypesList?.list?.map((value, index) => {
                    const labelId = `Categoty-${value.name}`;
                    return (
                      <ListItem key={value.name} role={undefined} dense button>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            id={`custom-checkbox-${index.id}`}
                            // checked={value?.isChecked ? true : false}
                            name={value.name}
                            value={value.id}
                            defaultChecked={value.isChecked}
                            // checked={value.isChecked}
                            tabIndex={-1}
                            onChange={(e, checked) => {
                              onChange({ id: value.id, type: type, checked });
                            }}
                            // onChange={() => handleChange(index, value.id)}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value.name} />
                      </ListItem>
                    );
                  })}
              </List>
            )}
          </div>
        )}
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(MailsList);

MailsList.prototype = {
  onClickSendMail: PropTypes.func,
  onClickForwardMail: PropTypes.func,
  viewMode: PropTypes.string,
};

MailsList.defaultProps = {
  viewMode: 'categories',
};
