import React from "react";
import CmtCard from "../../../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../../../@coremat/CmtCard/CmtCardHeader";
import Box from "@material-ui/core/Box";
import UserInfo from "./UserInfo";
import CmtCardContent from "../../../../../../@coremat/CmtCard/CmtCardContent";
import PostStats from "./PostStats";
import CmtList from "../../../../../../@coremat/CmtList";
// import CommentItem from '../CommentListView/CommentItem/CommentItem';
import AddComment from "./AddComment";
import Attachments from "./Attachments";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({}));

const PagePostItem = ({ item, postData, type }) => {
  // console.log(item, '=======item');
  const classes = useStyles();
  // const { owner, date, content, attachments, comments, id } = item;
  const { description, post_meta_data, total_likes, total_comments } = item;

  return (
    <CmtCard>
      {/* Same user detail upper post */}
      {/* <CmtCardHeader title={<UserInfo user={owner} date={date} />} /> */}
      <CmtCardContent>
        <Box>
          {/* Message */}
          <Box mb={2} component="p">
            {description}
          </Box>
          {/* Images */}
          <br />
          Post status :
          {/* {item?.status == 0 ? 'Inactive' : item?.status == 1 ? 'Active' : 'Deleted'} */}
          {item?.status == 0
            ? "Inactive"
            : item?.status == 1
            ? "Active"
            : item?.status == 2
            ? "Deleted"
            : "N/A"}
          <br />
          {/* {item?.status == 0 ? 'Inactive' : item?.status == 1 ? 'Active' : 'Deleted'} by :{' '}
          {item?.deleted_by ? item?.deleted_by : 'N/A'} */}
          {post_meta_data.length > 0 && (
            <Attachments attachments={post_meta_data} />
          )}
          {/* Likes, Comments, Delete Post */}
          <PostStats item={item} postData={postData} type={type} />
          {/* Comment on post  */}
          {/* {comments.length > 0 && (
            <CmtList
              data={comments}
              renderRow={(item, index) => <CommentItem key={index} item={item} classes={classes} />}
            />
          )} */}
          {/* <AddComment postId={id} /> */}
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default PagePostItem;
