import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MoreHoriz, Visibility } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  },
  Root: {
    "& .MuiTableCell-root": {
      padding: 9
    }
  }
}));

const getUserActions = (is_block, is_verify) => {
  const actions = [{ action: "view", label: "View", icon: <Visibility /> }];
  return actions;
};

const UserListRow = ({
  row,
  isSelected,
  onRowClick,
  onUserView,
  page,
  idx
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === "view") {
      onUserView(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row?.user_id}`;
  const isItemSelected = isSelected(row?.user_id);
  const userActions = getUserActions(row?.status, row?.verified);

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, row?.user_id)} // Long Press
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?.user_id}
      selected={isItemSelected}
      className={classes.Root}
    >
      <TableCell>{page * 10 + idx + 1}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row?.profile_url} alt={row?.name} />
          </Box>
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row?.name === null ? "N/A" : row?.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell> {row?.requested_user_detail?.email}</TableCell>
      <TableCell> {row?.requested_user_detail?.username} </TableCell>

      {row?.status === "0" ? (
        <TableCell>{"pending"}</TableCell>
      ) : (
        <TableCell>{row?.status === "1" ? "Verified" : "Unverified"}</TableCell>
      )}

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
