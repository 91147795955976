import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  },
  dialogActionsRoot: {
    padding: "16px 24px"
  }
}));

const RejectDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  btnLabels,
  InputReason,
  setInputReason,
  setInputReasonError
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      className={classes.dialogRoot}
    >
      <DialogTitle
        id="confirm-dialog-title"
        className={classes.dialogTitleRoot}
      >
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{content}</DialogContentText>
        <TextField
          id="outlined-basic"
          label="Reason"
          variant="outlined"
          required
          value={InputReason}
          onChange={async e => {
            let tt = e.target.value.trim();
            if (tt.length <= 40 && tt) {
              setInputReason(e.target.value);
              setInputReasonError("");
            } else {
              setInputReason(e.target.value);
              if (e.target.value == "") {
                setInputReasonError("This field is required");
              } else {
                setInputReasonError("No white space allowed");
              }
            }
          }}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button onClick={onClose} color="primary" variant="outlined">
          {btnLabels.cancel}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
          disabled={InputReason === ""}
        >
          {"Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RejectDialog.defaultProps = {
  open: false,
  title: "Confirm Delete",
  btnLabels: {
    cancel: "Cancel",
    confirm: "Confirm"
  }
};

export default React.memo(RejectDialog);
