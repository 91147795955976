import {
  UPDATE_WALLET_fUNDS_DATA,
  GET_WALLET_USER_LIST_DATA,
  GET_LOCKED_FUNDS_DATA,
  GET_REDEEM_HISTORY,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  LockedFundsData: [],
  WalletUserList: [],
  ReedemHistory: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCKED_FUNDS_DATA: {
      return {
        ...state,
        LockedFundsData: action.payload.data,
        LockedFundsMetaData: action.payload.meta,
      };
    }
    case GET_WALLET_USER_LIST_DATA: {
      return {
        ...state,
        WalletUserList: action.payload,
        WalletUserListMeta: action.payload.meta,
      };
    }

    case UPDATE_WALLET_fUNDS_DATA: {
      return {
        ...state,
        WalletUserList: [...state.WalletUserList, ...action.payload.data],
      };
    }
    case GET_REDEEM_HISTORY: {
      return {
        ...state,
        ReedemHistory: [action.payload.data],
      };
    }

    default:
      return state;
  }
};
