import { SAVE_AUTH_USER, UPDATE_EMAIL } from '../../@jumbo/constants/AdminTypes';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: SAVE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateAuthUser = email => {
  return dispatch => {
    dispatch({
      type: UPDATE_EMAIL,
      payload: email,
    });
  };
};
