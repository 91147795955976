import React, { useContext } from "react";
import {
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch } from "react-redux";
import { AuhMethods } from "../../../../services/auth";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SidebarThemeContext from "../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext";
import { SocialContext } from "context/SocialAuth";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "30px 16px 12px 16px",
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`
  },
  userInfo: {
    paddingTop: 24,
    transition: "all 0.1s ease",
    height: 75,
    opacity: 1,
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: "all 0.3s ease"
    }
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25
  }
}));

const SidebarHeader = ({ method = "admin" }) => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const dispatch = useDispatch();
  const auth = useContext(SocialContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(AuhMethods[method].onLogout());
  };

  return (
    <div className={classes.root}>
      <div className={classes.userInfo}>
        <div
          className="pointer"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end"
          }}
        >
          <div className="mr-2">
            <Typography
              className={classes.userTitle}
              component="h2"
              variant="h2"
            >
              {auth.userData.role !== "Subadmin" && "NowWay Admin"}
              {auth.userData.role === "Subadmin" && "NowWay Sub-Admin"}
            </Typography>
            {auth.userData.role === "Subadmin" && (
              <Typography className={classes.userSubTitle}>
                {auth.userData?.email ? auth.userData?.email : ""}
              </Typography>
            )}
          </div>
        </div>
      </div>

      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
        >
          <Paper elevation={8}>
            <MenuList>
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <div className="ml-2">Logout</div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}
    </div>
  );
};

export default SidebarHeader;
