import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MoreHoriz, Close, Done } from "@material-ui/icons";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import CmtAvatar from "@coremat/CmtAvatar";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const UserListRow = ({ row, page, idx, changeStatus }) => {
  const classes = useStyles();
  const getUserActions = () => {
    const actions = [
      { action: "View", label: "View", icon: <VisibilityIcon /> }
    ];
    if (row.is_verify === "0") {
      actions.push(
        {
          action: "Approve",
          label: "Approve",
          icon: <Done />
        },
        { action: "rejected", label: "REJECT", icon: <Close /> }
      );
    }

    return actions;
  };
  const onUserMenuClick = (menu, hdsg, row) => {
    changeStatus(menu.action, menu.label, hdsg.id, hdsg);
  };

  const labelId = `enhanced-table-checkbox-${row.user_id}`;
  const userActions = getUserActions(row.status, row.verified);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
      <TableCell>{page * 10 + idx + 1}</TableCell>
      <TableCell>{row.user_id}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar
              size={40}
              src={`${IMAGE_URL}${row.user_data.profile_url}`}
              alt={row.user_data.name}
            />
          </Box>
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.user_data.email === null ? "N/A" : row.user_data.email}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row.user_data.name}</TableCell>
      <TableCell>{row.user_data.username}</TableCell>
      <TableCell>{row.hiring_charge}</TableCell>
      <TableCell>
        {row.is_verify === "0"
          ? "Pending"
          : row.is_verify === "1"
          ? "Verified"
          : "Unverified"}
      </TableCell>
      <TableCell>{moment(row.created_at).format("lll")}</TableCell>

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          row={row}
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
