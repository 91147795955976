import React, { useState, useEffect } from 'react';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import Box from '@material-ui/core/Box';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { useDropzone } from 'react-dropzone';
import CmtImage from '../../../../@coremat/CmtImage';
import { useDispatch, useSelector } from 'react-redux';
import { createPost } from '../../../../redux/actions/WallApp';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtList from '../../../../@coremat/CmtList';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import { user } from '@fake-db/apps/wall';
import { Fab, Typography } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import RewardManagementServicesAuth from 'services/auth/Rewards';

const useStyles = makeStyles(() => ({
  textFieldRoot: {
    '& .MuiInput-underline': {
      '&:before, &:after': {
        display: 'none',
      },
    },
  },
  iconSm: {
    fontSize: 16,
  },
  gridThumb: {
    width: 60,
    height: 60,
    objectFit: 'cover',
    borderRadius: 4,
  },
  titleRoot: {
    // letterSpacing: 0.25,
    fontSize: 14,
    // color: theme.palette.primary.main,
    cursor: 'pointer',
    // padding: '20',
    color: 'black',
    marginLeft: '10px',
  },
}));

const UploadRewardList = ({ page, perPage, order }) => {
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  const csv = useSelector(state => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  //   const { userDetail } = useSelector(({ wallApp }) => wallApp);
  const userDetail = user;
  const [content, setContent] = useState('');
  const [contentError, setContentError] = useState('');

  const [attachments, setAttachments] = useState([]);

  useEffect(() => {}, [csv.rewardsManagement]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv', //'image/*, .pdf',
    multiple: false,
    onDrop: acceptedFiles => {
      const files = acceptedFiles.map(file => {
        const reader = new FileReader();
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
        };
        let dd = reader.readAsArrayBuffer(file);

        return {
          id: Math.floor(Math.random() * 10000),
          path: file.path,
          metaData: { type: file.type, size: file.size },
          preview: URL.createObjectURL(file),
        };
      });
      onAddAttachments(files);
    },
  });

  const onAddAttachments = async files => {
    // setAttachments([...attachments, ...files]);
    setAttachments([...files]);
    const file2 = document.getElementById('fileItem').files[0];
    let formdata = new FormData();
    await formdata.append('image', file2);
    dispatch(RewardManagementServicesAuth.uploadCSV(token, formdata));
  };

  const handlePostSubmit = () => {
    if (!content) {
      setContentError('');
    } else {
      let data = {
        file_name: csv.rewardsManagement?.csvFile?.key,
        comment: content,
      };
      dispatch(RewardManagementServicesAuth.uploadAirdropCSV_URL(token, data, page, perPage, order));
      // const post = {
      //   content,
      //   attachments,
      //   owner: {
      //     name: userDetail.name,
      //     profile_pic: userDetail.profile_pic,
      //     id: userDetail.id,
      //   },
      // };
      // dispatch(createPost(post));
      setAttachments([]);
      setContent('');
    }
  };

  return (
    <CmtCard>
      <Box width={1}>
        <CmtCardContent>
          <Box display="flex">
            {/* <CmtAvatar size={40} src={userDetail.profile_pic} alt={userDetail.name} /> */}
            <Box ml={4} flex={1}>
              <AppTextInput
                placeholder="Enter comment here."
                multiline
                rows={5}
                fullWidth
                value={content}
                className={classes.textFieldRoot}
                onChange={e => {
                  setContent(e.target.value.replace(/^\s/, ''));
                }}
                helperText={contentError}
              />
            </Box>
          </Box>
          {/* <Box mt={2}>
            <CmtList
              data={attachments}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              renderRow={(item, index) => (
                <Box p={1} key={index}>
                  <CmtImage className={classes.gridThumb} src={item.preview} />
                </Box>
              )}
            />
          </Box> */}
          <Box mt={2} display="flex">
            <Box
              {...getRootProps()}
              mr={{ xs: 0, md: 5 }}
              display="flex"
              alignItems="center"
              color="text.enabled" // "text.disabled"
              fontSize={12}
              className="pointer">
              <input id="fileItem" {...getInputProps()} />
              {/* <CloudUpload className={classes.iconSm} />{' '}
              <Box color="black" ml={3}>
                Add csv file
              </Box> */}

              <Fab variant="extended" size="medium" color="primary" aria-label="add" className={classes.margin}>
                {/* <CloudUpload className={classes.extendedIcon} margin="200px" /> */}
                Add csv file
              </Fab>

              <Typography component="div" variant="h4" className={classes.titleRoot}>
                {attachments.length === 0 ? '' : attachments[0].path}
                {/* //attachments[0].metaData.path */}
              </Typography>
            </Box>
            <Box ml="auto">
              <Button
                size='"medium"'
                color="primary"
                variant="contained"
                disabled={attachments.length === 0 || content === ''} //{!content.trim() && attachments.length === 0}
                onClick={handlePostSubmit}>
                Upload
              </Button>
            </Box>
          </Box>
        </CmtCardContent>
      </Box>
    </CmtCard>
  );
};

export default UploadRewardList;
