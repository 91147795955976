import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./index.style";
import { API_URL } from "constant/constant";
import axios from "axios";
import { fetchError } from "redux/actions";
import { DownLoadCSVFunction } from "customHook/debounse";

const UserTableToolbar = ({ count }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token);
  const DownLoadeCSV = async count => {
    try {
      await axios({
        method: "GET",
        url: `${API_URL}admin/influencer_apply/list`,
        headers: {
          Authorization: token
        },
        params: {
          perPage: count,
          page: 1
        }
      }).then(({ data }) => {
        if (data.data) {
          let arrayOfObjects = data.data;
          if (!arrayOfObjects.length) {
            return;
          }
          DownLoadCSVFunction(arrayOfObjects, "influencer_lists");
        } else {
          dispatch(fetchError(data.message));
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          Influencer List
        </Typography>

        <React.Fragment>
          <div>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: "260px" }}
              onClick={() => {
                if (count > 0) {
                  DownLoadeCSV(count);
                }
              }}
            >
              Download CSV File
            </Button>
          </div>
        </React.Fragment>
      </Toolbar>
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchText: PropTypes.string,
  handleSearchTextChange: PropTypes.func,
  onUserAdd: PropTypes.func
};

export default React.memo(UserTableToolbar);
