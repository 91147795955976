import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";
import { AuhMethods } from "services/auth";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import RejectDialog from "../RejectDialog";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

const VerificationModal = ({ open, onCloseDialog, selectedUser }) => {
  const classes = useStyles();
  const currentUser = {};
  const [InputReasonError, setInputReasonError] = useState("");
  const token = useSelector(state => state.admin.authUser.access_token);
  const [openApproveConfirm, setOpenApproveConfirm] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [InputReason, setInputReason] = useState("");

  const dispatch = useDispatch();
  const handleOpenConfirm = event => {
    event.stopPropagation();
    setOpenApproveConfirm(true);
  };

  const handleOpenReject = event => {
    event.stopPropagation();
    setOpenRejectDialog(true);
  };

  const handleApproveConfirm = action => {
    const params = {
      id: selectedUser.id,
      action: action,
      comment: InputReason
    };
    dispatch(AuhMethods["usersModule"].VerificationProcessAPI(token, params));
    setInputReason("");
    onCloseDialog(true);
  };

  const handleApproveCancel = () => {
    onCloseDialog(true);
  };
  const handleRejectCancel = () => {
    onCloseDialog(true);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseDialog}
        className={`${classes.dialogRoot} dialogCustom`}
      >
        <DialogTitle className={classes.dialogTitleRoot}>
          {" "}
          <span>{"Verification"} </span>
          <IconButton
            aria-label="close"
            onClick={onCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#000"
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            mb={{ xs: 6, md: 5 }}
          ></Box>

          <Box display="flex" justifyContent="flax-start" mb={4}>
            <Typography
              className={classes.title}
              variant="h5"
              id="tableTitle"
              component="div"
              style={{ marginBottom: 20 }}
            >
              User Name: {selectedUser?.requested_user_detail?.username}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flax-start" mb={4}>
            <Typography
              className={classes.title}
              variant="h5"
              id="tableTitle"
              component="div"
              style={{ marginBottom: 20 }}
            >
              Reason Of Verification : {selectedUser?.why_do_you_want_verified}
            </Typography>
          </Box>

          {Array.isArray(selectedUser?.request_genres) ? (
            selectedUser?.request_genres?.map((x, index) => (
              <Box display="flex" justifyContent="flax-start" mb={4}>
                <Typography
                  className={classes.title}
                  variant="h5"
                  id="tableTitle"
                  component="div"
                  key={index}
                  style={{ marginBottom: 20 }}
                >
                  Genre :{" "}
                  {x?.genre_detail?.name ? x?.genre_detail?.name : "N/A"}
                </Typography>
              </Box>
            ))
          ) : (
            <Box display="flex" justifyContent="flax-start" mb={4}>
              <Typography
                className={classes.title}
                variant="h5"
                id="tableTitle"
                component="div"
                style={{ marginBottom: 20 }}
              >
                Genre : {"N/A"}
              </Typography>
            </Box>
          )}

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={event => {
                handleOpenConfirm(event);
              }}
            >
              {"Approve"}
            </Button>

            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={event => handleOpenReject(event)}
              >
                {"Reject"}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {openApproveConfirm && (
        <ConfirmDialog
          open={openApproveConfirm}
          title={`Confirm to Approve the user`}
          content={`Are you sure, you want to Approve this user?`}
          onClose={handleApproveCancel}
          onConfirm={() => handleApproveConfirm(1)}
        />
      )}
      {openRejectDialog && (
        <RejectDialog
          open={openRejectDialog}
          title={`Confirm to Reject the user`}
          setInputReason={setInputReason}
          InputReason={InputReason}
          setInputReasonError={setInputReasonError}
          content={`Why you want to  reject this user?`}
          onConfirm={() => handleApproveConfirm(2)}
          onClose={handleRejectCancel}
        />
      )}
    </>
  );
};

export default VerificationModal;

VerificationModal.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
