import React, { useContext, useState } from "react";
import PostsList from "./PostsList";
import { getWallHeight } from "../../../../@jumbo/constants/AppConstants";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PerfectScrollbar from "react-perfect-scrollbar";
import AppContext from "../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext";
import PostsTabOptions from "./PostsTabOptions";
import CreatePost from "./CreatePost";

const useStyles = makeStyles(() => ({
  perfectScrollbarNewsFeed: {
    height: props => `calc(100vh - ${props.height}px)`
  }
}));

const NewsFeed = ({
  width,
  onChangeTab,
  handleScroll,
  postData,
  type,
  pageProfileData
}) => {
  const { showFooter } = useContext(AppContext);
  const classes = useStyles({
    height: getWallHeight(width, showFooter)
  });
  const [tabValue, setTabValue] = useState("created_at");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    onChangeTab(newValue);
  };
  const onScroll = e => {
    let el = document.getElementById("kk");
    if (
      parseInt(el.scrollHeight - el.scrollTop) === parseInt(el.clientHeight)
    ) {
      handleScroll();
    }
  };

  return (
    <PerfectScrollbar
      id="kk"
      onScroll={onScroll}
      className={classes.perfectScrollbarNewsFeed}
    >
      {pageProfileData?.creator_type == "admin" && <CreatePost />}

      <PostsTabOptions
        classes={classes}
        userDetail={{ name: "abc" }}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
      />
      <PostsList postData={postData} type={type} />
    </PerfectScrollbar>
  );
};

export default NewsFeed;
