import { EDIT_SETTING_TYPE, ADD_SETTING_TYPE, GET_SETTING_LIST } from '@jumbo/constants/ActionTypes';

export const getSettingList = data => {
  return dispatch => {
    dispatch({
      type: GET_SETTING_LIST,
      payload: data,
    });
  };
};

export const addSettingType = data => {
  return dispatch => {
    dispatch({
      type: ADD_SETTING_TYPE,
      payload: data,
    });
  };
};

export const editSettingType = data => {
  return dispatch => {
    dispatch({
      type: EDIT_SETTING_TYPE,
      payload: data,
    });
  };
};
