import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { AuhMethods } from "services/auth";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";

const FollowingInfo = ({ userDetail }) => {
  const [confirm, setConfirm] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(state => state.admin.authUser.access_token); //Fetch data from Redux
  const { status, user_id } = userDetail;

  useEffect(() => {}, [userDetail]);

  const chnageUserStatus = () => {
    const params = {
      user_id: user_id,
      action: status == "1" ? "0" : "1",
      order: "desc",
      perPage: 10
    };

    dispatch(
      AuhMethods["usersModule"].changeUserStatusAPI(token, params, "Profile")
    );
    setTimeout(() => {
      dispatch(
        AuhMethods["usersModule"].userProfileAPI({
          token: token,
          userId: params.user_id
        })
      );
    }, 1000);
  };

  const handleCancelBlock = () => {
    setConfirm(false);
  };

  const handleConfirmBlock = () => {
    setConfirm(false);
    chnageUserStatus();
  };
  return (
    <Box mb={4}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setConfirm(true);
        }}
      >
        {status == "0" ? "Unblock" : "Block"}
      </Button>
      <ConfirmDialog
        open={confirm}
        title={`${status == "0" ? "Unblock" : "Block"}`}
        content={`Are you sure, you want to ${
          status == "0" ? "Unblock" : "Block"
        }?`}
        onClose={handleCancelBlock}
        onConfirm={handleConfirmBlock}
      />
    </Box>
  );
};

export default FollowingInfo;

FollowingInfo.prototype = {
  userDetail: PropTypes.object.isRequired
};
